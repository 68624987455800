import React from 'react';
import styled from 'styled-components';
import { gradientColors, GradientColorsType } from '../../Shared/colors';

export type BadgeSizeType = 's' | 'm';

type Props = {
  content: string | number;
  size?: BadgeSizeType;
  fontColor?: string;
  icon?: React.ReactNode;
  backgroundColor?: string;
  gradientColor?: GradientColorsType;
  gradientColorsArray?: string[];
};

const BadgeContainer = styled.div<{
  gradientColor?: GradientColorsType;
  gradientColorsArray?: string[];
  backgroundColor?: string;
}>`
  display: flex;
  align-items: center;
  border-radius: 50px;
  padding: 4px 8px;
  line-height: 1.5;
  height: fit-content;
  background-color: ${({ backgroundColor }) => backgroundColor || 'unset'};
  background-image: ${({ gradientColor, gradientColorsArray }) => {
    if (gradientColor)
      return `linear-gradient(116deg, ${gradientColors[gradientColor][0]}, ${gradientColors[gradientColor][1]} 87%)`;
    if (gradientColorsArray) return `linear-gradient(116deg, ${gradientColorsArray[0]}, ${gradientColorsArray[1]} 87%)`;
  }};
`;

const Content = styled.div<{ size: BadgeSizeType; color: string }>`
  text-align: center;
  width: max-content;
  color: ${({ color }) => color || '#fff'};
  font-size: ${({ size }) => {
    if (size === 's') return '8px';
    if (size === 'm') return '12px';
  }};
`;

const Badge: React.FC<Props> = ({
  gradientColor,
  gradientColorsArray,
  content,
  icon,
  size = 'm',
  backgroundColor,
  fontColor,
}) => {
  return (
    <BadgeContainer
      gradientColor={gradientColor}
      backgroundColor={backgroundColor}
      gradientColorsArray={gradientColorsArray}
    >
      {icon && icon}
      <Content size={size} color={fontColor}>
        {content}
      </Content>
    </BadgeContainer>
  );
};

export default Badge;
