import { WheelMemberRoleType } from '../../../../API/interfaces';

export const NotificationsActionTypes = {
  GET_NOTIFICATIONS: 'GET_NOTIFICATIONS',
  MARK_ALL_NOTIFICATIONS_AS_READ: 'MARK_ALL_NOTIFICATIONS_AS_READ',
  MARK_NOTIFICATION_AS_READ: 'MARK_NOTIFICATION_AS_READ',
};

export type NotificationType = 'MemberJoined' | 'CheckInCreated' | 'CommentCreated';

export const NotificationTypes = {
  memberJoined: 'MemberJoined',
  checkInCreated: 'CheckInCreated',
  commentCreated: 'CommentCreated',
};

export type Notification = {
  createdAt: string;
  id: string;
  initiator: string;
  /**
   * user who created main comment/checkIn
   **/
  initiatorId: string;
  initiatorRole?: WheelMemberRoleType;
  /**
   * user who created reply to main comment/checkIn
   **/
  recipientId?: string;
  read: boolean;
  type: NotificationType;
  wheelId: string;
  comment?: string;
  commentId?: string;
  score?: number;
  wheel?: string;
  checkinCommentId?: string;
};

export type NotificationsReducerState = {
  notificationsList: Notification[];
};
