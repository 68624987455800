import React from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import CompleteCheckinPic from '../../../Assets/images/template_infographic/complete_checkin.png';
import CompleteCheckinPic2 from '../../../Assets/images/template_infographic/complete_checkin2.png';
import GetMoving from '../../../Assets/images/template_infographic/get_moving.png';
import ReflectAndImprove from '../../../Assets/images/template_infographic/reflect_and_improve.png';
import AllGetInvitationsPic from '../../../Assets/images/template_infographic/all_get_invitations.png';
import InviteTeachersPic from '../../../Assets/images/template_infographic/invite_teachers.png';
import InviteTeamMembersPic from '../../../Assets/images/template_infographic/invite_team_members.png';
import LeadTheProcessPic from '../../../Assets/images/template_infographic/lead_the_process.png';

import { AccountPlan, AccountRole, AccountType } from '../../../API/interfaces';

interface iInfographicProps {
  step: number;
  accountPlan?: AccountPlan;
  accountRole?: AccountRole;
  accountTag?: AccountType;
}

export default ({ step, accountPlan, accountRole, accountTag }: iInfographicProps) => {
  const classes = useStyles();
  const Number = () => <div className={classes.number}>{step}</div>;

  switch (step) {
    case 1:
      return (
        <div className={classes.step}>
          <Number />
          <Typography className={classes.bigText}>
            {accountTag === AccountType.ATHLETIC_WEST
              ? `Choose one of the wheel templates by finishing this statement. ‘I am here to…’`
              : `Use a template or create a template yourself`}
          </Typography>
        </div>
      );
    case 2:
      if (accountTag === AccountType.ATHLETIC_WEST) {
        return (
          <div className={`${classes.step} ${classes.smallStep}`}>
            <Number />
            <div>
              <Typography className={classes.mediumText}>Complete Check In</Typography>
              <Typography className={classes.smallText}>
                Purposeful check in's gives you an opportunity to reflect and understand what is going on in your life.
                It is a safe space for you to be honest and start working on your wellbeing.
              </Typography>
              <img className={classes.img} src={CompleteCheckinPic2} width={220} height={114} />
            </div>
          </div>
        );
      }
      if (accountRole === AccountRole.ADMIN && accountPlan === AccountPlan.SCHOOL) {
        return (
          <div className={`${classes.step} ${classes.smallStep}`}>
            <Number />
            <div>
              <Typography className={classes.mediumText}>Invite Teachers</Typography>
              <Typography className={classes.smallText}>
                Edit segment titles or descriptions to be ideal for your community.
              </Typography>
              <img className={classes.img} src={InviteTeachersPic} width={220} height={114} />
            </div>
          </div>
        );
      } else if (accountRole === AccountRole.ADMIN && accountPlan !== AccountPlan.SCHOOL) {
        return (
          <div className={`${classes.step} ${classes.smallStep}`}>
            <Number />
            <div>
              <Typography className={classes.mediumText}>Invite Team Members</Typography>
              <Typography className={classes.smallText}>
                Edit segment titles or descriptions to be ideal for your community.
              </Typography>
              <img className={classes.img} src={InviteTeamMembersPic} width={220} height={114} />
            </div>
          </div>
        );
      } else {
        return (
          <div className={`${classes.step} ${classes.smallStep}`}>
            <Number />
            <div>
              <Typography className={classes.mediumText}>Complete Check In</Typography>
              <Typography className={classes.smallText}>
                Edit segment titles or descriptions to be ideal for your community.
              </Typography>
              <img className={classes.img} src={CompleteCheckinPic} width={220} height={114} />
            </div>
          </div>
        );
      }
    case 3:
      if (accountTag === AccountType.ATHLETIC_WEST) {
        return (
          <div className={`${classes.step} ${classes.smallStep}`}>
            <Number />
            <div>
              <Typography className={classes.mediumText}>Get Moving</Typography>
              <Typography className={classes.smallText}>
                Whether it's running or walking, make sure you get up and active for at least 30 minutes to help
                strengthen your physical and mental health.
              </Typography>
              <img className={classes.img} src={GetMoving} width={220} height={170} />
            </div>
          </div>
        );
      }
      return (
        <div className={`${classes.step} ${classes.smallStep}`}>
          <Number />
          <div>
            <Typography className={classes.mediumText}>Check if all get invitations</Typography>
            <Typography className={classes.smallText}>
              Generate your unique invitation link and share with people who you wish to check in on.
            </Typography>
            <img className={classes.img} src={AllGetInvitationsPic} width={220} height={114} />
          </div>
        </div>
      );
    case 4:
      if (accountTag === AccountType.ATHLETIC_WEST) {
        return (
          <div className={`${classes.step} ${classes.smallStep}`}>
            <Number />
            <div>
              <Typography className={classes.mediumText}>
                Reflect on how you got active and improve you wellbeing
              </Typography>
              <Typography className={classes.smallText}></Typography>
              <div style={{ height: '80px' }}></div>
              <img className={classes.img} src={ReflectAndImprove} width={220} height={114} />
            </div>
          </div>
        );
      }
      return (
        <div className={`${classes.step} ${classes.smallStep}`}>
          <Number />
          <div>
            <Typography className={classes.mediumText}>Lead the process</Typography>
            <Typography className={classes.smallText}>
              Track and monitor your community over time with our data visualisation tools.
            </Typography>
            <img className={classes.img} src={LeadTheProcessPic} width={220} height={114} />
          </div>
        </div>
      );
    default:
      return null;
  }
};

const useStyles = makeStyles({
  number: {
    minWidth: '32px',
    maxWidth: '32px',
    minHeight: '32px',
    maxHeight: '32px',
    borderRadius: '50%',
    lineHeight: '32px',
    fontSize: '12px',
    letterSpacing: '.86px',
    textAlign: 'center',
    backgroundImage: 'linear-gradient(134deg, #fa8868 1%, #ff554d)',
    color: '#ffffff',
    fontWeight: 'bold',
  },
  step: {
    display: 'flex',
    justifyContent: 'flex-start',
    marginTop: '40px',
  },
  smallStep: {
    width: '30%',
    '& > div:last-child': {
      marginLeft: '15px',
    },
    '@media(max-device-width: 768px)': {
      width: '100%',
    },
  },
  bigText: {
    marginLeft: '18px',
    fontSize: '24px',
    fontWeight: 600,
    lineHeight: 1.33,
    color: '#2b2e31',
  },
  mediumText: {
    fontSize: '16px',
    lineHeight: 1.5,
    color: '#2b2e31',
  },
  smallText: {
    fontSize: '14px',
    lineHeight: 1.43,
    color: '#7f8b96',
  },
  img: {
    marginTop: '8px',
  },
});
