import { InvitationActionTypes } from './invitation.model';

export const saveInvitationLink = function (link: string) {
  return {
    type: InvitationActionTypes.SAVE_INVITATION_LINK,
    payload: link,
  };
};

export const resetInvitationLink = function () {
  return {
    type: InvitationActionTypes.RESET_INVITATION_LINK,
  };
};
