export type ReportAccessType = 'identified' | 'anonymous';

export const reportTypeTooltipText = {
  identified:
    'This will generate an identified report, showcasing a summary of relevant wheel data and listing wheel members by name.',
  anonymous: 'This will generate an anonymous report, removing wheel members names.',
};

export const reportTypeValues = {
  identified: 'identified',
  anonymous: 'anonymous',
};
