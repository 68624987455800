export enum SupportCompanyType {
  AdultSupport = 'Adult Support',
  OtherResources = 'Other Resources',
  YouthSupport = 'Youth Support',
}

export interface SupportCompany {
  name: string;
  description: string;
  type: SupportCompanyType;
  link: string;
  image: string;
  phone?: string;
}
