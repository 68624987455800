import { makeStyles, TextField } from '@material-ui/core';
import { analytics } from '../../../analytics/analytics';
import React, { useState } from 'react';
import { FiCheck, FiCopy } from 'react-icons/fi';
import styled from 'styled-components';
import { WheelMemberRole, WheelMemberRoleType } from '../../../API/interfaces';

type Props = {
  url: string;
  inviteRole: WheelMemberRoleType;
};

export const LinkInput: React.FC<Props> = ({ url, inviteRole }) => {
  const [copied, setCopied] = useState(null);
  const classes = useStyles();

  const copyFallback = (url: string): Promise<void> =>
    new Promise((resolve, reject) => {
      const textArea = document.createElement('textarea');
      textArea.value = url;
      textArea.style.position = 'fixed';
      textArea.style.top = '0';
      textArea.style.left = '0';
      textArea.style.width = '2em';
      textArea.style.height = '2em';
      textArea.style.padding = '0';
      textArea.style.border = 'none';
      textArea.style.outline = 'none';
      textArea.style.boxShadow = 'none';
      textArea.style.background = 'transparent';

      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();

      const isSuccessful = document.execCommand('copy');
      document.body.removeChild(textArea);

      isSuccessful ? resolve() : reject();
    });

  const copyToClipboard = (url: string) => {
    if (navigator.clipboard) {
      navigator.clipboard.writeText(url).catch(() => copyFallback(url));
    } else {
      copyFallback(url);
    }
  };

  const share = () => {
    inviteRole === WheelMemberRole.ACCOUNTABILITY_BUDDY ? analytics.clickInviteBuddy() : analytics.shareWheel();
    copyToClipboard(url);
    setCopied(true);
  };

  return (
    <InputWrapper className={classes.wrapper}>
      <TextField
        className={classes.textField}
        value={url}
        label={'URL Link'}
        variant="outlined"
        fullWidth={true}
        multiline={false}
        InputProps={{
          readOnly: true,
        }}
      />
      <CopyIcon copied={copied} onClick={share}>
        {copied ? <FiCheck size={20} stroke="#fff" /> : <FiCopy size={20} stroke="#fff" />}
      </CopyIcon>
    </InputWrapper>
  );
};

const InputWrapper = styled.div`
  position: relative;
  cursor: pointer;
`;

const CopyIcon = styled.div<any>`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  aspect-ratio: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  background-image: ${({ copied }) =>
    copied ? 'linear-gradient(175deg, #1cefef 3%, #009b8c)' : 'linear-gradient(175deg, #fa8868 3%, #ff554d)'};
  cursor: pointer;
`;

const useStyles = makeStyles<any>({
  textField: {
    width: '100%',
    borderColor: '#cececf',
    '& .MuiOutlinedInput-root.Mui-focused': {
      backgroundColor: '#f8f8f8',
    },
    '& input, textarea': {
      paddingRight: '70px',
      color: '#2b2e31',
    },
  },
});
