import { ArrowLeft } from 'react-feather';
import { BlackBtn, BlackButtonBox, ContentBox, LeftPanel, RedBtn } from '../Onboarding/_styled';
import { Text } from '../../Shared/Typography/Typography';
import 'dayjs/locale/en-gb';
import { analytics } from '../../../analytics/analytics';
import { TextField } from '@material-ui/core';
const AgeForm = ({ formik, prevStep }) => {
  const addPersonalData = () => {
    analytics.clickOnbPersonalDataAdded();
  };
  const isAgeValid = formik.values.age >= 6 && formik.values.age <= 99;
  return (
    <LeftPanel style={{ width: '100%' }}>
      <BlackButtonBox style={{ width: '100%' }}>
        <BlackBtn onClick={prevStep} style={{ color: '#2b2e31' }}>
          <ArrowLeft size={13} /> BACK
        </BlackBtn>
      </BlackButtonBox>
      <ContentBox style={{ width: '100%' }}>
        <Text weight={600} size="36px">
          What is your age?
        </Text>
        <form onSubmit={formik.handleSubmit} style={{ width: '200px' }}>
          <TextField
            id="age"
            name="age"
            label="Enter Your Age"
            type="number"
            variant="outlined"
            fullWidth
            inputProps={{ min: 6, max: 99 }}
            value={formik.values.age ?? ''}
            onChange={(e) => formik.setFieldValue('age', e.target.value === '' ? null : Number(e.target.value))}
            onBlur={formik.handleBlur('age')}
            error={formik.touched.age && Boolean(formik.errors.age)}
            helperText={formik.touched.age && formik.errors.age}
            className="mb-4"
          />
          {isAgeValid && (
            <RedBtn type="submit" onClick={addPersonalData}>
              Sign Up
            </RedBtn>
          )}
        </form>
      </ContentBox>
    </LeftPanel>
  );
};
export default AgeForm;
