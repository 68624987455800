import React, { useState } from 'react';
import { DataGrid, GridColDef, GridRowsProp } from '@mui/x-data-grid';
import { Text } from '../../../Shared/Typography/Typography';
import { BlackBtn } from '../../../Auth/Onboarding/_styled';
import { Button } from '../../../Shared/Buttons/Button';
import styled from 'styled-components';
import deleteIcon from '../../../../Assets/images/onboarding/trash-2.svg';
import plusIcon from '../../../../Assets/images/onboarding/plus.svg';
import { X } from 'react-feather';
import services from '../../../../API/services';
import { toasterService } from '../../../Shared/Toaster/Toaster.service';

const CustomTable = ({ handlers }) => {
  const [isLoading, setIsLoading] = useState(false);
  const {
    setUserData,
    data,
    setData,
    onInvitationsSent,
    validateRows,
    fileName,
    setFileName,
    errors,
    setErrors,
    onNext,
    onBack,
    wheelId,
    setCurrentStep,
  } = handlers;

  const handleSendInvitations = async () => {
    setIsLoading(true);
    const failedInvites = [];

    for (const member of data) {
      const response = await services.addManualUserToWheel(wheelId, member);
      if (response.status !== 200) {
        failedInvites.push(member.email);
      }
    }

    setIsLoading(false);

    if (failedInvites?.length === 0) {
      toasterService.addSuccessToast('All invitations sent successfully');
      onInvitationsSent();
      onNext();
    } else {
      toasterService.addErrorToast(`Failed to send invitations to: ${failedInvites.join(', ')}`);
    }
  };

  const handleRemoveFile = () => {
    setData([]);
    setFileName('');
    setUserData([]);
    setErrors([]);
    setCurrentStep(2);
  };

  const handleAddRow = () => {
    const newRow = { firstName: '', lastName: '', email: '' };
    const newData = [...data, newRow];
    setData(newData);
    setUserData(newData);
    validateRows(newData);
  };

  const handleProcessRowUpdate = (newRow) => {
    const updatedRows = data.map((row, index) =>
      index + 1 === newRow.id
        ? { ...row, firstName: newRow.firstName, lastName: newRow.lastName, email: newRow.email }
        : row
    );
    setData(updatedRows);
    setUserData(updatedRows);
    validateRows(updatedRows);
    return newRow;
  };

  const handleDeleteRow = (id) => {
    const newData = data.filter((_, index) => index !== id - 1);
    setData(newData);
    setUserData(newData);
    setErrors((prevErrors) => prevErrors.filter((error) => error.index !== id - 1));
  };

  const rows: GridRowsProp = data.map((row, index) => ({
    id: index + 1,
    firstName: row.firstName,
    lastName: row.lastName,
    email: row.email,
  }));
  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: '#',
      width: 50,
      editable: false,
      sortable: false,
      resizable: false,
      align: 'center',
      headerAlign: 'center',
    },
    { field: 'firstName', headerName: 'First Name', width: 100, editable: true, sortable: false, resizable: false },
    { field: 'lastName', headerName: 'Last Name', width: 106, editable: true, sortable: false, resizable: false },
    { field: 'email', headerName: 'Email', width: 205, editable: true, resizable: false, sortable: false },
    {
      field: 'actions',
      headerName: '',
      width: 50,
      resizable: false,
      sortable: false,
      align: 'center',
      renderCell: (params) => (
        <img
          src={deleteIcon}
          onClick={() => handleDeleteRow(params.id)}
          style={{ width: '14px', height: '14px', cursor: 'pointer' }}
          alt="Delete"
        />
      ),
    },
  ];

  const allFieldsFilled = data?.every((row) => row.firstName && row.lastName && row.email);
  const rowHeight = 52;
  const headerHeight = 58;
  const tableHeight = data?.length ? rowHeight * data?.length + headerHeight : 0;

  return (
    <div className="d-flex flex-column">
      <Text size="14px" className="mb-3">
        Step 3/3
      </Text>
      <FileNameContainer>
        <Text>{fileName}</Text>
        <X size={16} color="#ff0000" style={{ cursor: 'pointer', marginLeft: '10px' }} onClick={handleRemoveFile} />
      </FileNameContainer>

      <div style={{ height: tableHeight, marginTop: '20px' }}>
        <DataGrid
          rows={rows}
          columns={columns}
          processRowUpdate={handleProcessRowUpdate}
          onProcessRowUpdateError={(error) => console.error('Error updating row: ', error)}
          hideFooterPagination
          hideFooter
          autoPageSize
          disableColumnMenu
          editMode="row"
          getCellClassName={(params) => {
            const rowIndex = Number(params.id) - 1;
            const error = errors.find((error) => error.index === rowIndex);
            return error?.fields[params.field] ? 'Mui-error-cell' : '';
          }}
          sx={tableStyles}
          experimentalFeatures={{ newEditingApi: true }}
        />
      </div>

      {errors?.length > 0 && (
        <div className="mt-2 d-flex justify-content-center">
          <Text size="14px" color="#ff0000">
            Some cells are empty or have invalid data. Please fix the highlighted cells.
          </Text>
        </div>
      )}

      <div style={{ marginTop: '20px' }}>
        <button className="edit-button" onClick={handleAddRow}>
          <img src={plusIcon} alt="Edit" style={{ width: '14px', height: '14px', cursor: 'pointer' }} />
          Add Row
        </button>
      </div>

      <div className="d-flex justify-content-end mt-4" style={{ gap: '15px' }}>
        <BlackBtn onClick={onBack}>Cancel</BlackBtn>
        <Button
          onClick={handleSendInvitations}
          disabled={!allFieldsFilled || isLoading || errors?.length > 0}
          style={{ height: '40px', lineHeight: '40px' }}
        >
          {isLoading ? 'SENDING...' : 'SEND INVITATIONS'}
        </Button>
      </div>
    </div>
  );
};

export default CustomTable;

const FileNameContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 20px;
`;

const tableStyles = {
  borderRadius: 0,
  border: 0,
  '& .MuiDataGrid-iconSeparator': {
    display: 'none',
  },
  '& .MuiDataGrid-columnHeaders': {
    border: 0,
  },
  '& .MuiDataGrid-columnHeader, .MuiDataGrid-cell': {
    borderLeft: '1px solid #f0f0f0',
    padding: '0 7px',
  },
  '& .MuiDataGrid-columnHeader:focus, .MuiDataGrid-cell:focus': {
    outline: 'none',
  },
  '& .MuiDataGrid-columnHeader': {
    borderTop: '1px solid #f0f0f0',
    borderBottom: '1px solid #f0f0f0',
    backgroundColor: 'rgba(106, 119, 127, 0.2)',
  },
  '& .MuiDataGrid-columnHeadersInner': {
    borderRight: '1px solid #f0f0f0',
  },
  '& .MuiDataGrid-columnsContainer, .MuiDataGrid-cell': {
    borderBottom: '1px solid #f0f0f0',
  },
  '& .MuiDataGrid-cell': {
    color: 'rgba(0,0,0,.85)',
  },
  '& .MuiDataGrid-cell:last-child': {
    display: 'none',
  },
  '& .MuiDataGrid-cell:nth-last-of-type(2)': {
    borderRight: '1px solid #f0f0f0',
  },
  '& .MuiDataGrid-row.Mui-selected': {
    backgroundColor: 'transparent',
  },
  '& .MuiDataGrid-row:hover, .Mui-selected:hover': {
    backgroundColor: 'rgba(25, 118, 210, 0.08)',
  },
  '& .Mui-error-cell': {
    backgroundColor: 'rgba(255, 0, 0, 0.1)',
  },
};
