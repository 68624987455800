import styled from 'styled-components';
import { FiArrowRight } from 'react-icons/fi';
import { useHistory } from 'react-router-dom';

export const LineLink = ({ children, link = null }) => {
  const history = useHistory();

  const redirectTo = () => {
    if (link) {
      history.push(link);
    }
  };

  return (
    <Line onClick={redirectTo}>
      {children}
      <FiArrowRight size={18} />
    </Line>
  );
};

const Line = styled.div<any>`
  height: 40px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 16px 10px 24px;
  background-color: ${(props) => props.bColor};
  cursor: pointer;
  border-radius: ${(props) => props.radius};
`;

Line.defaultProps = {
  bColor: '#f8f8f8',
  radius: '0px 0px 4px 4px',
};
