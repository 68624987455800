import { useEffect, useState } from 'react';
import { ClickAwayListener, TextField } from '@material-ui/core';
import { Text } from '../Shared/Typography/Typography';
import arrowLeft from '../../Assets/images/arrow-left.png';
import aiMessage from '../../Assets/images/aiMessage.png';
import send from '../../Assets/images/send.svg';
import disabled from '../../Assets/images/disabled.svg';
import services from '../../API/services';
import { connect } from 'react-redux';
import { iCheckinsAggregation, iUser, iWheel } from '../../API/interfaces';
import { TemplatesList } from './TemplatesList';
import plusIcon from '../../Assets/images/AI/plus.png';
import './AI.scss';
import { SpinnerBackground, Spinner } from '../Shared/Spinner/Spinner';
import ChatsList from './ChatsList';
import TypingEffect from './TypingEffect';
import WheelImage from './WheelImage';
import { ChevronDown as ChevronDownIcon, ChevronUp as ChevronUpIcon } from 'react-feather';
import utils from '../Shared/utils';
import {
  AIChatContainer,
  AIChatwrapper,
  AiContainer,
  AiMsg,
  BackToWheel,
  BtnContainer,
  Button,
  ChatContent,
  ContentWrapper,
  Conversation,
  Description,
  Initials,
  MobileSidebar,
  Replica,
  ShowBtn,
  SidebarWrapper,
  StartChat,
  StartDecs,
} from './_styled';
import { UserIpAddressType } from '../Auth/Auth.reducer';
import { getUserOnboardingData } from './helpers';

interface iAIProps {
  isOpen: boolean;
  isPublic?: boolean;
  onClose: () => void;
  wheel: iWheel;
  user: iUser;
  checkin: iCheckinsAggregation | null;
  userIpAddress: UserIpAddressType;
}

const AI = ({ isOpen, isPublic, onClose, wheel, checkin, ...props }: iAIProps) => {
  const [value, setValue] = useState('');
  const [messages, setMessages] = useState(null);
  const [activeChat, setActiveChat] = useState(null);
  const [chats, setChats] = useState(null);
  const [title, setTitle] = useState('');
  const [firstQuestion, setFirstQuestion] = useState('');
  const [isTemplateSelected, setIsTemplateSelected] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showChat, setShowChat] = useState(false);
  const [sidebarExpanded, setSidebarExpanded] = useState(false);
  const [isTypingEffect, setIsTypingEffect] = useState(true);

  const { user } = props;

  const timestamp = checkin?.comments ? Object.keys(checkin.comments)[0] : null;
  const generalComment = timestamp ? checkin.comments[timestamp][0].comment : '';

  let segmentsDataPromt = '';
  checkin?.scores?.forEach((item, index) => {
    let comment = '';
    for (let key in item.scoreComments) {
      if (item.scoreComments[key][0].comment !== null) {
        comment = item.scoreComments[key][0].comment;
        break;
      }
    }
    if (comment !== '') {
      segmentsDataPromt += `[Segment ${index + 1}: ${wheel.segments[index].name} - Score: ${
        item.score
      }, Comment: ${comment}]`;
      if (index !== checkin?.scores.length - 1) {
        segmentsDataPromt += ', ';
      }
    } else {
      segmentsDataPromt += `[Segment ${index + 1}: ${wheel.segments[index].name} - Score: ${item.score}]`;
      if (index !== checkin?.scores.length - 1) {
        segmentsDataPromt += ', ';
      }
    }
  });
  if (generalComment !== '') segmentsDataPromt += `. General comment: "${generalComment}"`;

  const userCountry = props?.userIpAddress.country || 'Australia';
  const userName = !isPublic ? `User's name is ${user?.first_name}` : '';
  const personalData = user?.personalData;
  const userTag = !isPublic ? `tagged as ${personalData?.userTag}` : '';

  const onboardingData = getUserOnboardingData(personalData);
  const userContext = {
    promt: value,
    context: `A user ${userTag} has completed a multi-segment check-in with the following scores: ${segmentsDataPromt}. The user has expressed a need for support. Considering the range of scores and comments provided across these segments, identify the areas of concern and provide a comprehensive list of resources, support options, and strategies that can help improve the user's well-being in the lower-scoring areas. ${onboardingData} Please write a cohesive response outlining the suggestions. This should be written as if it were being shown to the users themselves. This user is - ${userTag} and the response should be written as if it were being addressed to a ${userTag} user. For concise and precise responses, provide the best areas of support. Keep it between 400-500 words, and avoid being overly wordy. When providing your response, limit the suggestions to external parties, unless government led or non for profit organisations, additionally base your responded on the location of the users - for example this user is from the country ${userCountry}. ${userName} User's question to you: ${value}. Please do not show any notes or mention anything from the prompt I've written in your message back to the user. Please sign off on every message as "the iyarn team" after you. Additionally, if there are any comments, via the segment comments or general comments, please add more weighting to these within your response as this shows the users engagement.`,
  };

  const createNewChat = async () => {
    if (value.trim() !== '') {
      try {
        setShowChat(true);
        setMessages([{ content: value, role: 'user' }]);
        setLoading(true);
        const chatData = isTemplateSelected
          ? await services.createChat(wheel?.id, firstQuestion, title, value, isPublic)
          : await services.createChat(wheel?.id, userContext.context, userContext.promt, userContext.promt, isPublic);

        setIsTypingEffect(true);
        setActiveChat(chatData.data.id);
        setLoading(false);
        setValue('');
      } catch (error) {
        console.error('Error creating new chat:', error);
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    if (activeChat) {
      (async () => {
        const chatHistory = await services.getChatHistory(activeChat);
        const updatedChatHistory = [
          { ...chatHistory?.data?.messages[0], content: chatHistory.data.firstQuestion },
          ...chatHistory?.data?.messages.slice(1),
        ];
        setMessages(updatedChatHistory);
        setLoading(false);
      })();
    }
  }, [activeChat, isTypingEffect]);

  const fetchRecentChats = async () => {
    if (isPublic) {
      setChats([]);
      return;
    }

    const recentChats = await services.getRecentChats(wheel?.id);
    setChats(recentChats.data);
  };

  useEffect(() => {
    if (activeChat || isTypingEffect) fetchRecentChats();
  }, [isTypingEffect, activeChat]);

  const closeDrawer = () => {
    isOpen && onClose();
  };

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'visible';
    }

    return () => {
      document.body.style.overflow = 'visible';
    };
  }, [isOpen]);

  const onToggleMobileSideBar = () => {
    setSidebarExpanded(!sidebarExpanded);
  };

  const initials = props?.user ? props?.user.first_name.charAt(0) + props?.user.last_name.charAt(0) : 'AU';

  const conversation = messages?.map((replica, idx) => {
    return (
      <Replica key={idx}>
        {replica.role === 'user' ? (
          <Initials>{initials}</Initials>
        ) : (
          <AiMsg>
            <img src={aiMessage} alt={idx} />
          </AiMsg>
        )}
        <div className="replica">
          {idx === 0 && <Text size="14px">"{wheel.name}"</Text>}
          {idx === 0 && (
            <WheelImage
              wheel={wheel}
              scores={checkin?.scores.map((item, idx) => ({
                segmentId: item.segmentId,
                score: item.score,
                description: '',
                segmentName: wheel.segments[idx].name,
              }))}
              activeSegmentIndex={null}
              sizes={[550, 270]}
            />
          )}
          {replica.role === 'user' ? (
            <Text size="14px">{replica.content}</Text>
          ) : isTypingEffect ? (
            <TypingEffect text={replica.content} />
          ) : (
            <Text size="14px">{replica.content}</Text>
          )}
        </div>
      </Replica>
    );
  });

  const handleExistingChatClick = (chat) => {
    setValue('');
    setIsTemplateSelected(false);
    setLoading(true);
    setActiveChat(chat.id);
    setLoading(false);
    setShowChat(true);
    setIsTypingEffect(false);
  };

  const handleTemplateItemClick = (templateValue: string) => {
    setValue(templateValue);
  };

  const handleNewChatClick = () => {
    setValue('');
    setIsTemplateSelected(false);
    fetchRecentChats();
    setActiveChat(null);
    setShowChat(false);
    setIsTypingEffect(true);
  };

  return (
    <ClickAwayListener onClickAway={closeDrawer}>
      <AiContainer isOpen={isOpen} className="ai-container">
        {!isPublic && (
          <SidebarWrapper expanded={sidebarExpanded}>
            {utils.checkIsPhone() && (
              <MobileSidebar>
                <Text size="14px" color="#fff">
                  Your Chats
                </Text>
                <ShowBtn onClick={onToggleMobileSideBar}>
                  {sidebarExpanded ? <ChevronUpIcon size={16} /> : <ChevronDownIcon size={16} />}
                </ShowBtn>
              </MobileSidebar>
            )}
            <BtnContainer>
              <Button
                variant="contained"
                startIcon={<img src={plusIcon} width="16" />}
                onClick={() => handleNewChatClick()}
                disabled={!activeChat}
                id="new-chat"
              >
                New Chat
              </Button>
            </BtnContainer>
            <Text className="mt-4 mb-3" size="12px" color="#6a777f" style={{ marginLeft: '32px' }}>
              Recent
            </Text>
            <ChatsList chats={chats} activeChat={activeChat} handleExistingChatClick={handleExistingChatClick} />
          </SidebarWrapper>
        )}
        <ContentWrapper>
          {!isPublic && (
            <BackToWheel onClick={closeDrawer}>
              <img src={arrowLeft} alt="arrow-left" width={12} height={9} />
              <Text size="14px" color="#2b2e31">
                Back to Wheel
              </Text>
            </BackToWheel>
          )}
          <AIChatContainer>
            <AIChatwrapper>
              <ChatContent>
                {!showChat ? (
                  <StartChat>
                    <Description>
                      <AiMsg>
                        <img src={aiMessage} alt="ai-chat" />
                      </AiMsg>
                      <StartDecs>
                        {isPublic ? (
                          <Text size="30px" mSize="24px" color="#0C2337">
                            Hey, this is AI <br />
                            I'm here to help you.
                          </Text>
                        ) : (
                          <Text size="30px" mSize="24px" color="#0C2337">
                            {`Hey ${props?.user?.first_name},`} <br /> We're here to help you
                          </Text>
                        )}
                        <Text size="14px" color="#0C2337">
                          Tell me what’s on your mind, or pick a suggestion 👇
                        </Text>
                      </StartDecs>
                    </Description>

                    <TemplatesList
                      setValue={handleTemplateItemClick}
                      setFirstQuestion={setFirstQuestion}
                      setTemplateSelected={setIsTemplateSelected}
                      setTitle={setTitle}
                      user={user}
                      userCountry={userCountry}
                      segmentsData={segmentsDataPromt}
                      isPublic={isPublic}
                    />
                  </StartChat>
                ) : (
                  <Conversation>
                    {loading ? (
                      <>
                        {conversation}
                        <SpinnerBackground style={{ height: '80px', marginTop: '50px' }}>
                          <Spinner />
                        </SpinnerBackground>
                      </>
                    ) : (
                      conversation
                    )}
                  </Conversation>
                )}
              </ChatContent>
              {activeChat === null ? (
                <ChatContent id="chat-content">
                  <TextField
                    fullWidth
                    value={value}
                    onChange={(e) => setValue(e.target.value)}
                    multiline={true}
                    className="mt-3 mb-3"
                    id="outlined-basic"
                    label={isTemplateSelected ? 'iyarn Message' : 'Your Message'}
                    variant="outlined"
                    minRows={1}
                    maxRows={5}
                    InputProps={{
                      readOnly: isTemplateSelected,
                      endAdornment: (
                        <img
                          src={value.trim() === '' ? disabled : send}
                          alt="send"
                          width="40"
                          height="40"
                          style={{ cursor: 'pointer', marginLeft: '8px', alignSelf: 'end' }}
                          onClick={createNewChat}
                        />
                      ),
                    }}
                  />
                </ChatContent>
              ) : (
                <Text size="14px" color="#7f8b96" className="text-center p-4 p-sm-3 p-md-4">
                  You can send only one question at a time to help us provide you with the best possible answer.
                </Text>
              )}
            </AIChatwrapper>
          </AIChatContainer>
        </ContentWrapper>
      </AiContainer>
    </ClickAwayListener>
  );
};

const mapStateToProps = (state) => ({
  user: state.userRD.user,
  userIpAddress: state.userRD.userIpAddress,
});

export default connect(mapStateToProps)(AI);
