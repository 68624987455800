import React from 'react';
import styled, { css } from 'styled-components';

const DEFAULT_EXPANDABLE_WRAPPER_WIDTH = '94px';

type tExpandablePropsT = {
  width?: string;
  children?: any;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
  onClick?: (e) => void;
  isExpendedOnActive?: boolean;
  containerHeight?: string;
  containerWidth?: string;
  inactiveContainerStyle?: string;
};

export const Expandable = (props: tExpandablePropsT) => {
  const {
    children,
    onMouseEnter,
    onMouseLeave,
    onClick,
    width,
    isExpendedOnActive,
    containerHeight,
    containerWidth,
    inactiveContainerStyle,
  } = props;
  const [expanded, setExpanded] = React.useState(false);

  const onClickInner = React.useCallback((e) => {
    onClick && onClick(e);
  }, []);
  const onMouseEnterInner = React.useCallback(() => {
    setExpanded(true);
    onMouseEnter && onMouseEnter();
  }, []);
  const onMouseLeaveInner = React.useCallback(() => {
    setExpanded(false);
    onMouseLeave && onMouseLeave();
  }, []);

  return (
    <ExpandableWrapper
      width={width}
      expanded={expanded}
      onClick={(e) => onClickInner(e)}
      onMouseEnter={onMouseEnterInner}
      onMouseLeave={onMouseLeaveInner}
      isExpendedOnActive={isExpendedOnActive}
      containerHeight={containerHeight}
      containerWidth={containerWidth}
      inactiveContainerStyle={inactiveContainerStyle}
    >
      {children}
    </ExpandableWrapper>
  );
};

const ExpandableWrapper = styled.div<{
  expanded: boolean;
  width: string;
  isExpendedOnActive: boolean;
  inactiveContainerStyle?: string;
  containerHeight?: string;
  containerWidth?: string;
}>`
  height: ${({ containerHeight }) => containerHeight || '32px'};
  width: ${({ containerWidth }) => containerWidth || '32px'};
  background-color: #2a3e50;
  border-radius: 50px;
  padding: 0;
  transition: width 0.2s ease-in-out;
  overflow: hidden;
  position: relative;
  color: #fff;

  ${({ inactiveContainerStyle }) =>
    inactiveContainerStyle &&
    css`
      ${inactiveContainerStyle}
    `}

  &:hover {
    padding: 0 12px;
    width: ${({ width }) => (width ? width : DEFAULT_EXPANDABLE_WRAPPER_WIDTH)};
    background-color: #2a3e50;
    color: #fff;
  }

  & + & {
    margin-left: 8px;
  }

  ${({ isExpendedOnActive, width }) =>
    isExpendedOnActive &&
    css`
      padding: 0 12px;
      width: ${width ? width : DEFAULT_EXPANDABLE_WRAPPER_WIDTH};
      background-color: #2a3e50;
      color: #fff;
    `}
`;
