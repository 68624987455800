import { ShareActions } from './JoinWheel.actions';

const initialState = {
  joinParams: '',
};

function JoinWheelReducer(state = initialState, action) {
  switch (action.type) {
    case ShareActions.SHARE:
      return {
        ...state,
        joinParams: action.payload,
      };
    default:
      break;
  }

  return state;
}

export default JoinWheelReducer;
