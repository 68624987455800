import React, { Component, createRef } from 'react';
import { Calendar as CalendarIcon } from 'react-feather';
import { DateTime } from 'luxon';
import { AggregationUnit } from '../../../../API/interfaces';
import DateRangeCalendar from './DateRangeCalendar/DateRangeCalendar';
import { CALENDAR_DATE_FORMAT } from '../../../../constants';
import './DateRangeDropdown.scss';

export type DateRangeLabel =
  | 'Latest check ins'
  | 'Latest weekly results'
  | 'Last week'
  | 'Last 28 days'
  | 'Last quarter'
  | 'Last year'
  | 'Custom range';

export interface iDateRangeOptionData {
  label: DateRangeLabel;
  from: DateTime | null;
  to: DateTime | null;
  unit: 'none' | 'day' | 'week' | 'month' | 'year';
}

interface iDateRangeDropdownProps {
  className?: string;
  onChange: (filter: iDateRangeOptionData) => void;
  activeOption: iDateRangeOptionData;
  isTeamResults: boolean;
}

interface iDateRangeDropdownState {
  showDropdown: boolean;
  showCalendar: boolean;
}

export default class extends Component<iDateRangeDropdownProps, iDateRangeDropdownState> {
  dropdownRef = createRef<HTMLDivElement>();
  static defaultProps = {
    isTeamResults: false,
  };

  constructor(props) {
    super(props);

    this.state = {
      showDropdown: false,
      showCalendar: false,
    };
  }

  getOptions = (): Array<iDateRangeOptionData> => {
    const today = DateTime.now();

    const options = [
      {
        label: 'Last week' as DateRangeLabel,
        from: today.minus({ days: 7 }),
        to: today,
        unit: 'day' as AggregationUnit,
      },
      {
        label: 'Last 28 days' as DateRangeLabel,
        from: today.minus({ days: 28 }),
        to: today,
        unit: 'week' as AggregationUnit,
      },
      {
        label: 'Last quarter' as DateRangeLabel,
        from: today.minus({ months: 3 }),
        to: today,
        unit: 'week' as AggregationUnit,
      },
      {
        label: 'Last year' as DateRangeLabel,
        from: today.minus({ months: 12 }),
        to: today,
        unit: 'month' as AggregationUnit,
      },
    ];

    options.unshift(
      this.props.isTeamResults
        ? { label: 'Latest weekly results' as DateRangeLabel, from: null, to: null, unit: 'week' as AggregationUnit }
        : { label: 'Latest check ins' as DateRangeLabel, from: null, to: null, unit: 'none' as AggregationUnit }
    );

    return options;
  };

  toggleDropdown = () => this.setState({ showDropdown: !this.state.showDropdown });

  toggleCalendar = () => this.setState({ showCalendar: !this.state.showCalendar, showDropdown: false });

  onClickOutside = (event) => {
    const { showDropdown } = this.state;
    if (showDropdown && !this.dropdownRef.current.contains(event.target)) {
      this.toggleDropdown();
    }
  };

  setActiveOption = (option) => {
    this.setState({ showDropdown: false });
    this.props.onChange(option);
  };

  componentDidMount() {
    document.addEventListener('mousedown', this.onClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.onClickOutside);
  }

  render() {
    const { className, activeOption } = this.props;
    const { showDropdown, showCalendar } = this.state;

    // TODO change to material
    return (
      <>
        <div className={`date-range-dropdown${className ? ` ${className}` : ''}`} ref={this.dropdownRef}>
          <div className={`dropdown-top${showDropdown ? ' opened' : ''}`} onClick={this.toggleDropdown}>
            <div className="active-option">
              <span className="label">{activeOption.label}</span>
              {activeOption.from && (
                <span className="date">
                  {`${activeOption.from.toFormat(CALENDAR_DATE_FORMAT)} - ${activeOption.to.toFormat(
                    CALENDAR_DATE_FORMAT
                  )}`}
                </span>
              )}
            </div>
            <div className={`arrow${showDropdown ? ' opened' : ''}`} />
          </div>

          {showDropdown && (
            <div className="dropdown-body">
              <div className="default-options">
                {this.getOptions().map((option) => (
                  <div className="option" onClick={() => this.setActiveOption(option)} key={option.label}>
                    <span className="label">{option.label}</span>
                    {option.from && (
                      <span className="date">
                        {`${option.from.toFormat(CALENDAR_DATE_FORMAT)} - ${option.to.toFormat(CALENDAR_DATE_FORMAT)}`}
                      </span>
                    )}
                  </div>
                ))}
              </div>

              <div className="custom" onClick={this.toggleCalendar}>
                <CalendarIcon size={16} color="#86919a" />
                <span>Custom Range</span>
              </div>
            </div>
          )}
        </div>

        {showCalendar && <DateRangeCalendar toggle={this.toggleCalendar} setActiveOption={this.setActiveOption} />}
      </>
    );
  }
}
