import React from 'react';

import { tIconProps } from '../_types';

export const SortIcon = ({ className }: tIconProps) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
    <defs>
      <filter id="ldad5s14ga" colorInterpolationFilters="auto">
        <feColorMatrix
          in="SourceGraphic"
          values="0 0 0 0 1.000000 0 0 0 0 1.000000 0 0 0 0 1.000000 0 0 0 1.000000 0"
        />
      </filter>
    </defs>
    <g fill="none" fillRule="evenodd">
      <g>
        <g>
          <g>
            <g>
              <g
                filter="url(#ldad5s14ga)"
                transform="translate(-1087.000000, -128.000000) translate(1039.000000, 108.000000) translate(0.000000, 12.000000) translate(40.000000, 0.000000) translate(8.000000, 8.000000)"
              >
                <path d="M0 0L16 0 16 16 0 16z" />
                <path
                  stroke="#2C3E50"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="1.333"
                  d="M2 6l2.667-2.667L7.333 6M4.667 3.333v9.334M14 10l-2.667 2.667L8.667 10m2.666 2.667V3.333"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);
