import React, { Component } from 'react';
import { iUser, iWheel } from '../../../../API/interfaces';
import { connect } from 'react-redux';
import { isWheelAdmin } from '../../../Shared/utils';
import { CheckinReminderAdmin } from './CheckinReminderAdmin';
import { CheckinReminderMember } from './CheckinReminderMember';
import { analytics } from '../../../../analytics/analytics';
import { iTabChildProps } from '../../../Shared/TabContainer/TabContainer';

interface iCheckinReminderProps extends iTabChildProps {
  wheel: iWheel;
  user: iUser;
}

interface iCheckinReminderState {
  isAdmin: boolean;
}

class CheckinReminderComponent extends Component<iCheckinReminderProps, iCheckinReminderState> {
  copy: any;

  constructor(props) {
    super(props);
    this.state = {
      isAdmin: isWheelAdmin(props.wheel, props.user._id),
    };
  }

  componentDidMount() {
    analytics.checkinReminderTab();
    this.props.setIsLoading(false);
  }

  render() {
    return this.state.isAdmin ? (
      <CheckinReminderAdmin wheel={this.props.wheel} />
    ) : (
      <CheckinReminderMember wheel={this.props.wheel} />
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.userRD.user,
});

export const CheckinReminder = connect(mapStateToProps)(CheckinReminderComponent);
