import React, { Component, SyntheticEvent } from 'react';
import './CountableTextBox.scss';

interface iCountableTextBoxProps {
  value: string;
  max: number;
  id?: string;
  multipleLine?: boolean;
  placeholder?: string;
  className?: string;
  onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  onClick?: (e: SyntheticEvent) => void;
  onBlur?: (e: SyntheticEvent) => void;
  onEnter?: (e: SyntheticEvent) => void;
  focusEnabled: boolean;
}

interface iCountableTextBoxState {
  isActive: boolean;
}

class CountableTextBox extends Component<iCountableTextBoxProps, iCountableTextBoxState> {
  readonly state = {
    isActive: false,
  };

  static defaultProps = {
    focusEnabled: true,
  };

  handleChange = (e) => {
    const { max, onChange } = this.props;
    const len = e.target.value.length;

    if (len <= max && onChange) {
      onChange(e);
    }
  };

  handleClick = (e) => {
    const { onClick } = this.props;
    onClick && onClick(e);
  };

  handleFocus = () => {
    this.setState({ isActive: true });
  };

  handleBlur = (e) => {
    const { onBlur } = this.props;

    this.setState({ isActive: false });

    onBlur && onBlur(e);
  };

  handleKeyDown = (e) => {
    const { onEnter } = this.props;
    if (e.keyCode === 13) {
      e.preventDefault();

      onEnter && onEnter(e);
    }
  };

  render() {
    const { isActive } = this.state;
    const { id, className, multipleLine, placeholder, value, max, focusEnabled } = this.props;
    const isFocused = isActive && focusEnabled;

    return (
      <div id={`${id}_wrapper`} className={`ctb-wrapper${className ? ` ${className}` : ''}`}>
        {multipleLine ? (
          <div className={`textarea-wrapper ${isFocused ? ' focused' : ''}`}>
            <textarea
              className="form-control"
              id={id}
              name={id}
              value={value}
              placeholder={placeholder}
              onClick={this.handleClick}
              onFocus={this.handleFocus}
              onBlur={this.handleBlur}
              onChange={this.handleChange}
            />
            <div className="ctb-postfix-br text-muted">
              {value.length}/{max}
            </div>
          </div>
        ) : (
          <>
            <input
              type="text"
              className="form-control"
              id={id}
              name={id}
              value={value}
              placeholder={placeholder}
              onClick={this.handleClick}
              onFocus={this.handleFocus}
              onBlur={this.handleBlur}
              onChange={this.handleChange}
              onKeyDown={this.handleKeyDown}
            />
            <div className="ctb-postfix-r text-muted">
              {value.length}/{max}
            </div>
          </>
        )}
      </div>
    );
  }
}

export default CountableTextBox;
