import React, { FC, useState } from 'react';
import { DateTime } from 'luxon';

import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import LuxonUtils from '@date-io/luxon';
import { makeStyles } from '@material-ui/core';

interface iCalendarProps {
  activeDate: DateTime;
  setNewDate?: (newDate: DateTime) => void;
  disabled: boolean;
}

const useStyles = makeStyles({
  root: {
    '& .MuiInputBase-root': {
      cursor: 'pointer',
    },
    '& .MuiInputBase-input': {
      cursor: 'pointer',
    },
  },
});

export const CheckinReminderCalendar: FC<iCalendarProps> = ({ activeDate, setNewDate, disabled }) => {
  const classes = useStyles();
  const firstAvailableDate = DateTime.local().set({
    hour: 0,
    minute: 0,
    second: 0,
    millisecond: 0,
  });

  const [inputValue, changeInputValue] = useState(activeDate);
  const [isOpen, setIsOpen] = useState(false);

  const handleDateChange = (value: DateTime) => {
    if (value && value.isValid) {
      changeInputValue(value);
      setNewDate(value);
    }
  };

  const tileDisabledCheck = (date) => date < firstAvailableDate;
  return (
    <>
      <MuiPickersUtilsProvider utils={LuxonUtils}>
        <KeyboardDatePicker
          className={classes.root}
          margin="none"
          id="date-picker-dialog"
          label="Date"
          format="dd LLLL yyyy"
          disabled={disabled}
          inputVariant="outlined"
          fullWidth={true}
          shouldDisableDate={tileDisabledCheck}
          inputValue={inputValue.toFormat('dd LLLL yyyy')}
          InputAdornmentProps={{ position: 'end' }}
          value={firstAvailableDate}
          onChange={handleDateChange}
          onClick={() => {
            if (!disabled) {
              setIsOpen(true);
            }
          }}
          open={isOpen}
          onAccept={(e) => {
            setIsOpen(false);
          }}
          onClose={() => {
            setIsOpen(false);
          }}
          // KeyboardButtonProps={{
          //   onFocus: (e) => {
          //     setIsOpen(true);
          //   },
          // }}
          DialogProps={{
            disableRestoreFocus: true,
          }}
          // InputProps={{
          //   onFocus: () => {
          //     setIsOpen(true);
          //   },
          // }}
        />
      </MuiPickersUtilsProvider>
    </>
  );
};
