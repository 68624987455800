import React, { useState } from 'react';
import { makeStyles, Menu, MenuItem } from '@material-ui/core';
import { Option, StyledCopyIcon, StyledEditIcon, StyledInviteIcon, StyledTrashIcon } from '../Card/_styled';

type tFolderContextMenuProps = {
  folderName: string;
  anchorEl: any;
  onRename: () => void;
  onDuplicate: () => void;
  onDelete: () => void;
  onClose: () => void;
  onInvite: () => void;
  isSubfolder: boolean;
};

export const FolderContextMenu = ({
  anchorEl,
  onClose,
  folderName,
  onRename,
  onDuplicate,
  onDelete,
  isSubfolder,
  onInvite,
}: tFolderContextMenuProps) => {
  return (
    <>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={onClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem onClick={onRename}>
          <StyledEditIcon size={16} />
          <Option>Rename Folder</Option>
        </MenuItem>
        <MenuItem onClick={onDuplicate}>
          <StyledCopyIcon />
          <Option>Duplicate</Option>
        </MenuItem>
        <MenuItem onClick={onInvite}>
          <StyledInviteIcon />
          <Option>Invite to {isSubfolder ? 'subfolder' : 'folder'}</Option>
        </MenuItem>
        <MenuItem onClick={onDelete}>
          <StyledTrashIcon />
          <Option color="#fb463b">Delete</Option>
        </MenuItem>
      </Menu>
    </>
  );
};
