import { useState, useEffect } from 'react';
import {
  BlackBtn,
  BlackButtonBox,
  BtnsGroup,
  CheckContainer,
  ContentBox,
  CustomOptionContainer,
  EmptyButton,
  LeftPanel,
  OptionButton,
  Options,
  OptionsContainer,
  RedBtn,
  RedButtonBox,
  Root,
  VioletBox,
  GoalsOptionsContainer,
  toolTipStyles,
} from './_styled';
import { ArrowLeft, ArrowRight, Check } from 'react-feather';
import { RightScreen } from './RightScreen';
import { TextField, Tooltip } from '@material-ui/core';
import plus from '../../../Assets/images/onboarding/plus.png';
import { Text } from './../../Shared/Typography/Typography';
import { useSelector } from 'react-redux';
import { RootState } from '../../../Redux/Reducers';
import { useHistory } from 'react-router-dom';
import { getNextStep, getPreviousStep } from './helpers';
import services from '../../../API/services';
import { StyledModal } from '../../Dashboard/Sidebar';
import { ModalBody, ModalFooter } from 'reactstrap';
import { toolTips } from './constants';
import alertIcon from '../../../Assets/images/onboarding/alert-circle.svg';
import { analytics } from '../../../analytics/analytics';

const QuestionScreen = ({
  data,
  onNext,
  onBack,
  userData,
  step,
  totalSteps,
  userType,
  personalData,
  updatePersonalDataAction,
}) => {
  const { id, name, question, options = [], canSkipQuestion } = data;
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [customOptions, setCustomOptions] = useState([]);
  const [customOption, setCustomOption] = useState('');
  const [showCreateFolderModal, setShowCreateFolderModal] = useState(false);

  const shareLinkParams = useSelector((state: RootState) => (state.shareLink ? state.shareLink.joinParams : ''));
  const history = useHistory();

  useEffect(() => {
    const initSelectedOptions = userData[name] || personalData[name] || [];
    const initCustomOptions = userData.customOptions?.[step] || personalData.customOptions?.[step] || [];

    setSelectedOptions(initSelectedOptions);
    setCustomOptions(initCustomOptions);
  }, [userData, personalData, name, step]);

  const handleCheckboxChange = (option) => {
    const maxSelections = userType === 'Education' && step === 0 ? 1 : step === 0 ? 3 : undefined;
    setSelectedOptions((prevSelected) =>
      prevSelected.includes(option)
        ? prevSelected.filter((item) => item !== option)
        : maxSelections !== undefined
        ? [...prevSelected, option].slice(0, maxSelections)
        : [...prevSelected, option]
    );
  };

  const handleCustomOptionAdd = () => {
    if (customOption && !selectedOptions.includes(customOption) && !customOptions.includes(customOption)) {
      setCustomOptions((prevOptions) => [...prevOptions, customOption]);
      setSelectedOptions((prevSelected) => {
        const maxSelections = userType === 'Education' && step === 0 ? 1 : step === 0 ? 3 : undefined;
        return maxSelections !== undefined
          ? [...prevSelected, customOption].slice(0, maxSelections)
          : [...prevSelected, customOption];
      });
      setCustomOption('');
    }
  };

  const handleNext = async () => {
    if (step === 0) analytics.clickOnbQuestionsStarted();
    if (step === totalSteps - 4) analytics.clickOnbQuestionsFinished();
    const updatedData = {
      [name]: selectedOptions,
      customOptions: { ...userData.customOptions, [step]: customOptions },
    };
    const updatedPersonalData = { ...personalData, ...updatedData };
    try {
      await services.updatePersonalData(updatedPersonalData);
      updatePersonalDataAction(updatedPersonalData);
      onNext(updatedData, getNextStep(step, userData, userType));
    } catch (error) {
      console.error('Failed to update personal data:', error);
    }
  };

  const handleBack = () => {
    const updatedData = {
      [name]: selectedOptions,
      customOptions: { ...userData.customOptions, [step]: customOptions },
    };
    onBack(updatedData, getPreviousStep(step, userData, userType));
  };

  const skipOnboarding = async () => {
    analytics.clickOnbSkipAll();
    await services.updatePersonalData(personalData);
    updatePersonalDataAction(personalData);
    history.push(shareLinkParams ? `/share${shareLinkParams}` : '/dashboard');
  };

  const skipQuestion = () => {
    onNext(userData, getNextStep(step, userData, userType));
  };

  const handleCloseModal = () => {
    setShowCreateFolderModal(false);
  };

  const OptionsComponent = name === 'goals' ? GoalsOptionsContainer : OptionsContainer;
  const formatOptionText = (option) => {
    if (name !== 'goals') return option;
    const [boldText, plainText] = option.split(':\n');
    return (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <b>{boldText}:</b>
        {plainText && <span>{plainText}</span>}
      </div>
    );
  };

  const classes = toolTipStyles();

  return (
    <Root>
      <LeftPanel>
        <BlackButtonBox>
          <BlackBtn onClick={handleBack}>
            <ArrowLeft size={13} /> BACK
          </BlackBtn>
          <BlackBtn onClick={() => setShowCreateFolderModal(true)}>SKIP ALL</BlackBtn>
          <StyledModal isOpen={showCreateFolderModal} centered>
            <ModalBody style={{ padding: '5px 2px' }}>
              <Text className="mb-4" weight={600} size="30px" color="#2b2e31">
                Are you sure you want to skip onboarding?
              </Text>
              <br />
              <Text size="14px">Setting up your profile will better inform your iyarn support resources</Text>
            </ModalBody>
            <ModalFooter style={{ borderTop: 'none', marginTop: '25px', padding: '5px 2px' }}>
              <BlackBtn onClick={() => setShowCreateFolderModal(false)}>Cancel</BlackBtn>
              <button onClick={skipOnboarding} style={{ marginLeft: '10px' }} className="exit-btn">
                Accept
              </button>
            </ModalFooter>
          </StyledModal>
        </BlackButtonBox>

        <ContentBox>
          <VioletBox>☝️ Don’t worry, only you can see your responses, we got you!</VioletBox>
          <Text size="14px">{`Step ${step + 1}/${totalSteps}`}</Text>
          <Text lineHeight="1.2" weight={600} size="36px">
            {question}
            {name !== 'userRole' && (
              <Tooltip arrow title={toolTips[name]} classes={{ tooltip: classes.tooltip, arrow: classes.arrow }}>
                <img src={alertIcon} style={{ marginLeft: '5px', width: '20px', height: '20px' }} alt="" />
              </Tooltip>
            )}
          </Text>
          <Text size="14px">
            {userType === 'Education' && step === 0
              ? 'Select 1 option'
              : step === 0
              ? 'You can select up to 3 options'
              : 'You can select unlimited options'}
          </Text>
          <Options>
            <OptionsComponent>
              {[...options, ...customOptions].map((option, index) => {
                return (
                  <OptionButton
                    key={index}
                    selected={selectedOptions.includes(option)}
                    onClick={() => handleCheckboxChange(option)}
                  >
                    <CheckContainer>{selectedOptions.includes(option) && <Check size={13} />}</CheckContainer>
                    {formatOptionText(option)}
                  </OptionButton>
                );
              })}
            </OptionsComponent>
            <CustomOptionContainer>
              <TextField
                fullWidth
                value={customOption}
                onChange={(e) => setCustomOption(e.target.value)}
                multiline={false}
                id="outlined-basic"
                label="Your option"
                variant="outlined"
                InputProps={{
                  endAdornment: customOption ? (
                    <img src={plus} alt="plus" width="52" height="52" onClick={handleCustomOptionAdd} />
                  ) : null,
                }}
              />
            </CustomOptionContainer>
          </Options>

          <RedButtonBox style={{ width: '100%' }}>
            <BtnsGroup>
              {selectedOptions.length > 0 ? (
                <RedBtn onClick={handleNext}>
                  Next <ArrowRight size={13} />
                </RedBtn>
              ) : (
                <EmptyButton style={{ height: '42px' }} />
              )}
              {canSkipQuestion && <BlackBtn onClick={skipQuestion}>SKIP QUESTION</BlackBtn>}
            </BtnsGroup>
          </RedButtonBox>
        </ContentBox>
      </LeftPanel>
      <RightScreen />
    </Root>
  );
};

export default QuestionScreen;
