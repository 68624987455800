export enum EducationStatus {
  REMAINING = 'REMAINING',
  PASSED = 'PASSED',
}

export enum EducationFeature {
  FIRST_VISIT_OF_DASHBOARD = 'FIRST_VISIT_OF_DASHBOARD',
  AFTER_FIRST_CHECKIN = 'AFTER_FIRST_CHECKING',
  MEMBERS_TAB = 'MEMBERS_TAB',
  FLOW = 'FLOW',
}
