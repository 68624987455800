import React, { useState, useMemo } from 'react';
import styled from 'styled-components';
import Badge, { BadgeSizeType } from '../../Shared/Badge';
import { FiArrowUp, FiArrowDown, FiAlertCircle } from 'react-icons/fi';
import { summaryBadgeTooltipText } from './utils';
import { GradientColorsType } from '../../Shared/colors';
import { Text } from '../../Shared/Typography/Typography';
import { makeStyles, Tooltip, ClickAwayListener } from '@material-ui/core';

type Props = {
  count: number;
  text: string;
  changePercentage: number;
};

const BadgeWrapper = styled.div`
  display: flex;
  padding: 12px;
  background-color: #f8f8f8;
  border-radius: 4px;
  margin-right: 12px;
  cursor: pointer;
`;

const TooltipContentWrapper = styled.div`
  display: inline;
`;

const SummaryBadge: React.FC<Props> = ({ count, text, changePercentage }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const ArrowUpIcon = <FiArrowUp size={10} color="#fff" className="mr-1" />;
  const ArrowDownIcon = <FiArrowDown size={10} color="#fff" className="mr-1" />;
  const isSmallScreen = window.innerWidth <= 768;

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const getBadgeProps = (countChange: number) => {
    if (countChange < 0)
      return {
        gradientColor: 'green' as GradientColorsType,
        icon: ArrowDownIcon,
        content: `${Math.round(Math.abs(+countChange) * 100)} %`,
        size: 's' as BadgeSizeType,
      };
    return {
      gradientColor: 'pinkOrange' as GradientColorsType,
      icon: countChange !== 0 ? ArrowUpIcon : null,
      content: `${Math.round(+countChange * 100)} %`,
      size: 's' as BadgeSizeType,
    };
  };

  const getTooltipContent = (summaryType: string, countChange: number) => {
    const type = summaryType === 'low scores' ? 'lowScores' : summaryType;
    let changeState = countChange < 0 ? 'negative' : 'positive';
    if (countChange === 0) changeState = 'stable';

    return (
      <TooltipContentWrapper>
        <FiAlertCircle size={16} color="#2b2e31" className="mr-1" />
        {summaryBadgeTooltipText[type][changeState]}
      </TooltipContentWrapper>
    );
  };

  const TooltipSummaryBadge = useMemo(
    () => (
      <Tooltip
        arrow
        title={getTooltipContent(text, changePercentage)}
        classes={{ tooltip: classes.tooltip, arrow: classes.arrow }}
        {...(isSmallScreen ? { open } : {})}
      >
        <BadgeWrapper {...(isSmallScreen ? { onClick: handleTooltipOpen } : {})}>
          <Text size="14px" weight="500" className="mr-2">
            {count}
          </Text>
          <Text size="14px" className={count ? 'mr-2' : ''}>
            {text}
          </Text>
          {count && <Badge {...getBadgeProps(changePercentage)} />}
        </BadgeWrapper>
      </Tooltip>
    ),
    [count, text, changePercentage, isSmallScreen, open]
  );

  return (
    <>
      {isSmallScreen ? (
        <ClickAwayListener onClickAway={handleTooltipClose}>{TooltipSummaryBadge}</ClickAwayListener>
      ) : (
        TooltipSummaryBadge
      )}
    </>
  );
};

const useStyles = makeStyles({
  tooltip: {
    backgroundColor: '#ffffff',
    color: '#2b2e31',
    fontSize: '14px',
    lineHeight: 1.43,
    padding: '16px 24px',
    boxShadow: '1px 5px 32px 0 rgba(0, 0, 0, 0.1)',
    maxWidth: '316px',
    fontFamily: 'Rubik',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
  },
  arrow: {
    color: '#fff',
  },
});

export default SummaryBadge;
