import styled from 'styled-components';

export const Divider = styled.div`
  display: flex;
  flex-shrink: 0;
  white-space: nowrap;
  margin: 20px 0 15px;
  &:before {
    position: relative;
    width: 100%;
    border-top: thin solid #dddfe1;
    top: 50%;
    content: '';
    transform: translateY(50%);
  }
  &:after {
    position: relative;
    width: 100%;
    border-top: thin solid #dddfe1;
    top: 50%;
    content: '';
    transform: translateY(50%);
  }
`;

export const DividerContent = styled.span`
  display: inline-block;
  padding-left: calc(8px * 1.2);
  padding-right: calc(8px * 1.2);
  color: #7f8b96;
`;
