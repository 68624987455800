export enum AuthActions {
  LOGIN = 'Log in',
  LOGOUT = 'Log out',
  ACCOUNT_PERMISSIONS = 'Set Account Permissions',
  TOKEN = 'TOKEN',
  GET_IP_ADDRESS = 'GET_IP_ADDRESS',
  HIDE_CHECKIN_ASSIST_INFO_MODAL = 'HIDE_CHECKIN_ASSIST_INFO_MODAL',
  UPDATE_PERSONAL_DATA = 'UPDATE_PERSONAL_DATA',
}

export const loginAction = function (user) {
  return {
    type: AuthActions.LOGIN,
    payload: user,
  };
};

export const setUpTokenAction = function (token) {
  return {
    type: AuthActions.TOKEN,
    payload: token,
  };
};

export const accountPermissionsAction = function (permissions) {
  return {
    type: AuthActions.ACCOUNT_PERMISSIONS,
    payload: permissions,
  };
};

export const logoutAction = () => {
  return {
    type: AuthActions.LOGOUT,
    payload: null,
  };
};

export const getIpAddressAction = function (payload) {
  return {
    type: AuthActions.GET_IP_ADDRESS,
    payload,
  };
};

export const hideCheckAssistInfoModalAction = () => {
  return {
    type: AuthActions.HIDE_CHECKIN_ASSIST_INFO_MODAL,
    payload: null,
  };
};

export const updatePersonalDataAction = (personalData) => ({
  type: AuthActions.UPDATE_PERSONAL_DATA,
  payload: personalData,
});
