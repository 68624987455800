import React, { useMemo, useState } from 'react';
import styled from 'styled-components';
import { Text } from '../../Shared/Typography/Typography';
import { MdKeyboardArrowUp, MdKeyboardArrowDown } from 'react-icons/md';
import { AiOutlineDownload } from 'react-icons/ai';
import { CgClose } from 'react-icons/cg';
import { Document, Page, pdfjs } from 'react-pdf';
import { InsightsCommentReplyType, InsightsCommentType } from '../../Insights/insights.model';
import { saveAs } from 'file-saver';

type Comment = {
  averageScore?: number;
  comment: string;
  commentId: string;
  date: string;
  replies?: Comment[];
  userId: string;
  attachment?: string;
};

type Pros = {
  reply: Comment | InsightsCommentType | InsightsCommentReplyType;
};

const AttachmentContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const ShowAttachmentButton = styled.div`
  display: flex;
  align-items: center;
  background-color: #f3f3f3;
  border-radius: 16px;
  padding: 2px 8px;
  width: fit-content;
  margin: 8px 0;
  cursor: pointer;
`;

const Attachment = styled.div<{ image?: string }>`
  display: flex;
  justify-content: end;
  width: 288px;
  height: 200px;
  border-radius: 4px;
  ${({ image }) => (image ? { cursor: 'pointer' } : {})};
  background-image: url(${({ image }) => image || 'unset'});
  position: relative;
  ${({ image }) => (image ? { 'background-size': 'cover' } : {})};
  overflow: hidden;

  &:after {
    content: '';
    display: block;
    position: absolute;
    height: 0%;
    width: 100%;
    top: 0;
    border-radius: 4px;
    transition: 0.2s ease-out;
    background: linear-gradient(to bottom, black 0%, transparent 50%);
  }
  &:hover:after {
    height: 100%;
  }
  &:hover {
    & > div {
      opacity: 1;
      display: flex;
    }
  }
`;

const PreviewHeader = styled.div`
  width: inherit;
  display: flex;
  justify-content: space-between;
  opacity: 0;
  padding: 12px;
  z-index: 1;
  transition: 0.2s ease-out;
  position: absolute;
  align-items: center;
`;

const DownloadImageButton = styled.div`
  height: fit-content;
  color: #fff;
  padding: 4px 10px;
  border-radius: 16px;
  background: #2a3e50;
  align-items: center;
  cursor: pointer;
`;

const OpenFileContainer = styled.div`
  height: 100%;
  width: 100%;
  position: fixed;
  display: flex;
  flex-direction: column;
  background-color: rgba(0, 0, 0, 0.7);
  padding: 32px;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 99;
  overflow: auto;
`;

const OpenFileHeader = styled.div`
  display: flex;
  justify-content: end;
  align-items: center;
`;

const CloseButtonContainer = styled.div`
  cursor: pointer;
`;

const OpenFileWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const OpenFilePreview = styled.img`
  height: 100%;
  width: fit-content;
`;

const OpenFileDownloadButton = styled.div`
  height: fit-content;
  color: #fff;
  padding: 4px 10px;
  border-radius: 16px;
  background: #2a3e50;
  align-items: center;
  margin: 12px;
  z-index: 1;
  transition: 0.2s ease-out;
`;

const FileLink = styled.a`
  text-decoration: none !important;
`;

const FileName = styled.div`
  overflow: hidden;
  color: #fff;
  font-size: 12px;
  width: 55%;
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const CommentAttachment: React.FC<Pros> = ({ reply }) => {
  const [showAttachment, setShowAttachment] = useState<boolean>(false);
  const [openFile, setOpenFile] = useState<string>('');
  const [isPDFLoaded, setIsPDFLoaded] = useState(false);

  // makes react-pdf library to work with correct version
  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

  const onDocumentLoadSuccess = () => {
    setIsPDFLoaded(true);
  };

  const attachment = useMemo(() => {
    if (!reply.attachment) return null;
    const fileName = reply.attachment.match(/\/([^/]+)_\d{4}-\d{2}-\d{2}T\d{2}%3A\d{2}%3A\d{2}.\d{3}Z$/)[1];
    const fileFormat = fileName.split('.').pop() || '';
    return {
      url: reply.attachment,
      name: fileName,
      type: fileFormat,
    };
  }, [reply]);

  const onFileDownload = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    e.preventDefault();
    const url = attachment.url;
    saveAs(url, attachment.name);
  };

  const isImage = attachment && ['png', 'jpeg', 'jpg'].includes(attachment.type);
  const isPDF = attachment && attachment.type === 'pdf';

  return (
    <>
      {attachment && (
        <AttachmentContainer>
          <ShowAttachmentButton onClick={() => setShowAttachment(!showAttachment)}>
            {showAttachment ? (
              <MdKeyboardArrowUp color="#005ebd" size={12} />
            ) : (
              <MdKeyboardArrowDown color="#005ebd" size={12} />
            )}
            <Text color="#005ebd" size="12px" margin="0 0 0 4px">
              Attachment
            </Text>
          </ShowAttachmentButton>
          {showAttachment && isImage && (
            <Attachment image={attachment.url} onClick={() => setOpenFile(attachment.url)}>
              <PreviewHeader>
                <FileName color="#fff">{attachment.name}</FileName>
                <DownloadImageButton onClick={onFileDownload}>
                  <AiOutlineDownload color="#fff" size={16} />
                  <Text size="14px" color="#fff" margin="0 0 0 4px">
                    Download
                  </Text>
                </DownloadImageButton>
              </PreviewHeader>
            </Attachment>
          )}
          {showAttachment &&
            isPDF &&
            (isPDFLoaded ? (
              <FileLink href={attachment.url} target="_blank">
                <Attachment>
                  <PreviewHeader>
                    <FileName color="#fff">{attachment.name}</FileName>
                    <DownloadImageButton onClick={onFileDownload}>
                      <AiOutlineDownload color="#fff" size={16} />
                      <Text size="14px" color="#fff" margin="0 0 0 4px">
                        Download
                      </Text>
                    </DownloadImageButton>
                  </PreviewHeader>
                  <Document file={attachment.url}>
                    <Page pageNumber={1} width={288} />
                  </Document>
                </Attachment>
              </FileLink>
            ) : (
              <Document file={attachment.url} onLoadSuccess={onDocumentLoadSuccess}>
                <Page pageNumber={1} width={288} />
              </Document>
            ))}
        </AttachmentContainer>
      )}
      {openFile && (
        <OpenFileContainer>
          <OpenFileHeader>
            <OpenFileDownloadButton onClick={onFileDownload}>
              <FileLink href={attachment.url} target="_blank" download rel="noreferrer">
                <AiOutlineDownload color="#fff" size={16} />
                <Text size="16px" color="#fff" margin="0 0 0 4px">
                  Download
                </Text>
              </FileLink>
            </OpenFileDownloadButton>
            <CloseButtonContainer onClick={() => setOpenFile('')}>
              <CgClose size="24px" color="#fff" />
            </CloseButtonContainer>
          </OpenFileHeader>
          <OpenFileWrapper>
            <OpenFilePreview src={openFile} />
          </OpenFileWrapper>
        </OpenFileContainer>
      )}
    </>
  );
};

export default CommentAttachment;
