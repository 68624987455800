import React from 'react';
import * as d3 from 'd3';
import { iScore } from '../../../../../API/interfaces';
import { DISABLED_GRADIENT, GRADIENTS } from '../../../../Shared/colors';
import './WheelSegment.scss';

interface iWheelSegmentProps {
  score: iScore;
  innerRadius: number;
  outerRadius: number;
  startAngle: number;
  endAngle: number;
  onClick?: () => void;
  onMouseOver?: () => void;
  onMouseOut?: () => void;
  isFocused: boolean;
  disabled: boolean;
  index: number;
  id: string;
}

export default ({
  score,
  innerRadius,
  outerRadius,
  startAngle,
  endAngle,
  onClick,
  isFocused,
  disabled,
  index,
  id,
  onMouseOut,
  onMouseOver,
}: iWheelSegmentProps) => {
  const d = d3.arc()({
    innerRadius: innerRadius,
    outerRadius: outerRadius,
    startAngle: (Math.PI / 180) * startAngle,
    endAngle: (Math.PI / 180) * endAngle,
  });
  const [startColor, endColor] = disabled ? DISABLED_GRADIENT : GRADIENTS[index % GRADIENTS.length];

  return (
    <g>
      <defs>
        <linearGradient x1={0} x2={0} y1={0} y2="100%" id={`${id}_${score.segmentId}_gradient`}>
          <stop offset="0" stopColor={startColor} stopOpacity={isFocused ? 1 : 0.5} />
          <stop offset="1" stopColor={endColor} stopOpacity={isFocused ? 0.7 : 0.3} />
        </linearGradient>
      </defs>
      <path
        className="wheel-segment-path"
        d={d}
        onClick={onClick}
        onMouseOver={onMouseOver}
        onMouseOut={onMouseOut}
        fill={`url(#${id}_${score.segmentId}_gradient)`}
      />
    </g>
  );
};
