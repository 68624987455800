import ChipInput from 'material-ui-chip-input';
import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core';
import { validateEmail } from '../Shared/utils';

interface InvitationInputProps {
  handleChips: (chips: string[]) => any;
  emails?: string[];
}

export const InvitationInput = ({ emails, handleChips }: InvitationInputProps) => {
  const classes = useStyles();
  const [chips, setChips] = useState([]);
  const [text, setText] = useState('');

  useEffect(() => {
    if (emails) {
      setChips(emails);
      handleChips(emails);
    }
  }, emails);

  const onPaste = (e) => {
    e.preventDefault();
    const raw = e.clipboardData.getData('Text');
    const elements = raw.replace(/\s+/g, '').split(',');
    const insert = elements.filter((e) => validateEmail(e)).filter((e) => chips.every((chip) => chip !== e));
    const insertAsText = elements.filter((e) => !validateEmail(e));
    insert.forEach((e) => handleAddChip(e));
    setText(insertAsText.join(','));
  };

  const handleAddChip = (chip) => {
    chips.push(chip);
    setChips([...chips]);
    setText('');
    handleChips([...chips]);
  };

  const handleDeleteChip = (chip, index) => {
    chips.splice(index, 1);
    setChips([...chips]);
    handleChips([...chips]);
  };

  const handleInput = (e) => {
    const text = e.target.value;
    setText(text);
  };

  const checkIfAdd = (chip) => validateEmail(chip) && chips.every((c) => c !== chip);

  return (
    <ChipInput
      variant="outlined"
      className="mt-3"
      classes={{
        inputRoot: classes.chipInputRoot,
        input: classes.chipInput,
        label: classes.label,
        chip: classes.chip,
      }}
      value={chips}
      inputValue={text}
      onUpdateInput={handleInput}
      onAdd={(chip) => handleAddChip(chip)}
      onDelete={(chip, index) => handleDeleteChip(chip, index)}
      onPaste={onPaste}
      onBeforeAdd={checkIfAdd}
      label="Email, Comma-seperated"
      fullWidth={true}
      fullWidthInput={true}
      allowDuplicates={false}
      newChipKeyCodes={[13, 32, 188]}
    />
  );
};

const useStyles = makeStyles({
  chipInputRoot: {
    paddingTop: '10px!important',
    backgroundColor: '#ffffff',
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: '#cececf!important',
    },
    '&.Mui-focused': {
      backgroundColor: '#f8f8f8',
    },
  },
  chipInput: {
    marginBottom: '8px!important',
    marginTop: '8px!important',
    paddingTop: '4px!important',
    paddingBottom: '8px!important',
    minWidth: '60px',
  },
  chip: {
    height: '32px',
    padding: '8px 12px 10px',
    borderRadius: '20px',
    border: 'solid 1px #cececf',
    backgroundColor: '#fff',
    marginRight: '10px',
    '&:hover': {
      backgroundColor: '#ffffff',
    },
    '& .MuiChip-label': {
      paddingLeft: '0px',
      paddingRight: '0px',
    },
    '& .MuiChip-deleteIcon': {
      margin: '0 0 0 3px',
    },
  },
  chipLabel: {
    display: 'none',
  },
  label: {
    transform: 'translate(15px, 17px) scale(1)',
  },
});
