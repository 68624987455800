import React, { Component } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { connect } from 'react-redux';
import { Typography, withStyles } from '@material-ui/core';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import { ChevronDown } from 'react-feather';
import services from '../../API/services';
import { analytics } from '../../analytics/analytics';
import {
  AccountPlan,
  AccountRole,
  AccountType,
  iAccount,
  iMember,
  iUser,
  iWheelDefaultTemplate,
  iWheelTemplate,
  WheelMemberRole,
} from '../../API/interfaces';
import { ManageContainer } from '../_Containers/ManageContainer';
import WheelTemplate from './WheelTemplate/WheelTemplate';
import CreateTemplate from './CreateTemplate/CreateTemplate';
import UseTemplateModal from './UseTemplate/UseTemplateModal';
import utils from '../Shared/utils';
import DeleteTemplateModal from './DeleteTemplate/DeleteTemplateModal';
import Infographic from './Infographic/Infographic';
import { makeSubtitleText, SeparatedTemplates, separateTemplates } from './template.utils';
import { DateTime } from 'luxon';

interface iWheelTemplatePageProps extends RouteComponentProps {
  user: iUser;
  classes: any;
}

interface iWheelTemplatePageState {
  separatedTemplates: SeparatedTemplates;
  ownTemplates: Array<iWheelTemplate>;
  accountTemplates: Array<iWheelTemplate>;
  templateToUse: iWheelTemplate | iWheelDefaultTemplate;
  templateToDelete: iWheelTemplate;
  wheelsCount: number;
  expandedTemplateId: string | null;
  accountTag: AccountType;
  accountRole: AccountRole;
  account: iAccount;
}

class WheelTemplatesPage extends Component<iWheelTemplatePageProps, iWheelTemplatePageState> {
  state = {
    separatedTemplates: null,
    accountTagDefaultTemplates: [],
    otherDefaultTemplates: [],
    ownTemplates: [],
    accountTemplates: [],
    templateToUse: null,
    templateToDelete: null,
    wheelsCount: null,
    expandedTemplateId: null,
    accountTag: null,
    accountRole: null,
    account: null,
  };

  loadData = async () => {
    try {
      utils.startLoading();

      const [defaultTemplates, ownTemplates, accountTemplates, wheelsResponse] = await Promise.all([
        services.getDefaultTemplates(),
        services.getOwnTemplates(),
        services.getAccountTemplates(),
        services.getWheelsByUserId(),
      ]);
      const separatedTemplates = separateTemplates(defaultTemplates);

      const accountTag = {
        [AccountType.PERSONAL]: AccountType.PERSONAL,
        [AccountType.PERSONAL_BUSINESS]: AccountType.PERSONAL,
        [AccountType.BUSINESS]: AccountType.BUSINESS,
        [AccountType.WORK]: AccountType.BUSINESS,
        [AccountType.SCHOOL]: AccountType.SCHOOL,
        [AccountType.SPORT]: AccountType.PERSONAL,
        [AccountType.WELLBEING]: AccountType.PERSONAL,
        [AccountType.MENTAL_HEALTH]: AccountType.PERSONAL,
        [AccountType.GT_CONNECT]: AccountType.GT_CONNECT,
        [AccountType.NO_STRESS]: AccountType.NO_STRESS,
        [AccountType.OTHER_PROGRAM]: AccountType.PERSONAL,
        //company templates
        [AccountType.ATHLETIC_WEST]: AccountType.ATHLETIC_WEST,
        [AccountType.SKILLHIRE]: AccountType.SKILLHIRE,
        [AccountType.WUNAN]: AccountType.WUNAN,
        [AccountType.METATTUDE]: AccountType.METATTUDE,
      }[this.props.user.accountTag || AccountType.PERSONAL];

      this.setState({
        accountTag,
        accountRole: this.props.user.accountRole,
        account: this.props.user.account,
        separatedTemplates,
        ownTemplates,
        accountTemplates,
        wheelsCount: wheelsResponse.data.length,
      });
    } catch (e) {
      // TODO handle error
    } finally {
      utils.endLoading();
    }
  };

  setTemplateToUse = (templateToUse: iWheelTemplate | iWheelDefaultTemplate) => {
    this.setState({ templateToUse });
    if ((templateToUse as iWheelTemplate)?.creator) {
      analytics.useOwnTemplate();
    } else if (templateToUse) {
      analytics.useDefaultTemplate(this.props.user.accountTag, templateToUse.name);
    }
  };

  setTemplateToDelete = (templateToDelete: iWheelTemplate) => this.setState({ templateToDelete });

  setExpandedTemplateId = (expandId: string) => {
    this.setState({ expandedTemplateId: this.state.expandedTemplateId === expandId ? null : expandId });
  };

  onCloseDeleteModal = (result: boolean) => {
    this.setState({ templateToDelete: null }, async () => {
      if (result) {
        await this.loadData();
      }
    });
  };

  async componentDidMount() {
    await this.loadData();
  }

  render() {
    const { classes, user, history } = this.props;
    const {
      separatedTemplates,
      ownTemplates,
      accountTemplates,
      templateToUse,
      templateToDelete,
      wheelsCount,
      expandedTemplateId,
      accountTag,
      accountRole,
      account,
    } = this.state;
    const showInfographics = wheelsCount < 1;

    const sortByNewest = (a, b) => DateTime.fromISO(b.createdAt).toMillis() - DateTime.fromISO(a.createdAt).toMillis();

    return (
      <ManageContainer showLoader={true} className={classes.manageContainer}>
        <div className={classes.root}>
          <Typography className={classes.title}>
            {showInfographics ? 'Lets start your interesting journey with iyarn' : 'Templates'}
          </Typography>
          {showInfographics && <Infographic step={1} accountTag={accountTag} />}
          <Typography className={classes.description}>
            Make life easier and start off a wheel using one of our pre-filled templates, you can edit it in settings
            once you create any of these.
          </Typography>

          <span className={classes.subtitle}>{makeSubtitleText(accountTag)}</span>

          <div className={classes.templates}>
            {separatedTemplates &&
              separatedTemplates[accountTag].map((template: iWheelDefaultTemplate, i) => {
                const expandId = template.name + i;
                return (
                  <WheelTemplate
                    key={expandId}
                    expandId={expandId}
                    expanded={expandId === expandedTemplateId}
                    onExpand={this.setExpandedTemplateId}
                    template={template}
                    setTemplateToUse={this.setTemplateToUse}
                    isTemplateAdmin={false}
                  />
                );
              })}

            {ownTemplates.map((template: iWheelTemplate) => {
              const isTemplateAdmin =
                template.members.find((m: iMember) => m.userId === user._id)?.memberRole === WheelMemberRole.ADMIN;
              const expandId = template.id;
              return (
                <WheelTemplate
                  key={expandId}
                  expandId={expandId}
                  onExpand={this.setExpandedTemplateId}
                  expanded={expandId === expandedTemplateId}
                  template={template}
                  setTemplateToUse={this.setTemplateToUse}
                  setTemplateToDelete={this.setTemplateToDelete}
                  isTemplateAdmin={isTemplateAdmin}
                  accountLogo={account?.logo}
                />
              );
            })}

            {accountTemplates
              .filter((template) => template.creator.id !== user._id)
              .map((template: iWheelTemplate) => {
                const expandId = template.id;
                return (
                  <WheelTemplate
                    key={expandId}
                    expandId={expandId}
                    onExpand={this.setExpandedTemplateId}
                    expanded={expandId === expandedTemplateId}
                    template={template}
                    setTemplateToUse={this.setTemplateToUse}
                    setTemplateToDelete={this.setTemplateToDelete}
                    isTemplateAdmin={false}
                    accountLogo={account?.logo}
                  />
                );
              })}

            {user.account &&
              user.account.plan !== AccountPlan.FREE &&
              user.accountTag !== AccountType.ATHLETIC_WEST &&
              user.accountTag !== AccountType.WUNAN && (
                <CreateTemplate
                  onClick={() => {
                    analytics.createTemplate();
                    history.push('/create-template');
                  }}
                />
              )}
          </div>

          <Accordion
            className={classes.otherTemplates}
            onChange={(_, expanded: boolean) => !expanded && this.setExpandedTemplateId(null)}
          >
            <AccordionSummary expandIcon={<ChevronDown size={16} />}>All templates</AccordionSummary>

            <AccordionDetails className="d-block">
              {accountTag !== AccountType.PERSONAL && (
                <div>
                  <span className={classes.subtitle}>{makeSubtitleText(AccountType.PERSONAL)}</span>

                  <div className={classes.templates}>
                    {separatedTemplates &&
                      separatedTemplates[AccountType.PERSONAL]
                        .sort(sortByNewest)
                        .map((template: iWheelDefaultTemplate, i) => {
                          const expandId = template.name + i;
                          return (
                            <WheelTemplate
                              key={expandId}
                              expandId={expandId}
                              expanded={expandId === expandedTemplateId}
                              onExpand={this.setExpandedTemplateId}
                              template={template}
                              setTemplateToUse={this.setTemplateToUse}
                              isTemplateAdmin={false}
                            />
                          );
                        })}
                  </div>
                </div>
              )}
              {accountTag !== AccountType.SCHOOL && (
                <div>
                  <span className={classes.subtitle}>{makeSubtitleText(AccountType.SCHOOL)}</span>

                  <div className={classes.templates}>
                    {separatedTemplates &&
                      separatedTemplates[AccountType.SCHOOL].map((template: iWheelDefaultTemplate, i) => {
                        const expandId = template.name + i;
                        return (
                          <WheelTemplate
                            key={expandId}
                            expandId={expandId}
                            expanded={expandId === expandedTemplateId}
                            onExpand={this.setExpandedTemplateId}
                            template={template}
                            setTemplateToUse={this.setTemplateToUse}
                            isTemplateAdmin={false}
                          />
                        );
                      })}
                  </div>
                </div>
              )}
              {accountTag !== AccountType.BUSINESS && (
                <div>
                  <span className={classes.subtitle}>{makeSubtitleText(AccountType.BUSINESS)}</span>

                  <div className={classes.templates}>
                    {separatedTemplates &&
                      separatedTemplates[AccountType.BUSINESS].map((template: iWheelDefaultTemplate, i) => {
                        const expandId = template.name + i;
                        return (
                          <WheelTemplate
                            key={expandId}
                            expandId={expandId}
                            expanded={expandId === expandedTemplateId}
                            onExpand={this.setExpandedTemplateId}
                            template={template}
                            setTemplateToUse={this.setTemplateToUse}
                            isTemplateAdmin={false}
                          />
                        );
                      })}
                  </div>
                </div>
              )}
            </AccordionDetails>
          </Accordion>

          {showInfographics && (
            <div className={classes.infographics}>
              <Infographic step={2} accountRole={accountRole} accountPlan={account?.plan} accountTag={accountTag} />
              <Infographic step={3} accountTag={accountTag} />
              <Infographic step={4} accountTag={accountTag} />
            </div>
          )}

          {templateToUse && <UseTemplateModal template={templateToUse} toggle={() => this.setTemplateToUse(null)} />}

          {templateToDelete && <DeleteTemplateModal template={templateToDelete} onClose={this.onCloseDeleteModal} />}
        </div>
      </ManageContainer>
    );
  }
}

const styles = {
  manageContainer: {
    backgroundColor: '#f8f8f7',
    '@media(max-device-width: 768px)': {
      paddingBottom: '315px', // TODO fix (workaround before release)
    },
  },
  root: {
    width: '1032px',
    margin: '48px auto',
    paddingBottom: '250px',
    '@media(max-device-width: 768px)': {
      width: 'auto',
      padding: '0 16px',
    },
  },
  title: {
    fontSize: '40px',
    fontWeight: 'bold' as any,
    lineHeight: 1.2,
    color: '#0c2337',
    '@media(max-device-width: 768px)': {
      fontSize: '24px',
    },
  },
  subtitle: {
    display: 'block',
    fontSize: '20px',
    fontWeight: 500,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.33,
    letterSpacing: 'normal',
    marginTop: '30px',
  },
  description: {
    marginTop: '8px',
    fontSize: '14px',
    lineHeight: 1.43,
    color: '#7f8b96',
    '@media(max-device-width: 768px)': {
      fontSize: '12px',
    },
  },
  templates: {
    display: 'flex',
    flexWrap: 'wrap' as any,
    '& > div': {
      marginTop: '32px',
      width: '496px',
    },
    '& > div:nth-child(odd)': {
      marginRight: '40px',
    },
    '@media(max-device-width: 768px)': {
      justifyContent: 'center',
      '& > div:nth-child(odd)': {
        marginRight: '0',
      },
    },
  },
  otherTemplates: {
    marginTop: '40px',
  },
  infographics: {
    display: 'flex',
    justifyContent: 'space-between',
    '@media(max-device-width: 768px)': {
      flexDirection: 'column',
    },
  },
};

const mapStateToProps = (state) => ({
  user: state.userRD.user,
});

export default withStyles(styles)(connect(mapStateToProps)(WheelTemplatesPage));

// TODO copied from wheel page comment section, refactor
const Accordion = withStyles({
  root: {
    marginBottom: '8px',
    borderRadius: '4px',
    backgroundColor: 'transparent',
    boxShadow: 'none',
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      marginTop: '40px',
    },
  },
  expanded: {
    margin: '40px',
  },
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    minHeight: '40px',
    backgroundColor: '#ededed',
    '&$expanded': {
      minHeight: '40px',
    },
  },
  content: {
    margin: '8px 0',
    fontSize: '12px',
    color: '#2b2e31',
    '&$expanded': {
      margin: '8px 0',
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles({
  root: {
    padding: '8px 0',
  },
})(MuiAccordionDetails);
