import * as actionTypes from '../Actions/ActionTypes';

const initialState = {
  count: 0,
  showQuestionnaire: true,
};

function sessionReducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.SESSION_INCR:
      return {
        ...state,
        count: ++state.count,
      };
    case actionTypes.SHOW_QUESTIONNAIRE:
      return {
        ...state,
        showQuestionnaire: action.payload,
      };
    default:
      return state;
  }
}

export default sessionReducer;
