export const getUserOnboardingData = (personalData) => {
  return `Additionally, consider the following onboarding information: ${
    personalData?.gender ? `Gender: ${personalData.gender},` : ''
  } ${personalData?.ethnicity ? `Ethnicity: ${personalData.ethnicity},` : ''} ${
    personalData?.age ? `Age: ${personalData.age},` : ''
  } ${personalData?.gender ? `Gender: ${personalData.gender},` : ''} ${
    personalData?.focus.length > 0 ? `Focus areas: ${personalData.focus.join(', ')},` : ''
  } ${personalData?.resources.length > 0 ? `Support resources: ${personalData.resources.join(', ')},` : ''} ${
    personalData?.challenges.length > 0 ? `Challenges: ${personalData.challenges.join(', ')},` : ''
  } ${personalData?.goals.length > 0 ? `Goals: ${personalData.goals.join(', ')}.` : ''} ${
    personalData?.reason.length > 0 ? `Reason to signup: ${personalData.reason.join(', ')}.` : ''
  }
    `;
};
