import { ManageContainer } from '../_Containers/ManageContainer';
import React, { useEffect, useState } from 'react';
import { Button } from '../Shared/Buttons/Button';
import { Membership } from './Membership';
import { InvitationModal } from './InvitationModal';
import { InvitationClient } from '../../API/invitation.client';
import { Spinner } from '../Shared/Spinner/Spinner';
import invitationsEmptyState from '../../Assets/images/invitations/invitations-empty-state.png';
import { Text } from '../Shared/Typography/Typography';
import { toasterService } from '../Shared/Toaster/Toaster.service';
import { showInfoModal } from '../Shared/InfoModal/InfoModal';
import { makeStyles } from '@material-ui/core';
import { customConfirm } from '../Shared/ConfirmModal/ConfirmationModal';
import { Invitation } from './invitation.model';
import { analytics } from '../../analytics/analytics';
import { useSelector } from 'react-redux';
import { selectUser } from '../Auth/Auth.selectors';
import {
  makeDeleteConfirmationText,
  makeDeleteSuccessText,
  makeEmptyStateText,
  makeInvitationSuccessText,
  makeInviteButtonText,
  makeTitle,
} from './invitation.utils';
import { AccountPlan } from '../../API/interfaces';

export const InvitationsPage = () => {
  const [inviteModal, setInviteInviteModal] = useState(false);
  const [resendModal, setResendModal] = useState(false);
  const [invitations, setInvitations] = useState([]);
  const [resendEmails, setResendEmails] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const classes = useStyles();
  const user = useSelector(selectUser);
  const accountPlan = user.account.plan;

  const fetchInvitations = async () => {
    const res = await InvitationClient.getInvitations();
    setInvitations(res.data);
    setLoading(false);
  };

  useEffect(() => {
    fetchInvitations();
  }, []);

  const toggleInviteModal = () => setInviteInviteModal(!inviteModal);
  const toggleResendModal = () => setResendModal(!resendModal);

  const deleteSubmit = async (invitation: Invitation) => {
    analytics.deleteTeamMember(accountPlan);
    const isConfirmed = await customConfirm({
      title: `Are you sure you want to delete ${
        invitation.firstName ? invitation.firstName + ' ' + invitation.lastName : invitation.email
      }?`,
      body: makeDeleteConfirmationText(accountPlan, invitation.status),
      confirmationText: 'DELETE',
    });
    if (isConfirmed) {
      analytics.deleteTeamMemberConfirm(accountPlan);
      setLoading(true);
      const res = await InvitationClient.deleteInvitation(invitation._id);
      if (res.status === 200) {
        const wheelsReassigned = res.data.wheels;
        showInfoModal({
          title: 'Done!',
          description: makeDeleteSuccessText(accountPlan, invitation, wheelsReassigned),
        });
        await fetchInvitations();
      } else {
        toasterService.addErrorToast('Something went wrong');
      }
      setLoading(false);
    }
  };

  const resendInvite = async (email) => {
    analytics.resendInvite(accountPlan);
    setResendEmails([email]);
    setResendModal(true);
  };

  const sendInvitations = async (emails) => {
    setLoading(true);
    const invitations = emails.map((email) => ({ email }));
    const res = await InvitationClient.createInvitations(invitations);
    if (res.status === 200) {
      await fetchInvitations();
      showInfoModal({
        title: 'Invitation sent!',
        description: makeInvitationSuccessText(accountPlan),
      });
    } else {
      toasterService.addErrorToast('Something went wrong');
    }
    setLoading(false);
  };

  const invite = async (emails: string[]) => {
    analytics.inviteTeamMemberSubmit(accountPlan);
    await sendInvitations(emails);
  };

  const reinvite = async (emails: string[]) => {
    analytics.resendInviteSubmit(accountPlan);
    await sendInvitations(emails);
  };

  const openInviteModal = () => {
    setInviteInviteModal(true);
    analytics.inviteTeamMembers(accountPlan);
  };

  const emptyState = (
    <div className="w-100 d-flex flex-column justify-content-center align-items-center pt-5">
      <Text color="#6a777f" className="text-center p-5 p-lg-0">
        {makeEmptyStateText(accountPlan)}
      </Text>
      <img src={invitationsEmptyState} style={{ width: '600px' }} className="d-none d-lg-block" />
    </div>
  );

  return (
    <ManageContainer className="gray">
      <div className="container p-0">
        <div className={classes.sectionHeader}>
          <Text size="40px" mSize="24px" weight="bold" lineHeight={1.2} className="d-block mt-4 mt-lg-0">
            {makeTitle(accountPlan)}
          </Text>
          <Button fontSize="14px" height="56px" onClick={openInviteModal}>
            {makeInviteButtonText(accountPlan)}
          </Button>
        </div>
        {accountPlan === AccountPlan.SCHOOL && (
          <Text
            size="14px"
            mSize="12px"
            weight="normal"
            color="#7f8b96"
            lineHeight={1.2}
            className="d-block mb-5"
          >{`This section is for teachers, school psychologists and other support staff who need extended access to iyarn's platform. This section is not intended for adding students.`}</Text>
        )}
        {invitations.length ? (
          <div className={classes.invitationsContainer}>
            <div className={classes.all}>All ({invitations.length})</div>
            {invitations.map((i) => {
              return (
                <Membership
                  key={i._id}
                  invitation={i}
                  onDelete={() => deleteSubmit(i)}
                  onResend={() => resendInvite(i.email)}
                  plan={accountPlan}
                />
              );
            })}
          </div>
        ) : (
          emptyState
        )}
        {isLoading && (
          <div className={classes.spinnerContaineer}>
            <Spinner />
          </div>
        )}
      </div>
      <InvitationModal
        modal={inviteModal}
        toggle={toggleInviteModal}
        submitInvite={invite}
        plan={accountPlan}
        resend={false}
      />
      <InvitationModal
        modal={resendModal}
        toggle={toggleResendModal}
        submitInvite={reinvite}
        plan={accountPlan}
        resend={true}
        emails={resendEmails}
      />
    </ManageContainer>
  );
};

const useStyles = makeStyles((theme) => ({
  invitationsContainer: {
    width: '100%',
    borderRadius: '8px',
    boxShadow: '0 2px 16px 0 rgba(0, 0, 0, 0.08)',
    backgroundColor: '#fff',
    position: 'relative',
    padding: '32px 40px 40px',
    [theme.breakpoints.down('md')]: {
      padding: '16px',
    },
  },
  all: {
    fontFamily: 'Rubik',
    fontSize: '14px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    color: '#2b2e31',
    marginBottom: '20px',
  },
  spinnerContaineer: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    top: '0',
    left: '0',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  sectionHeader: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '48px 0 36px',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column-reverse',
      alignItems: 'start',
      padding: '34px 16px',
    },
  },
}));
