import { InsightsActionTypes, InsightsReducerStateType, InsightsCommentType } from './insights.model';

const initialState: InsightsReducerStateType = {
  isLoading: true,
  periodStart: '',
  periodEnd: '',
  membersAmount: 0,
  membersChange: 0,
  lowScoresAmount: 0,
  lowScoresChange: 0,
  teamAlertThreshold: 1,
  comments: [],
  users: [],
  segments: [],
  wheels: [],
};

const getSortedCommentsFromNewest = (comments: InsightsCommentType[]) => {
  if (!comments.length) return [];
  return comments.sort((current, next) => new Date(current.date).getTime() - new Date(next.date).getTime()).reverse();
};

export const insightsReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case InsightsActionTypes.LOAD_INSIGHTS:
      const commentsArray = payload?.comments || [];
      return {
        ...payload,
        comments: getSortedCommentsFromNewest(commentsArray),
        isLoading: false,
      };
    case InsightsActionTypes.START_LOAD:
      return {
        ...state,
        isLoading: true,
      };
    case InsightsActionTypes.CREATE_INSIGHTS_REPLY:
    case InsightsActionTypes.EDIT_INSIGHTS_REPLY:
      return {
        ...state,
        comments: state.comments.map((comment) => {
          if (comment.id === payload.id)
            return {
              ...comment,
              replies: payload.replies,
            };
          return comment;
        }),
      };
    case InsightsActionTypes.DELETE_INSIGHTS_REPLY:
      return {
        ...state,
        comments: state.comments.map((comment) => {
          if (comment.id === payload.commentId)
            return {
              ...comment,
              replies: comment.replies.filter((reply) => reply.id !== payload.replyId),
            };
          return comment;
        }),
      };
    default:
      return state;
  }
};
