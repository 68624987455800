import React from 'react';
import { TextFieldProps } from '@material-ui/core/TextField/TextField';
import { makeStyles, TextField } from '@material-ui/core';

type tTextFieldWithCounterProps = {
  value: string;
  maxValueLength: number;
  rightShift?: number;
} & TextFieldProps;

const TextFieldWithCounter = (props: tTextFieldWithCounterProps) => {
  const {
    value,
    maxValueLength,
    onChange,
    label,
    placeholder,
    variant,
    fullWidth,
    multiline,
    rows,
    required,
    rightShift = 0,
  } = props;
  const classes = useStyles({ rightShift });

  const onInputChange = React.useCallback(
    (e) => {
      const newValue = e.target.value;
      if (newValue.length <= maxValueLength) {
        onChange(e);
      }
    },
    [maxValueLength]
  );

  return (
    <div className={classes.wrapper}>
      <span className={classes.counter}>
        {value?.length}/{maxValueLength}
      </span>
      <TextField
        className={classes.textField}
        value={value}
        onChange={onInputChange}
        label={label}
        placeholder={placeholder}
        variant={variant}
        fullWidth={fullWidth}
        multiline={multiline}
        rows={rows}
        required={required}
      />
    </div>
  );
};

export default TextFieldWithCounter;

const useStyles = makeStyles<any, Partial<tTextFieldWithCounterProps>>({
  wrapper: {
    position: 'relative',
    '& + &': {
      marginTop: '18px',
    },
  },
  textField: {
    width: '100%',
    borderColor: '#cececf',
    '& .MuiOutlinedInput-root.Mui-focused': {
      backgroundColor: '#f8f8f8',
    },
    '& input, textarea': {
      paddingRight: ({ rightShift }) => `calc(70px + ${rightShift}px)`,
      color: '#2b2e31',
    },
  },
  counter: {
    position: 'absolute',
    top: '12px',
    right: ({ rightShift }) => `calc(24px + ${rightShift}px)`,
    fontSize: '12px',
    lineHeight: 1.67,
    color: '#6a777f',
    zIndex: 1,
  },
  text: {
    marginTop: '16px',
    fontSize: '14px',
    lineHeight: 1.43,
    color: '#0c2337',
    '@media(max-device-width: 768px)': {
      fontSize: '12px',
      lineHeight: 1.67,
    },
  },
});
