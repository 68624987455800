import React, { Component } from 'react';
import { withStyles } from '@material-ui/core';
import { analytics } from '../../../analytics/analytics';
import { iCommentsAggregation, iWheelMember } from '../../../API/interfaces';
import { iDateRangeOptionData } from '../common/DateRangeDropdown/DateRangeDropdown';
import CommentSectionHeader from './CommentSectionHeader';
import { CommentSectionBody } from './CommentSectionBody';

interface iCommentsSectionProps {
  comments: iCommentsAggregation;
  dateRange: iDateRangeOptionData;
  activeCheckinDate: string | null;
  activeSegmentName: string | null;
  numberOfCheckins: number;
  showOnlyCommentsWithText: boolean;
  setShowOnlyCommentsWithText: (showOnlyCommentsWithText: boolean) => void;
  teamAlertThreshold: number;
  members?: Array<iWheelMember>;
  classes: any;
  refresh: () => Promise<void>;
  isReplyEnabled: boolean;
}

interface iCommentsSectionState {
  expandAll: boolean;
}

class CommentsSection extends Component<iCommentsSectionProps, iCommentsSectionState> {
  state = {
    expandAll: false,
  };

  setExpandAll = () => {
    const expandAll = !this.state.expandAll;

    this.setState({ expandAll });
    analytics.clickExpandComments(expandAll);
  };

  render() {
    const {
      dateRange,
      activeSegmentName,
      activeCheckinDate,
      members,
      // numberOfCheckins,
      classes,
      showOnlyCommentsWithText,
      setShowOnlyCommentsWithText,
      comments,
      teamAlertThreshold,
      refresh,
      isReplyEnabled,
    } = this.props;
    const { expandAll } = this.state;
    const isCollapsable = !['none', 'day'].includes(dateRange.unit);

    return (
      <div className={classes.container}>
        <CommentSectionHeader
          activeSegmentName={activeSegmentName}
          isCollapsable={isCollapsable}
          expandAll={expandAll}
          setExpandAll={this.setExpandAll}
          onlyWithCommentText={showOnlyCommentsWithText}
          setOnlyWithCommentText={setShowOnlyCommentsWithText}
        />

        <CommentSectionBody
          key={`${activeSegmentName}${activeCheckinDate}${dateRange.label}`}
          refresh={refresh}
          comments={comments}
          members={members}
          isCollapsable={isCollapsable}
          expandAll={expandAll}
          teamAlertThreshold={teamAlertThreshold || 0}
          isReplyEnabled={isReplyEnabled}
        />
      </div>
    );
  }
}

const styles = {
  container: {
    marginTop: '40px',
    padding: '24px 0px',
    borderRadius: '4px',
    border: 'solid 1px #eaeaea',
    backgroundColor: '#f8f8f8',
  },
};

export default withStyles(styles)(CommentsSection);
