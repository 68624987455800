import React, { useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { MoreVertical, Settings, Trash2 } from 'react-feather';
import { makeStyles, Menu, MenuItem, IconButton, ListItemIcon, ListItemText } from '@material-ui/core';
import { iWheelTemplate } from '../../../API/interfaces';

interface iTemplateContextMenuProps extends RouteComponentProps {
  template: iWheelTemplate;
  setTemplateToDelete?: (template: iWheelTemplate) => void;
}

const TemplateContextMenu = ({ history, setTemplateToDelete, template }: iTemplateContextMenuProps) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const handleOpen = (e) => setAnchorEl(e.currentTarget);
  const handleClose = () => setAnchorEl(null);

  return (
    <>
      <IconButton onClick={handleOpen}>
        <MoreVertical size={18} stroke={anchorEl ? '#fb463b' : '#2b2e31'} />
      </IconButton>

      <Menu
        classes={{ list: classes.list }}
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={handleClose}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuItem onClick={() => history.push(`/template/${template.id}/settings`)}>
          <ListItemIcon className={classes.icon}>
            <Settings size={14} stroke="#2b2e31" />
          </ListItemIcon>
          <ListItemText>Settings</ListItemText>
        </MenuItem>

        <MenuItem
          onClick={() => {
            handleClose();
            setTemplateToDelete?.(template);
          }}
        >
          <ListItemIcon className={classes.icon}>
            <Trash2 size={14} stroke="#fb463b" />
          </ListItemIcon>
          <ListItemText style={{ color: '#fb463b' }}>Delete</ListItemText>
        </MenuItem>
      </Menu>
    </>
  );
};

const useStyles = makeStyles({
  list: {
    minWidth: '148px',
    padding: '0',
  },
  icon: {
    minWidth: '25px',
  },
});

export default withRouter(TemplateContextMenu);
