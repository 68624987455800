import React from 'react';
import styled from 'styled-components';
import { Text } from '../Typography/Typography';
import { MdCheckCircle } from 'react-icons/md/index';
import { ToastTypes } from './Toaster.actions';

const ToastContainer = styled.div`
  width: 416px;
  height: 60px;
  border-radius: 2px;
  box-shadow: 0 5px 10px 2px rgba(0, 0, 0, 0.2);
  background-color: #ffffff;
  position: relative;
  padding: 5px;
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  z-index: 9;
  @media all and (max-width: 1199px) {
    width: 100%;
  }
`;

const ToastLine = styled.div<{ type: ToastTypes }>`
  width: 5px;
  height: 100%;
  border-radius: 2px;
  background-color: ${(props) => (props.type === ToastTypes.SUCCESS ? '#009e8c' : '#e63d3d')};
  position: absolute;
  left: 0;
  top: 0;
`;

const ToastIcon = styled.div<{ type: ToastTypes }>`
  padding: 10px;
  display: flex;
  align-items: center;
  font-size: 20px;
  color: ${(props) => (props.type === ToastTypes.SUCCESS ? '#009e8c' : '#e63d3d')};
`;

export const Toast = ({ message = '', type = ToastTypes.SUCCESS, ...props }) => (
  <ToastContainer {...props}>
    <ToastLine type={type} />
    <ToastIcon type={type}>
      <MdCheckCircle />
    </ToastIcon>
    <Text size="14px">{message}</Text>
  </ToastContainer>
);
