import React from 'react';
import './Spinner.scss';
import styled from 'styled-components';

export const Spinner = () => (
  <div className="spinner">
    <div className="shape shape-1" />
    <div className="shape shape-2" />
    <div className="shape shape-3" />
    <div className="shape shape-4" />
  </div>
);

export const SpinnerBackground = styled.div<any>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: ${({ height }) => height};
`;
