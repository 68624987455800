import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { TextField } from '@material-ui/core';
import { Button } from '../../Shared/Buttons/Button';
import services from '../../../API/services';
import { Toast } from '../../Shared/Toaster/Toast';
import { ToastTypes } from '../../Shared/Toaster/Toaster.actions';
import { toasterService } from '../../Shared/Toaster/Toaster.service';
import './ManualUserForm.scss';
import { Divider, DividerContent } from '../../Auth/SSO/Divider';
import { Text } from '../../Shared/Typography/Typography';
import upload from '../../../Assets/images/invitations/upload.svg';

const initialValues = {
  firstName: '',
  lastName: '',
  email: '',
};

const validationSchema = yup.object({
  firstName: yup.string().required('The field can’t be empty'),
  lastName: yup.string().required('The field can’t be empty'),
  email: yup.string().email('Not valid email format').required('The field can’t be empty'),
});

export const ManualUserForm = ({ wheelId, onUserAdded, onUploadClick }) => {
  const [serverError, setServerError] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (values) => {
    setIsLoading(true);
    const response = await services.addManualUserToWheel(wheelId, values);
    if (response.status === 200) {
      toasterService.addSuccessToast('User added successfully');
      onUserAdded(values.firstName, values.lastName, values.email, response?.data?.message);
    } else {
      setServerError(response.data.message);
    }
    setIsLoading(false);
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: handleSubmit,
    validateOnChange: true,
    validateOnBlur: true,
  });

  const allFieldsFilledAndValid =
    formik.values.firstName &&
    formik.values.lastName &&
    formik.values.email &&
    !formik.errors.firstName &&
    !formik.errors.lastName &&
    !formik.errors.email;

  const handleSendButtonClick = async () => {
    formik.setTouched({ firstName: true, lastName: true, email: true });
    if (formik.isValid) {
      formik.handleSubmit();
    }
  };

  const handleUploadButtonClick = (event) => {
    event.preventDefault();
    onUploadClick();
  };

  return (
    <form className="manual-user-form">
      {serverError && (
        <div className="mb-4">
          <Toast type={ToastTypes.ERROR} message={serverError} />
        </div>
      )}
      <div className="d-flex justify-content-between mb-4" style={{ gap: '16px', width: '100%' }}>
        <TextField
          fullWidth
          id="first_name"
          label="First Name"
          variant="outlined"
          placeholder="First Name"
          value={formik.values.firstName}
          onChange={formik.handleChange('firstName')}
          onBlur={formik.handleBlur}
          error={formik.errors.firstName && formik.touched.firstName}
          helperText={formik.touched.firstName && formik.errors.firstName}
        />
        <TextField
          fullWidth
          id="last_name"
          label="Last Name"
          variant="outlined"
          placeholder="Last Name"
          value={formik.values.lastName}
          onChange={formik.handleChange('lastName')}
          onBlur={formik.handleBlur}
          error={formik.errors.lastName && formik.touched.lastName}
          helperText={formik.touched.lastName && formik.errors.lastName}
        />
      </div>
      <div className="mb-2" style={{ width: '100%' }}>
        <TextField
          id="email"
          label="Member Email"
          variant="outlined"
          placeholder="Member Email"
          type="email"
          value={formik.values.email}
          onChange={formik.handleChange('email')}
          onBlur={formik.handleBlur}
          error={formik.errors.email && formik.touched.email}
          helperText={formik.touched.email && formik.errors.email}
          fullWidth
        />
      </div>
      <div style={{ width: '100%' }} className="mb-2">
        <Divider>
          <DividerContent>or</DividerContent>
        </Divider>
      </div>
      <div className="d-flex justify-content-between align-items-center mb-4" style={{ width: '100%' }}>
        <Text size="16px" mSize="12px">
          Upload members list table <br />
          (csv, xlsx or xls file)
        </Text>
        <button className="edit-button" onClick={handleUploadButtonClick} style={{}}>
          <img
            src={upload}
            alt="Edit"
            style={{ width: '14px', height: '14px', cursor: 'pointer', whiteSpace: 'normal' }}
          />
          Download Template & Upload Table
        </button>
      </div>
      <div>
        <Button
          style={{ height: '40px', lineHeight: '40px' }}
          type="button"
          onClick={handleSendButtonClick}
          disabled={!allFieldsFilledAndValid || isLoading}
        >
          {isLoading ? 'SENDING...' : 'SEND INVITE'}
        </Button>
      </div>
    </form>
  );
};
