import React, { Fragment } from 'react';
import { iCommentsAggregation, iWheelMember } from '../../../API/interfaces';
import { Checkin } from './Checkin';
import { CommentAccordion } from './CommentSectionBodyAccordion';

interface iCommentSectionBodyProps {
  comments: iCommentsAggregation;
  isCollapsable: boolean;
  expandAll: boolean;
  teamAlertThreshold: number;
  members?: Array<iWheelMember>;
  refresh: () => Promise<void>;
  isReplyEnabled: boolean;
}

export const CommentSectionBody = ({
  comments,
  members,
  teamAlertThreshold,
  refresh,
  isCollapsable,
  expandAll,
  isReplyEnabled,
}: iCommentSectionBodyProps) => {
  const commentsEntries = Object.entries(comments);

  return (
    <>
      {commentsEntries.map(([dateRange, comments], i) => {
        const numberOfComments = comments.filter((c) => !!c.comment).length;
        return (
          <Fragment key={dateRange}>
            {isCollapsable ? (
              <CommentAccordion
                dateRange={dateRange}
                numberOfComments={numberOfComments}
                defaultExpanded={expandAll || i === 0}
                expandAll={expandAll}
              >
                {comments.map((c) => {
                  return (
                    <div key={c.date}>
                      <Checkin
                        refresh={refresh}
                        key={c.date}
                        comment={c}
                        members={members}
                        teamAlertThreshold={teamAlertThreshold}
                        isReplyEnabled={isReplyEnabled}
                      />
                    </div>
                  );
                })}
              </CommentAccordion>
            ) : (
              comments.map((c) => {
                return (
                  <div key={c.date}>
                    <Checkin
                      refresh={refresh}
                      key={c.date}
                      comment={c}
                      members={members}
                      teamAlertThreshold={teamAlertThreshold}
                      isReplyEnabled={isReplyEnabled}
                    />
                  </div>
                );
              })
            )}
          </Fragment>
        );
      })}
    </>
  );
};
