import { EducationStatus } from '../Components/Education/Education.model';

export default {
  // reset education state for wheel page after UI redesign
  0: (prevVersionState) => ({
    ...prevVersionState,
    education: {
      firstVisitOfDashboard: prevVersionState.education.firstVisitOfDashboard,
      afterFirstCheckin: EducationStatus.REMAINING,
      membersTab: EducationStatus.REMAINING,
      currentFlow: [],
    },
  }),
};
