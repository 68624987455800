import { LogoBox, RightPanel } from './_styled';
import onboardingBg from '../../../Assets/images/onboarding/onboarding-bg.png';
import logo from '../../../Assets/images/onboarding/logo.png';

export const RightScreen = () => {
  return (
    <RightPanel>
      <LogoBox>
        <img src={logo} alt="logo" />
      </LogoBox>
      <img src={onboardingBg} alt="Illustration" style={{ width: '100%' }} />
    </RightPanel>
  );
};
