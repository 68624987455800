export enum Colors {
  NAVI_BLUE = '#0f2136',
}

// keep the same length for both gradient arrays
export const GRADIENTS = [
  ['#ffc200', '#fb900b'],
  ['#ffb8a0', '#ff5149'],
  ['#1cefef', '#009b8c'],
  ['#7d80f0', '#452fb0'],
  ['#fd9b00', '#fc5a00'],
  ['#ff6261', '#bd0000'],
  ['#31d574', '#1c8f8f'],
  ['#6894ff', '#003dad'],
  ['#fff187', '#f9b600'],
  ['#ff8181', '#dd1242'],
  ['#16c259', '#3c7f44'],
  ['#9655e1', '#530da3'],
  ['#7fceff', '#0087b2'],
];

export const DIMMED_GRADIENTS = [
  ['#ffecaf', '#ffdcb3'],
  ['#ffe7e0', '#ffcac9'],
  ['#adf9f8', '#a7dfd9'],
  ['#d7d8fa', '#cac1ea'],
  ['#ffdfaf', '#ffccb0'],
  ['#ffcecf', '#f4b3b5'],
  ['#b6f2d3', '#b5dfdd'],
  ['#cfdefe', '#b3c7ea'],
  ['#fcf8d5', '#fde7ad'],
  ['#ffd6d7', '#fdb8c8'],
  ['#aeeccb', '#c1dac8'],
  ['#e2cbf6', '#d2b7e6'],
  ['#d3f0fe', '#addcea'],
];

export const DISABLED_GRADIENT = ['rgba(208, 219, 231, 0)', 'rgba(147, 168, 193, 0.28)'];

export const gradientColors = {
  orange: ['#ffc200', '#fb900b'],
  pinkOrange: ['#ffb8a0', '#ff5149'],
  blue: ['#6894ff', '#003dad'],
  purple: ['#7d80f0', '#452fb0'],
  cyan: ['#1cefef', '#009b8c'],
  red: ['#ff6261', '#bd0000'],
  green: ['#31d574', '#1c8f8f'],
  yellow: ['#fff187', '#f9b600'],
};

export type GradientColorsType = keyof typeof gradientColors;
