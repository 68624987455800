import React, { useState, useEffect } from 'react';
import { ConnectedProps, connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import QuestionScreen from './QuestionScreen';
import InfoScreen from './InfoScreen';
import { questionnaireData } from './constants';
import { StaticContentScreen } from './StaticContentScreen';
import CheckinScreen from './CheckinScreen';
import { getNextStep, getPreviousStep, updateUserData } from './helpers';
import services from '../../../API/services';
import { PersonalData, iUser } from '../../../API/interfaces';
import { updatePersonalDataAction } from '../Auth.actions';
import { RootState } from '../../../Redux/Reducers';

interface UserData {
  userRole?: string[];
  customOptions?: { [key: number]: string[] };
  [key: string]: any;
}

interface StepsProps {
  user: iUser;
  userType: string;
  setUserType: (userType: string | null) => void;
  updatePersonalDataAction: (personalData: PersonalData) => void;
  move?: () => void;
}

const Steps: React.FC<StepsProps> = ({ user, userType, setUserType, updatePersonalDataAction, move }) => {
  const [currentStep, setCurrentStep] = useState<number>(-1);
  const [userData, setUserData] = useState<UserData>({});
  const [personalData, setPersonalData] = useState({
    userTag: userType || null,
    userRole: user.personalData.userRole || [],
    focus: user.personalData.focus || [],
    resources: user.personalData.resources || [],
    challenges: user.personalData.challenges || [],
    goals: user.personalData.goals || [],
    reason: user.personalData.reason || [],
    birth: user.personalData.birth,
    age: user.personalData.age,
    gender: user.personalData.gender,
    ethnicity: user.personalData.ethnicity,
    nationality: user.personalData.nationality,
  });
  const history = useHistory();

  useEffect(() => {
    setPersonalData({
      userTag: userType || null,
      userRole: user.personalData.userRole || userData.userRole || [],
      focus: user.personalData.focus || userData.focus || [],
      resources: user.personalData.resources || userData.resources || [],
      challenges: user.personalData.challenges || userData.challenges || [],
      goals: user.personalData.goals || userData.goals || [],
      reason: user.personalData.reason || userData.reason || [],
      birth: user.personalData.birth,
      age: user.personalData.age,
      gender: user.personalData.gender,
      ethnicity: user.personalData.ethnicity,
      nationality: user.personalData.nationality,
    });
  }, [userData, userType, user.personalData]);

  useEffect(() => {
    setPersonalData((prevData) => ({
      ...prevData,
      userTag: userType,
      userRole: prevData.userRole.length > 0 ? prevData.userRole : [],
      focus: prevData.focus.length > 0 ? prevData.focus : [],
      resources: prevData.resources.length > 0 ? prevData.resources : [],
      challenges: prevData.challenges.length > 0 ? prevData.challenges : [],
      goals: prevData.goals.length > 0 ? prevData.goals : [],
      reason: prevData.reason.length > 0 ? prevData.reason : [],
    }));
  }, [userType]);

  const handleNext = (data: Partial<UserData>, nextStep?: number) => {
    setUserData((prevData) => updateUserData(data, prevData));
    setTimeout(async () => {
      if (nextStep !== undefined) {
        setCurrentStep(nextStep);
      } else if (currentStep < totalSteps - 1) {
        setCurrentStep(getNextStep(currentStep, userData, userType));
      } else {
        const updatedData = { ...personalData, ...data };
        await services.updatePersonalData(updatedData);
        updatePersonalDataAction(updatedData);
        history.push('/dashboard');
      }
    }, 0);
  };

  const handleBack = (data: Partial<UserData>, previousStep?: number) => {
    if (data && typeof data === 'object' && !('nativeEvent' in data)) {
      setUserData((prevData) => updateUserData(data, prevData));
    }
    if (previousStep !== undefined) {
      setCurrentStep(previousStep);
    }
    if (currentStep === 0) {
      setCurrentStep(-1);
    }
    if (currentStep > 0) {
      setCurrentStep(getPreviousStep(currentStep, userData, userType));
    }
  };

  const totalSteps = questionnaireData[userType].length;

  const moveToAccountSelection = () => {
    move();
    handleBack(userData, undefined);
  };

  const renderStep = () => {
    if (currentStep === -1) {
      return <StaticContentScreen onBack={moveToAccountSelection} onNext={() => handleNext(userData, 0)} />;
    }
    if (currentStep < totalSteps) {
      const stepData = questionnaireData[userType][currentStep];
      if (stepData.question) {
        return (
          <QuestionScreen
            data={stepData}
            onNext={handleNext}
            onBack={handleBack}
            userData={userData}
            step={currentStep}
            totalSteps={totalSteps}
            userType={userType}
            personalData={personalData}
            updatePersonalDataAction={updatePersonalDataAction}
          />
        );
      } else if (stepData.title && stepData.content) {
        return (
          <InfoScreen
            data={stepData}
            onNext={(data) => handleNext(data, getNextStep(currentStep, userData, userType))}
            onBack={(data) => handleBack(data, getPreviousStep(currentStep, userData, userType))}
            step={currentStep}
            totalSteps={totalSteps}
          />
        );
      }
    } else {
      return (
        <CheckinScreen
          onNext={() => history.push('/dashboard')}
          onBack={() => setCurrentStep(totalSteps - 1)}
          step={currentStep}
          totalSteps={totalSteps + 1}
        />
      );
    }
  };

  return <div>{renderStep()}</div>;
};

const mapStateToProps = (state: RootState) => ({
  user: state.userRD.user || {},
});

const mapDispatchToProps = {
  updatePersonalDataAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(Steps);
