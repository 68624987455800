import React from 'react';
import { ClickAwayListener, Grow, MenuItem, MenuList, Paper, Popper } from '@material-ui/core';
import NestedMenuItem from 'material-ui-nested-menu-item';
import styled from 'styled-components';

import { Expandable } from '../Expandable';
import { eSortOptions, eSortOptionValues } from '../_types';
import { IconLabel } from '../_styled';

import { tProps } from './_types';
import { StyledChevron, StyledSortIcon } from './_styled';

export const SortDesktop = ({ setSort }: tProps) => {
  const wrapperRef = React.useRef(null);
  const [active, setActive] = React.useState(false);

  return (
    <>
      <Expandable width="76px" onClick={() => setActive(true)}>
        <SortIconWrapper ref={wrapperRef}>
          <StyledSortIcon />
          <IconLabel>Sort</IconLabel>
        </SortIconWrapper>
      </Expandable>

      <Popper open={active} anchorEl={wrapperRef.current} role={undefined} transition>
        {({ TransitionProps, placement }) => (
          <Grow {...TransitionProps} style={{ transform: 'translateY(50px)', zIndex: 1 }}>
            <Paper>
              <ClickAwayListener onClickAway={() => setActive(false)}>
                <MenuList id="menu-list-grow">
                  <MenuItem
                    onClick={() => {
                      setActive(false);
                      setSort({ [eSortOptions.BY_LATEST_UPDATES]: eSortOptionValues.DESC });
                    }}
                  >
                    By latest updates
                  </MenuItem>

                  <NestedMenuItem label={'By creation date'} parentMenuOpen={active} rightIcon={<StyledChevron />}>
                    <MenuItem
                      onClick={() => {
                        setActive(false);
                        setSort({ [eSortOptions.BY_CREATION_DATE]: eSortOptionValues.ASC });
                      }}
                    >
                      From old to new
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        setActive(false);
                        setSort({ [eSortOptions.BY_CREATION_DATE]: eSortOptionValues.DESC });
                      }}
                    >
                      From new to old
                    </MenuItem>
                  </NestedMenuItem>

                  <NestedMenuItem label={'By score'} parentMenuOpen={active} rightIcon={<StyledChevron />}>
                    <MenuItem
                      onClick={() => {
                        setActive(false);
                        setSort({ [eSortOptions.BY_SCORE]: eSortOptionValues.DESC });
                      }}
                    >
                      From high to low
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        setActive(false);
                        setSort({ [eSortOptions.BY_SCORE]: eSortOptionValues.ASC });
                      }}
                    >
                      From low to high
                    </MenuItem>
                  </NestedMenuItem>

                  <NestedMenuItem label={'By checkins'} parentMenuOpen={active} rightIcon={<StyledChevron />}>
                    <MenuItem
                      onClick={() => {
                        setActive(false);
                        setSort({ [eSortOptions.BY_CHECKINS]: eSortOptionValues.ASC });
                      }}
                    >
                      From less to more
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        setActive(false);
                        setSort({ [eSortOptions.BY_CHECKINS]: eSortOptionValues.DESC });
                      }}
                    >
                      From more to less
                    </MenuItem>
                  </NestedMenuItem>

                  <NestedMenuItem label={'By Name'} parentMenuOpen={active} rightIcon={<StyledChevron />}>
                    <MenuItem
                      onClick={() => {
                        setSort({ [eSortOptions.BY_ALPHABET]: eSortOptionValues.ASC });
                        setActive(false);
                      }}
                    >
                      A-Z
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        setSort({ [eSortOptions.BY_ALPHABET]: eSortOptionValues.DESC });
                        setActive(false);
                      }}
                    >
                      Z-A
                    </MenuItem>
                  </NestedMenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};

const SortIconWrapper = styled.div`
  cursor: pointer;
`;
