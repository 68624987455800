import React from 'react';
import { makeStyles } from '@material-ui/core';
import styled from 'styled-components';

import { tStep } from '../Sidebar/Sidebar';
import { MediumButton, TransparentButton } from '../../Shared/Buttons/Button';

interface iStepContainerProps {
  activeStep: tStep;
  setStep: (nextStep: tStep) => void;
  isNextDisabled: boolean;
  onSubmit: () => void;
  children: React.ReactNode;
  isTemplate: boolean;
  steps: tStep[];
}

const StepContainer = ({
  children,
  activeStep,
  isNextDisabled,
  setStep,
  onSubmit,
  isTemplate,
  steps,
}: iStepContainerProps) => {
  const classes = useStyles();
  const activeStepIndex = steps.indexOf(activeStep);
  const isLastStep = activeStepIndex === steps.length - 1;

  const handleBack = () => setStep(steps[activeStepIndex - 1]);
  const handleNext = () => (isLastStep ? onSubmit() : setStep(steps[activeStepIndex + 1]));

  return (
    <div className={classes.root}>
      <div>{children}</div>

      {activeStepIndex === 0 ? (
        <div className={classes.buttonsFirstStep}>
          <MediumButton disabled={isNextDisabled} onClick={handleNext}>
            {!isLastStep ? 'NEXT STEP' : `CREATE ${isTemplate ? 'TEMPLATE' : 'WHEEL'}`}
          </MediumButton>
        </div>
      ) : (
        <div className={classes.buttons}>
          <TransparentButton onClick={handleBack}>
            <StyledArrowLeft />
            BACK
          </TransparentButton>
          <MediumButton disabled={isNextDisabled} onClick={handleNext}>
            {!isLastStep ? 'NEXT STEP' : `CREATE ${isTemplate ? 'TEMPLATE' : 'WHEEL'}`}
          </MediumButton>
        </div>
      )}
    </div>
  );
};

export default StepContainer;

const useStyles = makeStyles({
  root: {
    marginTop: '40px',
    width: '662px',
    '@media(max-device-width: 768px)': {
      width: '100%',
      paddingBottom: '100px',
    },
  },
  buttons: {
    minHeight: '108px',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginTop: '40px',
    '@media(max-device-width: 768px)': {
      position: 'fixed', // relatively to create wheel page
      bottom: 0,
      justifyContent: 'space-between',
      left: '-16px',
      right: '32px',
      width: 'calc(100% + 16px)', // createWheelSection padding,
      padding: '16px 16px 40px',
      zIndex: 100,
      background: '#fff',
      boxShadow: '0 2px 9px 0 rgba(0, 0, 0, 0.4)',
    },
  },
  buttonsFirstStep: {
    minHeight: '108px',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginTop: '40px',
    '@media(max-device-width: 768px)': {
      position: 'fixed', // relatively to create wheel page
      bottom: 0,
      left: '-16px',
      right: '32px',
      width: 'calc(100% + 16px)', // createWheelSection padding,
      padding: '16px 16px 40px',
      zIndex: 100,
      background: '#fff',
      boxShadow: '0 2px 9px 0 rgba(0, 0, 0, 0.4)',
    },
  },
});

const ArrowLeft = ({ className }) => (
  <svg className={className} width="12" height="10" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M4.195.529a.667.667 0 1 1 .943.942L2.276 4.332l9.057.001c.335 0 .612.247.66.568L12 5a.667.667 0 0 1-.667.667H2.276l2.862 2.862c.234.234.258.6.07.86l-.07.082a.667.667 0 0 1-.943 0l-4-4a.679.679 0 0 1-.18-.329.67.67 0 0 1 .18-.613l-.05.056a.67.67 0 0 1 .037-.042l.013-.014z"
      fill="#0C2337"
      fillRule="nonzero"
    />
  </svg>
);

const StyledArrowLeft = styled<any>(ArrowLeft)`
  margin-right: 8px;
`;
