import { Dialog, DialogActions, makeStyles, TextField } from '@material-ui/core';
import { Text } from '../../Shared/Typography/Typography';
import React from 'react';
import { FiAlertCircle } from 'react-icons/fi';
import { Button, TransparentButton } from '../../Shared/Buttons/Button';
import { useSelector } from 'react-redux';
import { selectWheelsById } from '../Dashboard.selectors';
import { useFormik } from 'formik';
import * as yup from 'yup';

const validationSchema = yup.object({
  name: yup.string().required().max(25),
  wheels: yup.array().of(
    yup.object().shape({
      name: yup.string().required('The field can’t be empty').max(40),
    })
  ),
});

export const DuplicateFolderModal = ({ isOpen, onCancel, duplicateFolder, duplicate }) => {
  const wheels = useSelector(selectWheelsById(duplicateFolder.wheels));
  const classes = useStyles();

  const formik = useFormik({
    initialValues: {
      ...duplicateFolder,
      name: duplicateFolder.name + ' (1)',
      wheels: wheels.map((w) => ({ id: w.id, name: w.name + ' (1)' })),
    },
    validationSchema,
    onSubmit: () => {
      duplicate(formik.values);
    },
  });

  const handleWheelName = (e, i) => {
    if (e.target.value.length < 40 || e.target.value.length < formik.values.wheels[i].name.length) {
      const handler = formik.handleChange(`wheels[${i}].name`);
      handler(e);
    }
  };

  const handleFolderName = (e) => {
    if (e.target.value.length < 25 || e.target.value.length < formik.values.name.length) {
      const handler = formik.handleChange(`name`);
      handler(e);
    }
  };

  return (
    <>
      <Dialog open={isOpen} onClose={onCancel} classes={{ paper: classes.duplicateModal }}>
        <Text size="30px" mSize="16px" mWeight="500" color="#0c2337">
          Duplicate folder "{duplicateFolder.name}"
        </Text>
        <div className="mt-2">
          <TextField
            autoFocus
            margin="dense"
            id="duplicateFolderName"
            label="Duplicate Folder Name"
            type="text"
            fullWidth
            variant="outlined"
            value={formik.values.name}
            onChange={(e) => handleFolderName(e)}
            className={classes.input}
            error={!!formik.errors.name}
          />
        </div>
        <Text size="12px" color="#6a777f">
          This can be edited later on
        </Text>
        <Text size="14px" color="#0c2337" className="mt-3">
          Your duplication folder will consist of:
        </Text>
        {formik.values.wheels.map((w, i) => {
          return (
            <div className="mt-2" key={w.id}>
              <TextField
                autoFocus
                margin="dense"
                id={'wheel_' + w.id}
                label="Duplicate Wheel Name"
                type="text"
                fullWidth
                variant="outlined"
                value={formik.values.wheels[i].name}
                onChange={(e) => handleWheelName(e, i)}
                className={classes.input}
                error={!!formik.errors.wheels?.[i]}
              />
            </div>
          );
        })}
        <div className="d-flex mt-2 justify-content-start align-items-center">
          <FiAlertCircle color="#ff4138" className="mr-2" />
          <Text size="14px" color="#2b2e31">
            Wheel members won't be carried over
          </Text>
        </div>
        <DialogActions>
          <TransparentButton onClick={onCancel}>CANCEL</TransparentButton>
          <Button onClick={formik.submitForm} disabled={!formik.isValid}>
            DUPLICATE
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  duplicateModal: {
    padding: '40px 50px',
    borderRadius: '4px',
    backgroundColor: '#fefaf2',
    width: '550px',
    [theme.breakpoints.down('md')]: {
      padding: '32px 24px',
      margin: '16px',
    },
  },
  input: {
    '& input, textarea': {
      padding: '16px 16px',
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-marginDense': {
      transform: 'translate(14px, 17px) scale(1)',
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
      transform: 'translate(14px, -6px) scale(0.75)',
    },
  },
}));
