import React from 'react';
import { Box, makeStyles, Typography } from '@material-ui/core';
import { AlertCircle } from 'react-feather';
import InviteLinkImage from '../../../../Assets/images/invite-link.png';
import { iScore } from '../../../../API/interfaces';
import Wheel from '../Wheel/Wheel';
import DisabledChart from '../../../Shared/HistoryChart/DisabledChart';

interface iErrorContainerProps {
  error: Error;
}

export default ({ error }: iErrorContainerProps) => {
  const classes = useStyles();
  const fakeChartScores = [0.8, 3, 4, 1.5, 4, 2.5];
  const fakeWheelScores = [
    { score: 8, segmentId: '1' },
    { score: 6.5, segmentId: '2' },
    { score: 8, segmentId: '3' },
    { score: 6.5, segmentId: '4' },
    { score: 8, segmentId: '5' },
    { score: 6.5, segmentId: '6' },
    { score: 8, segmentId: '7' },
    { score: 6.5, segmentId: '8' },
  ] as Array<iScore>;

  const isNotMember = error.name === 'NotMember';
  const title = isNotMember ? 'You need an Invite Link to access this wheel' : 'Error';

  return (
    <Box className={classes.container}>
      <Wheel maxScale={10} averageScore={0} scores={fakeWheelScores} activeSegmentId={null} width={300} disabled />
      <DisabledChart scores={fakeChartScores} />
      <Box className={classes.overlay}>
        <Box className={classes.hui}>
          {isNotMember ? <img src={InviteLinkImage} width={250} /> : <AlertCircle size={64} color="#0c2337" />}
          <Typography className={classes.title}>{title}</Typography>
          <Typography className={classes.message}>{error.message}</Typography>
        </Box>
      </Box>
    </Box>
  );
};

const useStyles = makeStyles({
  container: {
    position: 'relative',
    marginTop: '46px',
    marginBottom: '210px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    minHeight: '520px',
    padding: '50px',
    borderRadius: '4px',
    backgroundColor: '#ffffff',
    '@media(max-device-width: 767px)': {
      width: '100%',
      flexDirection: 'column',
      alignItems: 'flex-start',
      overflow: 'hidden',
    },
  },
  overlay: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  hui: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '40%',
    padding: '40px',
    borderRadius: '4px',
    textAlign: 'center',
    color: '#0c2337',
    boxShadow: '0 2px 18px 0 rgba(0, 0, 0, 0.19)',
    backgroundColor: '#ffffff',
    '@media(max-device-width: 767px)': {
      width: '90%',
    },
  },
  title: {
    marginTop: '32px',
    fontSize: '22px',
    fontWeight: 600,
  },
  message: {
    marginTop: '8px',
    fontSize: '16px',
    lineHeight: 1.5,
  },
});
