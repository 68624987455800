import React from 'react';
import { makeStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';

interface iFeatureProps {
  title: string;
  description: string;
  image: string;
}

export default ({ title, description, image }: iFeatureProps) => {
  const classes = useStyles();

  return (
    <Grid container className={classes.root}>
      <Grid item md={8} xs={12}>
        <Grid container className={classes.textContainer}>
          <Grid item className={classes.title}>
            {title}
          </Grid>
          <Grid item className={classes.description}>
            {description}
          </Grid>
        </Grid>
      </Grid>
      <Grid item md={4} xs={12}>
        <img src={image} width={320} height={240} alt={`${title} image`} />
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles({
  root: {
    padding: '4px',
    borderRadius: '8px',
    boxShadow: '0 2px 16px 0 rgba(0, 0, 0, 0.08)',
    backgroundColor: '#ffffff',
    '@media(max-device-width: 768px)': {
      flexDirection: 'column-reverse',
      padding: '8px',
    },
  },
  textContainer: {
    padding: '40px 85px 0 40px',
    '@media(max-device-width: 1024px)': {
      paddingRight: '0',
    },
    '@media(max-device-width: 768px)': {
      padding: '16px',
      flexDirection: 'column',
    },
  },
  title: {
    fontSize: '24px',
    fontWeight: 600,
    lineHeight: '1.25',
    color: '#2b2e31',
    '@media(max-device-width: 768px)': {
      fontSize: '16px',
    },
  },
  description: {
    marginTop: '16px',
    fontSize: '18px',
    lineHeight: '1.44',
    color: '#6a777f',
    '@media(max-device-width: 768px)': {
      fontSize: '12px',
    },
  },
});
