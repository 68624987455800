import React from 'react';
import styled from 'styled-components';

const NewBadgeWrapper = styled.div`
  display: flex;
  width: 30px;
  background-image: linear-gradient(103deg, #ff815d 0%, #ff4138 100%);
  border-radius: 10px;
  align-items: center;
  justify-content: center;
`;

const NewBadgeText = styled.div`
  display: inline-block;
  color: #ffffff;
  font-weight: 700;
  line-height: 15px;
  font-size: 8px;
`;

const NewFeatureBadge: React.FC = () => (
  <NewBadgeWrapper>
    <NewBadgeText>NEW</NewBadgeText>
  </NewBadgeWrapper>
);

export default NewFeatureBadge;
