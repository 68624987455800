import { InsightsActionTypes, InsightsDataType, InsightsCommentReplyType } from './insights.model';

export const loadInsightsSuccess = (payload: InsightsDataType) => ({
  type: InsightsActionTypes.LOAD_INSIGHTS,
  payload,
});

export const startLoad = () => ({
  type: InsightsActionTypes.START_LOAD,
});

export const createInsightsReplySuccess = (payload: { id: string; replies: InsightsCommentReplyType[] }) => ({
  type: InsightsActionTypes.CREATE_INSIGHTS_REPLY,
  payload,
});

export const editInsightsReplySuccess = (payload: { id: string; replies: InsightsCommentReplyType[] }) => ({
  type: InsightsActionTypes.EDIT_INSIGHTS_REPLY,
  payload,
});

export const deleteInsightsReplySuccess = (payload: { commentId: string; replyId: string }) => ({
  type: InsightsActionTypes.DELETE_INSIGHTS_REPLY,
  payload,
});
