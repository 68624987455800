import { useEffect } from 'react';
import { InvitationClient } from '../../API/invitation.client';
import { useDispatch, useSelector } from 'react-redux';
import { selectUser } from '../Auth/Auth.selectors';
import { useHistory, useLocation } from 'react-router-dom';
import { showInfoModal } from '../Shared/InfoModal/InfoModal';
import { authService } from '../Auth/Auth.service';
import { toasterService } from '../Shared/Toaster/Toaster.service';
import { saveInvitationLink } from './invitation.actions';
import { AccountPlan, AccountType } from '../../API/interfaces';

export const JoinAccount = (props) => {
  const {
    match: {
      params: { id: accountId },
    },
  } = props;
  const user = useSelector(selectUser);
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  const join = async () => {
    const res = await InvitationClient.joinInvitation(accountId);
    const updatedUser = await authService.updateUserData();
    if (res.status === 200) {
      showInfoModal({
        title: `Welcome ${updatedUser.first_name} ${updatedUser.last_name}!`,
        description: `You’ve successfully joined to the account ${updatedUser.account.accountName}`,
      });
    } else if (res.status === 409) {
      showInfoModal({
        title: `Hey ${updatedUser.first_name} ${updatedUser.last_name}!`,
        description: `You’ve already joined to the account ${updatedUser.account.accountName}`,
      });
    } else {
      toasterService.addErrorToast('Invitation link is invalid');
    }
    history.push('/dashboard');
  };

  const redirectToSignup = async () => {
    const id = location.pathname.split('/')[2];
    const res = await InvitationClient.getInvitationById(id);
    if (res.status === 200) {
      dispatch(saveInvitationLink(location.pathname));
      const accountPlan = res.data.accountPlan;
      switch (accountPlan) {
        case AccountPlan.SCHOOL:
          history.push(`/sign-up?tag=${AccountType.SCHOOL}`);
          break;
        case AccountPlan.PERFORMANCE:
          history.push(`/sign-up?tag=${AccountType.WORK}`);
          break;
        default:
          break;
      }
    }
  };

  useEffect(() => {
    if (user) {
      join();
    } else {
      redirectToSignup();
    }
  }, []);
  return <></>;
};
