import React from 'react';
import { FiSearch } from 'react-icons/fi';
import styled from 'styled-components';

interface iSearchProps {
  onChange: (data) => void;
  width: string;
}

const SearchContainer = styled.div<{ width?: string }>`
  position: relative;
  width: ${(props) => props.width};

  input {
    width: 100%;
    height: 40px;
    padding: 0 5px 0 40px;
    font-size: 14px;
    border-radius: 4px;
    border: solid 1px #d8d8d8;
    background-color: #ffffff;
  }

  svg {
    position: absolute;
    top: 50%;
    transform: translate(0%, -50%);
    left: 16px;
  }
`;

export const Search: React.FC<iSearchProps> = ({ onChange, width = '100%' }) => {
  return (
    <SearchContainer width={width}>
      <input onChange={onChange} placeholder="Search" />
      <FiSearch />
    </SearchContainer>
  );
};
