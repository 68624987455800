import React from 'react';

import Fade from '../Fade';
import { Expandable } from '../Expandable';
import { IconLabel } from '../_styled';

import { tSearchProps } from './_types';
import { SearchWrapper, StyledSearchIcon, InputWrapper, StyledInput } from './_styled';
import { analytics } from '../../../analytics/analytics';

export const SearchDesktop = ({ value, onChange }: tSearchProps) => {
  const [active, setActive] = React.useState(false);
  const inputRef = React.useRef();

  const onInputBlur = React.useCallback(() => {
    if (!value) {
      setActive(false);
    }
  }, [value]);

  const onInputChange = React.useCallback((e) => {
    onChange(e.target.value);
  }, []);

  React.useEffect(() => {
    if (active && inputRef.current) {
      // @ts-ignore
      inputRef.current.focus();
    }
  }, [active]);

  return (
    <SearchWrapper>
      <div
        style={{
          position: 'absolute',
          right: 0,
          top: 'calc(50% - 16px)',
          zIndex: active ? 0 : 1,
        }}
      >
        <Fade in={!active}>
          <Expandable
            width="94px"
            onClick={() => {
              setActive(true);
              analytics.dashboardSearch();
            }}
          >
            <div>
              <StyledSearchIcon />
              <IconLabel>Search</IconLabel>
            </div>
          </Expandable>
        </Fade>
      </div>
      <div
        style={{
          position: 'absolute',
          right: 0,
          top: 'calc(50% - 16px)',
          maxWidth: '290px',
        }}
      >
        <Fade in={active}>
          <InputWrapper>
            <StyledSearchIcon />
            <StyledInput
              ref={inputRef}
              onChange={onInputChange}
              placeholder="Search wheel, segment, person…"
              onBlur={onInputBlur}
              autoFocus
            />
          </InputWrapper>
        </Fade>
      </div>
    </SearchWrapper>
  );
};
