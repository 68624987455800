import services from '../../API/services';
import { put } from 'redux-saga/effects';
import { accountPermissionsAction } from './Auth.actions';
import { mixpanelAnalytics } from '../../analytics/mixpanel';

export function* authMiddleware(action) {
  const id = action.payload?.userRD?.user?._id || action.payload?._id;
  if (id) {
    const user = action.payload?.userRD?.user || action.payload;
    mixpanelAnalytics.identify(user.email);
    mixpanelAnalytics.people.set({
      $email: user.email,
      Name: `${user.first_name} ${user.last_name}`,
      User_id: user._id,
      Sign_up_date: user.createdAt,
      Account_role: user.accountRole,
    });
    const response = yield services.fetchAccountPermissions();
    yield put(accountPermissionsAction(response.data));
  }
}
