import { useState } from 'react';
import styled from 'styled-components';
import conversationIcon from '../../Assets/images/AI/conversation.png';
import { ChevronDown as ChevronDownIcon, ChevronUp as ChevronUpIcon } from 'react-feather';
import { ChatListItem, ShowBtn } from './_styled';

interface ChatListProps {
  chats: any[];
  activeChat: string | null;
  handleExistingChatClick: (chat: any) => void;
}

const ChatsList = ({ chats, activeChat, handleExistingChatClick }: ChatListProps) => {
  const [showMore, setShowMore] = useState(false);

  const toggleShowMore = () => {
    setShowMore(!showMore);
  };

  const renderChatItems = (chatList: any[]) =>
    chatList?.map((chat, idx) => (
      <ChatListItem
        key={idx}
        onClick={() => handleExistingChatClick(chat)}
        className={activeChat === chat.id ? 'active' : ''}
      >
        <img src={conversationIcon} width={16} alt="Conversation Icon" />
        <span>{chat.title}</span>
      </ChatListItem>
    ));

  const firstFiveChats = chats?.slice(0, 5);
  const remainingChats = chats?.slice(5);

  return (
    <>
      {renderChatItems(firstFiveChats)}
      {remainingChats?.length > 0 && (
        <>
          <ShowBtn onClick={toggleShowMore}>
            {showMore ? 'Show less' : 'Show more'}
            {showMore ? <ChevronUpIcon size={16} /> : <ChevronDownIcon size={16} />}
          </ShowBtn>
          {showMore && renderChatItems(remainingChats)}
        </>
      )}
    </>
  );
};

export default ChatsList;
