import { ToastActions } from '../../../Redux/Actions/ActionTypes';

export enum ToastTypes {
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
}

const defaultOptions = {
  color: '#6796e6',
  type: ToastTypes.SUCCESS,
};

let id = 0;

const createToastConfig = (options) => {
  return {
    ...defaultOptions,
    ...options,
    id: id++,
  };
};

export function addToast(options = {}) {
  return {
    payload: createToastConfig(options),
    type: ToastActions.ADD_TOAST,
  };
}

export function removeToast(id) {
  return {
    payload: id,
    type: ToastActions.REMOVE_TOAST,
  };
}
