import React from 'react';
import styled from 'styled-components';
import { Text } from '../../Shared/Typography/Typography';
import insightEmptyPageImg from '../../../Assets/images/insight-empty-page.png';
import insightEmptyPageImg2x from '../../../Assets/images/insight-empty-page@2x.png';
import insightEmptyPageImg3x from '../../../Assets/images/insight-empty-page@3x.png';
import { InsightsPeriodType } from '../insights.model';
import { CgLockUnlock } from 'react-icons/cg';
import services from '../../../API/services';
import { showInfoModal } from '../../Shared/InfoModal/InfoModal';
import { analytics } from '../../../analytics/analytics';

type Props = {
  isLockedInsights: boolean;
  period: InsightsPeriodType;
};

const NoInsightsComments: React.FC<Props> = ({ isLockedInsights, period }) => {
  const infoText = isLockedInsights
    ? 'Unlock your Insights to get data analytics and identify issues in all your wheels from one place.'
    : `Good news! No insights in this ${period}.`;

  const unlockInsightRequest = async () => {
    analytics.unlockRedFlags();
    await services
      .sendRequestToUnlockInsights()
      .then(
        (res) =>
          res.status === 200 &&
          showInfoModal({
            title: `Request sent!`,
            description: `Our team will contact you shortly via email.`,
          })
      )
      .catch((err) => err);
  };

  return (
    <Wrapper>
      {isLockedInsights && (
        <Text color="#2b2e31" size="16px">
          Insights is locked for your account
        </Text>
      )}
      <Text color="#6a777f" size="14px" lineHeight={1.7}>
        {infoText}
      </Text>
      {isLockedInsights && (
        <UnlockButton onClick={unlockInsightRequest}>
          <CgLockUnlock color="#2b2e31" size={14} />
          <Text size={14} margin="0 0 0 8px">
            Unlock Insights
          </Text>
        </UnlockButton>
      )}
      <StyledImg
        src={insightEmptyPageImg}
        srcSet={insightEmptyPageImg2x + ' 2x,' + insightEmptyPageImg3x + ' 3x'}
        alt="Dashboard Empty"
      />
    </Wrapper>
  );
};

export default NoInsightsComments;

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 72px;
  text-align: center;

  @media (max-width: 768px) {
    padding: 16px;
  }
`;

const UnlockButton = styled.div`
  display: flex;
  align-items: center;
  border: solid 1px rgba(64, 64, 66, 0.24);
  border-radius: 50px;
  background-color: #f8f8f8;
  color: #2b2e31;
  padding: 7px 12px;
  margin-top: 24px;
  margin-bottom: 8px;
  cursor: pointer;
`;

const StyledImg = styled.img`
  height: 100%;
  width: 100%;
  max-width: 600px;
`;
