import React, { Component } from 'react';
import * as _ from 'lodash';
import { iCheckinModalScore, iWheel } from '../../../API/interfaces';
import StepCounter from '../../WheelPage/CheckinModal/StepCounter/StepCounter';
import CheckinStep from './CheckinStep';
import services from '../../../API/services';

interface iCheckinModalProps {
  wheel: iWheel;
  onComplete: (checkin) => void;
}

interface iCheckinModalState {
  currentStep: number;
  scores: Array<iCheckinModalScore> | null;
}

class CheckinSteps extends Component<iCheckinModalProps, iCheckinModalState> {
  constructor(props) {
    super(props);

    this.state = {
      currentStep: 0,
      scores: props.wheel.segments.map((segment) => ({
        segmentId: segment.id,
        comment: null,
        score: 1,
        segmentName: segment.name,
      })),
    };
  }

  setScore = (score: number) => {
    const { scores, currentStep } = this.state;
    scores[currentStep].score = score;
    this.setState({ scores: [...scores] });
  };

  setComment = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const { scores, currentStep } = this.state;
    const comment = e.target.value;

    scores[currentStep].comment = comment;
    this.setState({ scores: [...scores] });
  };

  createCheckin = async () => {
    const { wheel } = this.props;
    const { scores } = this.state;

    const formData = new FormData();
    formData.append('scores', JSON.stringify(scores));

    const { data: checkin } = await services.createCheckin(wheel.id, formData);

    return checkin;
  };

  onBack = () => this.setState({ currentStep: this.state.currentStep - 1 });

  onNext = async () => {
    const isLast = this.state.currentStep === this.props.wheel.segments.length - 1;
    if (isLast) {
      const checkin = await this.createCheckin();
      this.props.onComplete(checkin);
    } else {
      this.setState({ currentStep: this.state.currentStep + 1 });
    }
  };

  render() {
    const { wheel } = this.props;
    const { currentStep, scores } = this.state;

    return (
      <>
        <StepCounter currentStep={currentStep} amountOfSegments={wheel.segments.length} />
        {currentStep < wheel.segments.length && (
          <CheckinStep
            wheel={wheel}
            scores={scores}
            currentStep={currentStep}
            isLast={currentStep === wheel.segments.length - 1}
            setScore={this.setScore}
            setComment={this.setComment}
            onBack={this.onBack}
            onNext={this.onNext}
          />
        )}
      </>
    );
  }
}

export default CheckinSteps;
