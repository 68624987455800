import { AccountPlan, iWheel } from '../../API/interfaces';
import { Invitation, InvitationStatus } from './invitation.model';

export const makeTitle = (accountPlan: AccountPlan) => {
  switch (accountPlan) {
    case AccountPlan.SCHOOL:
      return 'Teachers';
    case AccountPlan.PERFORMANCE:
      return 'Team Members';
    default:
      return null;
  }
};

export const makeEmptyStateText = (accountPlan: AccountPlan) => {
  switch (accountPlan) {
    case AccountPlan.SCHOOL:
      return 'No teachers have been invited or received an invitation yet';
    case AccountPlan.PERFORMANCE:
      return 'No team members have been invited or received an invitation yet';
    default:
      return null;
  }
};

export const makeInviteButtonText = (accountPlan: AccountPlan) => {
  switch (accountPlan) {
    case AccountPlan.SCHOOL:
      return 'INVITE TEACHERS';
    case AccountPlan.PERFORMANCE:
      return 'INVITE TEAM MEMBERS';
    default:
      return null;
  }
};

export const makeInvitationSuccessText = (accountPlan: AccountPlan) => {
  switch (accountPlan) {
    case AccountPlan.SCHOOL:
      return 'As soon as the teacher accepts the invitation his status will change from Pending to Joined.';
    case AccountPlan.PERFORMANCE:
      return 'As soon as the team member accepts the invitation his status will change from Pending to Joined.';
    default:
      return null;
  }
};

export const makeModalTitleText = (accountPlan: AccountPlan, resend: boolean) => {
  switch (accountPlan) {
    case AccountPlan.SCHOOL:
      if (resend) {
        return 'Re-invite teacher';
      } else {
        return 'Invite teachers';
      }
    case AccountPlan.PERFORMANCE:
      if (resend) {
        return 'Re-invite team member';
      } else {
        return 'Invite team members';
      }
    default:
      return null;
  }
};

export const makeModalDescriptionText = (accountPlan: AccountPlan, resend: boolean) => {
  switch (accountPlan) {
    case AccountPlan.SCHOOL:
      if (resend) {
        return 'The teacher could have missed your invitation. Remind them of the invitation again.';
      } else {
        return 'Add the email of the teachers you would like to invite and they will receive an invitation email and all admin rights.';
      }
    case AccountPlan.PERFORMANCE:
      if (resend) {
        return 'The team member could have missed your invitation. Remind them of the invitation again.';
      } else {
        return 'Add the email of the team members you would like to invite and they will receive an invitation email and all admin rights.';
      }
    default:
      return null;
  }
};

export const makeDeleteConfirmationText = (accountPlan: AccountPlan, invitationStatus: InvitationStatus) => {
  switch (accountPlan) {
    case AccountPlan.SCHOOL:
      if (invitationStatus === InvitationStatus.JOINED) {
        return 'This action will remove admin rights and delete the manager from all wheels as admin. You will inherit all wheels as an administrator.';
      } else {
        return 'The teacher will not be able to join the account.';
      }
    case AccountPlan.PERFORMANCE:
      if (invitationStatus === InvitationStatus.JOINED) {
        return 'This action will remove admin rights and delete the manager from all wheels as admin. You will inherit all wheels as an administrator.';
      } else {
        return 'The team member will not be able to join the account.';
      }
    default:
      return null;
  }
};

export const makeDeleteSuccessText = (
  accountPlan: AccountPlan,
  invitation: Invitation,
  wheelsReassigned: Array<iWheel>
) => {
  switch (accountPlan) {
    case AccountPlan.SCHOOL:
      if (wheelsReassigned?.length > 0) {
        return `${invitation.email} is removed from the teachers\' list successfully.
              You have become an admin of the next wheels:
              ${wheelsReassigned.map((wheel) => `<strong>${wheel.name}</strong> \n`).join('')}
              `;
      } else {
        return `${invitation.email} is removed from the teachers\' list successfully.`;
      }
    case AccountPlan.PERFORMANCE:
      if (wheelsReassigned?.length > 0) {
        return `${invitation.email} is removed from the team members\' list successfully.
              You have become an admin of the next wheels:
              ${wheelsReassigned.map((wheel) => `<strong>${wheel.name}</strong> \n`).join('')}
              `;
      } else {
        return `${invitation.email} is removed from the team members\' list successfully.`;
      }
    default:
      return null;
  }
};

export const makeTooltipText = (accountPlan: AccountPlan) => {
  switch (accountPlan) {
    case AccountPlan.SCHOOL:
      return "The teacher hasn't accepted the invitation yet. You can resend the invitation or wait until they accept it.";
    case AccountPlan.PERFORMANCE:
      return "The team member hasn't accepted the invitation yet. You can resend the invitation or wait until they accept it.";
    default:
      return null;
  }
};
