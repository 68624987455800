import React, { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Colors } from '../Shared/colors';
import { ManageContainer } from '../_Containers/ManageContainer';
import { BackButton } from '../Shared/Buttons/BackButton/BackButton';
import { Text } from '../Shared/Typography/Typography';
import { Tab, TabContainer } from '../Shared/TabContainer/TabContainer';
import { CrisisSupportTab } from './CrisisSupport';
import { useLocation } from 'react-router-dom';
import { Tutorials } from './Tutorials';
import { Resources } from './Resources';
import { analytics } from '../../analytics/analytics';
import { useSelector } from 'react-redux';
import { selectIpAddress } from '../Auth/Auth.selectors';

enum ModalTabs {
  Resources = 'Resources',
  Tutorial = 'Tutorial',
  CrisisSupport = 'Crisis Support',
}

export const Support: FC = () => {
  const location = useLocation();
  const [defaultTab, setDefaultTab]: [number, (value) => void] = useState(-1);
  const userIpAddress = useSelector(selectIpAddress);

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const type = query.get('type');
    if (type) {
      setDefaultTab(parseInt(type));
    } else {
      setDefaultTab(1);
    }
  }, []);

  const onChangeTab = (index) => {
    switch (index) {
      case 0:
        analytics.chooseResourceTab();
        break;
      case 1:
        analytics.chooseTutorialsTab();
        break;
      case 2:
        analytics.chooseCrisisSupportTab();
        break;
      default:
        break;
    }
  };

  const isAustraliaLocation = userIpAddress?.country === 'AU';

  return (
    <ManageContainer>
      <SupportContainer>
        <div className="mb-3 d-lg-block d-none">
          <BackButton text="Back" defaultLink="/dashboard" />
        </div>
        <TitleContainer>
          <Text color="#ffffff" size="40px" weight="bold">
            Support
          </Text>
        </TitleContainer>
        <div>
          {defaultTab >= 0 && (
            <TabContainer onChangeTab={onChangeTab} defaultTabIndex={defaultTab}>
              <Tab title={ModalTabs.Resources}>
                <TabWrapper>
                  <Resources />
                </TabWrapper>
              </Tab>
              <Tab title={ModalTabs.Tutorial}>
                <TabWrapper>
                  <Tutorials />
                </TabWrapper>
              </Tab>
              <Tab title={ModalTabs.CrisisSupport} show={isAustraliaLocation}>
                <TabWrapper>
                  <CrisisSupportTab />
                </TabWrapper>
              </Tab>
            </TabContainer>
          )}
        </div>
      </SupportContainer>
    </ManageContainer>
  );
};

const TitleContainer = styled.div`
  margin-bottom: 36px;
  @media (max-width: 768px) {
    margin: 0;
    padding: 40px 16px;
  }
`;

const SupportContainer = styled.div`
  position: relative;
  padding: 24px 5%;
  background-color: ${Colors.NAVI_BLUE};
  @media (max-width: 768px) {
    padding: 0;
  }
`;

const TabWrapper = styled.div`
  padding: 50px;
  position: relative;
  width: 100%;
  @media (max-width: 768px) {
    padding: 20px;
  }
`;
