import React from 'react';
import { ArrowLeft } from 'react-feather';
import './BackButton.scss';
import styled from 'styled-components';
import { useHistory } from 'react-router';

export const BackButton = ({ text, defaultLink }: { text: string; defaultLink: string }) => {
  const history = useHistory();

  const goBack = () => {
    if (history.length > 1) {
      history.goBack();
    } else {
      history.push(defaultLink);
    }
  };

  return (
    <Link onClick={goBack} className="back-button">
      <ArrowLeft color="#ffffff" size={18} className="arrow-left" />
      <span>{text}</span>
    </Link>
  );
};

const Link = styled.div`
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;
