import React from 'react';
import { MenuItem } from '@material-ui/core';
import { Check as CheckIcon } from 'react-feather';
import styled from 'styled-components';

import of from '../../../_utils/of';
import { iFolder } from '../../../API/interfaces';
import NestedMenuItem, { Direction } from './NestedMenuItem';
import { Option } from './_styled';
import { iHasChild } from '../_types';

export type tProps = iFolder & {
  onClick: (_) => void;
  name: string;
  containsWheel: boolean;
  optionsOpened: boolean;
};

export type tPropsWithChild = tProps & iHasChild<tPropsWithChild>;

const FolderMenuItem = ({ onClick, name, containsWheel, child, optionsOpened }: tPropsWithChild) => {
  const onClickWithoutPropagation = React.useCallback((e) => {
    e.stopPropagation();
    onClick(e);
  }, []);
  if (child?.length) {
    return (
      <NestedMenuItem
        direction={Direction.LEFT}
        mainMenuOpen={optionsOpened}
        onClick={onClickWithoutPropagation}
        label={
          <>
            {containsWheel && <StyledCheckIcon size={16} />}
            <StyledOption>{name}</StyledOption>
          </>
        }
      >
        {child.map(of(FolderMenuItem))}
      </NestedMenuItem>
    );
  }
  return (
    <MenuItem onClick={onClickWithoutPropagation} className="py-2 position-relative">
      {containsWheel && <StyledCheckIcon size={16} />}
      <StyledOption>{name}</StyledOption>
    </MenuItem>
  );
};

export default FolderMenuItem;

const StyledOption = styled(Option)`
  padding-left: 15px;
  padding-right: 20px;
`;

const StyledCheckIcon = styled(CheckIcon)`
  position: absolute;
  left: 16px;
`;
