import React, { Suspense, lazy } from 'react';

export default (importCb) => (props) => {
  const WrappedComponent = lazy(importCb);

  return (
    <Suspense fallback={<div>Loading</div>}>
      <WrappedComponent {...props} />
    </Suspense>
  );
};
