import React, { Component } from 'react';
import './ModalContainer.scss';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';

interface iModalContainerProps {
  close: (string, any?) => void;
  onClose?: (string?) => boolean;
  closeBtnText?: string;
  title?: any;
  className?: any;
  children: any;
  isMobile: any;
  backdrop: any;
}

interface iModalContainerState {
  isOpen: boolean;
  title: any;
  className: any;
}

class ModalContainer extends Component<iModalContainerProps, iModalContainerState> {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: true,
      title: props.title,
      className: props.className,
    };
  }

  toggle = (action?, payload?) => {
    this.setState({
      isOpen: !this.state.isOpen,
    });

    this.props.close(action, payload);
  };

  changeTitle = (title) => {
    this.setState({ title });
  };

  changeClassName = (className) => {
    this.setState({ className });
  };

  handleClose = () => {
    let result = true;

    if (this.props.onClose) {
      result = this.props.onClose();
    }

    if (result) {
      this.toggle();
    }
  };

  render() {
    const children = React.Children.map(this.props.children, (child, index) => {
      return React.cloneElement(child, {
        index,
        resultfunc: (action, payload) => this.toggle(action, payload),
        changeTitle: (title) => this.changeTitle(title),
        changeClassName: (className) => this.changeClassName(className),
      });
    });

    const closeBtn = this.props.closeBtnText ? (
      <button className="close" onClick={() => this.handleClose()}>
        {this.props.closeBtnText}
      </button>
    ) : (
      <button className="close" onClick={() => this.handleClose()}>
        &times;
      </button>
    );

    const backDrop = this.props.isMobile ? 'static' : this.props.backdrop ? this.props.backdrop : true;

    return (
      <Modal
        isOpen={this.state.isOpen}
        toggle={(e) => this.toggle()}
        className={this.state.className}
        backdrop={backDrop}
      >
        <ModalHeader style={this.state.title ? {} : { padding: '0' }} toggle={(e) => this.toggle()} close={closeBtn}>
          <span className="h2 mb-0">{this.state.title}</span>
        </ModalHeader>
        <ModalBody>{children}</ModalBody>
      </Modal>
    );
  }
}

export default ModalContainer;
