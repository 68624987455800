import React, { ChangeEvent, Component } from 'react';
import { ArrowLeft } from 'react-feather';
import { Checkbox as MuiCheckbox, withStyles } from '@material-ui/core';
import services from '../../../../API/services';
import { iScore, iUser, iWheel } from '../../../../API/interfaces';
import CountableTextBox from '../../../Shared/CountableTextBox/CountableTextBox';
import Wheel from '../../common/Wheel/Wheel';
import SegmentTags from '../../../Shared/SegmentTags/SegmentTags';
import { Spinner } from '../../../Shared/Spinner/Spinner';
import { analytics } from '../../../../analytics/analytics';
import { MAX_SCORE_COMMENT_LENGTH, WHEEL_ACCESS_TYPES } from '../../../../constants';
import AddCommentAttachment from '../../CommentsSection/AddCommentAttachment';
import styled from 'styled-components';
import { AssistMember } from '../../WheelPage';

interface iLastStepProps {
  wheel: iWheel;
  scores: Array<iScore>;
  comment: string;
  setComment: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  onBack: () => void;
  toggle: (isCheckinCompleted: boolean, checkin?) => void;
  assistMember?: AssistMember;
  isHideCheckinAssistInfoChecked?: boolean;
  onCheckinAssistConfirm?: (index: number, id: string) => void;
}

interface iLastStepState {
  isFetching: boolean;
  activeSegmentId: string | null;
  averageScore: number;
  isFollowUpRequested: boolean;
  file: File;
}

const TextareaContainer = styled.div`
  margin-bottom: 8px;
`;

export default class extends Component<iLastStepProps, iLastStepState> {
  constructor(props) {
    super(props);

    this.state = {
      isFetching: false,
      activeSegmentId: null,
      averageScore: 0,
      isFollowUpRequested: false,
      file: null,
    };
  }

  onMouseOver = (segmentId: string) => this.setState({ activeSegmentId: segmentId });

  onMouseOut = () => this.setState({ activeSegmentId: null });

  onConfirm = () => {
    const { file, isFollowUpRequested } = this.state;
    const {
      wheel,
      comment,
      scores,
      toggle,
      assistMember = null,
      isHideCheckinAssistInfoChecked = false,
      onCheckinAssistConfirm,
    } = this.props;
    const isPublic = wheel.access === WHEEL_ACCESS_TYPES.PUBLIC;

    const formData = new FormData();
    comment && formData.append('comment', comment);
    scores && formData.append('scores', JSON.stringify(scores));
    formData.append('isFollowUpRequested', JSON.stringify(isFollowUpRequested));
    file && formData.append('attachment', file, file.name);
    // if !!assistMember it is Check in Assist
    if (assistMember) {
      formData.append('member_id', assistMember.id); // wheel member for whom Check in Assist was made
      isHideCheckinAssistInfoChecked &&
        formData.append('disableAssistInfoModal', JSON.stringify(isHideCheckinAssistInfoChecked)); // to hide Check in Assist info popup
    }

    this.setState({ isFetching: true }, () =>
      services[isPublic ? 'createPublicCheckin' : 'createCheckin'](wheel.id, formData).then(({ data }) => {
        this.setState({ isFetching: false }, () => toggle(true, { comment, scores, ...data }));
        if (assistMember) {
          const memberResultsTabIndex = 2;
          onCheckinAssistConfirm(memberResultsTabIndex, assistMember.id);
        }
      })
    );

    assistMember ? analytics.submitCheckinAssist() : analytics.createCheckinSubmit(isPublic);
  };

  shouldShowFollowUpCheckbox() {
    const { wheel, scores } = this.props;
    return (
      wheel.wheelCreator.account.followUpRequest &&
      wheel.wheelCreator.account.teamAlert &&
      wheel.wheelCreator.account.teamAlertThreshold &&
      wheel.access !== WHEEL_ACCESS_TYPES.PUBLIC &&
      scores.some((score) => score.score < wheel.wheelCreator.account.teamAlertThreshold)
    );
  }

  componentDidMount() {
    const { scores, wheel } = this.props;
    this.setState({
      averageScore: +(scores.reduce((agg, score) => agg + score.score, 0) / wheel.segments.length).toFixed(1),
    });
  }

  handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      this.setState({ file: e.target.files[0] });
    }
  };

  render() {
    const { isFetching, averageScore, activeSegmentId } = this.state;
    const { wheel, scores, comment, setComment, onBack } = this.props;
    const showFollowUp = this.shouldShowFollowUpCheckbox();

    return (
      <>
        {!isFetching && (
          <>
            <div className="checkin-results-container">
              <div className="final-wheel-container">
                <Wheel
                  width={180}
                  maxScale={wheel?.maxScale}
                  averageScore={averageScore}
                  scores={scores}
                  activeSegmentId={activeSegmentId}
                  onMouseOver={this.onMouseOver}
                  onMouseOut={this.onMouseOut}
                  id="final_checkin_wheel"
                />
              </div>

              <SegmentTags
                scores={scores}
                activeSegmentId={activeSegmentId}
                onMouseOver={this.onMouseOver}
                onMouseOut={this.onMouseOut}
              />
            </div>

            {wheel.isGeneralComments && (
              <div className="comment-container">
                <div className="tell-us">
                  Tell us more<span>(optional)</span>
                </div>

                <TextareaContainer>
                  <CountableTextBox
                    id="comment"
                    placeholder="Add a comment to the check in"
                    value={comment}
                    onChange={setComment}
                    max={MAX_SCORE_COMMENT_LENGTH}
                    focusEnabled={false}
                    multipleLine
                  />
                </TextareaContainer>
                <AddCommentAttachment
                  isFullWidth
                  file={this.state.file}
                  onEdit={this.handleFileChange}
                  onRemove={() => this.setState({ file: null })}
                />
              </div>
            )}

            {showFollowUp && (
              <div className="follow-up">
                <Checkbox onChange={(e) => this.setState({ isFollowUpRequested: e.target.checked })} />
                <div className="follow-up-text">
                  <div className="follow-up-text-primary">I would like to have a chat about this check in</div>
                  <div className="follow-up-text-secondary">
                    If you would like someone to follow up with you on this check in and organise a time to discuss
                    click here, they will receive an email and be in touch.
                  </div>
                </div>
              </div>
            )}

            <div className="buttons">
              <div className="secondary-button">
                <button onClick={onBack}>
                  <ArrowLeft size={13} /> BACK
                </button>
              </div>
              <div className="primary-button">
                <button onClick={this.onConfirm}>{showFollowUp ? 'COMPLETE AND REQUEST MEETING' : 'CONFIRM'}</button>
              </div>
            </div>
          </>
        )}

        {isFetching && (
          <div className="spinner-container">
            <Spinner />
          </div>
        )}
      </>
    );
  }
}

const Checkbox = withStyles({
  root: {
    marginRight: '16px',
    color: '#ffe2c1',
    '&$checked': {
      color: '#ff554d',
    },
  },
  checked: {},
})(MuiCheckbox);
