import React from 'react';
import { connect } from 'react-redux';
import {
  Checkbox,
  FormControlLabel,
  makeStyles,
  RadioGroup,
  TextField,
  Typography,
  withStyles,
} from '@material-ui/core';
import MuiRadio from '@material-ui/core/Radio';
import { CheckBoxOutlineBlank } from '@material-ui/icons';
import { AccountPlan, iUser, WheelAccess, WheelStep } from '../../../../API/interfaces';
import Section from '../common/Section';
import { MAX_WHEEL_SCALE, MIN_WHEEL_SCALE, WHEEL_ACCESS_TYPES, WHEEL_STEP_TYPES } from '../../../../constants';

interface iSettingStep {
  isTemplate: boolean;
  user: iUser;
  access: WheelAccess;
  step: WheelStep;
  maxScale: number;
  isScoreComments: boolean;
  onChange: (filedName: string, value: any) => void;
}

const SettingsStep = ({ user, access, step, isScoreComments, maxScale, onChange, isTemplate }: iSettingStep) => {
  const classes = useStyles();

  return (
    <>
      <Section number={4} title={`What kind of ${isTemplate ? 'template' : 'wheel'} do you want to create?`}>
        <RadioGroup
          classes={{ root: classes.radioGroup }}
          name="access"
          value={access}
          onChange={(e) => onChange('access', e.target.value)}
        >
          <FormControlLabel value={WHEEL_ACCESS_TYPES.PRIVATE} control={<Radio />} label="Private" />
          <Typography className={classes.accessText}>
            Private allows you to invite peers to join, you will be able to see the results of everyone who joins.
          </Typography>
          <FormControlLabel value={WHEEL_ACCESS_TYPES.PUBLIC} control={<Radio />} label="Public" />
          <Typography className={classes.accessText}>
            Public is for presentations or workshops, this is an anonymous pulse check.
          </Typography>
        </RadioGroup>
      </Section>
      <Section number={5} title="How would you like your scale?">
        <div className={classes.maxScale}>
          <Typography className={classes.maxScaleText}>Score from 1 - </Typography>
          <TextField
            type="number"
            inputProps={{ min: MIN_WHEEL_SCALE, max: MAX_WHEEL_SCALE }}
            variant="outlined"
            value={maxScale}
            onChange={(e) => onChange('maxScale', +e.target.value)}
          />
        </div>

        <RadioGroup
          classes={{ root: classes.radioGroup }}
          name="step"
          value={step}
          onChange={(e) => onChange('step', e.target.value)}
        >
          <FormControlLabel value={WHEEL_STEP_TYPES.NUMBER} control={<Radio />} label="Decimal Point (eg. 7.9)" />
          <FormControlLabel value={WHEEL_STEP_TYPES.INTEGER} control={<Radio />} label="Whole Number (eg. 7)" />
        </RadioGroup>
      </Section>
      {user.account?.plan && user.account.plan !== AccountPlan.FREE && (
        <Section number={6} title="Would you like members to leave segment comments?">
          <FormControlLabel
            classes={{ root: classes.formControl, label: classes.formControlLabel }}
            control={
              <Checkbox
                icon={<CheckBoxOutlineBlank className={classes.checkboxUnchecked} />}
                checked={isScoreComments}
                onChange={(e) => onChange('isScoreComments', e.target.checked)}
                name="isScoreComments"
                color="primary"
              />
            }
            label="Yes, I want to have segment comments"
          />
        </Section>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({ user: state.userRD.user });

export default connect(mapStateToProps)(SettingsStep);

const Radio = withStyles({
  root: {
    color: '#0c2337',
    '&$checked': {
      color: '#0c2337',
    },
  },
})((props) => <MuiRadio color="default" {...props} />);

const useStyles = makeStyles({
  accessText: {
    marginLeft: '42px',
    fontSize: '14px',
    lineHeight: 1.43,
    color: '#0c2337',
  },
  maxScale: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '24px',
  },
  maxScaleText: {
    paddingTop: '3px',
    marginRight: '8px',
    fontSize: '16px',
    lineHeight: 1.5,
    color: '#0c2337',
  },
  radioGroup: {
    marginTop: '24px',
    marginBottom: '32px',
  },
  checkboxUnchecked: {
    stroke: '#fff',
    fill: '#ffd29b',
  },
  formControl: {
    marginTop: '24px',
    marginBottom: 0,
  },
  formControlLabel: {
    marginLeft: '8px',
    color: '#0c2337',
  },
});
