import React from 'react';
import { connect } from 'react-redux';
import ModalContainer from '../../_Containers/ModalContainer';
import { TUTORIAL_VIDEO_URL } from '../../../constants';

interface iTutorialModalProps {
  isMobile: boolean;
  toggle: () => void;
}

const TutorialModal = ({ isMobile, toggle }: iTutorialModalProps) => (
  <ModalContainer title="Tutorial" close={toggle} className="xx-lg-dialog" isMobile={isMobile} backdrop="static">
    <iframe
      width="100%"
      height="678"
      src={TUTORIAL_VIDEO_URL}
      frameBorder="0"
      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
    />
  </ModalContainer>
);

const mapStateToProps = (state) => ({
  isMobile: state.windowRD.viewModal === 'mobile',
});

export default connect(mapStateToProps)(TutorialModal);
