import ListGroup from 'reactstrap/es/ListGroup';
import ListGroupItem from 'reactstrap/es/ListGroupItem';
import { SegmentItem } from './SegmentItem';
import { AddSegmentItem } from './AddSegmentItem';
import React, { Component } from 'react';
import services from '../../API/services';
import Alert from 'reactstrap/es/Alert';
import { iWheel, iWheelTemplate } from '../../API/interfaces';

interface iWheelSegmentsProps {
  wheelId: string;
  wheel: iWheel | iWheelTemplate;
  isTemplate: boolean;
}

interface iWheelSegmentsState {
  segments: Array<Object>;
}

export class WheelSegments extends Component<iWheelSegmentsProps, iWheelSegmentsState> {
  readonly state: iWheelSegmentsState = {
    segments: [],
  };

  constructor(props) {
    super(props);
    this.loadSegments();
  }

  async loadSegments() {
    const { wheelId, isTemplate } = this.props;

    const res = await services.getSegmentsList({ wheelId, isTemplate });

    if (res.status === 200) {
      const segments = res.data.filter((segment) => segment.name !== '_comments');
      this.setState({ segments });
    }
  }

  handleDeleteSegment = (deletedSegment) => {
    const newSegmentsList = this.state.segments.filter((segment: any) => segment._id !== deletedSegment._id);
    this.setState({ segments: newSegmentsList });
  };

  handleAddSegment = async (segment) => {
    const { wheel, isTemplate } = this.props;

    const segmentData = {
      name: segment.name,
      description: segment.description,
      [isTemplate ? 'template_id' : 'wheel_id']: wheel.id,
    };

    const res = await services.addSegment(segmentData);

    if (res.status === 200 && (res.data.wheel_id || res.data.template_id)) {
      this.setState({ segments: [...this.state.segments, res.data] });
    }
  };

  render() {
    const { segments } = this.state;

    return (
      <>
        <h4 className="mb-3">Segments</h4>
        <Alert color="warning" isOpen={segments.length <= 3}>
          The minimum number of segments is 3
        </Alert>
        <ListGroup>
          {segments &&
            segments.map((segment: any) => (
              <ListGroupItem key={segment._id}>
                <SegmentItem
                  segment={segment}
                  amountOfSegments={segments.length}
                  handleDeleteSegment={this.handleDeleteSegment}
                />
              </ListGroupItem>
            ))}
          <ListGroupItem>
            <AddSegmentItem segments={segments} addSegment={this.handleAddSegment} />
          </ListGroupItem>
        </ListGroup>
      </>
    );
  }
}
