import { useState, useEffect, useRef } from 'react';
import { Text } from '../Shared/Typography/Typography';

const TypingEffect = ({ text }) => {
  const [displayText, setDisplayText] = useState('');
  const [currentIndex, setCurrentIndex] = useState(0);
  const [autoScroll, setAutoScroll] = useState(true);
  const textRef = useRef(null);

  useEffect(() => {
    const typingTimer = setTimeout(() => {
      if (currentIndex < text.length) {
        setDisplayText((prevText) => prevText + text[currentIndex]);
        setCurrentIndex((prevIndex) => prevIndex + 1);
        if (autoScroll) {
          scrollToBottom();
        }
      }
    }, 5);

    return () => clearTimeout(typingTimer);
  }, [currentIndex, text, autoScroll]);

  useEffect(() => {
    const handleScroll = () => {
      setAutoScroll(false);
    };

    if (textRef.current) {
      textRef.current.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (textRef.current) {
        textRef.current.removeEventListener('scroll', handleScroll);
      }
    };
  }, []);

  const scrollToBottom = () => {
    if (textRef.current) {
      textRef.current.scrollIntoView({ block: 'end' });
    }
  };

  return (
    <Text ref={textRef} size="14px" onWheel={() => setAutoScroll(false)}>
      {displayText}
    </Text>
  );
};

export default TypingEffect;
