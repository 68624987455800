import React from 'react';

import Fade from '../Fade';
import { IconLabel } from '../_styled';

import { tSearchProps } from './_types';
import { SearchWrapperMobile, Wrapper, StyledSearchIcon, InputWrapper, StyledInput } from './_styled';

export const SearchMobile = ({ value, onChange }: tSearchProps) => {
  const [active, setActive] = React.useState(false);
  const inputRef = React.useRef();

  const onInputBlur = React.useCallback(() => {
    if (!value) {
      setActive(false);
    }
  }, [value]);

  const onInputChange = React.useCallback((e) => {
    onChange(e.target.value);
  }, []);

  React.useEffect(() => {
    if (active && inputRef.current) {
      // @ts-ignore
      inputRef.current.focus();
    }
  }, [active]);

  return (
    <SearchWrapperMobile>
      <div style={{ position: 'absolute', right: 0, top: 'calc(50% - 16px)' }}>
        <Fade in={!active}>
          <Wrapper width="94px" onClick={() => setActive(true)}>
            <div>
              <StyledSearchIcon />
              <IconLabel>Search</IconLabel>
            </div>
          </Wrapper>
        </Fade>
      </div>
      <div style={{ position: 'absolute', right: 0, top: 'calc(50% - 16px)', width: '100%' }}>
        <Fade in={active}>
          <InputWrapper>
            <StyledSearchIcon />
            <StyledInput ref={inputRef} onChange={onInputChange} placeholder="Search" onBlur={onInputBlur} autoFocus />
          </InputWrapper>
        </Fade>
      </div>
    </SearchWrapperMobile>
  );
};
