import React from 'react';
import { makeStyles } from '@material-ui/core';
import { X } from 'react-feather';
import { iSegment } from '../../../../API/interfaces';
import { GRADIENTS } from '../../../Shared/colors';

interface iSegmentListProps {
  segments: Array<Partial<iSegment>>;
  removeSegmentFromList: (segment: Partial<iSegment>) => void;
}

export default ({ segments, removeSegmentFromList }: iSegmentListProps) => {
  const classes = useStyles();

  return (
    <div className={classes.segmentList}>
      {segments.map((segment, i) => {
        const gi = i % GRADIENTS.length;

        return (
          <div
            key={segment._id}
            className={classes.segment}
            style={{ backgroundImage: `linear-gradient(110deg, ${GRADIENTS[gi][0]}, ${GRADIENTS[gi][1]})` }}
          >
            <span>{segment.name}</span>
            <X size={14} stroke="#fff" onClick={() => removeSegmentFromList(segment)} />
          </div>
        );
      })}
    </div>
  );
};

const useStyles = makeStyles({
  segmentList: {
    display: 'flex',
    flexWrap: 'wrap',
    marginTop: '4px',
  },
  segment: {
    marginTop: '12px',
    marginRight: '8px',
    padding: '6px 12px',
    fontSize: '14px',
    lineHeight: 1.43,
    borderRadius: '20px',
    backgroundColor: '#ededed',
    '& span': {
      marginRight: '4px',
      color: '#fff',
    },
    '& svg': {
      cursor: 'pointer',
    },
  },
});
