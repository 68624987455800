import * as Yup from 'yup';
import jq from 'jquery';
import { default as axios } from 'axios';
import _ from 'lodash';
import { loadingChange } from '../../Redux/Actions/Actions';
import resources from '../../API/resources';
import { store } from '../../Redux/store';
import {
  MAX_DESCRIPTION_LENGTH,
  MAX_SEGMENT_NAME_LENGTH,
  MAX_SEGMENTS,
  MAX_WHEEL_NAME_LENGTH,
  MAX_WHEEL_SCALE,
  MIN_SEGMENTS,
  MIN_WHEEL_SCALE,
} from '../../constants';
import { iMember, iScoresAggregation, iWheel, WheelMemberRole, iWheelMember } from '../../API/interfaces';

function getInvalidMsgOfField(context, formName, fieldName, invalidType, defaultMsg = '') {
  try {
    return resources[context].fieldValidationMsg[formName][fieldName][invalidType];
  } catch (ex) {
    return defaultMsg;
  }
}

function getErrorMsg(context, category, id, defaultMsg = '') {
  try {
    console.log(resources[context].errorMsg[category][id]);
    return resources[context].errorMsg[category][id];
  } catch (ex) {
    console.log(ex);
    return defaultMsg;
  }
}

export default {
  startLoading: () => {
    const st = jq(window).scrollTop();
    const headHeight = 120;

    jq('body,.modal-open .modal').css({
      'overflow-y': 'hidden',
    });

    jq('.app-loader-img, .app-loader-img-hide').css({
      top: document.documentElement.clientHeight / 2 + headHeight + st - 25 + 'px',
    });
    store.dispatch(loadingChange(true));
  },

  endLoading: () => {
    jq('body,.modal-open .modal').css({
      'overflow-y': 'auto',
    });
    store.dispatch(loadingChange(false));
  },

  genCancelTokenSource: () => axios.CancelToken.source(),

  submittingGuard: async (formEvent, a_func) => {
    formEvent.preventDefault();

    if (!formEvent.target) return;

    const element = formEvent.target.querySelector("button[type='submit']");

    if (element) element.disabled = true;

    let timer: any = 0;

    try {
      await a_func();
    } finally {
      if (element)
        timer = setTimeout(() => {
          element.disabled = false;
          clearTimeout(timer);
        }, 2000);
    }
  },

  keepDecimal: (value: any, digit = 1) => {
    const d: any = Math.pow(10, digit);
    return Math.round(parseFloat(String(value * d))) / d;
  },

  testValidScope(user) {
    if (!user)
      return {
        valid: false,
        redirectUrl: '/login',
      };

    return {
      valid: true,
    };
  },

  getFormValidationSchema(formName, context = '_default') {
    const _g = (fieldName, type) => getInvalidMsgOfField(context, formName, fieldName, type);

    switch (formName) {
      case 'login':
        return {
          password: Yup.string().required(_g('password', 'required')).min(1, _g('password', 'required')),
          email: Yup.string().required(_g('email', 'required')).email(_g('email', 'email')),
        };
      case 'resetpwd':
        return {
          email: Yup.string().required(_g('email', 'required')).email(_g('email', 'email')),
        };
      case 'password':
        return {
          pass: Yup.string().required(_g('pass', 'required')).min(6, _g('pass', 'min')),
          newPass: Yup.string()
            .required(_g('newPass', 'required'))
            .min(6, _g('newPass', 'min'))
            .max(32, _g('newPass', 'max')),
          confirmPass: Yup.string()
            .required(_g('confirmPass', 'required'))
            .oneOf([Yup.ref('newPass'), null], _g('confirmPass', 'consist')),
        };
      case 'wheel':
        return {
          name: Yup.string()
            .required(_g('name', 'required'))
            .min(1, _g('name', 'min'))
            .max(MAX_WHEEL_NAME_LENGTH, _g('name', 'max')),
          total: Yup.number().min(MIN_SEGMENTS, _g('total', 'min')).max(MAX_SEGMENTS, _g('total', 'max')),
          max_scale: Yup.number()
            .strict(true)
            .integer()
            .typeError(_g('max_scale', 'type'))
            .required(_g('max_scale', 'required'))
            .min(MIN_WHEEL_SCALE, _g('max_scale', 'min'))
            .max(MAX_WHEEL_SCALE, _g('max_scale', 'max')),
        };
      case 'wheel_edit':
        return {
          name: Yup.string()
            .required(_g('name', 'required'))
            .min(1, _g('name', 'min'))
            .max(MAX_WHEEL_NAME_LENGTH, _g('name', 'max')),
        };
      case 'score':
        return {
          comments: Yup.string().max(150, _g('comments', 'max')),
        };
      case 'segment':
        return {
          name: Yup.string()
            .required(_g('name', 'required'))
            .min(1, _g('name', 'min'))
            .max(MAX_SEGMENT_NAME_LENGTH, _g('name', 'max')),
          description: Yup.string().max(MAX_DESCRIPTION_LENGTH, _g('description', 'max')).nullable(),
        };
      case 'settings':
        return {
          firstName: Yup.string().required(_g('firstName', 'required')).min(1, _g('firstName', 'min')),
          lastName: Yup.string().required(_g('lastName', 'required')).min(1, _g('lastName', 'min')),
          email: Yup.string().required(_g('email', 'required')).email(_g('email', 'email')),
        };
      case 'signup':
        return {
          first_name: Yup.string().required(_g('first_name', 'required')).min(1, _g('first_name', 'min')),
          last_name: Yup.string().required(_g('last_name', 'required')).min(1, _g('last_name', 'min')),
          email: Yup.string().required(_g('email', 'required')).email(_g('email', 'email')),
          password: Yup.string()
            .required(_g('password', 'required'))
            .min(6, _g('password', 'min'))
            .max(32, _g('password', 'max')),
          confPassword: Yup.string()
            .required(_g('confPassword', 'required'))
            .oneOf([Yup.ref('password'), null], _g('confPassword', 'consist')),
        };
      default:
        break;
    }

    return null;
  },

  validateFields: (schema, dataToValidate) => {
    if (!schema) {
      throw Error('no validation rules found');
    }
    const result = {};

    for (const key in schema) {
      const shape = Yup.object().shape({ [key]: schema[key] });

      try {
        shape.validateSync(dataToValidate);
      } catch (err: any) {
        if (err.errors) {
          result[err.path] = err.errors[0];
        }
      }
    }

    if (Object.keys(result).length === 0 && result.constructor === Object) {
      return null;
    }

    return result;
  },

  _EMSG: (category, id, context = '_default') => {
    return getErrorMsg(context, category, id);
  },

  checkIsPhone: (): boolean => {
    return !!(
      navigator.userAgent.match(/Android/i) ||
      navigator.userAgent.match(/webOS/i) ||
      navigator.userAgent.match(/iPhone/i) ||
      navigator.userAgent.match(/iPad/i) ||
      navigator.userAgent.match(/iPod/i) ||
      navigator.userAgent.match(/BlackBerry/i) ||
      navigator.userAgent.match(/Windows Phone/i)
    );
  },
};

export const isWheelAdmin = (wheel: iWheel, memberId: string): boolean => {
  const isAdmin = (member: iMember) => member?.memberRole === WheelMemberRole.ADMIN;
  const member = wheel?.members.find((member) => member.userId === memberId);
  return member && isAdmin(member);
};

export const isWheelAccountabilityBuddy = (wheel: iWheel, memberId: string): boolean => {
  const isAccountabilityBuddy = (member: iMember) => member?.memberRole === WheelMemberRole.ACCOUNTABILITY_BUDDY;
  const member = wheel?.members.find((member) => member.userId === memberId);
  return member && isAccountabilityBuddy(member);
};

export const isWheelMemberRole = (wheel: iWheel, memberId: string): boolean => {
  const isMember = (member: iMember) => member?.memberRole === WheelMemberRole.MEMBER;
  const member = wheel?.members.find((member) => member.userId === memberId);
  return member && isMember(member);
};

export const isWheelMember = (members: iMember[], memberId: string): boolean => {
  const member = members.find((member) => member.userId === memberId);
  return !!member;
};

export const getWheelMember = (members: iWheelMember[], memberId: string): iWheelMember =>
  members.find((member) => member.userId === memberId);

export const getAverageScore = (scores: Array<iScoresAggregation>): number => {
  if (_.isEmpty(scores)) return 0;

  return Number((scores.reduce((agg, score) => agg + Number(score.score), 0) / scores.length).toFixed(1));
};

export const validateEmail = (email) => {
  const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
};

export const getWheelRole = (wheel: iWheel, userId: string) =>
  wheel?.members?.find((member) => member.userId === userId)?.memberRole;

export const getWheelRoleWithAnonymous = (wheel: iWheel, userId: string) =>
  wheel?.members?.find((member) => member.userId === userId)?.memberRole || 'anonymous';
