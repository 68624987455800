import { ArrowLeft, ArrowRight } from 'react-feather';
import { useHistory } from 'react-router-dom';
import { useState, useEffect } from 'react';
import {
  AccountButton,
  BlackBtn,
  BlackButtonBox,
  ContentBox,
  FieldWrapper,
  GoalsOptionsContainer,
  LeftPanel,
  RedBtn,
  RedButtonBox,
  Root,
} from './_styled';
import { RightScreen } from './RightScreen';
import { Text } from '../../Shared/Typography/Typography';
import { accountTypes } from './helpers';
import { RootState } from '../../../Redux/Reducers';
import { connect } from 'react-redux';
import { analytics } from '../../../analytics/analytics';

const AccountTypeSelection = ({ user, onSelect, onBack, move }) => {
  const [userType, setUserType] = useState<string | null>(null);

  useEffect(() => {
    if (user.personalData.userTag) {
      setUserType(user.personalData.userTag);
    }
  }, [user]);

  const handleAccountSelect = (title: string) => {
    setUserType(title);
  };

  const handleNext = () => {
    analytics.clickOnbUserTagAdded();
    move();
    onSelect(userType);
  };

  return (
    <Root>
      <LeftPanel>
        <BlackButtonBox>
          <BlackBtn onClick={onBack}>
            <ArrowLeft size={13} /> BACK
          </BlackBtn>
        </BlackButtonBox>
        <ContentBox style={{ justifyContent: 'center' }}>
          <Text className="mb-2" weight={600} size="36px" color="#2b2e31">
            What is your main use?
          </Text>
          <FieldWrapper>
            <GoalsOptionsContainer>
              {accountTypes.map((account) => (
                <AccountButton
                  key={account.title}
                  selected={userType === account.title}
                  onClick={() => handleAccountSelect(account.title)}
                >
                  <Text weight={500} size="18px">
                    {account.title}
                  </Text>
                  <Text size="14px">{account.subtitle}</Text>
                </AccountButton>
              ))}
            </GoalsOptionsContainer>
          </FieldWrapper>
          {userType && (
            <RedButtonBox>
              <RedBtn onClick={handleNext}>
                NEXT <ArrowRight size={13} />
              </RedBtn>
            </RedButtonBox>
          )}
        </ContentBox>
      </LeftPanel>
      <RightScreen />
    </Root>
  );
};

const mapStateToProps = (state: RootState) => ({
  user: state.userRD.user || {},
});

export default connect(mapStateToProps)(AccountTypeSelection);
