import React, { Component } from 'react';
import Button from 'reactstrap/es/Button';
import Input from 'reactstrap/es/Input';
import InputGroup from 'reactstrap/es/InputGroup';
import Label from 'reactstrap/es/Label';
import FormGroup from 'reactstrap/es/FormGroup';
import services from '../../API/services';
import Alert from 'reactstrap/es/Alert';
import { ButtonWithConfirmation } from '../Shared/ConfirmModal/ButtonWithConfirmation';
import './SegmentItem.scss';
import utils from '../Shared/utils';

interface iSegmentItemProps {
  segment: any;
  handleDeleteSegment: (any) => void;
  amountOfSegments: number;
}

interface iSegmentItemState {
  segment: any;
  name: string;
  description: string;
  active: boolean;
  alertVisible: boolean;
  confirmationModal: boolean;
}

export class SegmentItem extends Component<iSegmentItemProps, iSegmentItemState> {
  state: iSegmentItemState = {
    segment: this.props.segment,
    name: this.props.segment.name,
    description: this.props.segment.description,
    active: false,
    alertVisible: false,
    confirmationModal: false,
  };

  readonly schema = utils.getFormValidationSchema('segment');

  constructor(props: iSegmentItemProps) {
    super(props);
  }

  changeView = () => {
    this.setState({
      active: !this.state.active,
    });
  };

  handleChangeName = (event) => {
    this.setState({ name: event.target.value });
  };

  handleChangeDescription = (event) => {
    this.setState({ description: event.target.value });
  };

  handleSave = async () => {
    const res = await services.updateSegment({
      ...this.state.segment,
      name: this.state.name,
      description: this.state.description,
    });
    if (res.status === 200) {
      this.setState({ segment: res.data, alertVisible: true });
    }
  };

  onDismiss = () => {
    this.setState({ alertVisible: false });
  };

  deleteSegment = async () => {
    const res = await services.deleteSegment(this.state.segment._id);
    if (res.status === 200) {
      this.props.handleDeleteSegment(this.state.segment);
    }
  };

  render() {
    const errors: any = utils.validateFields(this.schema, {
      name: this.state.name,
      description: this.state.description,
    });

    return (
      <div className="segment-item">
        {!this.state.active && (
          <div>
            <div className="row justify-content-between">
              <div className="segment-item_name col-3">{this.state.segment.name}</div>
              <div className="col-9 d-flex justify-content-end">
                <Button size="sm" onClick={this.changeView} className="iyarn-button mr-2">
                  Edit
                </Button>
                <ButtonWithConfirmation
                  onConfirmed={this.deleteSegment}
                  message="You will lose all statistic about this segment and you will not be able to recover it any longer"
                  title="Are you sure you want to delete the segment?"
                  button={
                    <Button
                      disabled={this.props.amountOfSegments <= 3}
                      className="iyarn-button btn-danger"
                      size="sm"
                      color="danger"
                    >
                      Delete
                    </Button>
                  }
                />
              </div>
            </div>
            <div>
              {this.state.segment.description && 'Description:'} {this.state.segment.description}
            </div>
          </div>
        )}
        {this.state.active && (
          <div>
            <Alert color="success" isOpen={this.state.alertVisible} toggle={this.onDismiss}>
              Segment is updated!
            </Alert>
            <FormGroup>
              <Label className="d-block">Segment name</Label>
              <Input type="text" name="name" value={this.state.name} onChange={this.handleChangeName} />
              {errors && errors.name && (
                <Alert color="danger" className="mt-2">
                  {errors.name}
                </Alert>
              )}
            </FormGroup>
            <FormGroup>
              <Label className="d-block">Segment description</Label>
              <Input
                type="textarea"
                name="description"
                value={this.state.description}
                onChange={this.handleChangeDescription}
              />
              {errors && errors.description && (
                <Alert color="danger" className="mt-2">
                  {errors.description}
                </Alert>
              )}
            </FormGroup>
            <InputGroup className="justify-content-end mt-3">
              <Button size="sm" onClick={this.changeView} className="iyarn-button mr-2">
                Close
              </Button>
              <Button
                className="iyarn-button"
                size="sm"
                disabled={
                  (this.state.segment.name === this.state.name &&
                    this.state.segment.description === this.state.description) ||
                  !!errors
                }
                onClick={this.handleSave}
              >
                Save
              </Button>
            </InputGroup>
          </div>
        )}
      </div>
    );
  }
}
