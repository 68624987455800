import { Redirect, Route } from 'react-router-dom';
import utils from '../Shared/utils';
import { alertModal } from '../../Redux/Actions/Actions';
import React from 'react';
import { connect } from 'react-redux';

const PrivateRouteComponent = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) => {
      const testResult = utils.testValidScope(rest.user);

      if (!testResult.valid) {
        rest.setAlertModal(alertModal(utils._EMSG('response', 'need login')));
      }

      return testResult.valid ? <Component {...props} /> : <Redirect to="/" />;
    }}
  />
);

const mapStateToProps = (state) => {
  return {
    user: state.userRD.user ? state.userRD.user : null,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setAlertModal: (alertModal) => {
      dispatch(alertModal);
    },
  };
};

export const PrivateRoute = connect(mapStateToProps, mapDispatchToProps)(PrivateRouteComponent);
