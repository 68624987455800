import React from 'react';

interface iStepCounterProps {
  currentStep: number;
  amountOfSegments: number;
  hasLastStep?: boolean;
}

export default ({ currentStep, amountOfSegments, hasLastStep }: iStepCounterProps) => (
  <div className="step-counter">
    <span className="current-step">
      {`${currentStep === amountOfSegments ? 'Your result' : `Step ${currentStep + 1}`}`}
    </span>
    {currentStep < amountOfSegments && <span>{`/${amountOfSegments + (hasLastStep ? 1 : 0)}`}</span>}
  </div>
);
