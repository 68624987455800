import React, { ChangeEvent, Component } from 'react';
import { iUser, iWheel, ReminderFrequency } from '../../../../API/interfaces';
import { Section } from '../../../Shared/Layout/Section';
import { Text } from '../../../Shared/Typography/Typography';
import { CheckinReminderCalendar } from './CheckinReminderCalendar';
import { CheckinReminderFrequency } from './CheckinReminderFrequency';
import { CheckinReminderTime } from './CheckinReminderTime';
import { DateTime } from 'luxon';
import { FormControlLabel, Switch, withStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import { FaBellSlash } from 'react-icons/fa';
import { MembersClient } from '../../../../API/members.client';
import { toasterService } from '../../../Shared/Toaster/Toaster.service';
import { analytics } from '../../../../analytics/analytics';
import { Theme } from '@material-ui/core';

const styles = (theme: Theme) => ({
  wrapper: {
    padding: '45px',
    [theme.breakpoints.down('md')]: {
      padding: '16px',
    },
  },
  editorContainer: {
    margin: '24px 0',
    borderRadius: '4px',
    border: 'solid 1px #eaeaea',
    backgroundColor: '#f8f8f8',
    padding: '25px',
    [theme.breakpoints.down('md')]: {
      margin: '15px 0',
      padding: '16px',
    },
  },
  mainSwitcher: {
    margin: 0,
  },
  datePicker: {
    width: '250px',
    display: 'inline-flex',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      marginBottom: '15px',
    },
  },
  at: {
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '50px',
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  timePicker: {
    width: '150px',
    display: 'inline-flex',
    position: 'relative' as const,
    zIndex: 2,
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  frequencyPicker: {
    width: '450px',
    marginTop: '20px',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      marginTop: '15px',
    },
  },
  emptyWrapper: {
    height: '350px',
  },
  emptyIcon: {
    fontSize: '80px',
    color: '#cfd9e5',
    marginBottom: '32px',
    [theme.breakpoints.down('md')]: {
      fontSize: '60px',
    },
  },
  divider: {
    width: '100%',
    height: '1px',
    backgroundColor: '#dfdfdf',
    margin: '20px 0',
  },
});

interface iCheckinReminderProps {
  wheel: iWheel;
  user: iUser;
  classes: any;
}

interface iCheckinReminderState {
  frequency: ReminderFrequency;
  nextDate: string;
  isReminderEnabledForUser: boolean;
}

class CheckinReminderMemberComponent extends Component<iCheckinReminderProps, iCheckinReminderState> {
  constructor(props) {
    super(props);
    this.state = {
      frequency: props.wheel.reminder.frequency,
      nextDate: props.wheel.reminder.nextDate,
      isReminderEnabledForUser: this.checkIsReminderEnabled(props.wheel, props.user._id),
    };
  }

  checkIsReminderEnabled(wheel, userId) {
    const member = wheel.members.find((member) => member.userId === userId);
    return member?.isReminderEnabled;
  }

  setReminderStatus = async (value: ChangeEvent<HTMLInputElement>) => {
    const isReminderEnabledForUser: boolean = value.target.checked;
    if (!isReminderEnabledForUser) {
      analytics.checkinReminderMemberOff();
    }
    this.setState({ isReminderEnabledForUser }, async () => {
      await this.saveConfig();
    });
  };

  saveConfig = async () => {
    const res = await MembersClient.updateWheelMember(this.props.wheel.id, this.props.user._id, {
      isReminderEnabled: this.state.isReminderEnabledForUser,
    });
    toasterService.handleResponse(res, 'Subscription for check in reminder has been successfully updated');
  };

  render() {
    const { classes } = this.props;

    const MainSwitcher = (
      <FormControlLabel
        value="start"
        control={
          <Switch
            size="medium"
            color="secondary"
            checked={this.state.isReminderEnabledForUser}
            onChange={this.setReminderStatus}
          />
        }
        label={this.state.isReminderEnabledForUser ? 'On' : 'Off'}
        labelPlacement="start"
      />
    );

    const Header = (
      <div className="d-block d-lg-flex">
        <Section width="70%" mWidth="100%">
          <div className="d-flex flex-column w-100">
            <div className="d-flex align-items-center justify-content-between mb-2 mb-lg-0">
              <Text size="30px" weight="500" mSize="18px">
                Check in Reminder
              </Text>
              <div className="d-block d-lg-none">{MainSwitcher}</div>
            </div>
            {this.state.isReminderEnabledForUser && (
              <Text color="#86919a" size="14px">
                Time and frequency of reminders has been set by the wheel admin.
              </Text>
            )}
          </div>
        </Section>
        <Section width="30%" className="d-none d-lg-flex justify-content-end align-items-center">
          {MainSwitcher}
        </Section>
      </div>
    );

    const Editor = (
      <div className={classes.editorContainer}>
        <Text size="18px" weight="500">
          Admin of this wheel set up time and frequency to remind you to check in
        </Text>
        <div className="mt-4 d-block">
          <div className={classes.datePicker}>
            <CheckinReminderCalendar disabled={true} activeDate={DateTime.fromISO(this.state.nextDate)} />
          </div>
          <div className={classes.at}>
            <Text size="16px" color="#6a777f;">
              at
            </Text>
          </div>
          <div className={classes.timePicker}>
            <CheckinReminderTime disabled={true} activeTime={DateTime.fromISO(this.state.nextDate)} />
          </div>
        </div>
        <div className={classes.frequencyPicker}>
          <CheckinReminderFrequency disabled={true} activeFrequency={this.state.frequency} />
        </div>
      </div>
    );

    const EmptyState = (
      <div>
        <div className={classes.divider} />
        <div
          className={`${classes.emptyWrapper} d-flex align-items-center justify-content-lg-center justify-content-start flex-column`}
        >
          <FaBellSlash className={classes.emptyIcon} />
          <Text size="30px" mSize="16px" weight="500">
            Reminders are off.
          </Text>
          <Text color="#86919a" size="14px" className="mt-3 mt-lg-0">
            The admin of this wheel has set reminders for this wheel. You can reactivate to recieve the reminder.
          </Text>
        </div>
      </div>
    );
    return (
      <div className={classes.wrapper}>
        {Header}
        {this.state.isReminderEnabledForUser ? <div>{this.props.wheel && Editor}</div> : EmptyState}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.userRD.user,
});

export const CheckinReminderMember = withStyles(styles)(connect(mapStateToProps)(CheckinReminderMemberComponent));
