import React, { useState } from 'react';
import oktaLogo from '../../../../Assets/images/okta-logo.svg';
import { initOktaClient } from './okta.client';
import { SsoButton } from '../../../Shared/Buttons/Button';
import services from '../../../../API/services';
import { Modal } from '@material-ui/core';
import styled from 'styled-components';
import { Text } from '../../../Shared/Typography/Typography';
import { TextInput } from '../../../Shared/Inputs/TextInput';

export const SignInButtonOkta = ({ submitSSO, children }: { submitSSO: (res) => void; children? }) => {
  const [open, setOpen] = useState(false);
  const [email, setEmail] = useState('');

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleLogin = async () => {
    const creds: { clientId: string; issuer: string } = await services.userLoginGetSsoOktaCreds(email);
    if (creds) {
      const client = initOktaClient(creds);
      const res = await client.token.getWithPopup({ popupTitle: 'SSO' });
      const { accessToken, idToken } = res.tokens;
      const authData = await services.userLoginSsoOkta({ accessToken, idToken, issuer: creds.issuer });
      submitSSO(authData);
    }
  };

  const body = (
    <SsoModal>
      <Text>Enter your company's email or domain:</Text>

      <TextInput id="email" name="email" type="email" value={email} onChange={(e) => setEmail(e.target.value)} />

      <SsoButton className="mt-3" onClick={handleLogin}>
        Login
      </SsoButton>
    </SsoModal>
  );

  return (
    <>
      <SsoButton onClick={handleOpen}>
        <img src={oktaLogo} className="mr-1" height="14px" />
        {children}
      </SsoButton>
      <Modal open={open} onClose={handleClose}>
        {body}
      </Modal>
    </>
  );
};

const SsoModal = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fffaf3;
  padding: 40px;
`;
