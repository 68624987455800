import React, { useState } from 'react';
import { Trash } from 'react-feather';
import { Dialog, DialogActions, makeStyles, Typography } from '@material-ui/core';
import { RedBorderButton, TransparentButton } from '../../../Shared/Buttons/Button';
import { iWheelMember } from '../../../../API/interfaces';
import services from '../../../../API/services';
import ControlButton from '../../common/ControlButton/ControlButton';

interface iDeleteCheckinProps {
  member: iWheelMember;
  wheelId: string;
  checkinId: string;
  onDelete: () => void;
}

export default ({ member, wheelId, checkinId, onDelete }: iDeleteCheckinProps) => {
  const [showModal, setShowModal] = useState(false);
  const classes = useStyles();
  const deleteCheckin = async () => {
    setShowModal(false);
    await services.deleteCheckin(wheelId, checkinId);
    onDelete();
  };

  return (
    <>
      <ControlButton Icon={Trash} label="Delete this Check in" width={138} onClick={() => setShowModal(true)} />

      <Dialog open={showModal} classes={{ paper: classes.dialog }}>
        <Typography className={classes.title}>
          Are you sure you want to delete this {member.firstName} {member.lastName} check in?
        </Typography>
        <Typography className={classes.text}>
          Delete this check in will influence to all statistics result and change the team result view.
        </Typography>

        <DialogActions className={classes.buttons}>
          <TransparentButton className={classes.button} onClick={() => setShowModal(false)}>
            CANCEL
          </TransparentButton>
          <RedBorderButton className={classes.button} onClick={deleteCheckin}>
            DELETE
          </RedBorderButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

const useStyles = makeStyles({
  dialog: {
    padding: '40px',
    backgroundColor: '#fefaf2',
    color: '#0c2337',
  },
  title: {
    fontSize: '30px',
  },
  text: {
    marginTop: '16px',
    fontSize: '16px',
    lineHeight: 1.5,
  },
  buttons: {
    padding: 0,
  },
  button: {},
});
