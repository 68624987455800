import React from 'react';
import {
  ChevronDown as ChevronDownIcon,
  ChevronRight as ChevronRightIcon,
  Folder as FolderIcon,
  MoreVertical as MoreVerticalIcon,
  Plus as PlusIcon,
} from 'react-feather';
import styled from 'styled-components';
import { Input } from 'reactstrap';

import of from '../../../_utils/of';

import { tProps } from './_types';
import { Container, CreateFolderButton, StyledSpan } from './_styled';
import { useDrop } from 'react-dnd';
import { eDraggableItems, ICON_MARGIN, ICON_SIZE } from '../_consts';
import { iWheelExt } from '../../../API/interfaces';
import { idEq } from '../_utils';

import { FolderContextMenu } from './FolderContextMenu';

const PREVENT_2ND_NESTING = true;

export const FolderDesktop = React.memo<tProps>((props) => {
  const {
    active,
    onClick,
    child,
    id,
    name,
    showCreateModal,
    parent,
    selectedFolderId,
    onDrop,
    updateFolder,
    duplicateFolder,
    deleteFolder,
    inviteToFolder,
  } = props;

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [showInput, setShowInput] = React.useState(false);
  const [newFolderName, setNewFolderName] = React.useState(name);

  const [isExpanded, setIsExpanded] = React.useState(false);
  const hasChildren = Boolean(child?.length);
  const showCreateSubfolder = active && isExpanded && PREVENT_2ND_NESTING && !parent;

  const isSomeChildActive = React.useMemo(() => child.some(idEq(selectedFolderId)), []);

  const [{ isOver }, drop] = useDrop<iWheelExt, any, { isOver: boolean }>(
    () => ({
      accept: eDraggableItems.WHEEL_CARD,
      drop: (wheel) => {
        onDrop(wheel);
      },
      collect: (monitor) => ({
        isOver: !!monitor.isOver(),
      }),
    }),
    []
  );

  // drag over
  React.useEffect(() => {
    if (isOver && !isExpanded) {
      setIsExpanded(true);
    }
  }, [isOver, isExpanded]);

  // page reload nested selected
  React.useEffect(() => {
    if (isSomeChildActive) {
      setIsExpanded(true);
    }
  }, [isSomeChildActive]);

  // rewrite local state when
  React.useEffect(() => {
    if (!active && !hasChildren) {
      setIsExpanded(false);
    }
  }, [active, hasChildren]);

  const onContainerClick = React.useCallback(() => {
    onClick();
    if (!(hasChildren && isExpanded && !active)) {
      setIsExpanded(!isExpanded);
    }
  }, [isExpanded, hasChildren, active]);

  // context menu
  const onContextMenu = React.useCallback((e) => {
    e.stopPropagation();
    e.preventDefault();
    setAnchorEl(e.currentTarget);
  }, []);

  const onFolderNameChanged = React.useCallback(
    (name: string) => {
      if (name) {
        updateFolder({ name, id });
      } else {
        // TODO
        // show modal
        // are you sure you want to cancel the change
      }
      setShowInput(false);
      setNewFolderName('');
    },
    [newFolderName]
  );

  const handleKeyPress = React.useCallback((e) => {
    if (e.key === 'Enter') {
      onFolderNameChanged(e.target.value);
    }
  }, []);

  const onFolderNameChange = React.useCallback((e) => {
    setNewFolderName(e.target.value);
  }, []);

  const onRename = React.useCallback(() => {
    setShowInput(true);
    setAnchorEl(null);
  }, []);

  const onDuplicateFolder = React.useCallback(() => {
    duplicateFolder(props);
    setAnchorEl(null);
  }, [props]);

  const onDeleteFolder = React.useCallback(() => {
    deleteFolder(props);
    setAnchorEl(null);
  }, [props]);

  const onInviteToFolder = React.useCallback(() => {
    inviteToFolder(props);
    setAnchorEl(null);
  }, [props]);

  if (showInput) {
    return (
      <InputWrapper>
        <StyledInput
          value={newFolderName || name}
          onChange={onFolderNameChange}
          onKeyPress={handleKeyPress}
          onBlur={() => onFolderNameChanged(newFolderName)}
          placeholder="Enter Folder Name"
          autoFocus
        />
      </InputWrapper>
    );
  }

  return (
    <>
      <Container
        onContextMenu={onContextMenu}
        ref={drop}
        active={active || isOver}
        hasParent={Boolean(parent)}
        onClick={onContainerClick}
      >
        {active ? (
          <MoreVerticalIcon size="16px" style={{ marginRight: '8px' }} onClick={onContextMenu} />
        ) : (
          <FolderIcon size="16px" style={{ marginRight: '8px' }} />
        )}

        <StyledSpan style={{ maxWidth: '150px' }}>{name}</StyledSpan>

        <ChevronWrapper>
          {hasChildren && (isExpanded ? <ChevronDownIcon size="16px" /> : <ChevronRightIcon size="16px" />)}
        </ChevronWrapper>
      </Container>

      {isExpanded && hasChildren && child?.map(of(FolderDesktop))}

      {showCreateSubfolder && (
        <CreateFolderButton
          onClick={showCreateModal}
          style={{ marginLeft: '56px', marginTop: '8px', marginBottom: '8px' }}
        >
          <PlusIcon size="16px" style={{ marginRight: '8px' }} />
          Create Subfolder
        </CreateFolderButton>
      )}

      <FolderContextMenu
        folderName={props.name}
        isSubfolder={Boolean(parent)}
        anchorEl={anchorEl}
        onRename={onRename}
        onDuplicate={onDuplicateFolder}
        onDelete={onDeleteFolder}
        onClose={() => setAnchorEl(null)}
        onInvite={onInviteToFolder}
      />
    </>
  );
});

export const AllWheels = ({ active, onClick, onDrop }: Partial<tProps>) => {
  const [{ isOver }, drop] = useDrop<iWheelExt, any, { isOver: boolean }>(
    () => ({
      accept: eDraggableItems.WHEEL_CARD,
      drop: (wheel) => {
        onDrop(wheel);
      },
      collect: (monitor) => ({
        isOver: !!monitor.isOver(),
      }),
    }),
    []
  );

  return (
    <FirstFolderContainer ref={drop} active={active || isOver} onClick={onClick}>
      All Wheels
    </FirstFolderContainer>
  );
};

const ChevronWrapper = styled.div`
  position: absolute;
  right: 6px;
  top: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
`;

export const FirstFolderContainer = styled(Container)`
  margin-bottom: 16px;
  font-size: 18px;
  font-weight: 500;
  padding: 16px 16px 16px 32px;
`;

export const InputWrapper = styled.div`
  padding: 4px 16px 4px 32px;
  max-width: 260px;
`;

export const StyledInput = styled(Input)`
  min-height: 40px;
  padding-left: calc(${ICON_SIZE} + ${ICON_MARGIN});
  border: none;
`;
