import React, { useEffect, useState } from 'react';
import { SupportClient } from '../../API/support.client';
import styled from 'styled-components';
import { Text } from '../Shared/Typography/Typography';
import FormControl from '@material-ui/core/FormControl';
import { Accordion, AccordionDetails, AccordionSummary, makeStyles, MenuItem, Select } from '@material-ui/core';
import { BsQuestionCircle } from 'react-icons/bs';
import { Breakpoint } from 'react-socks';
import { FiChevronDown } from 'react-icons/fi';
import { useSelector } from 'react-redux';
import { selectUser } from '../Auth/Auth.selectors';
import { AccountType } from '../../API/interfaces';

enum Persona {
  Teacher = 'Teacher',
  SchoolAdministrator = 'SchoolAdministrator',
  Manager = 'Manager',
  Facilitator = 'Facilitator',
  Student = 'Student',
}

interface Tutorial {
  title: string;
  video: string;
  description: string;
  helpers: string[];
}

export const Tutorials = () => {
  const [tutorials, setTutorials]: [Record<Persona, Tutorial[]>, (data) => void] = useState(null);
  const [persona, selectPersona]: [Persona, (data) => void] = useState(null);
  const [selectedTutorial, selectTutorial]: [number, (index) => void] = useState(0);
  const user = useSelector(selectUser);
  const classes = useStyles();

  useEffect(() => {
    switch (user.accountTag) {
      case AccountType.PERSONAL:
        selectPersona(Persona.Facilitator);
        break;
      case AccountType.SCHOOL:
        selectPersona(Persona.Student);
        break;
      case AccountType.WORK:
        selectPersona(Persona.Manager);
        break;
      case AccountType.ATHLETIC_WEST:
        selectPersona(Persona.Facilitator);
        break;
      default:
        selectPersona(Persona.Facilitator);
        break;
    }
  }, []);

  const fetchData = async () => {
    const tutorials: Record<Persona, Tutorial[]> = await SupportClient.getTutorials();
    setTutorials(tutorials);
  };

  const changeTutorialsSet = (e) => {
    selectTutorial(0);
    selectPersona(e.target.value);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      {persona && (
        <div className="mb-4 d-flex justify-content-between align-items-start flex-lg-row flex-column">
          <Text color="#2a3e50" size="24px" mSize="18px" weight={500}>
            Help topics for teacher onboarding
          </Text>
          <SelectorWrapper>
            <FormControl variant="outlined" className="w-100">
              <Select
                labelId="personaSelect"
                id="demo-simple-select-outlined"
                value={persona}
                onChange={changeTutorialsSet}
                className={classes.select}
                MenuProps={{ className: classes.menu }}
              >
                <MenuItem value={Persona.Teacher}>I am teacher</MenuItem>
                <MenuItem value={Persona.SchoolAdministrator}>I am school administrator</MenuItem>
                <MenuItem value={Persona.Student}>I am student</MenuItem>
                <MenuItem value={Persona.Facilitator}>I am facilitator</MenuItem>
                <MenuItem value={Persona.Manager}>I am manager</MenuItem>
              </Select>
            </FormControl>
          </SelectorWrapper>
        </div>
      )}
      {tutorials && (
        <div>
          <Breakpoint small up style={{ display: 'flex' }}>
            <TutorialSelectors>
              {tutorials[persona].map((t, index) => {
                return (
                  <TutorialSelector
                    key={t.title}
                    onClick={() => selectTutorial(index)}
                    selected={selectedTutorial === index}
                  >
                    {t.title}
                  </TutorialSelector>
                );
              })}
            </TutorialSelectors>
            <TutorialBlock>
              <TutorialVideo
                src={tutorials[persona][selectedTutorial].video}
                frameBorder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                height="500px"
              />
              <div className="my-3">
                <Text size="16px">{tutorials[persona][selectedTutorial].description}</Text>
              </div>
              <div>
                {tutorials[persona][selectedTutorial].helpers.map((h) => {
                  return (
                    <div key={h} className="d-flex align-items-center mb-1">
                      <BsQuestionCircle className="mr-2" size={14} />
                      <Text size="14px">{h}</Text>
                    </div>
                  );
                })}
              </div>
            </TutorialBlock>
          </Breakpoint>
          <Breakpoint small down style={{ display: 'flex', flexDirection: 'column' }}>
            {tutorials[persona].map((t) => {
              return (
                <Accordion key={t.title} style={{ width: '100%' }}>
                  <AccordionSummary expandIcon={<FiChevronDown />} aria-controls="panel1a-content" id="panel1a-header">
                    <Text>{t.title}</Text>
                  </AccordionSummary>
                  <AccordionDetails style={{ display: 'flex', flexDirection: 'column' }}>
                    <TutorialVideo
                      src={tutorials[persona][selectedTutorial].video}
                      frameBorder="0"
                      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                      height="180px"
                    />
                    <div className="my-3">
                      <Text size="16px">{tutorials[persona][selectedTutorial].description}</Text>
                    </div>
                    <div>
                      {tutorials[persona][selectedTutorial].helpers.map((h) => {
                        return (
                          <div key={h} className="d-flex align-items-center mb-1">
                            <BsQuestionCircle className="mr-2 flex-shrink-0" size={15} />
                            <Text size="14px">{h}</Text>
                          </div>
                        );
                      })}
                    </div>
                  </AccordionDetails>
                </Accordion>
              );
            })}
          </Breakpoint>
        </div>
      )}
    </>
  );
};

const useStyles = makeStyles({
  select: {
    '& .MuiSelect-select': {
      padding: '11px 16px',
    },
  },
  menu: {
    '& .MuiMenu-paper': {
      transition: 'none!important',
    },
  },
});

const TutorialVideo = styled.iframe`
  height: ${(props) => props.height};
  width: 100%;
`;

const TutorialSelectors = styled.div`
  width: 25%;
`;

const TutorialSelector = styled.div<{ selected: boolean }>`
  width: 100%;
  margin: 0 0 12px;
  padding: 16px 107px 16px 16px;
  border-radius: 4px;
  box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.08);
  background-color: ${({ selected }) => (selected ? '#0c2337' : '#fff')};
  color: ${({ selected }) => (selected ? '#fff' : '#0c2337')};
  cursor: pointer;
`;

const TutorialBlock = styled.div`
  width: 75%;
  padding-left: 50px;
`;

const SelectorWrapper = styled.div`
  width: 25%;
  @media (max-width: 768px) {
    width: 100%;
    padding: 20px 0 10px;
  }
`;
