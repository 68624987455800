import React from 'react';
import { makeStyles, TextField } from '@material-ui/core';
import { iSegment } from '../../../../API/interfaces';
import Section from '../common/Section';
import { MAX_DESCRIPTION_LENGTH } from '../../../../constants';
import TextFieldWithCounter from '../../../Shared/TextFieldWithCounter/TextFieldWithCounter';

interface iDescriptionStep {
  segments: Array<Partial<iSegment>>; // segment.description should be null if empty
  onChange: (filedName: string, value: any) => void;
}

export default ({ segments, onChange }: iDescriptionStep) => {
  const classes = useStyles();

  // mutates segment
  const onChangeDescription = (e, segment: Partial<iSegment>) => {
    // segment.description should be null if empty
    segment.description = e.target.value || null;
    onChange('segments', [...segments]);
  };

  return (
    <Section number={3} title="You can add a description to your segments, if you want?">
      <div className={classes.descriptionsList}>
        {segments.map((segment) => {
          return (
            <TextFieldWithCounter
              key={segment._id}
              className={classes.wheelDescription}
              variant="outlined"
              label={`${segment.name} Description`}
              value={segment.description || ''}
              onChange={(e) => onChangeDescription(e, segment)}
              maxValueLength={MAX_DESCRIPTION_LENGTH}
              fullWidth
              multiline
            />
          );
        })}
      </div>
    </Section>
  );
};

const useStyles = makeStyles({
  descriptionsList: {},
  wheelDescriptionWrapper: {
    position: 'relative',
    '& + &': {
      marginTop: '18px',
    },
  },
  wheelDescription: {
    width: '100%',
    borderColor: '#cececf',
    '& .MuiOutlinedInput-root': {
      paddingRight: '70px',
    },
    '& .MuiOutlinedInput-root.Mui-focused': {
      backgroundColor: '#f8f8f8',
    },
    '& input': {
      color: '#2b2e31',
    },
  },
  wheelDescriptionCounter: {
    position: 'absolute',
    top: '12px',
    right: '24px',
    fontSize: '12px',
    lineHeight: 1.67,
    color: '#6a777f',
    zIndex: 1,
  },
});
