import React, { Component, createRef } from 'react';
import { connect } from 'react-redux';
import { Search } from 'react-feather';
import { iUser, iWheelMember } from '../../../../../API/interfaces';
import './MemberDropdown.scss';
import { RootState } from '../../../../../Redux/Reducers';
import { Text } from '../../../../Shared/Typography/Typography';
import { Tooltip } from '@material-ui/core';
import alertIcon from '../../../../../Assets/images/onboarding/alert-circle.svg';

interface iMemberDropdownProps {
  user: iUser;
  members: Array<iWheelMember>;
  activeMemberId?: string;
  setActiveMemberId?: (memberId: string) => void;
  teamAlertThreshold?: number;
  className?: string;
  isDisabledAction?: boolean;
  inputText?: string;
  setActiveBuddy?: (buddy: iWheelMember) => void;
}

interface iMemberDropdownState {
  showDropdown: boolean;
  filteredMembers: Array<iWheelMember>;
  activeMember: iWheelMember;
}

class MemberDropdown extends Component<iMemberDropdownProps, iMemberDropdownState> {
  static defaultProps = {
    teamAlertThreshold: 0,
  };
  readonly state = {
    showDropdown: false,
    filteredMembers: [],
    activeMember: null,
  };
  dropdownRef = createRef<HTMLDivElement>();

  toggleDropdown = () => this.setState({ showDropdown: !this.state.showDropdown });

  searchMembers = (event) => {
    const { members } = this.props;
    const searchText = event.target.value.toLowerCase();

    this.setState({
      filteredMembers: members.filter(
        (member) =>
          member.firstName.toLowerCase().includes(searchText) || member.lastName.toLowerCase().includes(searchText)
      ),
    });
  };

  handleClickOutside = (event) => {
    const { showDropdown } = this.state;
    if (showDropdown && !this.dropdownRef.current.contains(event.target)) {
      this.toggleDropdown();
    }
  };

  onClick = (member) => {
    if (this.props.isDisabledAction) return;
    if (member.avgScore) {
      this.props.setActiveMemberId(member.userId);
      this.props.setActiveBuddy(member);
      this.toggleDropdown();
    }
  };

  componentDidMount() {
    const { members, activeMemberId } = this.props;

    this.setState({
      filteredMembers: members,
      activeMember: members.find((member) => member.userId === activeMemberId),
    });

    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  componentDidUpdate(prevProps: Readonly<iMemberDropdownProps>) {
    const { activeMemberId, members } = this.props;
    if (prevProps.activeMemberId !== activeMemberId) {
      this.setState({
        activeMember: members.find((member) => member.userId === activeMemberId),
      });
    }
  }

  render() {
    const { className = undefined, teamAlertThreshold, isDisabledAction, inputText } = this.props;
    const { showDropdown, filteredMembers, activeMember } = this.state;

    if (!activeMember && !isDisabledAction) {
      return null;
    }
    const redFlag =
      activeMember && typeof activeMember.avgScore === 'string' && +activeMember.avgScore < teamAlertThreshold;

    const manuallyAddedUserToolTip = 'Manually invited member.';

    // TODO create member component
    return (
      <div className={`member-dropdown${className ? ` ${className}` : ''}`} ref={this.dropdownRef}>
        <div className={`dropdown-top${showDropdown ? ' opened' : ''}`} onClick={this.toggleDropdown}>
          <div className="active-member">
            <div className="d-flex justify-content-between align-items-center">
              {activeMember ? (
                <>
                  <div className="d-flex justify-content-start flex-nowrap align-items-center">
                    <div className="initials">{`${activeMember.firstName[0]}${activeMember.lastName[0]}`}</div>
                    <div className="name">{`${activeMember.firstName} ${activeMember.lastName}`}</div>
                    {activeMember?.isManuallyAddedUser && (
                      <Tooltip arrow title={manuallyAddedUserToolTip}>
                        <img
                          src={alertIcon}
                          style={{ marginLeft: '5px', width: '14px', height: '14px', cursor: 'pointer' }}
                          alt=""
                        />
                      </Tooltip>
                    )}
                  </div>
                  <div className="d-flex justify-content-between align-items-center">
                    <div className={`score${redFlag ? ' red-flag' : ''}`}>{activeMember.avgScore || '-'}</div>
                    <div className={`arrow${showDropdown ? ' opened' : ''}`} />
                  </div>
                </>
              ) : (
                <>
                  <Text>{inputText || 'Members'}</Text>
                  <div className={`arrow${showDropdown ? ' opened' : ''}`} />
                </>
              )}
            </div>
          </div>
        </div>

        {showDropdown && (
          <div className="dropdown-body">
            <div className="search">
              <input onChange={this.searchMembers} placeholder="Search" />
              <Search size={18} color="#7b858e" />
            </div>
            {filteredMembers.map((member) => {
              const redFlag =
                activeMember && typeof activeMember.avgScore === 'string' && +member.avgScore < teamAlertThreshold;

              return (
                <div key={member.userId} className="member" onClick={() => this.onClick(member)}>
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="d-flex justify-content-start flex-nowrap align-items-center">
                      <div className="initials">{`${member.firstName[0]}${member.lastName[0]}`}</div>
                      <div className="name">{`${member.firstName} ${member.lastName}`}</div>
                      {member?.isManuallyAddedUser && (
                        <Tooltip arrow title={manuallyAddedUserToolTip}>
                          <img
                            src={alertIcon}
                            style={{ marginLeft: '5px', width: '14px', height: '14px', cursor: 'pointer' }}
                            alt=""
                          />
                        </Tooltip>
                      )}
                    </div>
                    {!isDisabledAction && (
                      <div className={`score${redFlag ? ' red-flag' : ''}`}>{member.avgScore || '-'}</div>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  user: state.userRD.user,
});

export default connect(mapStateToProps)(MemberDropdown);
