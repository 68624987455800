import { eWheelFilterTags } from './_types';

export const ICON_SIZE = '16px';
export const ICON_MARGIN = '8px';
export const ICON_FONT_SIZE = '14px';

// @ts-ignore
export const WHEEL_FILTER_TAGS: eWheelFilterTags[] = Object.values(eWheelFilterTags);

export enum eDraggableItems {
  WHEEL_CARD = 'WHEEL_CARD',
  FOLDER = 'FOLDER',
}

export const FILTER_INITIAL_VALUE = {
  PRIVATE: false,
  PUBLIC: false,
  WEAR_IT: false,
  I_AM_ADMIN: false,
  I_AM_MEMBER: false,
  I_AM_ACCOUNTABILITY_BUDDY: false,
};
