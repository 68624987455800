import { AxiosInstance, default as axios } from 'axios';
import Interceptor from './Interceptor';
import { apiBase } from '../config';

export const HttpClient: AxiosInstance = axios.create({
  baseURL: `${apiBase}`,
  timeout: 100 * 1000,
});

HttpClient.interceptors.request.use(Interceptor.request);
HttpClient.interceptors.response.use(undefined, Interceptor.responseError);
