import React, { useState } from 'react';
import { ArrowLeft, ArrowRight } from 'react-feather';
import { iCheckinModalScore, iWheel } from '../../../API/interfaces';
import Wheel from '../../WheelPage/common/Wheel/Wheel';
import Slider, { SliderSize } from '../../WheelPage/CheckinModal/Step/Slider/Slider';
import { GRADIENTS } from '../../Shared/colors';
import CountableTextBox from '../../Shared/CountableTextBox/CountableTextBox';
import { MAX_SCORE_COMMENT_LENGTH } from '../../../constants';
import { Text } from '../../Shared/Typography/Typography';
import { BlackBtn, BtnsGroup, EmptyButton, RedBtn } from './_styled';
import styled from 'styled-components';
import rule from '../../../Assets/images/onboarding/rule.png';
import { Spinner } from '../../Shared/Spinner/Spinner';
import { analytics } from '../../../analytics/analytics';

interface iStepProps {
  wheel: iWheel;
  scores: Array<iCheckinModalScore>;
  currentStep: number;
  isLast: boolean;
  setScore: (score: number) => void;
  setComment: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  onBack: () => void;
  onNext: () => void;
}

export default ({ wheel, scores, currentStep, setScore, setComment, onBack, onNext, isLast }: iStepProps) => {
  const [loading, setLoading] = useState(false);
  const [showSliderTooltip, setShowSliderTooltip] = useState(true);
  const [showCommentTooltip, setShowCommentTooltip] = useState(false);

  const { segments, maxScale, step } = wheel;
  const currentSegment = segments[currentStep];
  const currentScore = scores[currentStep];

  const emojis = [
    { left: '👎', right: '👍' },
    { left: '☹️', right: '🙂' },
    { left: '📉', right: '📈' },
    { left: '😔', right: '😊' },
    { left: '👎', right: '👍' },
    { left: '☹️', right: '🙂' },
  ];

  const tooltipSlider =
    'Reflect and slide from left or right to capture where you are in regards to the segment in this point in time 1 (👎) 5 (👍)';
  const tooltipComment = `Add a comment to your score to help you remember what was going on when looking back at check in's 📝`;

  const handleSliderChange = (score: number) => {
    setScore(score);
    setShowSliderTooltip(false);
    setShowCommentTooltip(true);
  };

  const handleCommentChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setComment(e);
    setShowCommentTooltip(false);
  };

  const handleConfirmClick = () => {
    analytics.clickOnbTestCheckinComplete();
    setLoading(true);
    onNext();
  };

  return (
    <div style={{ width: '100%' }}>
      <Text lineHeight="1.2" weight={600} size="36px">
        {currentSegment.name}
        <span style={{ marginLeft: '10px' }}>
          {` ${currentScore.score}`}
          {
            <Text size="24px" color="#6a777f">
              /5
            </Text>
          }
        </span>
      </Text>
      <div className="d-flex justify-content-between" style={{ marginTop: '24px' }}>
        <Text size="16px">{currentSegment.description}</Text>
      </div>

      <div className="slider-section">
        {currentStep === 0 && showSliderTooltip && (
          <div className="tooltip-slider">
            <Text>{tooltipSlider}</Text>
          </div>
        )}
        <Text size="20px" className="mt-2">
          {emojis[currentStep].left}
        </Text>
        <div className="slider-container">
          <Slider
            step={step === 'integer' ? 1 : 0.1}
            value={currentScore.score}
            domain={[1, maxScale]}
            onChange={handleSliderChange}
            size={SliderSize.SMALL}
            gradient={GRADIENTS[currentStep]}
          />
          <img src={rule} alt="rule" style={{ width: '101%', marginTop: '5px' }} />
        </div>
        <Text size="20px" className="mt-2">
          {emojis[currentStep].right}
        </Text>
      </div>

      <div className="comment-container" style={{ margin: '20px 0' }}>
        {currentStep === 0 && showCommentTooltip && (
          <div className="tooltip-comment">
            <Text>{tooltipComment}</Text>
          </div>
        )}
        <CountableTextBox
          id="comment"
          placeholder="Add a comment (optional)"
          value={currentScore.comment || ''}
          onChange={handleCommentChange}
          max={MAX_SCORE_COMMENT_LENGTH}
          focusEnabled={false}
          multipleLine
        />
      </div>
      <BtnsGroup>
        {currentStep > 0 ? (
          <BlackBtn onClick={onBack}>
            <ArrowLeft size={13} /> BACK
          </BlackBtn>
        ) : (
          <EmptyButton />
        )}

        {loading ? (
          <Spinner />
        ) : (
          <RedBtn onClick={isLast ? handleConfirmClick : onNext}>
            {isLast ? (
              loading ? (
                <Spinner />
              ) : (
                'CONFIRM'
              )
            ) : (
              <>
                NEXT <ArrowRight size={13} />
              </>
            )}
          </RedBtn>
        )}
      </BtnsGroup>
    </div>
  );
};
