import React, { useEffect, useMemo, useState } from 'react';
import InviteButton from '../../common/InviteButton/InviteButton';
import { iWheel, WheelMemberRole, WheelMemberRoleType, WheelRoleNaming } from '../../../../API/interfaces';
import styled from 'styled-components';
import { Text } from '../../../Shared/Typography/Typography';
import { FiLink, FiUserPlus, FiLifeBuoy } from 'react-icons/fi';
import InviteToWheelModal from '../../WheelInviteModal/InviteToWheelModal';
import { analytics } from '../../../../analytics/analytics';
import { isWheelAdmin } from '../../../Shared/utils';

interface iWheelMembersInviteButton {
  wheel: iWheel;
  role: WheelMemberRoleType;
  userId?: string;
}

const MembersInviteButtonContainer = styled.div`
  padding: 5px 10px;
  border-radius: 16px;
  border: solid 1px rgba(64, 64, 66, 0.24);
  background-color: #f8f8f8;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

export const WheelMembersInviteButton: React.FC<iWheelMembersInviteButton> = ({ wheel, role, userId }) => {
  const [wheelToInvite, setWheelToInvite] = useState<iWheel | null>(null);

  const Icon = useMemo(() => {
    const iconProps = {
      color: '#000000',
      size: 16,
      className: 'mr-1',
    };
    if (role === WheelMemberRole.MEMBER) return <FiUserPlus {...iconProps} />;
    if (role === WheelMemberRole.ACCOUNTABILITY_BUDDY) return <FiLifeBuoy {...iconProps} />;
    return <FiLink {...iconProps} />;
  }, []);

  return (
    <>
      <InviteButton
        wheel={wheel}
        role={role}
        userId={userId}
        onClick={() => {
          setWheelToInvite(wheel);
          role === WheelMemberRole.ACCOUNTABILITY_BUDDY ? analytics.clickInviteBuddy() : analytics.shareWheel();
        }}
      >
        <MembersInviteButtonContainer>
          {Icon}
          <Text size="14px">Invite {WheelRoleNaming[role].toLowerCase()}</Text>
        </MembersInviteButtonContainer>
      </InviteButton>
      <InviteToWheelModal wheel={wheelToInvite} onCancel={() => setWheelToInvite(null)} />
    </>
  );
};
