export const getJoinRoleModalDescription = (invitingFirstName, invitingLastName, wheelName) => {
  return `You've successfully joined ${invitingFirstName} ${invitingLastName}’s wheel ${wheelName} as an accountability buddy, 
    this means you can see his check in results and offer support to him through discussing results and even adding comments.
    Maybe ask ${invitingFirstName} how they would like to be best supported by you? would they in-person catch ups? or for you to offer comments?`;
};

export const normalizeHeader = (header) => {
  const headerMap = {
    first: 'firstName',
    f_name: 'firstName',
    'f. name': 'firstName',
    fname: 'firstName',
    'name (first)': 'firstName',
    name_first: 'firstName',
    'given name': 'firstName',
    forename: 'firstName',
    'last name': 'lastName',
    last: 'lastName',
    surname: 'lastName',
    'family name': 'lastName',
    familyname: 'lastName',
    l_name: 'lastName',
    lname: 'lastName',
    'name (last)': 'lastName',
    name_last: 'lastName',
    mail: 'email',
    'email address': 'email',
    emailaddress: 'email',
    e_mail: 'email',
    'e. mail': 'email',
  };

  const lowerHeader = header.toLowerCase().trim();

  if (headerMap[lowerHeader]) {
    return headerMap[lowerHeader];
  }

  const firstNameRegex = /^(first[\s+._-]*name|f(\.|_|\s)?name|given[\s+._-]*name|forename)$/i;
  if (firstNameRegex.test(header)) {
    return 'firstName';
  }

  const lastNameRegex = /^(last[\s+._-]*name|l(\.|_|\s)?name|surname|family[\s+._-]*name)$/i;
  if (lastNameRegex.test(header)) {
    return 'lastName';
  }

  const emailRegex = /^(e[\s+._-]?mail|email[\s+._-]*address)$/i;
  if (emailRegex.test(header)) {
    return 'email';
  }

  return header;
};
