import React from 'react';
import styled from 'styled-components';
import _ from 'lodash';
import { Container } from 'reactstrap';

import { TagMobile, Text1Mobile, TextMobile } from '../_styled';
import { SearchMobile } from '../Search/SearchMobile';
import { SortMobile } from '../Sort';
import { CardMobile } from '../Card';
import { WHEEL_FILTER_TAGS } from '../_consts';
import { eWheelFilterTags } from '../_types';
import { formatTag } from '../_utils';
import NoWheelsCreated from '../NoWheelsCreated';
import NothingFound from '../NothingFound';

import { iProps } from './_types';
import { StyledMainButton, List, Cards } from './_styled';
import nUser from '../../../_utils/user';
import { Button } from '../../Shared/Buttons/Button';

export const DashboardMobile = (props: iProps) => {
  const {
    query,
    setQuery,
    setSort,
    filter,
    onFilterTagClick,
    wheels,
    user,
    onWheelRemoved,
    showWheelDuplicateModal,
    showNoWheelsHaveBeenCreated,
    showNothingFound,
    history,
    onCreateWheel,
    folders,
    onMoveWheelToFolder,
    setWheelToDelete,
    setWheelToLeave,
    setWheelToInvite,
  } = props;

  return (
    <ContainerMobile withSidebar={nUser.hasAccessToFolders(user)}>
      <div className="col-12 mb-2 d-flex justify-content-between align-items-center">
        <TextMobile className="col-3 p-0">Dashboard</TextMobile>
        <Button fontSize="14px" height="56px" className="col-7" onClick={onCreateWheel}>
          CREATE A NEW WHEEL
        </Button>
      </div>

      <div className="col-12 d-flex justify-content-start position-relative mb-2 flex-nowrap">
        <SearchMobile value={query} onChange={setQuery} />

        <SortMobile setSort={setSort} />
      </div>

      <List className="col-12">
        {WHEEL_FILTER_TAGS.map((tag: eWheelFilterTags) => (
          <TagMobile selected={filter[tag]} key={tag} onClick={() => onFilterTagClick(tag)}>
            {formatTag(tag)}
          </TagMobile>
        ))}
      </List>

      {!_.isEmpty(wheels) && (
        <>
          <Text1Mobile className="col-12">{`All Wheels (${wheels?.length})`}</Text1Mobile>
          <Cards className="col-12">
            {wheels.map((wheel, index) => (
              <CardMobile
                key={wheel.id}
                id={wheel.id}
                wheel={wheel}
                user={user}
                history={history}
                removeWheel={() => setWheelToDelete(wheel)}
                duplicateWheel={() => showWheelDuplicateModal(wheel)}
                inviteToWheel={() => setWheelToInvite(wheel)}
                leaveWheel={() => setWheelToLeave(wheel)}
                folders={folders}
                onMoveWheelToFolder={(folder) => onMoveWheelToFolder(folder, wheel)}
              />
            ))}
          </Cards>
        </>
      )}
      {showNoWheelsHaveBeenCreated && <NoWheelsCreated />}
      {showNothingFound && !showNoWheelsHaveBeenCreated && <NothingFound />}
    </ContainerMobile>
  );
};

const ContainerMobile = styled<any>(Container)`
  padding-top: ${({ withSidebar }) => (withSidebar ? 78 : 30)}px;
  padding-left: 0;
  padding-right: 0;
  background-color: #fff;
  min-height: calc(100vh - 57px - 74px);
`;
