import * as actionTypes from '../Actions/ActionTypes';

const initialState = {
  modal: '',
};

function alertReducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.ALERTING:
      return {
        ...state,
        modal: action.payload,
      };
    default:
      break;
  }

  return state;
}

export default alertReducer;
