import React from 'react';
import { iScore } from '../../../../../API/interfaces';
import { GRADIENTS } from '../../../../Shared/colors';
import styled from 'styled-components';

interface iWheelCircle {
  averageScore: number;
  activeSegmentId: string | null;
  scores: Array<iScore>;
  disabled: boolean;
  radius: number;
  showLogo?: boolean;
}

export default ({ averageScore, activeSegmentId, scores, disabled, radius, showLogo = false }: iWheelCircle) => {
  let score, backgroundColor, color;

  if (activeSegmentId === null) {
    score = averageScore;
    backgroundColor = '#ffffff';
    color = '#000000';
  } else {
    const scoreIndex = scores.findIndex((score) => score.segmentId === activeSegmentId);
    score = scores[scoreIndex].score;
    backgroundColor = GRADIENTS[scoreIndex][1];
    // yellow and orange colors should have black font
    color = scoreIndex === 0 || scoreIndex === 4 ? '#000000' : '#ffffff';
  }

  return (
    <g>
      <circle cx={0} cy={0} r={radius} fill={backgroundColor} />
      {showLogo && (
        <LogoWrapper>
          <StyledLogo />
        </LogoWrapper>
      )}
      <text x={0} y={0} textAnchor="middle" dy="0.33em">
        <tspan fill={color} fontSize={radius / 0.07 / 18} fontWeight="bold">
          {disabled ? '' : score}
        </tspan>
      </text>
    </g>
  );
};

const LogoWrapper = styled.g`
  transform: translate(-39px, -8px);
  width: 100%;
  height: 100%;
`;

const Logo = ({ className }) => (
  <svg className={className} width="98" height="38" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 79 85">
    <defs>
      <linearGradient x1="55.14%" y1="90.778%" x2="41.27%" y2="18.902%" id="a">
        <stop stopColor="#07C7C7" offset="0%" />
        <stop stopColor="#129C8F" offset="100%" />
      </linearGradient>
      <linearGradient x1="46.296%" y1="96.517%" x2="24.001%" y2="10.359%" id="b">
        <stop stopColor="#FF4E4B" offset="0%" />
        <stop stopColor="#F79070" offset="100%" />
      </linearGradient>
    </defs>
    <g fill="none" fillRule="evenodd">
      <path
        d="M15.071.588a2.43 2.43 0 0 1 .792 1.807l-.046 9.012c-3.445.61-6.06 3.591-6.06 7.176 0 3.56 2.578 6.524 5.987 7.163l-.034 6.827a.81.81 0 0 1-1.442.503L.54 15.946A2.43 2.43 0 0 1 .64 12.79L11.638.744a2.43 2.43 0 0 1 3.433-.156z"
        fill="url(#a)"
        transform="translate(0 1)"
      />
      <path
        d="m22.586 5.932.15.087 11.27 7.083a2.43 2.43 0 0 1 .757 3.364l-.1.143L20.28 35.954a.81.81 0 0 1-1.454-.387l-.006-.102.07-9.775c3.123-.844 5.42-3.673 5.42-7.033 0-3.324-2.248-6.128-5.321-7.005l.024-3.593a2.43 2.43 0 0 1 3.573-2.127zm-5.402 8.648c2.26 0 4.09 1.813 4.09 4.05s-1.83 4.05-4.09 4.05-4.092-1.813-4.092-4.05 1.832-4.05 4.092-4.05z"
        fill="url(#b)"
        transform="translate(0 1)"
      />
    </g>
  </svg>
);

const StyledLogo = styled<any>(Logo)`
  height: 10px;
  width: 10px;
`;
