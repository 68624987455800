import React from 'react';
import { ClickAwayListener, Grow, MenuItem, MenuList, Paper, Popper } from '@material-ui/core';
import NestedMenuItem from 'material-ui-nested-menu-item';
import styled from 'styled-components';

import { Expandable } from './Expandable';
import { eSortOptions, eSortOptionValues, tIconProps, tPartialWheelSort } from './_types';
import { ICON_SIZE } from './_consts';
import { IconLabel } from './_styled';

type tProps = {
  setSort: (newSort: tPartialWheelSort) => void;
};

const Sort = ({ setSort }: tProps) => {
  const wrapperRef = React.useRef(null);
  const [active, setActive] = React.useState(false);

  return (
    <>
      <Expandable width="76px" onClick={() => setActive(true)}>
        <SortIconWrapper ref={wrapperRef}>
          <StyledSortIcon />
          <IconLabel>Sort</IconLabel>
        </SortIconWrapper>
      </Expandable>

      <Popper open={active} anchorEl={wrapperRef.current} role={undefined} transition>
        {({ TransitionProps, placement }) => (
          <Grow {...TransitionProps} style={{ transform: 'translateY(50px)', zIndex: 1 }}>
            <Paper>
              <ClickAwayListener onClickAway={() => setActive(false)}>
                <MenuList id="menu-list-grow">
                  <MenuItem
                    onClick={() => {
                      setActive(false);
                      setSort({ [eSortOptions.BY_LATEST_UPDATES]: eSortOptionValues.DESC });
                    }}
                  >
                    By latest updates
                  </MenuItem>

                  <NestedMenuItem label={'By creation date'} parentMenuOpen={active} rightIcon={<StyledChevron />}>
                    <MenuItem
                      onClick={() => {
                        setActive(false);
                        setSort({ [eSortOptions.BY_CREATION_DATE]: eSortOptionValues.ASC });
                      }}
                    >
                      From old to new
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        setActive(false);
                        setSort({ [eSortOptions.BY_CREATION_DATE]: eSortOptionValues.DESC });
                      }}
                    >
                      From new to old
                    </MenuItem>
                  </NestedMenuItem>

                  <NestedMenuItem label={'By score'} parentMenuOpen={active} rightIcon={<StyledChevron />}>
                    <MenuItem
                      onClick={() => {
                        setActive(false);
                        setSort({ [eSortOptions.BY_SCORE]: eSortOptionValues.DESC });
                      }}
                    >
                      From high to low
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        setActive(false);
                        setSort({ [eSortOptions.BY_SCORE]: eSortOptionValues.ASC });
                      }}
                    >
                      From low to high
                    </MenuItem>
                  </NestedMenuItem>

                  <NestedMenuItem label={'By checkins'} parentMenuOpen={active} rightIcon={<StyledChevron />}>
                    <MenuItem
                      onClick={() => {
                        setActive(false);
                        setSort({ [eSortOptions.BY_CHECKINS]: eSortOptionValues.ASC });
                      }}
                    >
                      From less to more
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        setActive(false);
                        setSort({ [eSortOptions.BY_CHECKINS]: eSortOptionValues.DESC });
                      }}
                    >
                      From more to less
                    </MenuItem>
                  </NestedMenuItem>

                  <NestedMenuItem label={'By Name'} parentMenuOpen={active} rightIcon={<StyledChevron />}>
                    <MenuItem
                      onClick={() => {
                        setSort({ [eSortOptions.BY_ALPHABET]: eSortOptionValues.ASC });
                        setActive(false);
                      }}
                    >
                      A-Z
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        setSort({ [eSortOptions.BY_ALPHABET]: eSortOptionValues.DESC });
                        setActive(false);
                      }}
                    >
                      Z-A
                    </MenuItem>
                  </NestedMenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};

export const SortMobile = ({ setSort }: tProps) => {
  const wrapperRef = React.useRef(null);
  const [active, setActive] = React.useState(false);

  return (
    <>
      <Wrapper onClick={() => setActive(true)}>
        <SortIconWrapper ref={wrapperRef}>
          <StyledSortIcon />
          <IconLabel>Sort</IconLabel>
        </SortIconWrapper>
      </Wrapper>

      <Popper open={active} anchorEl={wrapperRef.current} role={undefined} transition>
        {({ TransitionProps, placement }) => (
          <Grow {...TransitionProps} style={{ transform: 'translateY(50px)', zIndex: 1 }}>
            <Paper>
              <ClickAwayListener onClickAway={() => setActive(false)}>
                <MenuList id="menu-list-grow">
                  <MenuItem
                    onClick={() => {
                      setActive(false);
                      setSort({ [eSortOptions.BY_LATEST_UPDATES]: eSortOptionValues.DESC });
                    }}
                  >
                    By latest updates
                  </MenuItem>

                  <NestedMenuItem label={'By creation date'} parentMenuOpen={active} rightIcon={<StyledChevron />}>
                    <MenuItem
                      onClick={() => {
                        setActive(false);
                        setSort({ [eSortOptions.BY_CREATION_DATE]: eSortOptionValues.ASC });
                      }}
                    >
                      From old to new
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        setActive(false);
                        setSort({ [eSortOptions.BY_CREATION_DATE]: eSortOptionValues.DESC });
                      }}
                    >
                      From new to old
                    </MenuItem>
                  </NestedMenuItem>

                  <NestedMenuItem label={'By score'} parentMenuOpen={active} rightIcon={<StyledChevron />}>
                    <MenuItem
                      onClick={() => {
                        setActive(false);
                        setSort({ [eSortOptions.BY_SCORE]: eSortOptionValues.DESC });
                      }}
                    >
                      From high to low
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        setActive(false);
                        setSort({ [eSortOptions.BY_SCORE]: eSortOptionValues.ASC });
                      }}
                    >
                      From low to high
                    </MenuItem>
                  </NestedMenuItem>

                  <NestedMenuItem label={'By checkins'} parentMenuOpen={active} rightIcon={<StyledChevron />}>
                    <MenuItem
                      onClick={() => {
                        setActive(false);
                        setSort({ [eSortOptions.BY_CHECKINS]: eSortOptionValues.ASC });
                      }}
                    >
                      From less to more
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        setActive(false);
                        setSort({ [eSortOptions.BY_CHECKINS]: eSortOptionValues.DESC });
                      }}
                    >
                      From more to less
                    </MenuItem>
                  </NestedMenuItem>

                  <NestedMenuItem label={'By Name'} parentMenuOpen={active} rightIcon={<StyledChevron />}>
                    <MenuItem
                      onClick={() => {
                        setSort({ [eSortOptions.BY_ALPHABET]: eSortOptionValues.ASC });
                        setActive(false);
                      }}
                    >
                      A-Z
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        setSort({ [eSortOptions.BY_ALPHABET]: eSortOptionValues.DESC });
                        setActive(false);
                      }}
                    >
                      Z-A
                    </MenuItem>
                  </NestedMenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};

export default Sort;

const SortIcon = ({ className }: tIconProps) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
    <defs>
      <filter id="ldad5s14ga" colorInterpolationFilters="auto">
        <feColorMatrix
          in="SourceGraphic"
          values="0 0 0 0 1.000000 0 0 0 0 1.000000 0 0 0 0 1.000000 0 0 0 1.000000 0"
        />
      </filter>
    </defs>
    <g fill="none" fillRule="evenodd">
      <g>
        <g>
          <g>
            <g>
              <g
                filter="url(#ldad5s14ga)"
                transform="translate(-1087.000000, -128.000000) translate(1039.000000, 108.000000) translate(0.000000, 12.000000) translate(40.000000, 0.000000) translate(8.000000, 8.000000)"
              >
                <path d="M0 0L16 0 16 16 0 16z" />
                <path
                  stroke="#2C3E50"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="1.333"
                  d="M2 6l2.667-2.667L7.333 6M4.667 3.333v9.334M14 10l-2.667 2.667L8.667 10m2.666 2.667V3.333"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

const SortIconWrapper = styled.div`
  cursor: pointer;
`;

const StyledSortIcon = styled(SortIcon)`
  position: absolute;
  top: calc(50% - ${ICON_SIZE} / 2);
  left: calc(${ICON_SIZE} / 2);
`;

const Wrapper = styled.div`
  height: 32px;
  width: 32px;
  min-width: 32px;
  background-color: #2a3e50;
  border-radius: 16px;
  padding: 0;
  transition: width 0.2s ease-in-out;
  overflow: hidden;
  position: relative;

  color: #fff;
`;

const Chevron = ({ className }: tIconProps) => (
  <svg className={className} focusable="false" viewBox="0 0 24 24" aria-hidden="true">
    <path d="M10 17l5-5-5-5v10z"></path>
  </svg>
);
const StyledChevron = styled(Chevron)`
  position: absolute;
  right: 10px;
  height: 1em;
  width: 1em;
  font-size: 1.5rem;
  fill: #b1b1b1;
`;
