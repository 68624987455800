import React from 'react';
import { Box, makeStyles, Typography } from '@material-ui/core';
import { MediumButton } from '../../Shared/Buttons/Button';

interface iCreateTemplateProps {
  onClick: () => void;
}

export default ({ onClick }: iCreateTemplateProps) => {
  const classes = useStyles();

  return (
    <Box className={classes.box}>
      <Typography className={classes.title}>Create your own template</Typography>
      <Typography className={classes.text}>
        Create your own wheel from scratch, choose the segments and descriptions yourself
      </Typography>
      <div className={classes.controls}>
        <MediumButton onClick={onClick}>CREATE TEMPLATE</MediumButton>
      </div>
    </Box>
  );
};

const useStyles = makeStyles({
  box: {
    height: '174px',
    padding: '24px',
    backgroundColor: '#ffffff',
    borderRadius: '8px',
    boxShadow: '0 2px 30px 0 rgba(0, 0, 0, 0.07)',
  },
  title: {
    fontSize: '18px',
    fontWeight: 500,
    lineHeight: 1.33,
    color: '#2b2e31',
  },
  text: {
    marginTop: '4px',
    fontSize: '14px',
    lineHeight: 1.43,
    color: '#7f8b96',
  },
  controls: {
    display: 'flex',
    justifyContent: 'flex-end',
    '@media(max-device-width: 768px)': {
      marginTop: '16px',
    },
  },
});
