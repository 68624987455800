import { takeEvery } from 'redux-saga/effects';
import { REHYDRATE } from 'redux-persist/lib/constants';
import { AuthActions } from '../Components/Auth/Auth.actions';
import { authMiddleware } from '../Components/Auth/Auth.middleware';
import { watchFetchFolders } from '../Components/Dashboard/Dashboard.sagas';

export function* mySaga() {
  yield takeEvery([REHYDRATE, AuthActions.LOGIN], authMiddleware);
  yield watchFetchFolders();
}
