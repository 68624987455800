import React from 'react';
import styled from 'styled-components';
import { SmallButton } from '../Shared/Buttons/Button';

const TooltipBody = styled.div`
  background-color: #fff;
  border-radius: 5px;
  min-width: 290px;
  max-width: 420px;
  position: relative;
`;

const TooltipContent = styled.div`
  color: #000;
  padding: 20px;
`;

const TooltipTitle = styled.h2`
  color: #f04;
  padding: 20px;
  margin: 0;
`;

const TooltipFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 0 15px 15px;

  * + * {
    margin-left: 0.5rem;
  }
`;

export const EducationTooltip = ({
  continuous,
  index,
  step,
  backProps,
  closeProps,
  primaryProps,
  tooltipProps,
  isLastStep,
}) => (
  <TooltipBody {...tooltipProps}>
    {step.title && <TooltipTitle>{step.title}</TooltipTitle>}
    <TooltipContent>{step.content}</TooltipContent>
    <TooltipFooter>
      {index > 0 && (
        <SmallButton {...backProps}>
          <span id="back">Previous</span>
        </SmallButton>
      )}
      {continuous && (
        <SmallButton {...primaryProps}>
          <span id="next">{isLastStep ? 'Close' : 'Next'}</span>
        </SmallButton>
      )}
      {!continuous && (
        <SmallButton {...closeProps}>
          <span id="close">Close</span>
        </SmallButton>
      )}
    </TooltipFooter>
  </TooltipBody>
);
