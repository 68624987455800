import React from 'react';
import { Dialog, DialogContent, DialogActions, makeStyles, Typography } from '@material-ui/core';
import { RedBorderButton, TransparentButton } from '../Shared/Buttons/Button';

interface iExitPopupProps {
  isTemplate: boolean;
  onCancel: () => void;
  onConfirm: () => void;
}

export default ({ isTemplate, onCancel, onConfirm }: iExitPopupProps) => {
  const classes = useStyles();

  return (
    <Dialog open={true} classes={{ paper: classes.dialog }}>
      <DialogContent className={classes.content}>
        <Typography className={classes.title}>Back without saving</Typography>
        <Typography className={classes.message}>
          You will lose all progress if you back to {isTemplate ? ' all templates' : 'dashboard'} now. Are you sure you
          want to exit?
        </Typography>
      </DialogContent>
      <DialogActions className={classes.buttons}>
        <TransparentButton onClick={onCancel}>CANCEL</TransparentButton>
        <RedBorderButton onClick={onConfirm}>DON'T SAVE AND EXIT</RedBorderButton>
      </DialogActions>
    </Dialog>
  );
};

const useStyles = makeStyles({
  dialog: {
    padding: '50px 50px 36px',
    backgroundColor: '#fefaf2',
    color: '#0c2337',
  },
  content: {
    padding: 0,
    '&:first-child': {
      padding: 0,
    },
  },
  title: {
    fontSize: '30px',
  },
  message: {
    marginTop: '8px',
    fontSize: '16px',
    lineHeight: 1.5,
  },
  buttons: {
    marginTop: '36px',
    padding: 0,
  },
});
