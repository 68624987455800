import React from 'react';
import { ArrowLeft, ArrowRight } from 'react-feather';
import { iCheckinModalScore, iWheel } from '../../../../API/interfaces';
import CountableTextBox from '../../../Shared/CountableTextBox/CountableTextBox';
import Wheel from '../../common/Wheel/Wheel';
import Slider, { SliderSize } from './Slider/Slider';
import { GRADIENTS } from '../../../Shared/colors';
import { MAX_SCORE_COMMENT_LENGTH } from '../../../../constants';

interface iStepProps {
  wheel: iWheel;
  scores: Array<iCheckinModalScore>;
  currentStep: number;
  setScore: (score: number) => void;
  setComment: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  onExit: () => void;
  onBack: () => void;
  onNext: () => void;
}

export default ({ wheel, scores, currentStep, setScore, setComment, onExit, onBack, onNext }: iStepProps) => {
  const { segments, maxScale, step, isScoreComments, wheelCreator } = wheel;
  const currentSegment = segments[currentStep];
  const currentScore = scores[currentStep];

  const forceScoreComment = wheelCreator.account.teamAlert && wheelCreator.account.forceScoreComment;
  const isUnderThreshold = forceScoreComment && currentScore.score < wheelCreator.account.teamAlertThreshold;
  const showComment = forceScoreComment ? isUnderThreshold : isScoreComments;
  const blockNextStep = isUnderThreshold && !currentScore.comment;

  return (
    <>
      <div className="step-segment-name">{currentSegment.name}</div>

      <div className="d-flex justify-content-between mt-px-15">
        <div className="segment-description-container">
          <div className="segment-description ">{currentSegment.description}</div>
        </div>
        <div className="step-wheel-container">
          <Wheel
            width={180}
            maxScale={maxScale}
            onClick={() => {}}
            averageScore={0}
            scores={scores}
            activeSegmentId={currentSegment.id}
            id="checkin_wheel"
          />
        </div>
      </div>

      <div className="slider-container">
        <Slider
          step={step === 'integer' ? 1 : 0.1}
          value={currentScore.score}
          domain={[1, maxScale]}
          onChange={setScore}
          size={SliderSize.SMALL}
          gradient={GRADIENTS[currentStep]}
        />
        <div className="slider-ticks">
          <span>1 Low</span>
          <hr />
          <span>{`${maxScale} High`}</span>
        </div>
      </div>

      {showComment && (
        <div className="comment-container">
          <CountableTextBox
            className={blockNextStep ? 'comment-box-error' : ''}
            id="comment"
            placeholder="Add a comment to the segment"
            value={currentScore.comment || ''}
            onChange={setComment}
            max={MAX_SCORE_COMMENT_LENGTH}
            focusEnabled={false}
            multipleLine
          />
          {blockNextStep && <div className="comment-error-message">Please leave a comment before you can progress</div>}
        </div>
      )}

      <div className="buttons">
        <div className="secondary-button">
          {currentStep === 0 && <button onClick={onExit}>EXIT</button>}
          {currentStep > 0 && (
            <button onClick={onBack}>
              <ArrowLeft size={13} /> BACK
            </button>
          )}
        </div>
        <div className="primary-button">
          <button onClick={onNext} disabled={blockNextStep}>
            NEXT <ArrowRight size={13} />{' '}
          </button>
        </div>
      </div>
    </>
  );
};
