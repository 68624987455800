import React from 'react';
import { BlackBtn, BlackButtonBox, CheckboxContainer, ContentBox, LeftPanel, RedBtn, Root } from './_styled';
import { ArrowLeft, ArrowRight } from 'react-feather';
import { Text } from '../../Shared/Typography/Typography';
import { RightScreen } from './RightScreen';

import checkbox from '../../../Assets/images/onboarding/checkbox-blank.png';
import { useSelector } from 'react-redux';
import { RootState } from '../../../Redux/Reducers';
import { useHistory } from 'react-router-dom';

const InfoScreen = ({ data, onNext, onBack, step, totalSteps }) => {
  const { title, subtitle, content } = data;
  const isLastStep = step + 1 === totalSteps;
  const btnName = isLastStep ? `Let's try 🚀` : `Next`;

  const shareLinkParams = useSelector((state: RootState) => (state.shareLink ? state.shareLink.joinParams : ''));
  const history = useHistory();

  const skipOnboarding = () => {
    isLastStep && shareLinkParams ? history.push(`/share${shareLinkParams}`) : onNext();
  };

  const contentInfo = isLastStep ? (
    <Text className="mb-2" size="18px" weight={500} style={{ whiteSpace: 'pre-line' }}>
      {content[0]}
    </Text>
  ) : (
    content.map((text, id) => (
      <CheckboxContainer key={id}>
        <img src={checkbox} alt="checkbox" />
        <Text color="#0c2337">{text}</Text>
      </CheckboxContainer>
    ))
  );

  return (
    <Root>
      <LeftPanel>
        <BlackButtonBox>
          <BlackBtn onClick={onBack}>
            <ArrowLeft size={13} /> BACK
          </BlackBtn>
        </BlackButtonBox>
        <ContentBox style={{ justifyContent: 'center' }}>
          <Text size="14px">{`Step ${step + 1}/${totalSteps}`}</Text>
          <Text weight={600} size="36px">
            {title}
          </Text>
          {!!subtitle && (
            <Text weight={500} size="18px">
              {subtitle}
            </Text>
          )}
          {contentInfo}
          <RedBtn onClick={skipOnboarding} style={{ marginTop: '15px' }}>
            {btnName} <ArrowRight size={13} />
          </RedBtn>
        </ContentBox>
      </LeftPanel>
      <RightScreen />
    </Root>
  );
};

export default InfoScreen;
