import React from 'react';
import NoResultsBase from './NoResultsBase';
import SegmentTags from '../SegmentTags/SegmentTags';
import Wheel from '../Wheel/Wheel';
import DisabledChart from '../../../Shared/HistoryChart/DisabledChart';
import CheckinButton from '../CheckinButton/CheckinButton';
import { WHEEL_ACCESS_TYPES } from '../../../../constants';
import DateRangeDropdown from '../DateRangeDropdown/DateRangeDropdown';
import './NoResults.scss';

export default class extends NoResultsBase {
  render() {
    const { wheel, showCheckinModal, setDateRange, dateRange, isTeamResults, shouldShowDateDropdown } = this.props;
    const isPublic = wheel.access === WHEEL_ACCESS_TYPES.PUBLIC;

    return (
      <div className="no-results">
        <SegmentTags
          averageScore={0}
          scores={this.getFakeScoresForSegmentTag()}
          activeSegmentId={null}
          onClick={() => {}}
        />

        <div className="charts-container">
          <Wheel
            maxScale={wheel?.maxScale}
            averageScore={0}
            scores={this.getFakeScoresForWheel()}
            activeSegmentId={null}
            width={300}
            disabled
          />
          <DisabledChart scores={this.getFakeScoresForChart()} />
        </div>

        <div className="overlay">
          <div className="call-to-action">
            {!shouldShowDateDropdown && (
              <>
                <div className="message">Complete a check in to see statistics!</div>
                {isPublic && <div className="public-wheel-message">Your result is completely anonymous</div>}
                <div className="button-container">
                  <CheckinButton className="education_complete-checkin" onClick={showCheckinModal} />
                </div>
              </>
            )}

            {shouldShowDateDropdown && (
              <>
                <div className="message">No results. Try another date range</div>
                <div className="button-container">
                  <DateRangeDropdown activeOption={dateRange} onChange={setDateRange} isTeamResults={isTeamResults} />
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    );
  }
}
