import { iFolder, iWheelExt } from '../../API/interfaces';
import { FolderActions, WheelActions } from './Dashboard.actions';

interface iState {
  wheels: iWheelExt[];
  folders: iFolder[];
}

const initialState = {
  wheels: [],
  folders: [],
};

export const dashboardReducer = (state: iState = initialState, action) => {
  switch (action.type) {
    case WheelActions.LOAD_WHEELS_SUCCESS:
      return Object.assign({}, state, { wheels: action.payload.wheels });
    case WheelActions.UPDATE_WHEEL_SUCCESS:
      const { wheel: updatedWheel } = action.payload;
      const wheels = state.wheels.reduce(
        (acc, wheel) => acc.concat(wheel.id === updatedWheel.id ? updatedWheel : wheel),
        []
      );

      return Object.assign({}, state, { wheels });

    case FolderActions.LOAD_FOLDER_SUCCESS:
      return Object.assign({}, state, { folders: action.payload.folders });
    case FolderActions.UPDATE_FOLDER_SUCCESS:
      const { folder: updatedFolder } = action.payload;
      const folders = state.folders.reduce(
        (acc, folder) => acc.concat(folder.id === updatedFolder.id ? updatedFolder : folder),
        []
      );

      return Object.assign({}, state, { folders });
    default:
      return state;
  }
};
