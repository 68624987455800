import React, { useEffect, useRef, useState } from 'react';
import { iScore } from '../../../../API/interfaces';
import SegmentTag from '../../../Shared/SegmentTags/SegmentTag/SegmentTag';
import './SegementTags.scss';

interface iSegmentTags {
  averageScore: number;
  scores: Array<iScore>;
  activeSegmentId: string | null;
  onClick: (segmentId: string, segmentIndex: number) => void;
}

export default ({ averageScore, scores, onClick, activeSegmentId }: iSegmentTags) => {
  const [showLeftArrow, setShowLeftArrow] = useState(false);
  const [showRightArrow, setShowRightArrow] = useState(false);
  const activeTagRef = useRef<HTMLDivElement>(null);
  const arrowRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (arrowRef.current) {
      // change arrow position
      arrowRef.current.style.transform = `translateX(${
        activeTagRef.current.offsetLeft + (activeTagRef.current.offsetWidth - arrowRef.current.offsetWidth) / 2
      }px) rotate(45deg)`;
    }
  }, [activeSegmentId]);

  const segmentsContainerRef = useRef(null);
  const handleScroll = (direction) => {
    const container = segmentsContainerRef.current;
    if (container) {
      const scrollAmount = direction === 'left' ? -200 : 200;
      container.scrollTo({
        left: container.scrollLeft + scrollAmount,
        behavior: 'smooth',
      });
    }
  };

  const handleScrollVisibility = () => {
    const container = segmentsContainerRef.current;
    if (container) {
      setShowLeftArrow(container.scrollLeft > 0);
      setShowRightArrow(container.scrollLeft < container.scrollWidth - container.clientWidth);
    }
  };

  useEffect(() => {
    handleScrollVisibility();
    const container = segmentsContainerRef.current;
    if (container) {
      container.addEventListener('scroll', handleScrollVisibility);
    }
    return () => {
      if (container) {
        container.removeEventListener('scroll', handleScrollVisibility);
      }
    };
  }, []);

  return (
    <div className="segment-tag-container">
      <div className="segment-tags-arrows">
        {showLeftArrow && (
          <div className="arrow" onClick={() => handleScroll('left')}>
            {'<'}
          </div>
        )}
        <div className="segment-tags-list" ref={segmentsContainerRef}>
          <SegmentTag
            ref={activeSegmentId === null ? activeTagRef : null}
            key="all"
            title="All"
            score={averageScore}
            onClick={() => onClick(null, null)}
            isActive={activeSegmentId === null}
            index={null}
          />

          {scores.map((score, index) => (
            <SegmentTag
              ref={activeSegmentId === score.segmentId ? activeTagRef : null}
              key={score.segmentId}
              title={score.segmentName}
              score={score.score}
              onClick={() => onClick(score.segmentId, index)}
              isActive={activeSegmentId === score.segmentId}
              index={index}
            />
          ))}
        </div>
        {showRightArrow && (
          <div className="arrow" onClick={() => handleScroll('right')}>
            {'>'}
          </div>
        )}
      </div>

      <div className="segment-description-container">
        {scores
          .filter((score) => score.segmentId === activeSegmentId && score.description)
          .map((score) => (
            <div key={score.description} className="segment-description">
              <div ref={arrowRef} className="arrow" />
              <span>{score.description}</span>
            </div>
          ))}
      </div>
    </div>
  );
};
