import * as actionTypes from './ActionTypes';

interface iAction {
  type: string;
  payload: any | null;
}

export const loadingChange = function (loading: boolean): iAction {
  return {
    type: actionTypes.LOADING,
    payload: loading,
  };
};

export const alertModal = function (modal): iAction {
  return {
    type: actionTypes.ALERTING,
    payload: modal,
  };
};

export const resizeWindow = function (modal): iAction {
  return {
    type: actionTypes.RESIZE,
    payload: modal,
  };
};

export const countSession = function (): iAction {
  return {
    type: actionTypes.SESSION_INCR,
    payload: null,
  };
};

export const toggleQuestionnaire = function (show: boolean): iAction {
  return {
    type: actionTypes.SHOW_QUESTIONNAIRE,
    payload: show,
  };
};
