import {
  MAX_DESCRIPTION_LENGTH,
  MAX_SEGMENT_NAME_LENGTH,
  MAX_SEGMENTS,
  MAX_WHEEL_NAME_LENGTH,
  MAX_WHEEL_SCALE,
  MIN_SEGMENTS,
  MIN_WHEEL_SCALE,
} from '../constants';

const resources = {
  _default: {
    confirmMsg: {},
    errorMsg: {
      response: {
        '401': 'Login expired, please login again.',
        '422': 'Operation failed. Please check all fields are entered in the correct format and try again.',
        general: 'Server error.',
        fail: 'Your request cannot be completed, please try again later.',
        'need login': 'Please log in or sign up to access this page.',
      },
    },
    resultMsg: {
      empty: {},
      success: {
        password: {
          update: 'Password set successfully.',
        },
        settings: {
          update: 'Change saved successfully.',
        },
      },
      fail: {},
    },
    fieldValidationMsg: {
      setLogin: {
        email: {
          required: 'Email is required',
          email: 'Email format must be correct',
        },
        password: {
          required: 'Password is required',
          min: 'The password length is at least 6',
          max: 'The password length is at most 32',
        },
      },
      resetpwd: {
        email: {
          required: 'Email is required',
          email: 'Email format must be correct',
        },
      },
      password: {
        pass: {
          required: 'Current password is required',
          min: 'Current password must be at least 6 digits or letters.',
        },
        newPass: {
          required: 'New password is required',
          min: 'New password must be at least 6 digits or letters.',
          max: 'The password length is at most 32',
        },
        confirmPass: {
          required: 'Confirm password is required',
          consist: 'New password and confirmation password must match.',
        },
      },
      wheel: {
        name: {
          required: 'Wheel name is required',
          min: 'Wheel name is required',
          max: `Maximum ${MAX_WHEEL_NAME_LENGTH} characters`,
        },
        total: {
          max: `The maximun is ${MAX_SEGMENTS} total overall`,
          min: `The minimun is ${MIN_SEGMENTS} total overall`,
        },
        max_scale: {
          required: 'Maximun wheel score is required',
          type: 'Please input a valid number',
          max: `Maximum scale is ${MAX_WHEEL_SCALE}`,
          min: `Wheel scale must be at least ${MIN_WHEEL_SCALE}`,
        },
      },
      wheel_edit: {
        name: {
          required: 'Wheel name is required',
          min: 'Wheel name is required',
          max: `Maximum ${MAX_WHEEL_NAME_LENGTH} characters`,
        },
      },
      score: {
        comments: {
          max: 'Maximum 150 characters',
        },
      },
      segment: {
        name: {
          required: 'Segment name is required',
          min: 'Segment name is required',
          max: `Maximum ${MAX_SEGMENT_NAME_LENGTH} characters`,
        },
        description: {
          max: `Maximum ${MAX_DESCRIPTION_LENGTH} characters`,
        },
      },
      settings: {
        firstName: {
          required: 'First name is required',
          min: 'The first name length is at least 1',
        },
        lastName: {
          required: 'Last name is required',
          min: 'The last name length is at least 1',
        },
        email: {
          required: 'Email is required',
          email: 'Email format must be correct',
        },
      },
      signup: {
        first_name: {
          required: 'First name is required',
          min: 'The first name length is at least 1',
        },
        last_name: {
          required: 'Last name is required',
          min: 'The last name length is at least 1',
        },
        email: {
          required: 'Email is required',
          email: 'Email format must be correct',
        },
        password: {
          required: 'Password is required',
          min: 'Password must be at least 6 digits or letters.',
          max: 'The password length is at most 32',
        },
        confPassword: {
          required: 'Confirmation password is required',
          consist: 'Password and confirmation password must match.',
        },
      },
    },
  },
};

export default resources;
