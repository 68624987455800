import React from 'react';
import * as d3 from 'd3';
import { getAverageScore } from '../Components/Shared/utils';

// add gradients from lighter to darker color
export const COLORS = {
  NAVY_BLUE: '#0d2336',
  ORANGE_RED: '#ff554d',
  WHITE: '#FFFFFF',
  ERROR_RED: '#FF0000',
  TRANSPARENT: 'transparent',
  AQUA_BLUE: '#0ea094',
  IVORY: '#fffaf3',
  BEIGE: '#ffe2c1',
  GREEN: '#19AB27',
  WHEEL: [
    ['#ffc200', '#fb900b'],
    ['#ffb8a0', '#ff5149'],
    ['#1cefef', '#009b8c'],
    ['#7d80f0', '#452fb0'],
    ['#fd9b00', '#fc5a00'],
    ['#ff6261', '#bd0000'],
    ['#31d574', '#1c8f8f'],
    ['#6894ff', '#003dad'],
    ['#fff187', '#f9b600'],
    ['#ff8181', '#dd1242'],
    ['#16c259', '#3c7f44'],
    ['#9655e1', '#530da3'],
    ['#7fceff', '#0087b2'],
  ],
  DISABLED_WHEEL: [
    ['#C5C5C5', '#777777'],
    ['#F0F0F0', '#B8B8B8'],
    ['#D0D0D0', '#909090'],
    ['#6C6C6C', '#303030'],
    ['#C5C5C5', '#777777'],
    ['#F0F0F0', '#B8B8B8'],
    ['#D0D0D0', '#909090'],
    ['#6C6C6C', '#303030'],
    ['#C5C5C5', '#777777'],
    ['#F0F0F0', '#B8B8B8'],
    ['#D0D0D0', '#909090'],
    ['#6C6C6C', '#303030'],
    ['#C5C5C5', '#777777'],
  ],
  ORANGE_GRADIENT: ['#fa8868', '#ff554d'],
  GREY_GRADIENT: ['#5d6c79', '#273b4c'],
  AQUA_GRADIENT: ['#1becec', '#009a8c'],
};

export const segmentArc = (or, sa, ea): string => {
  return d3.arc()({
    innerRadius: 1,
    outerRadius: or,
    startAngle: (Math.PI / 180) * sa,
    endAngle: (Math.PI / 180) * ea,
  });
};

export const GradientFilter = ({ aid, index, disabled = false, isFocused = true }) => {
  const [startColor, endColor] = COLORS[disabled ? 'DISABLED_WHEEL' : 'WHEEL'][index % COLORS.WHEEL.length];

  return (
    <defs>
      <linearGradient x1={0} x2={0} y1={0} y2="100%" id={`${aid}_gradient`}>
        <stop offset="0" stopColor={startColor} stopOpacity={isFocused ? 1 : 0.5} />
        <stop offset="1" stopColor={endColor} stopOpacity={isFocused ? 0.7 : 0.3} />
      </linearGradient>
    </defs>
  );
};

export const ScoreCircle = ({ avgScore, width, scoreOptions }) => {
  const background = scoreOptions ? scoreOptions.background : COLORS.NAVY_BLUE;
  const radius = scoreOptions ? scoreOptions.radius : width * 0.07;
  const color = scoreOptions ? scoreOptions.fill : '#fff';

  return (
    <g>
      <circle cx={0} cy={0} r={radius} fill={background} />
      <text
        x={0}
        y={0}
        textAnchor="middle"
        alignmentBaseline="central"
        fill={color}
        {...(scoreOptions ? scoreOptions : {})}
      >
        {avgScore}
      </text>
    </g>
  );
};

interface iScoreOptions {
  radius?: number;
  background?: string;
  color?: string;
  fontWeight?: string;
  fontSize?: string;
  fontFamily?: string;
}

interface iProps {
  scores?: any[];
  cid?: string;
  width?: number;
  showScore?: boolean;
  wheel: any;
  scoreOptions?: iScoreOptions;
}
type tScore = {
  comment: string | null;
  createDate: Date;
  score: number;
  segmentId: string;
  segment_name: string;
};

export const WheelChart = (props: iProps) => {
  const { scores = [], cid = 'wh', width = 350, showScore = true, wheel, scoreOptions } = props;
  const baseRadius = width / 2;
  const center = { x: baseRadius, y: baseRadius };
  const angleInc = 360 / scores.length;
  // TODO take a look at types mismatch
  // @ts-ignore
  const avgScore = getAverageScore(scores);

  const createArea = (score: tScore, index) => {
    const percent = score.score / wheel.maxScale;
    const aid = `arc_area_${index}`;
    const startAngle = index * angleInc;
    const endAngle = startAngle + angleInc;
    const outerRadius = Math.max(percent * baseRadius, 25);
    return (
      <g key={score.segmentId}>
        <GradientFilter {...{ aid, index }} />
        <path id={aid} d={segmentArc(outerRadius, startAngle, endAngle)} fill={`url(#${aid}_gradient)`} />
      </g>
    );
  };

  // INFO scoreOptions might be the reason of black segments
  return (
    <svg width={width} height={width}>
      <g id={`${cid}_chart_svg`} transform={`translate(${center.x},${center.y})`}>
        {scores.map(createArea)}
        {showScore && <ScoreCircle {...{ avgScore, width }} scoreOptions={scoreOptions} />}
      </g>
    </svg>
  );
};
