import React from 'react';
import styled from 'styled-components';
import { InsightsPeriodType } from './insights.model';

type Props = {
  firstLabel: string;
  secondLabel: string;
  onChange: (button: InsightsPeriodType) => void;
  period: InsightsPeriodType;
};

const ToggleContainer = styled.div`
  display: flex;
  align-items: center;
  border: 1px solid #cececf;
  border-radius: 50px;
  background-color: rgb(248, 248, 247);
`;

const Button = styled.div<{ isActive: boolean }>`
  font-size: 14px;
  border-radius: 50px;
  padding: 5px 15px;
  cursor: pointer;

  background-color: ${({ isActive }) => (isActive ? '#2a3e50' : 'transparent')};
  color: ${({ isActive }) => (isActive ? '#fff' : '#233748')};
`;

const PeriodButton: React.FC<Props> = ({ firstLabel, secondLabel, onChange, period }) => (
  <ToggleContainer>
    <Button isActive={period === 'week'} onClick={() => onChange('week')}>
      {firstLabel}
    </Button>
    <Button isActive={period === 'month'} onClick={() => onChange('month')}>
      {secondLabel}
    </Button>
  </ToggleContainer>
);

export default PeriodButton;
