import React, { Component, useState } from 'react';
import { AuthContainer } from './AuthContainer';
import { TextInput } from '../Shared/Inputs/TextInput';
import './ResetPasswordStart.scss';
import { BsArrowLeftShort } from 'react-icons/bs';
import { Link } from 'react-router-dom';
import { Button } from '../Shared/Buttons/Button';
import services from '../../API/services';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { Toast } from '../Shared/Toaster/Toast';
import { Title, Text } from '../Shared/Typography/Typography';
import { analytics } from '../../analytics/analytics';
import { ToastTypes } from '../Shared/Toaster/Toaster.actions';
import { toasterService } from '../Shared/Toaster/Toaster.service';
import { BlackBtn, BlackButtonBox, LeftPanel } from './Onboarding/_styled';
import { ArrowLeft } from 'react-feather';
import { TextField } from '@material-ui/core';

const initialValues = {
  email: '',
};

const validationSchema = yup.object({
  email: yup.string().email('Not valid email format').required('The field can’t be empty'),
});

export const ResetPasswordStart = () => {
  const [isSent, setIsSent] = useState(false);
  const [serverError, setServerError] = useState('');

  const handleSubmit = async ({ email }) => {
    const response = await services.resetPasswordStart(email);
    if (response.status === 200) {
      if (!isSent) {
        setIsSent(true);
        analytics.forgetPassword();
      } else {
        toasterService.addSuccessToast('Password reset instructions have been resent');
      }
    } else {
      setServerError(response.data.message);
    }
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: handleSubmit,
  });

  return (
    <AuthContainer>
      <div className="reset-password">
        {serverError && (
          <div className="mb-4">
            <Toast type={ToastTypes.ERROR} message={serverError} />
          </div>
        )}
        <Link to="/login">
          <BlackButtonBox>
            <BlackBtn>
              <ArrowLeft size={13} /> BACK
            </BlackBtn>
          </BlackButtonBox>
        </Link>
        <LeftPanel style={{ width: '100%' }}>
          <div className="mb-4">
            <Text weight={600} size="36px">
              Reset Password
            </Text>
          </div>
          {!isSent && (
            <form onSubmit={formik.handleSubmit}>
              <div className="mb-4">
                <Text>Enter the email address associated with your account</Text>
              </div>
              <div className="mb-4 d-flex">
                <TextField
                  id="reset-password_email"
                  label="Email"
                  type="email"
                  variant="outlined"
                  placeholder="Enter Your Email"
                  value={formik.values.email}
                  onChange={formik.handleChange('email')}
                  onBlur={formik.handleBlur('email')}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  helperText={formik.touched.email && formik.errors.email}
                  style={{ width: '100%' }}
                />
              </div>
              <div>
                <Button type="submit" disabled={!(formik.isValid && formik.dirty)}>
                  Reset
                </Button>
              </div>
            </form>
          )}
          {isSent && (
            <div className="reset-password_success-message">
              Password reset instructions have been sent to {formik.values.email}
              <div className="mt-4">
                <Button onClick={() => formik.handleSubmit()}>Resend email</Button>
              </div>
            </div>
          )}
        </LeftPanel>
      </div>
    </AuthContainer>
  );
};
