import styled, { css } from 'styled-components';

import { Button } from '../../Shared/_styled';

const folderActiveCss = css`
  background-color: #2a3e50;
  &:before {
    background: linear-gradient(176deg, #fa8868 3%, #ff554d);
    opacity: 1;
  }
`;

type iFolderProps = React.ComponentProps<any> & {
  active: boolean;
  hasParent?: boolean;
  onClick: any;
};

const subfolderStyles = css`
  padding-left: 56px;
`;

export const Container = styled<iFolderProps>(Button)`
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  background-clip: padding-box;
  font-size: 14px;
  font-weight: normal;
  color: #fff;
  text-align: left;
  padding: 12px 16px 12px 32px;
  opacity: 0.9;
  :hover {
    opacity: 1;
  }

  &:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 4px;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
  }
  &:hover {
    background-color: #2a3e50;
  }

  ${({ active }) => active && folderActiveCss};
  ${({ hasParent }) => hasParent && subfolderStyles};
`;

export const CreateFolderButton = styled(Button)`
  background-color: #2a3e50;
  border-radius: 16px;
  color: #fff;
  font-size: 14px;
  font-weight: normal;
  padding: 4px 16px 4px 8px;
`;

export const StyledSpan = styled.span`
  display: block;
  max-width: 160px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;
