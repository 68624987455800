import { HttpClient } from './HttpClient';
import { AxiosResponse } from 'axios';
import { DateTime } from 'luxon';

export class SupportClient {
  static getCrisisSupport() {
    return HttpClient.get(`/support/crisis-support`)
      .then((res: AxiosResponse) => res.data)
      .catch((error) => error.response);
  }

  static getTutorials() {
    return HttpClient.get(`/support/tutorials`)
      .then((res: AxiosResponse) => res.data)
      .catch((error) => error.response);
  }

  static getResources(categories: number, per_page: number) {
    return HttpClient.get('https://iyarn.com/wp-json/wp/v2/posts', { params: { categories, per_page, _embed: true } })
      .then((res: AxiosResponse) => res.data)
      .catch((error) => error.response);
  }

  static getResourcesByTags(tags: number, per_page: number) {
    return HttpClient.get('https://iyarn.com/wp-json/wp/v2/posts', { params: { tags, per_page, _embed: true } })
      .then((res: AxiosResponse) => res.data)
      .catch((error) => error.response);
  }

  static getArticle(articleId: string) {
    return HttpClient.get(`https://iyarn.com/wp-json/wp/v2/posts/${articleId}`, { params: { _embed: true } })
      .then((res: AxiosResponse) => res.data)
      .catch((error) => error.response);
  }

  static parseArticles = (p) => ({
    id: p.id,
    title: p.yoast_head_json.og_title,
    date: DateTime.fromISO(p.date).toFormat('MMMM dd, yyyy'),
    image: p.yoast_head_json?.og_image?.[0].url,
    content: p.content.rendered,
    description: p.yoast_head_json.description,
  });
}
