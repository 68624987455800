import { APP_PLATFORMS } from '../constants';
import { googleAnalytics } from './google';
import { mixpanelAnalytics } from './mixpanel';
import { DateRangeLabel } from '../Components/WheelPage/common/DateRangeDropdown/DateRangeDropdown';
import { eSortOptions } from '../Components/Dashboard/_types';
import { AccountPlan } from '../API/interfaces';

class Analytics {
  ga = googleAnalytics;
  ma = mixpanelAnalytics;

  signUp() {
    this.ga.signUp();
    this.ma.signUp();
  }

  signUpSSO() {
    this.ga.signUpSSO();
    this.ma.signUpSSO();
  }

  signUpLink() {
    this.ga.signUpLink();
  }

  login() {
    this.ga.login();
    this.ma.login();
  }

  loginSSO() {
    this.ga.loginSSO();
    this.ma.loginSSO();
  }

  loginLink() {
    this.ga.loginLink();
  }

  publicWheelSignUp() {
    this.ga.publicWheelSignUp();
    this.ma.publicWheelSignUp();
  }

  publicWheelLogin() {
    this.ga.publicWheelLogin();
    this.ma.publicWheelLogin();
  }

  createWheelAttempt() {
    this.ga.createWheelAttempt();
    this.ma.createWheelAttempt();
  }

  createWheelSubmit(segmentsCount: number, access) {
    this.ga.createWheelSubmit(segmentsCount, access);
    this.ma.createWheelSubmit(segmentsCount, access);
  }

  createCheckinAttempt(isPublic: boolean) {
    this.ga.createCheckinAttempt(isPublic);
    this.ma.createCheckinAttempt(isPublic);
  }

  createCheckinSubmit(isPublic: boolean) {
    this.ga.createCheckinSubmit(isPublic);
    this.ma.createCheckinSubmit(isPublic);
  }

  shareWheel() {
    this.ga.shareWheel();
    this.ma.shareWheel();
  }

  forgetPassword() {
    this.ga.forgetPassword();
    this.ma.forgetPassword();
  }

  forgotPasswordLink() {
    this.ga.forgotPasswordLink();
    this.ma.forgotPasswordLink();
  }

  saveNewPassword() {
    this.ga.saveNewPassword();
  }

  wheelSettings() {
    this.ga.wheelSettings();
    this.ma.wheelSettings();
  }

  downloadApp(platform: APP_PLATFORMS) {
    this.ga.downloadApp(platform);
  }

  deleteWheel() {
    this.ga.deleteWheel();
    this.ma.deleteWheel();
  }

  tutorial() {
    this.ga.tutorial();
  }

  onboarding_tag_personal() {
    this.ga.onboarding_tag_personal();
    this.ma.onboarding_tag_personal();
  }

  onboarding_tag_business() {
    this.ga.onboarding_tag_business();
    this.ma.onboarding_tag_business();
  }

  onboarding_tag_school() {
    this.ga.onboarding_tag_school();
    this.ma.onboarding_tag_school();
  }

  onb_signup_personal() {
    this.ga.onb_signup_personal();
  }

  onb_signup_business() {
    this.ga.onb_signup_business();
  }

  onb_signup_school() {
    this.ga.onb_signup_school();
  }

  saveImage(share = false) {
    this.ga.saveImage(share);
    this.ma.saveImage(share);
  }

  duplicate_wheel() {
    this.ga.duplicate_wheel();
    this.ma.duplicate_wheel();
  }

  joinWheel() {
    this.ga.joinWheel();
    this.ma.joinWheel();
  }

  joinBuddyRoleWheel() {
    this.ga.joinBuddyRoleWheel();
    this.ma.joinBuddyRoleWheel();
  }

  onboardingCustomEvent() {
    this.ga.onboardingCustomEvent();
  }

  duplicateOnWheelPage() {
    this.ga.duplicateOnWheelPage();
    this.ma.duplicateOnWheelPage();
  }

  settingsOnWheelPage() {
    this.ga.settingsOnWheelPage();
    this.ma.settingsOnWheelPage();
  }

  downloadXLS() {
    this.ga.downloadXLS();
  }

  checkinReminderTab() {
    this.ga.checkinReminderTab();
    this.ma.checkinReminderTab();
  }

  checkinReminderMemberOff() {
    this.ga.checkinReminderMemberOff();
    this.ma.checkinReminderMemberOff();
  }

  checkinReminderAdminOff() {
    this.ga.checkinReminderAdminOff();
    this.ma.checkinReminderAdminOff();
  }

  checkinReminderAdminOn() {
    this.ga.checkinReminderAdminOn();
    this.ma.checkinReminderAdminOn();
  }

  leaveWheel() {
    this.ga.leaveWheel();
    this.ma.leaveWheel();
  }

  clickInviteLink(type: 'loggedIn' | 'loggedOut') {
    this.ga.clickInviteLink(type);
    this.ma.clickInviteLink();
  }

  clickInviteBuddy() {
    this.ga.clickInviteBuddy();
    this.ma.clickInviteBuddy();
  }

  dateRangeDropdown(tab: 'myResults' | 'teamResults' | 'memberResults', dropDownOption: DateRangeLabel) {
    this.ga.dateRangeDropdown(tab, dropDownOption);
  }

  clickMembersTab() {
    this.ga.clickMembersTab();
  }

  clickGenerateReport() {
    this.ga.clickGenerateReport();
    this.ma.clickGenerateReport();
  }

  generatePdfReport(tab: 'myResults' | 'teamResults' | 'memberResults', isAdmin = false) {
    this.ga.generatePdfReport(tab, isAdmin);
    this.ma.generatePdfReport(tab, isAdmin);
  }

  clickHelp(location: 'beforeSignIn' | 'afterSignIn' | 'publicCheckin') {
    this.ga.clickHelp(location);
    this.ma.clickHelp(location);
  }

  submitHelp() {
    this.ga.submitHelp();
  }

  clickExpandComments(expand = true) {
    this.ga.clickExpandComments(expand);
  }

  clickCommentsFilter(option: 'allCheckins' | 'onlyWithText') {
    this.ga.clickCommentsFilter(option);
  }

  clickUpgradeImHeader() {
    this.ga.clickUpgradeImHeader();
  }

  clickFeaturesInMenu() {
    this.ga.clickFeaturesInMenu();
  }

  clickOnLockedFeature(feature: 'generate-report' | 'members' | 'pdf-report') {
    this.ga.clickOnLockedFeature(feature);
  }

  questionnaireClickOnTrigger() {
    this.ga.questionnaireClickOnTrigger();
  }

  questionnaireSend() {
    this.ga.questionnaireSend();
  }

  requestDemo(location: 'page-header' | 'user-menu' | 'generate-report' | 'members' | 'pdf-report' | 'questionnaire') {
    this.ga.requestDemo(location);
  }

  templateTab() {
    this.ga.templateTab();
  }

  insightsTab() {
    this.ga.insightsTab();
  }

  useDefaultTemplate(accountTag: string, templateName: string) {
    this.ga.useDefaultTemplate(accountTag, templateName);
  }

  useOwnTemplate() {
    this.ga.useOwnTemplate();
  }

  createTemplate() {
    this.ga.createTemplate();
  }

  createTemplateSubmit() {
    this.ga.createTemplateSubmit();
  }

  teachersTab() {
    this.ga.teachersTab();
  }

  teamMembersTab() {
    this.ga.teamMembersTab();
  }

  inviteTeamMembers(plan: AccountPlan) {
    switch (plan) {
      case AccountPlan.SCHOOL:
        this.ga.inviteTeamMembers('Teachers');
        break;
      case AccountPlan.PERFORMANCE:
        this.ga.inviteTeamMembers('Team Members');
        break;
      default:
        break;
    }
  }

  inviteTeamMemberSubmit(plan: AccountPlan) {
    switch (plan) {
      case AccountPlan.SCHOOL:
        this.ga.inviteTeamMembersSubmit('Teachers');
        break;
      case AccountPlan.PERFORMANCE:
        this.ga.inviteTeamMembersSubmit('Team Members');
        break;
      default:
        break;
    }
  }

  deleteTeamMember(plan: AccountPlan) {
    switch (plan) {
      case AccountPlan.SCHOOL:
        this.ga.deleteTeamMember('Teachers');
        break;
      case AccountPlan.PERFORMANCE:
        this.ga.deleteTeamMember('Team Members');
        break;
      default:
        break;
    }
  }

  deleteTeamMemberConfirm(plan: AccountPlan) {
    switch (plan) {
      case AccountPlan.SCHOOL:
        this.ga.deleteTeamMemberConfirm('Teachers');
        break;
      case AccountPlan.PERFORMANCE:
        this.ga.deleteTeamMemberConfirm('Team Members');
        break;
      default:
        break;
    }
  }

  resendInvite(plan: AccountPlan) {
    switch (plan) {
      case AccountPlan.SCHOOL:
        this.ga.resendInvite('Teachers');
        break;
      case AccountPlan.PERFORMANCE:
        this.ga.resendInvite('Team Members');
        break;
      default:
        break;
    }
  }

  resendInviteSubmit(plan: AccountPlan) {
    switch (plan) {
      case AccountPlan.SCHOOL:
        this.ga.resendInviteSubmit('Teachers');
        break;
      case AccountPlan.PERFORMANCE:
        this.ga.resendInviteSubmit('Team Members');
        break;
      default:
        break;
    }
  }

  dashboardSearch() {
    this.ga.dashboardSearch();
  }

  dashboardSorting(type: eSortOptions) {
    this.ga.dashboardSorting(type);
  }

  moveToFolder() {
    this.ga.moveToFolder();
  }

  createFolder() {
    this.ga.createFolder();
  }

  createSubfolder() {
    this.ga.createSubfolder();
  }

  openResourceFromMenu() {
    this.ga.openResourceFromMenu();
  }
  chooseResourceTab() {
    this.ga.chooseResourceTab();
  }
  openCrisisFromMenu() {
    this.ga.openCrisisFromMenu();
  }
  chooseCrisisSupportTab() {
    this.ga.chooseCrisisSupportTab();
  }
  openTutorialsFromMenu() {
    this.ga.openTutorialsFromMenu();
  }
  chooseTutorialsTab() {
    this.ga.chooseTutorialsTab();
  }

  crisisSupportCall(name) {
    this.ga.crisisSupportCall(name);
  }

  crisisSupportRedirect(name) {
    this.ga.crisisSupportRedirect(name);
  }

  clickInsightsFree() {
    this.ga.clickInsightsFree();
    this.ma.clickInsightsFree();
  }

  clickInsightsPaid() {
    this.ga.clickInsightsPaid();
    this.ma.clickInsightsPaid();
  }

  unlockRedFlags() {
    this.ga.unlockRedFlags();
    this.ma.unlockRedFlags();
  }

  supportNowClick() {
    this.ga.supportNowClick();
    this.ma.supportNowClick();
  }

  supportNowSent() {
    this.ga.supportNowSent();
    this.ma.supportNowSent();
  }

  markAllNotificationsAsRead() {
    this.ga.markAllNotificationsAsRead();
    this.ma.markAllNotificationsAsRead();
  }

  openNotifications() {
    this.ga.openNotifications();
    this.ma.openNotifications();
  }

  // open Check in Assist modal on Wheel page Member results Tab
  openMemberResultsCheckinAssist() {
    this.ga.openMemberResultsCheckinAssist();
    this.ma.openMemberResultsCheckinAssist();
  }

  // open Check in Assist modal on Wheel page Members Tab
  openMembersCheckinAssist() {
    this.ga.openMembersCheckinAssist();
    this.ma.openMembersCheckinAssist();
  }

  submitCheckinAssist() {
    this.ga.submitCheckinAssist();
    this.ma.submitCheckinAssist();
  }

  openSuggestedResources() {
    this.ga.openSuggestedResources();
  }

  clickSuggestedResourcesTab() {
    this.ga.clickSuggestedResourcesTab();
  }

  clickAiSuggestedTab() {
    this.ga.clickAiSuggestedTab();
  }

  clickAiSuggestedPopup() {
    this.ga.clickAiSuggestedPopup();
  }

  clickAiTemplateSupport() {
    this.ga.clickAiTemplateSupport();
  }

  clickAiTemplatePlan() {
    this.ga.clickAiTemplatePlan();
  }

  clickAiTemplateResources() {
    this.ga.clickAiTemplateResources();
  }

  clickOnbPersonalDataAdded() {
    this.ga.clickOnbPersonalDataAdded();
  }

  clickOnbUserTagAdded() {
    this.ga.clickOnbUserTagAdded();
  }

  clickOnbLetsContinue() {
    this.ga.clickOnbLetsContinue();
  }

  clickOnbQuestionsStarted() {
    this.ga.clickOnbQuestionsStarted();
  }

  clickOnbQuestionsFinished() {
    this.ga.clickOnbQuestionsFinished();
  }

  clickOnbSkipAll() {
    this.ga.clickOnbSkipAll();
  }

  clickOnbTestCheckinSkip() {
    this.ga.clickOnbTestCheckinSkip();
  }

  clickOnbTestCheckinComplete() {
    this.ga.clickOnbTestCheckinComplete();
  }

  clickProfileUpdateNotif() {
    this.ga.clickProfileUpdateNotif();
  }

  clickProfileUpdateStart() {
    this.ga.clickProfileUpdateStart();
  }

  clickProfileUpdateComplete() {
    this.ga.clickProfileUpdateComplete();
  }
}

export const analytics = new Analytics();
