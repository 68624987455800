import { store } from '../Redux/store';
import { logoutAction } from '../Components/Auth/Auth.actions';
import { joinLinkAction } from '../Components/JoinWheel/JoinWheel.actions';

const redirectToLogin = () => {
  store.dispatch(logoutAction());
  store.dispatch(joinLinkAction(null));
  setTimeout(() => {
    window.location.href = '/';
  }, 100);
};

class Interceptor {
  public request(config) {
    const { user } = store.getState().userRD;
    if (user) {
      config.headers = {
        ...config.headers,
        Authorization: `Bearer ${user.token}`,
      };
    }
    return config;
  }

  public responseError(error) {
    if (error.response && error.response.status === 401) {
      redirectToLogin();
    }

    return Promise.reject(error);
  }
}

export default new Interceptor();
