import React from 'react';
import { useDropzone } from 'react-dropzone';
import { Text } from '../../../Shared/Typography/Typography';
import { BlackBtn } from '../../../Auth/Onboarding/_styled';
import { ArrowLeft } from 'react-feather';
import styled from 'styled-components';
import uploadDrag from '../../../../Assets/images/invitations/upload-drag-drop.svg';
import Papa from 'papaparse';
import * as XLSX from 'xlsx';
import { normalizeHeader } from '../../utils';

const FileUpload = ({ handlers }) => {
  const { setUserData, setData, setFileName, validateRows, setCurrentStep } = handlers;

  const filterEmptyRows = (rows) => {
    return rows.filter(
      (row) => row.firstName?.trim() !== '' && row.lastName?.trim() !== '' && row.email?.trim() !== ''
    );
  };

  const onDrop = (acceptedFiles) => {
    const file = acceptedFiles[0];
    setFileName(file.name);
    const reader = new FileReader();

    reader.onload = (e) => {
      const fileData = e.target.result;

      if (file.type === 'text/csv') {
        Papa.parse(fileData, {
          header: true,
          complete: (result) => {
            const normalizedData = (result.data as Array<Record<string, any>>).map((row) => {
              const normalizedRow: Record<string, any> = {};
              Object.keys(row).forEach((key) => {
                const normalizedKey = normalizeHeader(key);
                normalizedRow[normalizedKey] = row[key];
              });
              return normalizedRow;
            });

            console.log(normalizedData);

            const filteredData = filterEmptyRows(normalizedData);
            if (validateRows(filteredData)) {
              setData(filteredData);
              setUserData(filteredData);
              setCurrentStep(3);
            }
          },
        });
      } else if (
        file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
        file.type === 'application/vnd.ms-excel'
      ) {
        const workbook = XLSX.read(fileData, { type: 'array' });
        const sheetName = workbook.SheetNames[0];
        const worksheet: Array<Array<any>> = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName], { header: 1 });

        const filteredWorksheet = worksheet.filter((row) => row.some((cell) => cell !== ''));

        const headers = filteredWorksheet[0].map((header) => normalizeHeader(header as string));
        const body = filteredWorksheet.slice(1);

        const normalizedData = body.map((row: any) => {
          const rowData: Record<string, string> = {};
          headers.forEach((header: string, index: number) => {
            rowData[header] = row[index];
          });
          return rowData;
        });

        const filteredData = filterEmptyRows(normalizedData);
        if (validateRows(filteredData)) {
          setData(filteredData);
          setUserData(filteredData);
          setCurrentStep(3);
        }
      }
    };

    if (file.type === 'text/csv') {
      reader.readAsText(file);
    } else {
      reader.readAsArrayBuffer(file);
    }
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      'text/csv': [],
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [],
      'application/vnd.ms-excel': [],
    },
  });

  return (
    <div className="d-flex flex-column">
      <Text size="14px" className="mb-3">
        Step 2/3
      </Text>
      <Text size="30px" className="mb-3">
        Upload the filled sample back
      </Text>
      <div {...getRootProps()} className="d-flex flex-column align-items-center">
        <input {...getInputProps()} />
        <DragBox>
          <UploadIcon>
            <img src={uploadDrag} />
          </UploadIcon>
          <Text size="14px" className="mt-2">
            {isDragActive ? 'Drop the file here ...' : 'Drop file here or click here to upload'}
          </Text>
        </DragBox>
      </div>
      <div className="d-flex justify-content-between mt-4">
        <BlackBtn onClick={() => setCurrentStep(1)}>
          <ArrowLeft size={13} /> back to download
        </BlackBtn>
      </div>
    </div>
  );
};

export default FileUpload;

const UploadIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ffecda;
  border-radius: 50%;
  height: 72px;
  width: 72px;
`;

const DragBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 1px;
  border-radius: 8px;
  border-color: #d1d1d2;
  border-style: dashed;
  background-color: #fafafa;
  outline: none;
  transition: border 0.24s ease-in-out;
  cursor: pointer;

  &:hover {
    background-color: #f1f1f1;
  }
`;
