import styled from 'styled-components';
import { withStyles } from '@material-ui/core';
import MuiButton from '@material-ui/core/Button';

export const AiContainer = styled.div<{ isOpen: boolean }>`
  display: ${({ isOpen }) => (isOpen ? 'flex' : 'none')};
  position: fixed;
  top: 0;
  right: 0;
  flex-direction: row;
  height: 100%;
  background-color: #fff;
  box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.16);
  z-index: 11;
  width: 100%;
  overflow: hidden;
`;

export const ContentWrapper = styled.div`
  padding: 78px 48px 10px;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  background-color: #f8f8f7;
  height: 100%;
  gap: 20px;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    padding: 65px 0 0;
    background-color: #fff;
    max-width: 100%;
  }
`;

export const Conversation = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;

  @media (max-width: 768px) {
    gap: 16px;
  }
`;

export const Initials = styled.div`
  min-width: 32px;
  width: 32px;
  height: 32px;
  background: #0f2136;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  user-select: none;
`;

export const Replica = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;

  @media (max-width: 768px) {
    padding: 0 20px;
  }
`;

interface SidebarWrapperProps {
  expanded: boolean;
}

export const SidebarWrapper = styled.div<SidebarWrapperProps>`
  display: flex;
  flex-direction: column;
  padding: 78px 0 52px;
  max-width: 300px;
  width: 100%;
  background-color: #0f2136;
  height: 100%;
  overflow-y: auto;
  user-select: none;
  @media (max-width: 768px) {
    position: absolute;
    max-width: unset;
    width: 100%;
    z-index: 15;
    padding-top: 44px;
    padding-bottom: 20px;
    min-height: 98px;
    height: ${(props) => (props.expanded ? 'fit-content' : '98px')};
    overflow-x: hidden;
    overflow-y: ${(props) => (props.expanded ? 'auto' : 'hidden')};
    max-height: 100%;
  }
`;

export const BackToWheel = styled.div`
  width: fit-content;
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: center;
  cursor: pointer;

  @media (max-width: 768px) {
    padding-left: 20px;
    margin-top: 16px;
  }
`;

export const Description = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;

  @media (max-width: 768px) {
    padding-left: 20px;
  }
`;

export const AIChatContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 90%;
  max-height: 800px;
  width: 100%;
  max-width: 1032px;
  margin-left: auto;
  margin-right: auto;
  padding: 30px 0 0;
  background-color: #fff;
  box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.16);
  border-radius: 4px;

  @media (max-width: 768px) {
    box-shadow: unset;
    padding: 0;
    align-items: baseline;
    height: 96%;
  }
`;

export const AIChatwrapper = styled.div`
  width: 90%;
  max-width: 900px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const ChatContent = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 808px;
  width: 100%;
  padding: 20px;
  overflow-y: auto;
  overflow-x: hidden;

  @media (max-width: 768px) {
    padding: 0;
  }
`;

export const StartChat = styled.div`
  display: flex;
  flex-direction: column;
`;

export const AiMsg = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  min-width: 32px;
  height: 32px;
  box-shadow: 0 2px 11px 0 rgba(0, 0, 0, 0.1);
  border: solid 1px #eee;
  border-radius: 50%;
  user-select: none;

  img {
    width: 20px;
    height: 20px;
  }
`;

export const BtnContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-left: 32px;
  height: 40px;
`;

export const MobileSidebar = styled.div`
  padding: 6px 16px 12px 32px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const Button = withStyles({
  root: {
    whiteSpace: 'nowrap',
    // fontSize: '16px',
    fontWeight: 400,
    lineHeight: 1.13,
    textTransform: 'none',
    borderRadius: '20px',
    boxShadow: '0 2px 16px 0 rgba(0, 0, 0, 0.24)',
    zIndex: 2,
    maxWidth: '120px',
  },
  contained: {
    color: '#ffffff',
    backgroundColor: '#2a3e50',
    '&:hover': {
      backgroundColor: '#2a3e50',
    },
  },
})(MuiButton);

export const ChatListItem = styled.div`
  display: flex;
  position: relative;
  flex-direction: row;
  gap: 8px;
  width: 100%;
  align-items: center;
  position: relative;
  width: 100%;
  background-clip: padding-box;
  font-size: 14px;
  font-weight: normal;
  color: #fff;
  text-align: left;
  padding: 12px 6px 12px 32px;
  cursor: pointer;

  &.active {
    background-color: rgb(42, 62, 80);

    &:before {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      width: 4px;
      opacity: 1;
      transition: opacity 0.3s ease-in-out;
      background: linear-gradient(176deg, rgb(250, 136, 104) 3%, rgb(255, 85, 77));
    }

    &:hover {
      background-color: rgba(42, 62, 80, 1);
    }
  }

  &:hover {
    background-color: rgba(42, 62, 80, 0.5);
  }

  span {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
`;

export const ShowBtn = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 6px;
  color: white;
  padding: 12px 0;
  cursor: pointer;

  & > * {
    color: white !important;
  }
`;

export const ListContainer = styled.div`
  padding-top: 40px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media (max-width: 768px) {
    padding-top: 18px;
    overflow-x: auto;
    overflow-y: hidden;
    gap: 20px;
    scrollbar-width: none;
    padding: 20px;
  }
`;

export const ItemContainer = styled.div`
  height: 100%;
  width: 24%;
  min-height: 250px;
  max-width: 180px;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.16);
  position: relative;
  justify-content: end;
  user-select: none;
  cursor: pointer;
  :hover {
    box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.4);
  }
  &.active {
    background: #fefaf2;
  }

  @media (max-width: 768px) {
    width: 55%;
    max-width: 200px;
  }
`;

export const TextBox = styled.div`
  position: absolute;
  top: 20px;
  left: 15px;
  bottom: 20px;
  right: 15px;
  display: flex;
  flex-direction: column;
`;

export const StartDecs = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;
