import React from 'react';
import { useMsal } from '@azure/msal-react';
import { loginRequest } from './msal.config';
import services from '../../../../API/services';
import { SsoButton } from '../../../Shared/Buttons/Button';
import microsoftLogo from '../../../../Assets/images/microsoft-logo.svg';

export const SignInButtonMS = ({ submitSSO, children }: { submitSSO: (res) => void; children? }) => {
  const { instance } = useMsal();

  const handleLogin = async () => {
    const authData = await instance.loginPopup(loginRequest).catch((e) => {
      console.log(e);
    });
    const res = await services.userLoginSsoMs(authData);
    submitSSO(res);
  };

  return (
    <SsoButton onClick={handleLogin}>
      <img src={microsoftLogo} className="mr-1" />
      {children}
    </SsoButton>
  );
};
