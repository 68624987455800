import React from 'react';
import styled, { css } from 'styled-components';
import _ from 'lodash';

import { Tag, Text, Text1 } from '../_styled';
import { SearchDesktop } from '../Search/SearchDesktop';
import { SortDesktop } from '../Sort/SortDesktop';
import { WHEEL_FILTER_TAGS } from '../_consts';
import { eWheelFilterTags } from '../_types';
import { formatTag } from '../_utils';
import NoWheelsCreated from '../NoWheelsCreated';
import NothingFound from '../NothingFound';

import { StyledMainButton, List, Cards } from './_styled';
import { iProps } from './_types';
import { CardDesktop } from '../Card';
import nUser from '../../../_utils/user';

export const DashboardDesktop = (props: iProps) => {
  const {
    query,
    setQuery,
    setSort,
    filter,
    onFilterTagClick,
    wheels,
    user,
    onWheelRemoved,
    showWheelDuplicateModal,
    showNoWheelsHaveBeenCreated,
    showNothingFound,
    history,
    onCreateWheel,
    folders,
    onMoveWheelToFolder,
    setWheelToDelete,
    setWheelToInvite,
    setWheelToLeave,
  } = props;

  return (
    <ContainerDesktop className="container" fullWidth={nUser.hasAccessToFolders(user)}>
      <div className="col-12 d-flex">
        <Text className="col-4 pl-0">Dashboard</Text>

        <div className="col-8 pr-0 d-flex justify-content-end align-items-center">
          <SearchDesktop value={query} onChange={setQuery} />

          <SortDesktop setSort={setSort} />

          <StyledMainButton onClick={onCreateWheel}>CREATE A NEW WHEEL</StyledMainButton>
        </div>
      </div>

      <List className="col-12">
        {WHEEL_FILTER_TAGS.map((tag: eWheelFilterTags) => (
          <Tag selected={filter[tag]} key={tag} onClick={() => onFilterTagClick(tag)}>
            {formatTag(tag)}
          </Tag>
        ))}
      </List>

      {!_.isEmpty(wheels) && (
        <>
          <Text1 className="col-12">{`All Wheels (${wheels?.length})`}</Text1>
          <Cards className="col-12">
            {wheels.map((wheel, index) => (
              <CardDesktop
                key={wheel.id}
                id={wheel.id}
                wheel={wheel}
                user={user}
                history={history}
                removeWheel={() => setWheelToDelete(wheel)}
                inviteToWheel={() => setWheelToInvite(wheel)}
                duplicateWheel={() => showWheelDuplicateModal(wheel)}
                leaveWheel={() => setWheelToLeave(wheel)}
                folders={folders}
                onMoveWheelToFolder={(folder) => onMoveWheelToFolder(folder, wheel)}
              />
            ))}
          </Cards>
        </>
      )}
      {showNoWheelsHaveBeenCreated && <NoWheelsCreated />}
      {showNothingFound && !showNoWheelsHaveBeenCreated && <NothingFound />}
    </ContainerDesktop>
  );
};

const fullWidthContainerDesktopCss = css`
  max-width: 100%;
  margin-right: 70px;
  margin-left: 18px;
`;
const ContainerDesktop = styled.div<{ fullWidth?: boolean }>`
  padding-top: 48px;
  //padding-bottom: 20px;
  ${({ fullWidth }) => fullWidth && fullWidthContainerDesktopCss};
`;
