import * as _ from 'lodash';
import { AxiosResponse } from 'axios';
import { DateTime } from 'luxon';
import {
  AggregationUnit,
  iCheckinRequest,
  iCheckinsAggregation,
  iCommentsAggregation,
  iScoresAggregation,
  iWheel,
} from '../../../API/interfaces';
import services from '../../../API/services';
import { getAverageScore } from '../../Shared/utils';
import { DATE_RANGE_FORMAT, SINGLE_DATE_FORMAT, SINGLE_DATETIME_FORMAT } from '../../../constants';

// handy aggregations for results tabs and templates
export default class AggregationService {
  private readonly wheel: iWheel;
  private readonly isTeamResults: boolean;
  private readonly segmentProperties: { [key: string]: { segmentName: string; description: string } };

  constructor(wheel: iWheel, isTeamResults = false) {
    this.wheel = wheel;
    this.isTeamResults = isTeamResults;
    this.segmentProperties = {};
  }

  // load checkins
  async loadData(requestParams: iCheckinRequest): Promise<AxiosResponse> {
    return await services[this.isTeamResults ? 'getWheelTeamCheckins' : 'getWheelCheckinsByUserId'](requestParams);
  }

  // get comments for representation
  getActiveComments(
    checkins: Array<iCheckinsAggregation>,
    onlyCommentsWithText: boolean,
    activeSegmentId: string
  ): iCommentsAggregation {
    let comments;
    if (this.wheel.isScoreComments && activeSegmentId !== null) {
      comments = this.getAllCommentsForSegment(checkins, activeSegmentId);
    } else {
      comments = this.getAllComments(checkins);
    }

    return onlyCommentsWithText ? this.getCommentsWithText(comments) : comments;
  }

  // get all score comments grouped by segment name
  getAllSegmentComments(checkins: Array<iCheckinsAggregation>): { [segmentName: string]: iCommentsAggregation } {
    const allSegments = {};
    this.wheel.segments.forEach((s) => {
      allSegments[s.name] = this.getAllCommentsForSegment(checkins, s.id);
    });

    return allSegments;
  }

  // get data from particular checkin or average from all checkins in date range
  getActiveCheckin(checkins: Array<iCheckinsAggregation>, activeCheckinDate: string): iCheckinsAggregation {
    if (activeCheckinDate) {
      return checkins.find((checkin) => checkin.dateRange === activeCheckinDate);
    }

    return this.getAverageDataAsCheckin(checkins);
  }

  // server response adapter
  mapCheckinsResponse(serverResponse: AxiosResponse, timeZone = 'local'): Array<iCheckinsAggregation> {
    const { aggregation, unit } = serverResponse.data;

    aggregation.forEach((checkin) => {
      // convert to luxon's DateTime
      checkin.from = DateTime.fromISO(checkin.from).setZone(timeZone);
      checkin.to = DateTime.fromISO(checkin.to).setZone(timeZone);

      checkin.dateRange = this.getCheckinDateRange(checkin.from, checkin.to, unit);
      checkin.comments = this.mapComments(checkin.comments, checkin.dateRange);
      checkin.scores = this.mapScores(checkin.averageScores, checkin.dateRange);
      // calculate average score
      checkin.averageScore = getAverageScore(checkin.scores);

      delete checkin.averageScores;
    });

    return aggregation;
  }

  // get date range string representation
  private getCheckinDateRange(from: DateTime, to: DateTime, unit: AggregationUnit): string {
    return to.equals(from)
      ? to.toFormat(unit === 'day' ? SINGLE_DATE_FORMAT : SINGLE_DATETIME_FORMAT)
      : `${from.toFormat(DATE_RANGE_FORMAT)} - ${to.toFormat(DATE_RANGE_FORMAT)}`;
  }

  // get segment properties required for scores (memoization)
  private getSegmentProperties(segmentId: string): { segmentName: string; description: string } {
    let segmentProperties = this.segmentProperties[segmentId];

    if (!segmentProperties) {
      const { name, description } = this.wheel.segments.find((segment) => segment.id === segmentId) || {};
      segmentProperties = { segmentName: name, description };
      this.segmentProperties[segmentId] = segmentProperties;
    }

    return segmentProperties;
  }

  // change comments structure
  private mapComments = (comments, dateRange): iCommentsAggregation => {
    // most recent - first
    comments.sort((a, b) => b.date.localeCompare(a.date));
    return { [dateRange]: comments };
  };

  // change structure, merge segment properties (segment tags)
  private mapScores(scores: Array<iScoresAggregation>, dateRange: string): Array<iScoresAggregation> {
    // keep the same order as segments
    scores.sort((a, b) => a.segmentId.localeCompare(b.segmentId));

    return scores.map((score) => ({
      ...score,
      ...this.getSegmentProperties(score.segmentId),
      scoreComments: this.mapComments(score.scoreComments, dateRange),
    }));
  }

  // get comments from all checkins
  private getAllComments(checkins: Array<iCheckinsAggregation>): iCommentsAggregation {
    return checkins.reduce((comments, checkin) => ({ ...comments, ...checkin.comments }), {});
  }

  // get comments for particular segment among all checkins
  private getAllCommentsForSegment(
    checkins: Array<iCheckinsAggregation>,
    activeSegmentId: string
  ): iCommentsAggregation {
    return checkins.reduce((scoreComments, checkin) => {
      const score = checkin.scores.find((score) => score.segmentId === activeSegmentId);
      return { ...scoreComments, ...(score?.scoreComments || {}) };
    }, {});
  }

  // get comments only with comment text
  private getCommentsWithText(comments: iCommentsAggregation): iCommentsAggregation {
    const commentsWithText = {};

    Object.entries(comments).forEach(([aggregationDate, comments]) => {
      const filteredComments = comments.filter((c) => !!c.comment);

      if (filteredComments.length) {
        commentsWithText[aggregationDate] = filteredComments;
      }
    });

    return commentsWithText;
  }

  // aggregate data from the whole data range and represent as one checkin aggregation
  private getAverageDataAsCheckin(checkins: Array<iCheckinsAggregation>): iCheckinsAggregation {
    if (_.isEmpty(checkins)) {
      return null;
    }
    const averageCheckin = {} as iCheckinsAggregation;
    const averageScoreData = { sum: 0, amount: 0 };
    const scoresData = {};

    // first checkin from
    averageCheckin.from = checkins[checkins.length - 1].from;
    // latest checkin to
    averageCheckin.to = checkins[0].to;

    averageCheckin.dateRange = this.getCheckinDateRange(averageCheckin.from, averageCheckin.to, null);
    averageCheckin.comments = {};
    averageCheckin.numberOfCheckins = 0;

    checkins.forEach((aggregatedCheckin) => {
      // collect all comments
      averageCheckin.comments = { ...averageCheckin.comments, ...aggregatedCheckin.comments };
      averageCheckin.numberOfCheckins += aggregatedCheckin.numberOfCheckins;
      // collect scores from particular segments
      aggregatedCheckin.scores.forEach((aggregatedScore) => {
        // keep tracking of the comments, sum and amount of scores to calculate the average among particular segment
        Object.values(aggregatedScore.scoreComments).forEach((segmentComments) => {
          segmentComments.forEach((individualComment) => {
            if (scoresData[aggregatedScore.segmentId]) {
              const scoreData = scoresData[aggregatedScore.segmentId];
              scoreData.scoreComments = { ...scoreData.scoreComments, ...aggregatedScore.scoreComments };
              scoreData.sum += individualComment.score;
              ++scoreData.amount;
            } else {
              scoresData[aggregatedScore.segmentId] = {
                ...aggregatedScore,
                scoreComments: aggregatedScore.scoreComments || {},
                sum: aggregatedScore.score,
                amount: 1,
              };
            }
          });
        });

        // collect scores for average among all segments in data range
        averageScoreData.sum += aggregatedScore.score;
        ++averageScoreData.amount;
      });
    });

    averageCheckin.scores = Object.entries<any>(scoresData).map(([segmentId, score]) => {
      return {
        segmentId,
        segmentName: score.segmentName,
        description: score.description,
        score: +(score.sum / score.amount).toFixed(1),
        scoreComments: score.scoreComments,
      };
    });

    averageCheckin.averageScore = +(averageScoreData.sum / averageScoreData.amount).toFixed(1);

    return averageCheckin;
  }
}
