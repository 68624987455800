import React from 'react';
import { Dialog, DialogActions, makeStyles, Typography } from '@material-ui/core';
import { Button, TransparentButton } from '../../Shared/Buttons/Button';

interface iThanksModalProps {
  onClick: (requestDemo: boolean) => void;
}

export default ({ onClick }: iThanksModalProps) => {
  const classes = useStyles();

  return (
    <Dialog open={true} classes={{ paper: classes.dialog }}>
      <Typography className={classes.title}>Thanks for your input!</Typography>
      <Typography className={classes.text}>
        An email has been sent to our team with your feedback. Would you like a demo of our upgraded features?!
      </Typography>

      <DialogActions className={classes.buttons}>
        <TransparentButton className={classes.button} onClick={() => onClick(false)}>
          MAYBE LATER
        </TransparentButton>
        <Button className={classes.button} onClick={() => onClick(true)}>
          REQUEST DEMO
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const useStyles = makeStyles({
  dialog: {
    maxWidth: '540px',
    padding: '40px',
    background: '#fefaf2',
    '@media(max-device-width: 768px)': {
      padding: '32px 16px',
    },
  },
  title: {
    fontSize: '30px',
    color: '#0c2337',
    '@media(max-device-width: 768px)': {
      paddingLeft: '8px',
      fontSize: '16px',
      fontWeight: 600,
      lineHeight: 1.5,
    },
  },
  text: {
    marginTop: '16px',
    fontSize: '16px',
    lineHeight: 1.5,
    color: '#2b2e31',
    '@media(max-device-width: 768px)': {
      marginTop: '8px',
      paddingLeft: '8px',
      fontSize: '12px',
      lineHeight: 1.33,
    },
  },
  buttons: {
    marginTop: '32px',
    padding: 0,
    '@media(max-device-width: 768px)': {
      justifyContent: 'space-between',
    },
  },
  button: {
    height: '40px',
    lineHeight: '40px',
    '@media(max-device-width: 768px)': {
      fontSize: '10px',
      lineHeight: 1.67,
      letterSpacing: '.86px',
      whiteSpace: 'nowrap',
    },
  },
});
