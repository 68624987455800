import styled from 'styled-components';
import { Text } from '../Shared/Typography/Typography';
import { useEffect, useState } from 'react';
import { FolderClient } from '../../API/folder.client';
import { FiCheck } from 'react-icons/fi';
import { MediumButton, TransparentButton } from '../Shared/Buttons/Button';
import { useHistory, useLocation } from 'react-router-dom';
import { WheelMemberRole } from '../../API/interfaces';
import { useDispatch, useSelector } from 'react-redux';
import { saveInvitationLink } from './invitation.actions';
import { selectUser } from '../Auth/Auth.selectors';
import { makeStyles } from '@material-ui/core';
import { WHEEL_ACCESS_TYPES } from '../../constants';

export const JoinFolder = (props) => {
  const [wheels, setWheels] = useState([]);
  const [folderName, setFolderName] = useState('');
  const [folderAdmin, setFolderAdmin] = useState(null);
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const {
    match: {
      params: { id: folderId, role },
    },
  } = props;
  const classes = useStyles();

  useEffect(() => {
    if (!user) {
      redirectToSignup();
    } else {
      fetchData();
    }
  }, []);

  const redirectToSignup = async () => {
    dispatch(saveInvitationLink(location.pathname));
    history.push(`/login`);
  };

  const fetchData = async () => {
    const { data } = await FolderClient.getFolderById({ folderId });
    setWheels(data.wheels.filter((w) => w.access !== WHEEL_ACCESS_TYPES.PUBLIC || role === WheelMemberRole.ADMIN));
    setFolderName(data.name);
    setFolderAdmin(data.creator);
  };

  const onCancel = () => {
    history.push('/dashboard');
  };

  const onJoin = async () => {
    const { data } = await FolderClient.joinFolder({ folderId, role: role ?? WheelMemberRole.MEMBER });
    if (user.accountRole) {
      history.push(`/dashboard?folder_id=${data._id}`);
    } else {
      history.push(`/dashboard`);
    }
  };

  return (
    <Background>
      <div className={classes.joinPopup}>
        <div>
          <Text size="30px" mSize="16px" weight="400" mWeight="500">
            Invite to folder {folderName}
          </Text>
        </div>
        <div className="mt-3">
          {role === WheelMemberRole.ADMIN ? (
            <Text size="16px">
              {folderAdmin?.firstName} {folderAdmin?.lastName} has invited you to join the next wheels as a wheel
              administrator, this means you will be able to see all data, invite new members and edit:{' '}
            </Text>
          ) : (
            <Text size="16px">
              {folderAdmin?.firstName} {folderAdmin?.lastName} has invited you to join the below wheels:{' '}
            </Text>
          )}
        </div>
        <div className="mt-3">
          {wheels.map((w) => {
            return (
              <div key={w._id} className="d-flex justify-content-start align-items-center mb-1">
                <FiCheck color="#07b0a5" className="mr-2" size={16} />
                <Text size="16px">{w.name}</Text>
              </div>
            );
          })}
        </div>
        <div className="d-flex justify-content-end align-items-center">
          <TransparentButton className="mr-3" onClick={onCancel}>
            CANCEL
          </TransparentButton>
          <MediumButton onClick={onJoin}>JOIN</MediumButton>
        </div>
      </div>
    </Background>
  );
};

const useStyles = makeStyles((theme) => ({
  joinPopup: {
    width: '540px',
    padding: '40px 50px',
    borderRadius: '4px',
    backgroundColor: '#fefaf2',
    [theme.breakpoints.down('md')]: {
      width: 'calc(100vw - 40px)',
    },
  },
}));

const Background = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
`;
