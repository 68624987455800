import { ToastActions } from '../../../Redux/Actions/ActionTypes';
import { removeToast } from './Toaster.actions';

export const toasterMiddleware = (store) => (next) => (action) => {
  const result = next(action);
  if (action.type === ToastActions.ADD_TOAST) {
    setTimeout(() => {
      store.dispatch(removeToast(action.payload.id));
    }, 7000);
  }
  return result;
};
