import { tIconProps } from '../_types';

const EmptyWheel = ({ className }: tIconProps) => (
  <svg className={className} width="89" height="88" xmlns="http://www.w3.org/2000/svg">
    <defs>
      <linearGradient x1="-.468%" y1="54.228%" x2="66.456%" y2="120.016%" id="a">
        <stop stopColor="#EFF2F6" offset="0%" />
        <stop stopColor="#D5DDE7" offset="100%" />
        <stop stopColor="#DFE5EC" offset="100%" />
      </linearGradient>
      <linearGradient x1="-43.539%" y1="51.923%" x2="17.299%" y2="111.354%" id="b">
        <stop stopColor="#EFF2F6" offset="0%" />
        <stop stopColor="#D5DDE7" offset="100%" />
        <stop stopColor="#DFE5EC" offset="100%" />
      </linearGradient>
      <linearGradient x1="45.772%" y1="8.427%" x2="-14.462%" y2="65.96%" id="c">
        <stop stopColor="#EFF2F6" offset="0%" />
        <stop stopColor="#D5DDE7" offset="100%" />
        <stop stopColor="#DFE5EC" offset="100%" />
      </linearGradient>
      <linearGradient x1="2.956%" y1="54.228%" x2="65.948%" y2="118.773%" id="d">
        <stop stopColor="#EFF2F6" offset="0%" />
        <stop stopColor="#D5DDE7" offset="100%" />
        <stop stopColor="#DFE5EC" offset="100%" />
      </linearGradient>
      <linearGradient x1="101.344%" y1="45.772%" x2="31.354%" y2="-16.456%" id="e">
        <stop stopColor="#EFF2F6" offset="0%" />
        <stop stopColor="#D5DDE7" offset="100%" />
        <stop stopColor="#DFE5EC" offset="100%" />
      </linearGradient>
      <linearGradient x1="93.354%" y1="63.327%" x2="69.447%" y2="-9.599%" id="f">
        <stop stopColor="#EFF2F6" offset="0%" />
        <stop stopColor="#D5DDE7" offset="100%" />
        <stop stopColor="#DFE5EC" offset="100%" />
      </linearGradient>
      <linearGradient x1="54.228%" y1="96.965%" x2="117.045%" y2="33.233%" id="g">
        <stop stopColor="#EFF2F6" offset="0%" />
        <stop stopColor="#D5DDE7" offset="100%" />
        <stop stopColor="#DFE5EC" offset="100%" />
      </linearGradient>
      <linearGradient x1="35.451%" y1="89.421%" x2="117.885%" y2="69.602%" id="h">
        <stop stopColor="#EFF2F6" offset="0%" />
        <stop stopColor="#D5DDE7" offset="100%" />
        <stop stopColor="#DFE5EC" offset="100%" />
      </linearGradient>
      <filter x="-26.9%" y="-19.2%" width="153.8%" height="153.8%" filterUnits="objectBoundingBox" id="i">
        <feOffset dy="2" in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur stdDeviation="2" in="shadowOffsetOuter1" result="shadowBlurOuter1" />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.0798331307 0" in="shadowBlurOuter1" />
      </filter>
      <circle id="j" cx="47" cy="49" r="13" />
    </defs>
    <g fill="none" fillRule="evenodd">
      <path d="M82.258 16.235 47 48l.07-48c13.45 0 26.803 7.037 35.188 16.235z" fill="url(#a)" />
      <path d="m46 49 31.765-29C85.198 28.141 88.982 38.535 89 48.969L46 49z" fill="url(#b)" />
      <path d="M67.958 69 46 49l30 .012c-.015 8.141-2.55 14.65-8.042 19.988z" fill="url(#c)" />
      <path d="m46 48 28.367 26.984C67.128 82.845 56.18 87.95 46.349 88L46 48z" fill="url(#d)" />
      <path d="m46.824 50-.519 20.008C40.27 69.998 35.957 67.89 32 64.223L46.824 50z" fill="url(#e)" />
      <path d="M48 49 21.082 75C13.655 67.872 10.002 58.584 10 49.118l37.831.09L21.081 75 48 49z" fill="url(#f)" />
      <path d="M13.534 16 47.46 49.63 0 48.856C0 36.007 5.176 24.382 13.534 16z" fill="url(#g)" />
      <path d="M46.99 49 25 27.666C31.096 21.89 39.048 19.002 47 19l-.01 30L25 27.666 46.99 49z" fill="url(#h)" />
      <g>
        <use fill="#000" filter="url(#i)" href="#j" />
        <use fill="#FFF" href="#j" />
      </g>
    </g>
  </svg>
);

export default EmptyWheel;
