import React, { Component } from 'react';
import { connect } from 'react-redux';
import { analytics } from '../../../../analytics/analytics';
import { iWheel } from '../../../../API/interfaces';
import DownloadApp from '../../../Shared/DownloadApp/DownloadApp';
import Wheel from '../../common/Wheel/Wheel';
import SegmentTags from '../../common/SegmentTags/SegmentTags';
import utils, { getAverageScore } from '../../../Shared/utils';
import './PublicResult.scss';

interface iPublicResult {
  wheel: iWheel;
  checkin: any;
  isLoggedIn: boolean;
}

interface iPublicState {
  activeSegmentId: string | null;
  averageScore: number;
}

class PublicResult extends Component<iPublicResult, iPublicState> {
  isMobile = utils.checkIsPhone();

  constructor(props) {
    super(props);
    const { checkin } = props;

    this.state = {
      activeSegmentId: null,
      averageScore: getAverageScore(checkin.scores),
    };
  }

  setActiveSegmentId = (segmentId) => this.setState({ activeSegmentId: segmentId });

  componentDidUpdate(prevProps: Readonly<iPublicResult>) {
    if (prevProps.checkin !== this.props.checkin) {
      const { checkin } = this.props;
      const averageScore = getAverageScore(checkin.scores);

      this.setState({ averageScore });
    }
  }

  render() {
    const { checkin, wheel, isLoggedIn } = this.props;
    const { activeSegmentId, averageScore } = this.state;

    const scores = checkin.scores.map((score) => {
      const segmentName = wheel.segments?.find((segment) => segment?.id === score?.segment_id)?.name || '';
      return {
        ...score,
        segmentName,
        segmentId: score?.segment_id,
      };
    });

    return (
      <div className="results-container">
        <SegmentTags
          scores={scores}
          averageScore={averageScore}
          activeSegmentId={activeSegmentId}
          onClick={this.setActiveSegmentId}
        />

        <div className="main-section">
          <Wheel
            maxScale={wheel?.maxScale}
            averageScore={averageScore}
            scores={scores}
            activeSegmentId={activeSegmentId}
            onClick={this.setActiveSegmentId}
          />

          <div className="summary">
            <div className="your-result">Your result</div>
            <div className="score">{averageScore}</div>
            <hr />

            {!isLoggedIn && (
              <div className="links">
                <div className="message">Create your own wheel</div>
                <div>
                  <a
                    onClick={(e) => {
                      e.preventDefault();
                      analytics.publicWheelLogin();
                      window.location.href = '/login';
                    }}
                  >
                    Log In
                  </a>
                  <span>or</span>
                  <a
                    onClick={(e) => {
                      e.preventDefault();
                      analytics.publicWheelSignUp();
                      window.location.href = '/sign-up';
                    }}
                  >
                    Sign Up
                  </a>
                </div>
              </div>
            )}

            <div className="download">
              <div className="message">Download mobile application</div>
              <DownloadApp isMobile={this.isMobile} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  isLoggedIn: !!state.userRD.user,
});

export default connect(mapStateToProps)(PublicResult);
