import React, { Component } from 'react';
import { DateTime } from 'luxon';
import { Download } from 'react-feather';
import { analytics } from '../../../../analytics/analytics';
import services from '../../../../API/services';
import { iWheel } from '../../../../API/interfaces';
import './SaveShare.scss';
import ControlButton from '../ControlButton/ControlButton';

interface iSaveShareProps {
  wheel: iWheel;
  memberId?: string;
  setIsLoading: (isLoading: boolean) => void;
  className?: string;
  collapsable?: boolean;
}

interface iSaveShareState {}

interface ShareNavigator extends Navigator {
  canShare: (data: any) => boolean;
}

export default class extends Component<iSaveShareProps, iSaveShareState> {
  static defaultProps = {
    collapsable: false,
  };
  // has Share API level 2
  readonly canShare = !!(navigator as ShareNavigator).canShare;

  downloadFile = (blob: Blob, fileName: string): void => {
    const anchorElement = document.createElement('a');
    anchorElement.style.display = 'none';
    document.body.appendChild(anchorElement);

    const fr = new FileReader();
    if (fr) {
      fr.onload = (ev) => {
        // @ts-ignore
        anchorElement.href = ev.target.result;
        anchorElement.download = fileName;
        anchorElement.click();
      };
      fr.readAsDataURL(blob);
    }
  };

  shareFile = (blob: Blob): void => {
    const { wheel, memberId } = this.props;
    if (!blob) {
      return;
    }

    const date = DateTime.now();
    const dateString = date.toFormat('yyyy-LL-dd_HH.mm.ss');
    // keep the same as in native app
    const fileName = `${wheel.name.replace(/\s/g, '_')}_${!memberId ? '(Team)_' : ''}${dateString}.jpeg`;

    // if (this.canShare) {
    //   const appUrl = window.location.href.split('/wheel/')[0];
    //   const file = new File([blob], fileName, {
    //     lastModified: date.toMillis(),
    //     type: blob.type,
    //   });
    //
    //   if ((navigator as ShareNavigator).canShare({ files: [file] })) {
    //     navigator
    //       .share({
    //         // @ts-ignore
    //         files: [file],
    //         title: `Save or Share ${appUrl}`,
    //         text: `Save or Share - ${appUrl}`,
    //       })
    //       // @ts-ignore
    //       .catch((err) => {
    //         // if not DOMException: Share canceled (user closed dialogue)
    //         if (!err.message.includes('Share canceled')) {
    //           this.downloadFile(blob, fileName);
    //         }
    //       });
    //     return;
    //   }
    // }

    // if desktop or does not have share API
    this.downloadFile(blob, fileName);
  };

  onClick = () => {
    const { setIsLoading, memberId, wheel } = this.props;
    setIsLoading(true);
    analytics.saveImage();

    services[memberId ? 'getLatestScoresImage' : 'getLatestTeamScoresImage']({
      id: wheel.id,
      userId: memberId,
    })
      .then(this.shareFile)
      .finally(() => setIsLoading(false));
  };

  render() {
    const { className, collapsable } = this.props;

    return collapsable ? (
      <ControlButton Icon={Download} label="Save Latest" width={85} onClick={this.onClick} />
    ) : (
      <div className={`save-share-button${className ? ` ${className}` : ''}`} onClick={this.onClick}>
        <Download size={14} />
        <span>Save Latest</span>
      </div>
    );
  }
}
