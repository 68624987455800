import React, { useEffect, useState } from 'react';
import { MediumButton, TransparentButton } from '../Shared/Buttons/Button';
import { InvitationInput } from './InvitationInput';
import { Dialog, DialogContent, makeStyles } from '@material-ui/core';
import { makeModalDescriptionText, makeModalTitleText } from './invitation.utils';
import { AccountPlan } from '../../API/interfaces';

interface InvitationModalProps {
  modal: boolean;
  toggle: () => any;
  submitInvite: (emails) => any;
  emails?: string[];
  resend: boolean;
  plan: AccountPlan;
}

export const InvitationModal = ({ modal, toggle, submitInvite, emails, resend, plan }: InvitationModalProps) => {
  const [emailChips, setEmailChips] = useState([]);
  const classes = useStyles();

  const close = () => {
    setEmailChips([]);
    toggle();
  };

  const invite = async () => {
    submitInvite(emailChips);
    close();
  };

  const handleChips = (emails: string[]) => {
    setEmailChips(emails);
  };

  return (
    <Dialog open={modal} onClose={close} aria-labelledby="form-dialog-title" classes={{ paper: classes.modalBody }}>
      <DialogContent classes={{ root: classes.modalContainer }}>
        <div>
          <div className={classes.modalTitle}>{makeModalTitleText(plan, resend)}</div>
          <div className={classes.modalDescription}>{makeModalDescriptionText(plan, resend)}</div>
          <InvitationInput handleChips={handleChips} emails={emails} />
          <div className="d-flex justify-content-end align-items-center mt-4">
            <TransparentButton onClick={close}>CANCEL</TransparentButton>
            <MediumButton disabled={emailChips.length === 0} onClick={invite}>
              SEND INVITE
            </MediumButton>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

const useStyles = makeStyles((theme) => ({
  modalBody: {
    width: '540px',
    padding: '0',
    [theme.breakpoints.down('md')]: {
      margin: '16px',
    },
  },
  modalContainer: {
    padding: '40px 50px',
    backgroundColor: '#fefaf2',
    [theme.breakpoints.down('md')]: {
      padding: '20px',
    },
    '&:first-child': {
      paddingTop: '40px',
    },
  },
  modalTitle: {
    fontFamily: 'Rubik',
    fontSize: '30px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    color: '#0c2337',
    [theme.breakpoints.down('md')]: {
      fontSize: '16px',
      fontWeight: '500',
    },
  },
  modalDescription: {
    padding: '20px 0',
    fontFamily: 'Rubik',
    fontSize: '16px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.5',
    letterSpacing: 'normal',
    color: '#2b2e31',
    [theme.breakpoints.down('md')]: {
      fontSize: '12px',
    },
  },
}));
