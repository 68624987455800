import React, { useState } from 'react';
import Steps from './Steps';
import AccountTypeSelection from './AccountTypeSelection';
import PersonalInfoScreen from './PersonalInfoScreen';
import './Onboarding.scss';

const Onboarding: React.FC = () => {
  const [userType, setUserType] = useState<string | null>(null);
  const [step, setStep] = useState<number>(0);
  const [personalInfoStep, setPersonalInfoStep] = useState(0);
  const [accountStep, setAccountStep] = useState(0);

  const handleSelect = (type) => {
    setUserType(type);
  };

  const handleNextStep = () => {
    setStep(step + 1);
  };

  const handlePreviousStep = () => {
    setStep(step - 1);
    if (step === 0) setPersonalInfoStep(2);
  };

  return (
    <div className="onboarding-container">
      {!step ? (
        <PersonalInfoScreen onNext={handleNextStep} step={personalInfoStep} setStep={setPersonalInfoStep} />
      ) : !accountStep ? (
        <AccountTypeSelection onSelect={handleSelect} onBack={handlePreviousStep} move={() => setAccountStep(1)} />
      ) : (
        <Steps userType={userType} setUserType={setUserType} move={() => setAccountStep(0)} />
      )}
    </div>
  );
};

export default Onboarding;
