import React from 'react';
import { makeStyles } from '@material-ui/core';
import { Edit3, Check } from 'react-feather';
import nUser from '../../../_utils/user';
import styled from 'styled-components';

export type tStep = 'Wheel Name' | 'Segments' | 'Description' | 'Settings' | 'Complete' | 'Folder';
export const STEPS: Array<tStep> = ['Wheel Name', 'Segments', 'Description', 'Settings', 'Complete'];
export const STEPS_WITH_FOLDER: Array<tStep> = [
  'Wheel Name',
  'Segments',
  'Description',
  'Settings',
  'Folder',
  'Complete',
];

export const getAvailableSteps = (user: nUser.iUser, folders) =>
  nUser.hasAccessToFolders(user) && folders.length ? STEPS_WITH_FOLDER : STEPS;

interface iSidebarProps {
  activeStep: tStep;
  lastCompletedStep: tStep;
  setStep: (newStep: tStep) => void;
  isValidTransition: (step: tStep) => boolean;
  stepToEdit: tStep;
  isTemplate: boolean;
  steps: tStep[];
}

const Sidebar = ({
  activeStep,
  stepToEdit,
  isTemplate,
  setStep,
  isValidTransition,
  lastCompletedStep,
  steps,
}: iSidebarProps) => {
  const classes = useStyles();
  const activeStepIndex = steps.indexOf(lastCompletedStep);

  return (
    <ul className={classes.root}>
      {steps.map((step, i) => {
        const className = `${classes.item} ${activeStep === step ? ` ${classes.active}` : ''}`;
        const transitionValid = isValidTransition(step);

        return (
          <Step
            key={step}
            transitionValid={transitionValid}
            onClick={() => transitionValid && setStep(step)}
            className={className}
          >
            {stepToEdit === step ? (
              <Edit3 className={classes.icon} size={16} stroke="#fff" />
            ) : activeStepIndex > i ? (
              <Check className={classes.icon} size={16} stroke="#fff" />
            ) : (
              <div className={classes.icon} />
            )}
            {step === 'Wheel Name' && isTemplate ? 'Template Name' : step}
          </Step>
        );
      })}
    </ul>
  );
};

const useStyles = makeStyles({
  root: {
    width: '260px',
    margin: 0,
    padding: '58px 0 0',
    backgroundColor: '#0f2136',
    '@media(max-device-width: 768px)': {
      width: '100%',
      display: 'flex',
      padding: 0,
      overflowX: 'scroll',
      '&::-webkit-scrollbar': {
        display: 'none',
      },
    },
  },
  item: {
    display: 'flex',
    alignItems: 'center',
    padding: '12px 32px',
    borderLeft: '4px solid transparent',
    fontSize: '16px',
    lineHeight: 1.5,
    color: '#fff',
    userSelect: 'none',
    '@media(max-device-width: 768px)': {
      border: 'none',
      whiteSpace: 'nowrap',
      padding: '12px 24px',
    },
  },
  active: {
    backgroundColor: '#2a3e50',
    borderLeftColor: '#ff554d',
  },
  icon: {
    height: '16px',
    width: '16px',
    marginRight: '8px',
    '@media(max-device-width: 768px)': {
      display: 'none',
    },
  },
});

export default Sidebar;

const Step = styled.li<any>`
  cursor: ${({ transitionValid }) => (transitionValid ? 'pointer' : 'not-allowed')};
`;
