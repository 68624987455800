import React, { Component } from 'react';
import Joyride, { STATUS, Step } from 'react-joyride';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { EducationTooltip } from './EducationTooltip';
import { setEducationFlow } from './Education.actions';

interface iEducationState {
  steps: Array<Step>;
}

interface iEducationProps {
  flow: {
    steps: Array<Step>;
    run: boolean;
    scrollOffset: number;
    continuous: boolean;
    scrollToFirstStep: boolean;
    disableScrolling: boolean;
  };
  actions: {
    setEducationFlow: (config) => void;
  };
}

export const firstVisitOfDashboardFlow = {
  run: true,
  continuous: false,
  disableScrolling: false,
  scrollToFirstStep: true,
  steps: [
    {
      target: '.dashboard_create-wheel-button',
      content: 'Create a new wheel to check in. Define what matters for your check in.',
      disableBeacon: true,
      showSkipButton: false,
      hideCloseButton: true,
      hideFooter: true,
    },
  ],
};

export const afterFirstCheckinFlow = {
  run: true,
  scrollOffset: 200,
  continuous: true,
  disableScrolling: false,
  scrollToFirstStep: true,
  steps: [
    {
      target: '.education_save-share',
      content: 'Save and share your results as an image.',
      disableBeacon: true,
    },
    {
      target: '.education_invite',
      content: 'Invite others to complete check ins on your wheel.',
      disableBeacon: true,
    },
    {
      target: '.education_results',
      content: 'Track your progress over time.',
      disableBeacon: true,
    },
    {
      target: '.education_checkin_regularly',
      content: 'Remember to check in regularly.',
      disableBeacon: true,
    },
  ],
};

export const membersTabFlow = {
  run: true,
  continuous: true,
  disableScrolling: false,
  scrollToFirstStep: true,
  steps: [
    {
      target: '.education_member-dropdown',
      content: 'Select individual members to see their results.',
      disableBeacon: true,
    },
  ],
};

export class EducationComponent extends Component<iEducationProps, iEducationState> {
  constructor(props) {
    super(props);
  }

  handleJoyrideCallback = (data) => {
    const { status } = data;
    const { setEducationFlow } = this.props.actions;
    if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      setEducationFlow({
        run: false,
      });
    }
  };

  render() {
    const {
      steps,
      run,
      scrollOffset = 0,
      continuous = false,
      scrollToFirstStep = false,
      disableScrolling = true,
    } = this.props.flow;
    return (
      <Joyride
        run={run}
        steps={steps}
        callback={this.handleJoyrideCallback}
        scrollToFirstStep={scrollToFirstStep}
        continuous={continuous}
        scrollOffset={scrollOffset}
        disableScrolling={disableScrolling}
        floaterProps={{
          disableAnimation: true,
        }}
        tooltipComponent={EducationTooltip}
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    flow: state.education.currentFlow ? state.education.currentFlow : [],
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators({ setEducationFlow }, dispatch),
  };
};

export const Education = connect(mapStateToProps, mapDispatchToProps)(EducationComponent);
