import React, { FC, useState } from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import ReactDOM from 'react-dom';
import './ConfirmationModal.scss';
import { RedBorderButton, TransparentButton } from '../Buttons/Button';

const confirmRoot = document.createElement('div');
const body = document.querySelector('body');
body.appendChild(confirmRoot);

export const customConfirm = ({
  title = 'Please confirm your action',
  confirmationText = 'CONFIRM',
  body = null,
}): Promise<boolean> =>
  new Promise((res) => {
    const giveAnswer = (answer: boolean) => {
      ReactDOM.unmountComponentAtNode(confirmRoot);
      res(answer);
    };
    ReactDOM.render(
      <ConfirmationModal giveAnswer={giveAnswer} title={title} confirmationText={confirmationText} body={body} />,
      confirmRoot
    );
  });

const ConfirmationModal: FC<any> = ({ giveAnswer, title, body, confirmationText }) => {
  const [modal, setModal] = useState(true);
  const toggle = () => {
    setModal(!modal);
  };

  const confirm = () => {
    toggle();
    giveAnswer(true);
  };

  const decline = () => {
    toggle();
    giveAnswer(false);
  };

  return (
    <Modal isOpen={modal} toggle={decline} contentClassName="custom-modal" centered={true} zIndex={2000}>
      <ModalHeader>{title}</ModalHeader>
      {body && <ModalBody>{body}</ModalBody>}
      <ModalFooter>
        <TransparentButton onClick={decline}>CANCEL</TransparentButton>
        <RedBorderButton onClick={confirm}>{confirmationText}</RedBorderButton>
      </ModalFooter>
    </Modal>
  );
};
