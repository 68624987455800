import React, { FC } from 'react';
import _ from 'lodash';
import styled from 'styled-components';
import { AssistMember } from '../../WheelPage';
import { Text } from '../../../Shared/Typography/Typography';
import CheckInAssistRedIcon from '../../../../Assets/images/checkin-assist-red.png';
import LockIcon from '../../../../Assets/images/lock-red.png';
import Checkbox from '@material-ui/core/Checkbox';
import { withStyles } from '@material-ui/core/styles';

type Props = {
  assistMember?: AssistMember;
  isHideCheckinAssistInfoChecked?: boolean;
  toggleHideCheckinAssistInfo?: () => void;
  onCancel: () => void;
  onCompleteCheckinAssistInfoModal: () => void;
};

const Body = styled.div<{ width?: number | string }>`
  display: flex;
  flex-direction: column;
`;

const Description = styled.div`
  margin-bottom: 32px;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 12px;
`;

const StyledImage = styled.img`
  width: 16px;
  height: 16px;
  margin-right: 8px;
`;

const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 8px;
`;

const CancelButton = styled.div`
  color: #2a3e50;
  font-size: 12px;
  font-weight: bold;
  margin-right: 24px;
  cursor: pointer;
`;

const CompleteButton = styled.div`
  padding: 14px 24px;
  border-radius: 25px;
  color: #ffffff;
  background: linear-gradient(117deg, #fa8868 1%, #ff554d);
  font-size: 12px;
  font-weight: bold;
  cursor: pointer;
`;

const StyledCheckbox = withStyles({
  root: {
    marginRight: '8px',
    color: '#ffe2c1',
    '&$checked': {
      color: '#ff554d',
    },
  },
  checked: {},
})(Checkbox);

const CheckinAssistInfo: FC<Props> = ({
  assistMember,
  isHideCheckinAssistInfoChecked,
  toggleHideCheckinAssistInfo,
  onCancel,
  onCompleteCheckinAssistInfoModal,
}) => {
  return (
    <Body>
      <Text size="30px" margin="0 0 24px 0" lineHeight={1.2}>
        Complete a check in on behalf of {assistMember.name}
      </Text>
      <Description>
        <Row>
          <Text size="14px">For the member's security:</Text>
        </Row>
        <Row>
          <StyledImage src={CheckInAssistRedIcon} />
          <Text size="14px">Only admin can assist with check in</Text>
        </Row>
        <Row>
          <StyledImage src={LockIcon} />
          <Text size="14px">The admin's name will be indicated in check ins history</Text>
        </Row>
      </Description>
      <Row>
        <StyledCheckbox checked={isHideCheckinAssistInfoChecked} onChange={toggleHideCheckinAssistInfo} />
        <Text size="16px">I got it don't show me this message</Text>
      </Row>
      <Footer>
        <CancelButton onClick={onCancel}>CANCEL</CancelButton>
        <CompleteButton onClick={onCompleteCheckinAssistInfoModal}>COMPLETE CHECK IN</CompleteButton>
      </Footer>
    </Body>
  );
};

export default CheckinAssistInfo;
