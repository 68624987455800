import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { makeStyles, Menu, MenuItem, TextField } from '@material-ui/core';
import { Option, StyledEditIcon, StyledTrashIcon } from '../../Dashboard/Card/_styled';
import { DateTime } from 'luxon';
import { COMMENT_DATETIME_FORMAT } from '../../../constants';
import { FiCornerDownRight, FiMoreVertical, FiX } from 'react-icons/fi';
import { HiOutlineExternalLink } from 'react-icons/hi';
import styled from 'styled-components';
import { Button } from '../../Shared/Buttons/Button';
import { InitialsCircle } from '../../Shared/InitialsCircle/InitialsCircle';
import { useSelector } from 'react-redux';
import { selectUser } from '../../Auth/Auth.selectors';
import { Text } from '../../Shared/Typography/Typography';
import {
  InsightsUserType,
  InsightsCommentReplyType,
  InsightsCommentType,
  InsightsSegmentType,
  InsightsWheelType,
} from '../insights.model';
import Badge from '../../Shared/Badge';
import CommentAttachment from '../../Shared/CommentAttachment/CommentAttachment';
import AddCommentAttachment from '../../WheelPage/CommentsSection/AddCommentAttachment';

const CommentState = {
  READ: 'READ',
  EDIT: 'EDIT',
  REPLY: 'REPLY',
  DELETE: 'DELETE',
};

interface Props extends RouteComponentProps {
  member: InsightsUserType;
  reply: InsightsCommentType | InsightsCommentReplyType;
  onCreate: (value) => void;
  onDelete: (replyId) => void;
  onEdit: (replyId, vlaue) => void;
  last: boolean;
  isMain?: boolean;
  isReplyEnabled: boolean;
  isReplyComment?: boolean;
  commentSegment?: InsightsSegmentType;
  commentWheel?: InsightsWheelType;
  gradientColorsArray?: string[];
}

const CommentWrapper = styled.div<any>`
  width: 100%;
  padding: 6px 24px 6px 80px;
  position: relative;
  &:hover {
    background-color: #fcfcfc;
  }
  @media only screen and (max-device-width: 767px) {
    padding: 6px 24px 6px 58px;
  }
`;

const InitialsWrapper = styled.div`
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ReplyWrapper = styled.div`
  width: calc(100% - 50px);
  display: flex;
  flex-direction: column;
  @media only screen and (max-device-width: 767px) {
    width: 100%;
  }
`;

export const Reply = styled.div`
  display: inline-flex;
  align-items: center;
  padding: 4px 5px 4px 5px;
  margin: 5px 0;
  font-size: 12px;
  line-height: 14px;
  color: #2b2e31;
  border: solid 1px #86919a;
  border-radius: 16px;
  background-color: #f8f8f8;
  cursor: pointer;
`;

const ReplyCross = styled.div`
  position: absolute;
  top: -15px;
  right: -20px;
  color: #7b858e;
`;

const CommentControlsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 8px;
`;

const Comment: React.FC<Props> = ({
  member,
  reply,
  onCreate,
  onDelete,
  onEdit,
  last,
  isMain = false,
  isReplyEnabled,
  isReplyComment = false,
  commentSegment,
  commentWheel,
  gradientColorsArray,
}) => {
  const optionsRef = useRef(null);
  const [optionsOpened, setOptionsOpened] = useState(false);
  const [state, setState] = useState(CommentState.READ);
  const [isReply, setIsReply] = useState(false);
  const [editValue, setEditValue] = useState(reply.comment);
  const [replyValue, setReplyValue] = useState('');
  const [file, setFile] = useState(null);

  const user = useSelector(selectUser);

  const getAttachmentData = (attachment) => {
    if (!attachment) return null;
    const fileName = reply.attachment.match(/\/([^/]+)_\d{4}-\d{2}-\d{2}T\d{2}%3A\d{2}%3A\d{2}.\d{3}Z$/)[1];
    const fileFormat = fileName.split('.').pop() || '';
    return {
      url: reply.attachment,
      name: fileName,
      type: `type/${fileFormat}`,
      size: null,
    };
  };

  useEffect(() => {
    !isReply && reply?.attachment ? setFile(getAttachmentData(reply?.attachment)) : setFile(null);
  }, [reply, isReply]);

  const submitCreateReply = async () => {
    const formData = new FormData();
    replyValue && formData.append('comment', replyValue);
    file && formData.append('attachment', file, file.name);
    await onCreate(formData);
    setReplyValue('');
    setIsReply(false);
    setFile(null);
  };

  const submitEdit = async () => {
    const formData = new FormData();
    editValue && formData.append('comment', editValue);
    file && formData.append('attachment', file, file.name);
    await onEdit(reply.id, formData);
    setOptionsOpened(false);
    setState(CommentState.READ);
    setFile(null);
  };

  const submitDelete = async () => {
    await onDelete(reply?.id);
    setOptionsOpened(false);
  };

  const goToWheelPage = () => {
    window.open(`/wheel/${commentWheel?.id}?segment=${commentSegment?.id}`, '_blank');
  };

  const classes = useStyles();

  const memberName = `${member?.firstName} ${member?.lastName}`;
  const memberInitials = `${member?.firstName[0]} ${member?.lastName[0]}`;
  const wheelLink = `Open ${commentWheel?.name}`;

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setFile(e.target.files[0]);
    }
  };

  switch (state) {
    case CommentState.READ:
      return (
        <CommentWrapper>
          <div className={classes.commentHeaderWrapper}>
            <div className={classes.commentHeaderLefSide}>
              {!isReplyComment && !!commentSegment && (
                <div className={classes.segmentBadgeWrapper}>
                  <Badge gradientColorsArray={gradientColorsArray} content={commentSegment?.name} />
                </div>
              )}
              <div className={classes.member}>
                <div className="d-flex align-items-center">
                  {member && <div className={classes.initials}>{memberInitials}</div>}
                  <span className={classes.memberName}>{member ? memberName : 'Anonymous'}</span>
                </div>
              </div>
            </div>
            <div className={classes.commentHeaderRightSide}>
              <div className={classes.commentDateWrapper}>
                <Text size={'12px'} color={'#2b2e31'} opacity={'0.6'}>
                  {DateTime.fromISO(reply.date).toFormat(COMMENT_DATETIME_FORMAT)}
                </Text>
              </div>
              {!isReplyComment && !!commentWheel && (
                <div className={classes.wheelLinkContainer} onClick={goToWheelPage}>
                  <span className={classes.wheelLink}>{wheelLink}</span>
                  <HiOutlineExternalLink color="#005ebd" size={14} />
                </div>
              )}
            </div>
          </div>
          <>
            <div className="d-flex justify-content-between">
              <div>
                <Text whiteSpace="break-spaces" size={'14px'} className="mr-2">
                  {reply.comment || '-'}
                </Text>
                {(reply as InsightsCommentReplyType)?.isEdited && (
                  <Text color={'#6a777f'} size={'14px'} opacity={0.75}>
                    (Edited)
                  </Text>
                )}
              </div>
              <div ref={optionsRef} className="d-inline-flex">
                {!isMain && user._id === member?.id && (
                  <FiMoreVertical
                    size={20}
                    onClick={() => setOptionsOpened(true)}
                    style={{
                      color: optionsOpened ? '#fb463b' : '#0c2337',
                    }}
                    className="cursor-pointer"
                  />
                )}
              </div>
            </div>
            <CommentAttachment reply={reply} />
            {isReply ? (
              <div className="w-100 mt-3">
                <div className="d-flex flex-wrap position-relative">
                  <InitialsWrapper className="d-none d-lg-block">
                    <InitialsCircle
                      boxShadow={'1px 1px 10px 2px rgba(0, 0, 0, 0.3)'}
                      fontSize={'16px'}
                    >{`${user?.first_name[0]}${user?.last_name[0]}`}</InitialsCircle>
                  </InitialsWrapper>
                  <ReplyWrapper>
                    <TextField
                      autoFocus={true}
                      id="shareInput"
                      label="Your Note"
                      fullWidth={true}
                      multiline
                      maxRows={4}
                      variant="outlined"
                      placeholder={'Enter your note'}
                      value={replyValue}
                      onChange={(e) => setReplyValue(e.target.value)}
                      onFocus={(e) =>
                        e.currentTarget.setSelectionRange(e.currentTarget.value.length, e.currentTarget.value.length)
                      }
                      className={classes.input}
                      InputLabelProps={{ className: classes.label }}
                    />
                    <CommentControlsWrapper>
                      <AddCommentAttachment file={file} onEdit={handleFileChange} onRemove={() => setFile(null)} />
                      <Button height={'40px'} disabled={!(replyValue || file)} onClick={submitCreateReply}>
                        SHARE
                      </Button>
                    </CommentControlsWrapper>
                  </ReplyWrapper>
                  <ReplyCross>
                    <FiX className="close-button cursor-pointer" size={18} onClick={() => setIsReply(false)} />
                  </ReplyCross>
                </div>
              </div>
            ) : (
              last &&
              isReplyEnabled && (
                <Reply onClick={() => setIsReply(true)}>
                  <FiCornerDownRight size={12} style={{ marginRight: '2px' }} /> Reply
                </Reply>
              )
            )}
          </>
          <Menu
            anchorEl={optionsRef.current}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={optionsOpened}
            onClick={(e) => e.stopPropagation()}
            onClose={() => setOptionsOpened(false)}
            MenuListProps={{ disablePadding: true, style: { minWidth: '200px' } }}
            disableAutoFocusItem
          >
            <MenuItem onClick={() => setState(CommentState.EDIT)} className="py-2">
              <StyledEditIcon />
              <Option>Edit</Option>
            </MenuItem>
            <MenuItem onClick={submitDelete} className="py-2">
              <StyledTrashIcon className="mr-lg-2" />
              <Option color="#fb463b">Delete</Option>
            </MenuItem>
          </Menu>
        </CommentWrapper>
      );
    case CommentState.EDIT:
      return (
        <CommentWrapper className="mt-3 pt-3">
          <div className="d-flex flex-wrap position-relative">
            <InitialsWrapper className="d-none d-lg-block">
              <InitialsCircle fontSize={'16px'}>{memberInitials}</InitialsCircle>
            </InitialsWrapper>
            <ReplyWrapper>
              <TextField
                id="editInput"
                autoFocus={true}
                label="Your Note"
                fullWidth={true}
                multiline
                maxRows={4}
                variant="outlined"
                value={editValue}
                onChange={(e) => setEditValue(e.target.value)}
                onFocus={(e) =>
                  e.currentTarget.setSelectionRange(e.currentTarget.value.length, e.currentTarget.value.length)
                }
                className={classes.input}
                InputLabelProps={{ className: classes.label }}
              />
              <CommentControlsWrapper>
                <AddCommentAttachment file={file} onEdit={handleFileChange} onRemove={() => setFile(null)} />
                <Button height={'40px'} disabled={!(editValue || file)} onClick={submitEdit}>
                  SHARE
                </Button>
              </CommentControlsWrapper>
            </ReplyWrapper>
            <ReplyCross>
              <FiX
                className="close-button cursor-pointer"
                size={18}
                onClick={() => {
                  setOptionsOpened(false);
                  setState(CommentState.READ);
                }}
              />
            </ReplyCross>
          </div>
        </CommentWrapper>
      );
  }

  return <></>;
};

export default withRouter(Comment);

const useStyles = makeStyles({
  commentCounter: {
    marginLeft: '5px',
    color: '#6a777f',
  },
  commentsGroup: {
    paddingLeft: '15px',
  },
  commentsGroupTitle: {
    margin: '20px 0',
    fontSize: '12px',
    lineHeight: 1.17,
    color: '#2b2e31',
    opacity: 0.6,
  },
  comment: {
    display: 'flex',
  },
  member: {
    display: 'inline-block',
    padding: '4px 5px 4px 5px',
    margin: '5px 0',
    fontSize: '12px',
    lineHeight: '14px',
    color: '#2b2e31',
    border: 'solid 1px #86919a',
    borderRadius: '16px',
    backgroundColor: '#ffffff',
    marginRight: '10px',
  },
  initials: {
    width: '16px',
    height: '16px',
    padding: '4px 0',
    textAlign: 'center',
    fontSize: '7px',
    lineHeight: '8px',
    borderRadius: '50%',
    color: '#ffffff',
    backgroundColor: '#0f2136',
    boxShadow: '1px 1px 5px 1px rgba(0, 0, 0, 0.2);',
  },
  memberName: {
    margin: '0 5px',
    display: 'inline-block',
    maxWidth: '200px',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    '@media (max-width: 768px)': {
      maxWidth: '100px',
    },
  },
  input: {
    '& .MuiInputBase-root': {
      backgroundColor: '#f8f8f8',
    },
  },
  label: {
    color: '#2b2e31',
    '&.MuiFormLabel-root.Mui-focused': {
      color: '#2b2e31',
    },
  },
  commentHeaderWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    '@media (max-width: 768px)': {
      alignItems: 'baseline',
      justifyContent: 'space-between',
      width: '100%',
    },
  },
  commentHeaderLefSide: {
    display: 'flex',
    alignItems: 'center',
    '@media (max-width: 768px)': {
      flexWrap: 'wrap',
    },
  },
  commentHeaderRightSide: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
    '@media (max-width: 768px)': {
      width: 'unset',
    },
  },
  commentDateWrapper: {
    width: 'max-content',
    marginRight: '4px',
  },
  segmentBadgeWrapper: {
    marginRight: '4px',
  },
  wheelLinkContainer: {
    display: 'flex',
    padding: '4px 8px',
    borderRadius: '50px',
    backgroundColor: '#f3f3f3',
    color: '#005ebd',
    cursor: 'pointer',
    alignItems: 'center',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  wheelLink: {
    fontSize: '12px',
    marginRight: '8px',
    '@media (max-width: 768px)': {
      display: 'none',
    },
  },
});
