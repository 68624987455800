import NoResourcesBase from './NoResourcesBase';
import SegmentTags from '../SegmentTags/SegmentTags';
import Wheel from '../Wheel/Wheel';
import DisabledChart from '../../../Shared/HistoryChart/DisabledChart';
import CheckinButton from '../CheckinButton/CheckinButton';
import './NoResources.scss';

export default class extends NoResourcesBase {
  render() {
    const { wheel, showCheckinModal } = this.props;

    return (
      <div className="no-results">
        <SegmentTags
          averageScore={0}
          scores={this.getFakeScoresForSegmentTag()}
          activeSegmentId={null}
          onClick={() => {}}
        />

        <div className="charts-container">
          <Wheel
            maxScale={wheel?.maxScale}
            averageScore={0}
            scores={this.getFakeScoresForWheel()}
            activeSegmentId={null}
            width={300}
            disabled
          />
          <DisabledChart scores={this.getFakeScoresForChart()} />
        </div>

        <div className="overlay">
          <div className="call-to-action">
            <>
              <div className="message">Complete a check in to see your Suggested Resources!</div>
              <div className="button-container">
                <CheckinButton className="education_complete-checkin" onClick={showCheckinModal} />
              </div>
            </>
          </div>
        </div>
      </div>
    );
  }
}
