import React from 'react';

import { tIconProps } from '../../_types';

const UserCheckIcon = ({ className }: tIconProps) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12">
    <defs>
      <filter id="d4brryyhva" colorInterpolationFilters="auto">
        <feColorMatrix
          in="SourceGraphic"
          values="0 0 0 0 1.000000 0 0 0 0 1.000000 0 0 0 0 1.000000 0 0 0 1.000000 0"
        />
      </filter>
      <path
        id="cft3l0s7gb"
        d="M6 7c1.38 0 2.5 1.12 2.5 2.5v1c0 .276-.224.5-.5.5s-.5-.224-.5-.5v-1C7.5 8.672 6.828 8 6 8H2.5C1.672 8 1 8.672 1 9.5v1c0 .276-.224.5-.5.5s-.5-.224-.5-.5v-1C0 8.12 1.12 7 2.5 7zm5.146-2.854c.196-.195.512-.195.708 0 .195.196.195.512 0 .708l-2 2c-.196.195-.512.195-.708 0l-1-1c-.195-.196-.195-.512 0-.708.196-.195.512-.195.708 0l.646.647zM4.25 1c1.38 0 2.5 1.12 2.5 2.5S5.63 6 4.25 6s-2.5-1.12-2.5-2.5S2.87 1 4.25 1zm0 1c-.828 0-1.5.672-1.5 1.5S3.422 5 4.25 5s1.5-.672 1.5-1.5S5.078 2 4.25 2z"
      />
    </defs>
    <g fill="none" fillRule="evenodd">
      <g>
        <g>
          <g>
            <g
              filter="url(#d4brryyhva)"
              transform="translate(-527.000000, -622.000000) translate(308.000000, 536.000000) translate(136.000000, 80.000000) translate(75.000000, 0.000000)"
            >
              <g transform="translate(8.000000, 6.000000)">
                <mask id="oaa6anvwcc" fill="#fff">
                  <use href="#cft3l0s7gb" />
                </mask>
                <use fill="#000" fillRule="nonzero" href="#cft3l0s7gb" />
                <g fill="#000" mask="url(#oaa6anvwcc)">
                  <path d="M0 0H12V12H0z" />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default UserCheckIcon;
