import styled from 'styled-components';
import { SmallButton } from '../Shared/Buttons/Button';
import { useEffect, useState } from 'react';
import { SupportClient } from '../../API/support.client';
import { DateTime } from 'luxon';
import { Text } from '../Shared/Typography/Typography';
import { ResourcesArticle } from './ResourcesArticle';
import { Spinner, SpinnerBackground } from '../Shared/Spinner/Spinner';
import { useSelector } from 'react-redux';
import { selectUser } from '../Auth/Auth.selectors';
import { AccountType } from '../../API/interfaces';

const categories = [
  {
    title: 'Workplace',
    category: 6,
  },
  {
    title: 'Schools',
    category: 8,
  },
  {
    title: 'Community',
    category: 7,
  },
  {
    title: 'Personal',
    category: 2,
  },
  {
    title: 'Company News',
    category: 4,
  },
  {
    title: 'Latest',
    category: 1,
  },
];

export const Resources = () => {
  const [selectedCategory, selectCategory]: [number, (data) => void] = useState(null);
  const [posts, setPosts] = useState([]);
  const [article, setArticle] = useState(null);
  const [loading, setLoading] = useState(false);
  const user = useSelector(selectUser);

  useEffect(() => {
    switch (user.accountTag) {
      case AccountType.PERSONAL:
        selectCategory(2);
        break;
      case AccountType.SCHOOL:
        selectCategory(8);
        break;
      case AccountType.WORK:
        selectCategory(6);
        break;
      case AccountType.ATHLETIC_WEST:
        selectCategory(7);
        break;
      default:
        selectCategory(2);
        break;
    }
  }, []);

  useEffect(() => {
    if (selectedCategory) {
      fetchPosts(selectedCategory);
    }
  }, [selectedCategory]);

  const fetchPosts = async (category) => {
    setLoading(true);
    const data = await SupportClient.getResources(category, 100);
    const posts = data.map(SupportClient.parseArticles);
    await setPosts(posts);
    setLoading(false);
  };

  return article ? (
    <ResourcesArticle article={article} back={() => setArticle(null)} />
  ) : (
    <>
      <TagWrapper>
        {categories.map((c) => {
          return (
            <Tag key={c.title} selected={selectedCategory === c.category} onClick={() => selectCategory(c.category)}>
              {c.title}
            </Tag>
          );
        })}
      </TagWrapper>
      <div>
        <PostsWrapper>
          {loading ? (
            <SpinnerBackground height="50vh">
              <Spinner></Spinner>
            </SpinnerBackground>
          ) : (
            posts.map((p) => {
              return (
                <Post key={p.id} image={p.image} onClick={() => setArticle(p)}>
                  <PostInfo>
                    <Text size="18px" color="#ffffff" lineHeight={1.33} weight={500} className="mb-2">
                      {p.title}
                    </Text>
                    <Text size="14px" color="#ffffff" weight={300}>
                      {p.date}
                    </Text>
                  </PostInfo>
                </Post>
              );
            })
          )}
        </PostsWrapper>
      </div>
    </>
  );
};

const TagWrapper = styled.div`
  flex: 1;
  white-space: nowrap;
  overflow-x: scroll;
  padding: 1px 0px;
  scrollbar-width: none;
  -ms-overflow-style: none;
  @media (max-width: 768px) {
    width: 100vw;
    margin-left: -20px;
    padding-left: 20px;
    margin-top: 10px;
  }
`;

const Tag = styled<any>(SmallButton)`
  user-select: none;
  font-size: 14px;
  font-weight: normal;
  border: 1px solid;
  background-image: none;
  border-color: ${({ selected }) => (selected ? 'transparent' : '#cececf')};
  color: ${({ selected }) => (selected ? '#fff' : '#0c2337')};
  background-color: ${({ selected }) => (selected ? '#2a3e50' : '#fff')};
  box-shadow: none;
  transition: background-color 0.2s ease-in-out;
  margin-right: 4px;
`;

const PostsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 50px;
  justify-content: space-between;
  @media (max-width: 768px) {
    margin-top: 20px;
  }
`;

const Post = styled.div<any>`
  flex: 0 0 calc(33.3% - 15px);
  margin-bottom: 20px;
  background-image: url(${({ image }) => image});
  background-size: cover;
  background-position: center center;
  position: relative;
  cursor: pointer;
  border-radius: 5px;
  overflow: hidden;
  @media (max-width: 768px) {
    flex: 0 0 100%;
  }
  &:after {
    content: '';
    display: block;
    padding-bottom: 100%;
  }
`;

const PostInfo = styled.div<any>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: end;
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 1) 0%,
    rgba(0, 0, 0, 0.75) 25%,
    rgba(0, 0, 0, 0) 75%,
    rgba(0, 0, 0, 0) 100%
  );
  padding: 24px;
`;
