import React, { ComponentType } from 'react';
import { isStyledComponent, StyledComponent } from 'styled-components';
import { iHasId } from '../Components/Dashboard/_types';

/*
 * of function is used to describe instantiation
 * for example instead of passing arrow function to map method
 * { List.map(item => <Item item={item} />) }
 * you can pass of instead
 * { List.map(of(Item)) }
 * */
type OfT = <Props extends iHasId>(
  Component: ComponentType<Props> | StyledComponent<any, any>
) => (props: Props, index: number, array: Props[]) => JSX.Element;
const of: OfT = (Component) => (props, index) => {
  if (isStyledComponent(Component)) {
    const children = props;
    return <Component key={index}>{children}</Component>;
  } else {
    return <Component {...props} key={`${props?.id}-${index}`} />;
  }
};

export default of;
