import React, { useState } from 'react';
import styled from 'styled-components';
import { AxiosError, AxiosResponse } from 'axios';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { StringParam, useQueryParam } from 'use-query-params';
import { Modal } from 'reactstrap';
import _ from 'lodash';
import { analytics } from '../../../analytics/analytics';

import { Plus as PlusIcon, X } from 'react-feather';
import { ModalProps } from 'reactstrap/es/Modal';

import { toasterService } from '../../Shared/Toaster/Toaster.service';

import { FolderClient } from '../../../API/folder.client';
import { Folder, FolderWithoutParent, FolderWithParent, iFolder, iWheelExt, tFolder } from '../../../API/interfaces';

import { buildTreeByParent } from '../_utils';
import { CreateFolderDesktop, DeleteFolderDesktop } from '../FolderModals';
import of from '../../../_utils/of';
import { FolderDesktop, tFolderProps, AllWheels } from '../Folder';
import { Container, CreateFolderButton } from './_styled';
import { toSafeName } from './_utils';
import { Domains } from '../DashboardNew';
import { DuplicateFolderModal } from '../Folder/DuplicateFolderModal';
import { InviteToFolderModal } from '../Folder/InviteToFolderModal';

export const SidebarDesktop = () => {
  const queryClient = useQueryClient();
  const [selectedFolderId, setSelectedFolderId] = useQueryParam('folder_id', StringParam);
  const [showCreateFolderModal, setShowCreateFolderModal] = React.useState(false);
  const [folderToDuplicate, setFolderToDuplicate] = React.useState(null);
  const [folderToDelete, setFolderToDelete] = React.useState(null);
  const [folderToInvite, setFolderToInvite] = useState<boolean>(false);

  const folderQuery = useQuery<AxiosResponse<iFolder[]>, AxiosError, Folder[]>(Domains.FOLDERS, FolderClient.get, {
    select: ({ data }) => (data ? data.map(Folder.of) : []),
  });

  const folderUpdate = useMutation(FolderClient.update, {
    onSuccess: () => {
      queryClient.invalidateQueries(Domains.FOLDERS);
    },
  });
  const folderAddWheel = useMutation(FolderClient.addWheel);
  const folderDeleteWheel = useMutation(FolderClient.deleteWheel);
  const folderCreate = useMutation(FolderClient.create, {
    onSuccess: () => {
      toasterService.addSuccessToast('Successfully created');
      queryClient.invalidateQueries(Domains.FOLDERS);
      closeCreateFolderModal();

      if (selectedFolderId) {
        analytics.createSubfolder();
      } else {
        analytics.createFolder();
      }
    },
    onError: () => {
      toasterService.addErrorToast('Something went wrong');
    },
  });
  const folderDelete = useMutation(FolderClient.delete, {
    onSuccess: () => {
      toasterService.addSuccessToast('Successfully deleted');
      queryClient.invalidateQueries(Domains.FOLDERS);
      closeDeleteFolderModal();
      setSelectedFolderId('');
    },
    onError: () => {
      toasterService.addErrorToast('Something went wrong');
    },
  });

  const folderDuplicate = useMutation(FolderClient.duplicate, {
    onSuccess: ({ data: { _id } }) => {
      toasterService.addSuccessToast('Successfully duplicated');
      queryClient.invalidateQueries(Domains.FOLDERS);
      queryClient.invalidateQueries(Domains.WHEELS);
      closeDeleteFolderModal();
      setSelectedFolderId(_id);
    },
    onError: () => {
      toasterService.addErrorToast('Something went wrong');
    },
  });

  const closeCreateFolderModal = React.useCallback(() => setShowCreateFolderModal(false), []);
  const closeDeleteFolderModal = React.useCallback(() => setFolderToDelete(null), []);
  const closeDuplicateFolderModal = React.useCallback(() => setFolderToDuplicate(null), []);
  const closeInviteToFolderModal = React.useCallback(() => setFolderToInvite(null), []);

  const onDuplicateFolderSubmit = React.useCallback(
    async (folder) => {
      folderDuplicate.mutate(folder);
    },
    [folderToDuplicate]
  );

  const onDeleteFolderSubmit = React.useCallback(() => {
    folderDelete.mutate(folderToDelete.id);
  }, [folderToDelete]);

  const moveWheelToFolder = _.curry((folder: Folder, wheel: iWheelExt) => {
    folderAddWheel.mutate(
      { folderId: folder.id, wheelId: wheel.id },
      {
        onSuccess: () => {
          toasterService.addSuccessToast(`Wheel ${wheel.name} has been successfully moved to ${folder.name}`);
          setSelectedFolderId(folder.id);
          queryClient.invalidateQueries(Domains.FOLDERS);
        },
        onError: () => {
          toasterService.addErrorToast('Something went wrong');
        },
      }
    );
  });

  const onAllWheelsDrop = (wheel: iWheelExt) => {
    const folder = folderQuery.data.find((folder) => folder.wheels.includes(wheel.id));
    if (!folder) {
      toasterService.addErrorToast('Something went wrong');
    }

    folderDeleteWheel.mutate(
      { folderId: folder.id, wheelId: wheel.id },
      {
        onSuccess: () => {
          toasterService.addSuccessToast(`Wheel ${wheel.name} has been successfully moved to all wheels`);
          setSelectedFolderId(null);
          queryClient.invalidateQueries(Domains.FOLDERS);
        },
        onError: () => {
          toasterService.addErrorToast('Something went wrong');
        },
      }
    );
  };

  const existingFolderNames = React.useMemo(() => folderQuery.data?.map(({ name }) => name), [folderQuery]);

  const onDuplicateFolder = React.useCallback(
    (folder) => {
      const nameSafe = toSafeName(existingFolderNames, folder.name);
      folderCreate.mutate({ ...folder, name: nameSafe });
    },
    [existingFolderNames]
  );

  const folderProps: tFolderProps[] = React.useMemo(() => {
    return folderQuery.data?.map((folder) => ({
      ...folder,
      showCreateModal: () => setShowCreateFolderModal(true),
      onClick: () => setSelectedFolderId(folder.id),
      active: folder.id === selectedFolderId,
      selectedFolderId,
      onDrop: moveWheelToFolder(folder),
      updateFolder: (folder) => folderUpdate.mutate(folder),
      duplicateFolder: (folder) => setFolderToDuplicate(folder),
      deleteFolder: (folder) => setFolderToDelete(folder),
      inviteToFolder: (folder) => setFolderToInvite(folder),
    }));
  }, [folderQuery.data, selectedFolderId]);

  const folderPropsTree = React.useMemo(() => buildTreeByParent<tFolderProps>(folderProps || []), [folderProps]);

  const onCreateFolderSubmit = React.useCallback(
    (name: string) => {
      let newFolder: tFolder;
      const nameSafe = toSafeName(existingFolderNames, name);
      if (selectedFolderId) {
        newFolder = new FolderWithParent(nameSafe, selectedFolderId);
      } else {
        newFolder = new FolderWithoutParent(nameSafe);
      }
      folderCreate.mutate(newFolder);
    },
    [selectedFolderId, existingFolderNames]
  );

  return (
    <Container>
      <AllWheels active={!selectedFolderId} onClick={() => setSelectedFolderId('')} onDrop={onAllWheelsDrop} />

      {folderPropsTree?.map(of(FolderDesktop))}

      {!selectedFolderId && (
        <CreateFolderButton
          onClick={() => setShowCreateFolderModal(true)}
          style={{ marginLeft: '32px', marginTop: '8px' }}
        >
          <PlusIcon size="16px" style={{ marginRight: '8px' }} />
          Create Folder
        </CreateFolderButton>
      )}

      <StyledModal isOpen={showCreateFolderModal} onClose={closeCreateFolderModal} centered>
        <CreateFolderDesktop onCancel={closeCreateFolderModal} onSubmit={onCreateFolderSubmit} />
      </StyledModal>

      <StyledModal isOpen={!!folderToDelete} onClose={closeCreateFolderModal} centered>
        <DeleteFolderDesktop
          name={folderToDelete?.name}
          onCancel={closeDeleteFolderModal}
          onSubmit={onDeleteFolderSubmit}
        />
      </StyledModal>
      {folderToDuplicate && (
        <DuplicateFolderModal
          isOpen={!!folderToDuplicate}
          duplicateFolder={folderToDuplicate}
          onCancel={closeDuplicateFolderModal}
          duplicate={onDuplicateFolderSubmit}
        />
      )}
      {folderToInvite && (
        <InviteToFolderModal
          isOpen={!!folderToInvite}
          onClose={closeInviteToFolderModal}
          folder={folderToInvite}
        ></InviteToFolderModal>
      )}
    </Container>
  );
};

export const StyledModal = styled<React.ComponentType<ModalProps>>(Modal)`
  & .modal-content {
    border: none;
    padding: 40px 50px;
    background-color: #fefaf2;
    width: auto;
    min-width: 550px;
  }
`;
