import React, { Component } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Modal, ModalBody } from 'reactstrap';
import Footer from '../Shared/Footer/Footer';
import Header from '../Shared/Header/Header';
import { alertModal } from '../../Redux/Actions/Actions';
import './ManageContainer.scss';
import { store } from '../../Redux/store';
import { Spinner } from '../Shared/Spinner/Spinner';

const ERR_NOT_FOUND = 'Page does not exist.';

interface iManageContainerProps extends RouteComponentProps {
  user?: any;
  loading?: any;
  className?: any;
  modal?: any;
  isPublic?: boolean;
  children?: any;
  showLoader?: boolean;
  hasFooter?: boolean;
}

interface iManageContainerState {}

class Container extends Component<iManageContainerProps, iManageContainerState> {
  static defaultProps = {
    isPublic: false,
  };

  componentDidMount() {
    const { className } = this.props;
    const bodyElement = document.querySelector('body');

    switch (className) {
      case 'dark':
        // @ts-ignore TODO
        bodyElement.style = 'background-color:#0D2336';
        break;
      case 'gray':
        // @ts-ignore TODO
        bodyElement.style = 'background-color:#f8f8f7';
        break;
      default:
        // @ts-ignore TODO
        bodyElement.style = '';
        break;
    }
  }

  handleCloseAlert = () => {
    const { isPublic, user } = this.props;
    // @ts-ignore TODO
    const msg = store.getState().alertRD.modal;
    // @ts-ignore TODO
    store.dispatch(alertModal(''));

    if (!isPublic && !user._id) {
      window.location.href = '/login';
    }

    if (msg === ERR_NOT_FOUND) {
      window.location.href = '/';
    }
  };

  render() {
    const { modal, className, loading, showLoader = true, isPublic, user, children, hasFooter = true } = this.props;

    return (
      <div className={`manage-container ${className || ''}`}>
        {loading && showLoader ? (
          <div className="app-loader text-center">
            <Spinner />
          </div>
        ) : (
          <>
            <Header user={user} className={className || ''} isPublic={isPublic} />
            {children}
            {hasFooter && <Footer />}
          </>
        )}

        {modal && (
          <Modal isOpen={true} id="alert-model" toggle={this.handleCloseAlert} className="modal-md" backdrop="static">
            <ModalBody>
              <div className="body-content">
                <div className="row mb-px-3">
                  <div className="col-12">{modal}</div>
                </div>
                <div className="row">
                  <div className="col-12 text-right">
                    <button
                      className="btn btn-link ml-px-1 p-0"
                      id="alert-dialog-confirm"
                      onClick={this.handleCloseAlert}
                    >
                      Close
                    </button>
                  </div>
                </div>
              </div>
            </ModalBody>
          </Modal>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  modal: state.alertRD.modal,
  loading: state.loadingRD.loading,
  user: state.userRD.user || {},
});

export const ManageContainer = connect(mapStateToProps)(withRouter(Container));
