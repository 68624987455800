import { SupportCompany } from './support.types';
import styled from 'styled-components';
import { Text } from '../Shared/Typography/Typography';
import { FiArrowRight, FiPhoneIncoming } from 'react-icons/fi';
import { analytics } from '../../analytics/analytics';

export const CrisisCompany = ({ company }: { company: SupportCompany }) => {
  const link = () => {
    analytics.crisisSupportRedirect(company.name);
    window.open(company.link, '_blank').focus();
  };

  const onClick = () => {
    analytics.crisisSupportCall(company.name);
  };

  return (
    <Block>
      <LogoWrapper>
        <Logo src={company.image} alt="" />
      </LogoWrapper>
      <DescriptionWrapper>
        <div className="mb-2">
          <Text weight={500}>{company.name}</Text>
        </div>
        <Text>{company.description}</Text>
        {company.phone && (
          <div className="d-flex align-items-center mt-3">
            <FiPhoneIncoming className="mr-2"></FiPhoneIncoming>
            <a href={`tel:${company.phone}`} onClick={onClick}>
              <Text color="#fb463b">{company.phone}</Text>
            </a>
          </div>
        )}
      </DescriptionWrapper>
      <Arrow onClick={link}>
        <FiArrowRight size={24} />
      </Arrow>
    </Block>
  );
};

const Logo = styled.img`
  width: 75px;
  height: 75px;
`;

const LogoWrapper = styled.div`
  display: flex;
  align-items: start;
  justify-content: center;
  padding: 16px 16px 24px;
  @media (max-width: 768px) {
    padding: 10px;
  }
`;

const DescriptionWrapper = styled.div`
  min-width: 90%;
  display: flex;
  flex-direction: column;
  padding: 24px 48px 24px 0px;
  @media (max-width: 768px) {
    min-width: auto;
    padding: 16px 16px 16px 0px;
  }
`;

const Arrow = styled.div`
  position: absolute;
  top: 15px;
  right: 15px;
  cursor: pointer;
`;

const Block = styled.div`
  position: relative;
  width: calc(50% - 12px);
  border-radius: 4px;
  box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.08);
  background-color: #fff;
  display: flex;
  margin-bottom: 24px;
  &:nth-child(2n) {
    margin-left: 24px;
  }
  @media (max-width: 768px) {
    width: 100%;
    &:nth-child(2n) {
      margin-left: 0;
    }
  }
`;
