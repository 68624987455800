import * as d3 from 'd3';
import { iScore, iScoresAggregation, iWheel } from '../../API/interfaces';
import { getAverageScore } from '../../Components/Shared/utils';
import { DIMMED_GRADIENTS, GRADIENTS } from '../../Components/Shared/colors';

const colors = {
  GRADIENTS: GRADIENTS,
  DIMMED_GRADIENTS: DIMMED_GRADIENTS,
};

interface iWheelImageProps {
  wheel: iWheel;
  scores: Array<iScore>;
  sizes: number[];
  activeSegmentIndex?: number;
}

export default ({ scores = [], wheel, sizes, activeSegmentIndex }: iWheelImageProps) => {
  const baseRadius = 100,
    width = sizes[0],
    height = sizes[1],
    angleInc = 360 / scores.length,
    center = { x: width / 2, y: height / 2 };

  const circleScore =
    activeSegmentIndex === null
      ? getAverageScore(scores as Array<iScoresAggregation>)
      : scores[activeSegmentIndex].score;

  const createArea = (score, index) => {
    const percent = score.score / wheel.maxScale;
    const aid = `arc_area_${index}`;
    const startAngle = index * angleInc;
    const endAngle = startAngle + angleInc;
    const outerRadius = Math.max(percent * baseRadius, 25);
    const isFocused = [null, index].includes(activeSegmentIndex);
    const gradient = colors[isFocused ? 'GRADIENTS' : 'DIMMED_GRADIENTS'][index];
    const textColor =
      activeSegmentIndex === null
        ? '#000' // there is no selected segment at all
        : activeSegmentIndex !== index
        ? '#6A777F' // it is not a selected segment
        : [0, 4].includes(index)
        ? '#000'
        : '#fff'; // it's a selected segment (should be black on yellow backgrounds)

    const drawLine = (startAngle, endAngle) => {
      const angle = endAngle - (endAngle - startAngle) / 2;
      const radians = (angle - 90) * (Math.PI / 180);
      const x1 = baseRadius * Math.cos(radians);
      let y1 = baseRadius * Math.sin(radians);
      if (angle === 180) {
        y1 += 20;
      }
      const y2 = y1;
      const x2 = angle < 180 ? 120 : -120;
      return d3.line()([
        [0, 0],
        [x1, y1],
        [x2, y2],
      ]);
    };

    const calculateRectCoordinates = (startAngle, endAngle, rectLength) => {
      const angle = endAngle - (endAngle - startAngle) / 2;
      const radians = (angle - 90) * (Math.PI / 180);
      let y1 = baseRadius * Math.sin(radians);
      if (angle === 180) {
        y1 += 20;
      }
      const y2 = y1 - 10;
      const x2 = angle < 180 ? 120 : -120 - rectLength;
      return { x: x2, y: y2 };
    };

    const calculateRectLength = (nameLength) => {
      switch (true) {
        case nameLength <= 10:
          return 90;
        case nameLength <= 15:
          return 110;
        case nameLength <= 20:
          return 130;
        case nameLength > 20:
          return 150;
      }
    };

    const text = `${score.segmentName} ${score.score}`;
    const rectLength = calculateRectLength(text.length);
    const rectCoordinates = calculateRectCoordinates(startAngle, endAngle, rectLength);

    return (
      <g key={score.segmentId}>
        {/* area */}
        <GradientFilter aid={aid} gradient={gradient} />
        {/* segment name */}
        <GradientFilter aid={aid} gradient={gradient} vertical={false} />
        <path id={aid + '2'} stroke={gradient[0]} strokeWidth="1" fill="none" d={drawLine(startAngle, endAngle)} />
        <svg x={rectCoordinates.x} y={rectCoordinates.y} style={{ overflow: 'visible' }}>
          <rect
            x={0}
            y={0}
            stroke={gradient[index === activeSegmentIndex ? 1 : 0]}
            strokeWidth="1"
            width={rectLength}
            height={20}
            fill={index === activeSegmentIndex ? `url(#${aid}_hor_gradient)` : '#f8f8f8'}
            rx="10"
            ry="10"
          />
          <text x={rectLength / 2} y={12} dominantBaseline="middle" textAnchor="middle" fontSize={12} fill={textColor}>
            {text}
          </text>
        </svg>
        <path id={aid} d={segmentArc(outerRadius, startAngle, endAngle)} fill={`url(#${aid}_ver_gradient)`} />
      </g>
    );
  };

  return (
    <div className="wheel-container">
      <svg width={width} height={height}>
        <g id="report_chart_svg" transform={`translate(${center.x},${center.y})`}>
          {scores.map(createArea)}

          <ScoreCircle score={circleScore} width={180} activeSegmentIndex={activeSegmentIndex} />
        </g>
      </svg>
    </div>
  );
};

const segmentArc = (or, sa, ea): string => {
  const arc = d3.arc();
  return arc({
    innerRadius: 1,
    outerRadius: or,
    startAngle: (Math.PI / 180) * sa,
    endAngle: (Math.PI / 180) * ea,
  });
};

const GradientFilter = ({ aid, vertical = true, gradient }) => (
  <defs>
    <linearGradient
      id={`${aid}_${vertical ? 'ver' : 'hor'}_gradient`}
      x1={0}
      x2={vertical ? 0 : '100%'}
      y1={0}
      y2={vertical ? '100%' : 0}
    >
      <stop offset="0%" stopColor={gradient[0]} />
      <stop offset="100%" stopColor={gradient[1]} />
    </linearGradient>
  </defs>
);

const ScoreCircle = ({ score, width, activeSegmentIndex }) => {
  const radius = width * 0.07;
  const fill = activeSegmentIndex === null ? '#0d2336' : colors.GRADIENTS[activeSegmentIndex][1];
  // yellow and orange colors should have black font
  const color = activeSegmentIndex === 0 || activeSegmentIndex === 4 ? '#000000' : '#ffffff';

  return (
    <g>
      <circle cx={0} cy={0} r={radius} fill={fill} />
      <text x={0} y={0} textAnchor="middle" alignmentBaseline="central" fill={color} fontSize={12}>
        {score}
      </text>
    </g>
  );
};
