import React from 'react';
import styled from 'styled-components';
import { Text } from '../../Shared/Typography/Typography';

type Props = {
  title: string;
  subtitle?: string;
  cardWidth?: string;
  containerHeight?: string;
};

const InfoCardContainer = styled.div<{ containerHeight?: string }>`
  width: 100%;
  height: ${({ containerHeight }) => containerHeight || '515px'};
  display: flex;
  justify-content: center;
  align-items: center;
`;

const InfoCard = styled.div<{ cardWidth?: string }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 48px;
  border-radius: 10px;
  box-shadow: 0 2px 18px 0 rgba(0, 0, 0, 0.19);
  background-color: #fff;
  width: ${({ cardWidth }) => cardWidth || '636px'};
  text-align: center;
`;

export const TabInfoCard: React.FC<Props> = ({ title, subtitle, cardWidth, containerHeight }) => {
  return (
    <InfoCardContainer containerHeight={containerHeight}>
      <InfoCard cardWidth={cardWidth}>
        <Text size="22px" weight={500} margin="0 0 8px 0">
          {title}
        </Text>
        {subtitle && <Text size="16px">{subtitle}</Text>}
      </InfoCard>
    </InfoCardContainer>
  );
};
