import React, { ComponentProps } from 'react';
import { useSelector } from 'react-redux';

import { AccountPlan } from '../API/interfaces';
import nUser from './user';

export namespace Guard {
  interface iGuardProps extends ComponentProps<any> {
    allow: AccountPlan | AccountPlan[];
    not?: boolean;
  }

  export const Component = ({ children, allow: allowedPlan, not }: iGuardProps) => {
    const user = useSelector((state: any) => (state.userRD.user ? state.userRD.user : null));
    let userHasAccess = false;

    if (not) {
      if (Array.isArray(allowedPlan)) {
        userHasAccess = allowedPlan.every((allow) => !nUser.accountPlanEq(allow, user));
      } else {
        userHasAccess = !nUser.accountPlanEq(allowedPlan, user);
      }
    } else {
      if (Array.isArray(allowedPlan)) {
        userHasAccess = allowedPlan.some((allow) => nUser.accountPlanEq(allow, user));
      } else {
        userHasAccess = nUser.accountPlanEq(allowedPlan, user);
      }
    }

    if (userHasAccess) {
      return <>{children}</>;
    }
    return null;
  };
}
