import React, { Component, createRef } from 'react';
import Chart from 'chart.js';
import { DISABLED_GRADIENT } from '../colors';

interface iDisabledChartProps {
  scores: Array<number>;
}

export default class DisabledChart extends Component<iDisabledChartProps, any> {
  chartRef = createRef<HTMLCanvasElement>();

  getBarGradient = (): CanvasGradient => {
    const ctx = this.chartRef.current.getContext('2d');
    const gradient = ctx.createLinearGradient(350, 0, 350, 350);

    gradient.addColorStop(0, DISABLED_GRADIENT[0]);
    gradient.addColorStop(1, DISABLED_GRADIENT[1]);
    return gradient;
  };

  renderChart = () => {
    new Chart(this.chartRef.current.getContext('2d'), {
      type: 'bar',
      data: {
        datasets: [
          {
            barThickness: 88,
            backgroundColor: this.getBarGradient(),
            data: this.props.scores,
            label: null,
          },
        ],
        labels: Array(this.props.scores.length).fill(''),
      },
      options: {
        responsive: false,
        legend: {
          display: false,
        },
        tooltips: {
          enabled: false,
        },
        scales: {
          xAxes: [
            {
              gridLines: {
                display: false,
              },
            },
          ],
          yAxes: [
            {
              gridLines: {
                display: false,
              },
              ticks: {
                display: false,
                max: 10,
                beginAtZero: true,
              },
            },
          ],
        },
      },
    });
  };

  componentDidMount() {
    this.renderChart();
  }

  render() {
    return (
      <div className="disabled-chart-container">
        <canvas id="disabled-chart" width={900} height={300} ref={this.chartRef} />
      </div>
    );
  }
}
