import React from 'react';
import NoResultsBase from '../../../common/NoResults/NoResultsBase';
import SegmentTags from '../../../common/SegmentTags/SegmentTags';
import Wheel from '../../../common/Wheel/Wheel';
import DisabledChart from '../../../../Shared/HistoryChart/DisabledChart';
import MemberDropdown from '../MemberDropdown/MemberDropdown';
import DateRangeDropdown from '../../../common/DateRangeDropdown/DateRangeDropdown';
import './NoMemberResults.scss';

export default class extends NoResultsBase {
  getMessage = (): string => {
    const { dateRange, members, activeMemberId } = this.props;
    const activeMember = members.find((member) => member.userId === activeMemberId);

    // latest checkins
    if (dateRange.label === 'Latest check ins') {
      // at least one member has results (member are sorted descending)
      if (members[0].avgScore) {
        return `${activeMember.firstName} ${activeMember.lastName} has not completed check in yet`;
      }

      return `None of ${members.length} members has completed check in`;
    }

    return `No results for ${activeMember.firstName} ${activeMember.lastName}. Try another date range`;
  };

  render() {
    const { wheel, members, activeMemberId, setActiveMemberId, dateRange, setDateRange } = this.props;

    return (
      <div className="no-member-results">
        <SegmentTags
          averageScore={0}
          scores={this.getFakeScoresForSegmentTag()}
          activeSegmentId={null}
          onClick={() => {}}
        />

        <div className="charts-container">
          <Wheel
            maxScale={wheel?.maxScale}
            averageScore={0}
            scores={this.getFakeScoresForWheel()}
            activeSegmentId={null}
            width={300}
            disabled
          />
          <DisabledChart scores={this.getFakeScoresForChart()} />
        </div>

        <div className="overlay">
          <div className="no-results-warning">
            <div className="message">{this.getMessage()}</div>
            <div className="dropdown-container">
              {dateRange.from === null && (
                <MemberDropdown
                  members={members}
                  activeMemberId={activeMemberId}
                  setActiveMemberId={setActiveMemberId}
                  teamAlertThreshold={wheel.wheelCreator.account?.teamAlertThreshold}
                />
              )}
              {dateRange.from && <DateRangeDropdown activeOption={dateRange} onChange={setDateRange} />}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
