import { useState } from 'react';
import styled from 'styled-components';
import { Text } from '../Shared/Typography/Typography';
import firstTemplate from '../../Assets/images/AI/1.png';
import secondTemplate from '../../Assets/images/AI/2.png';
import thridTemplate from '../../Assets/images/AI/3.png';
import fourthTemplate from '../../Assets/images/AI/4.png';
import { iUser } from '../../API/interfaces';
import { ItemContainer, TextBox, ListContainer } from './_styled';
import { getUserOnboardingData } from './helpers';
import { analytics } from '../../analytics/analytics';

interface TemplatesListProps {
  setValue: (template) => void;
  setFirstQuestion: (question) => void;
  setTemplateSelected: (isSelected) => void;
  setTitle: (title) => void;
  user: iUser;
  userCountry: string;
  segmentsData: string;
  isPublic: boolean;
}

export const TemplatesList = ({
  setValue,
  setFirstQuestion,
  setTemplateSelected,
  setTitle,
  user,
  userCountry,
  segmentsData,
  isPublic,
}: TemplatesListProps) => {
  const [activeIndex, setActiveIndex] = useState(-1);

  const handleTemplateItemClick = (idx: number) => {
    switch (idx) {
      case 0:
        analytics.clickAiTemplateSupport();
        break;
      case 1:
        analytics.clickAiTemplatePlan();
        break;
      case 2:
        analytics.clickAiTemplateResources();
        break;
      default:
        break;
    }
    if (activeIndex === idx) {
      setActiveIndex(-1);
      setTemplateSelected(false);
      setValue('');
      setFirstQuestion('');
      setTitle('');
    } else {
      setFirstQuestion(data[idx].promt);
      setTitle(data[idx].title);
      setValue(`${data[idx].title} ${data[idx].text}`);
      setActiveIndex(idx);
      setTemplateSelected(true);
    }
  };

  const userName = !isPublic ? `User's name is ${user?.first_name}.` : '';
  const userTag = !isPublic ? `tagged as ${user?.accountTag}` : '';

  const personalData = user?.personalData;
  const onboardingData = getUserOnboardingData(personalData);

  const data = [
    {
      image: firstTemplate,
      title: 'I need support.',
      text: 'I don`t feel ok.',
      promt: `A user ${userTag} has completed a multi-segment check-in with the following scores: ${segmentsData}. The user has expressed a need for support. Considering the range of scores and comments provided across these segments, identify the areas of concern and provide a comprehensive list of resources, support options, and strategies that can help improve the user's well-being in the lower-scoring areas. ${onboardingData} Please write a cohesive response outlining the areas in which the user could benefit from receiving some additional support and what you would suggest directly to them to best support them if they don’t feel okay. This should be written as if it were being shown to the users themselves. This user is - tag and the plan should be written as if it were being addressed to a tag. For concise and precise responses, provide the best areas of support. Keep it between 400-500 words, and avoid being overly wordy.       When providing your response, limit the suggestions to external parties, unless government led or non for profit organisations, additionally base your responded on the location of the users - for example this user is from the country ${userCountry}. ${userName} Please do not show any notes or mention anything from the prompt I've written in your message back to the user. Please sign off on every message as "the iyarn team" after you. Additionally, if there are any comments, via the segment comments or general comments, please add more weighting to these within your response as this shows the users engagement.`,
    },
    {
      image: secondTemplate,
      title: 'I need an action plan.',
      text: 'Give me ideas on things to do that could help me bridge the gap from where I am to where I want to be.',
      promt: `A user ${userTag} is seeking an action plan and has shared scores for multiple life segments: ${segmentsData}. Given these insights, formulate an action plan with targeted activities, habits, and strategies focused on improving the areas with lower scores. Include recommendations that leverage the high-scoring areas to support growth and development across the board. ${onboardingData} This should be written as if it were being shown to the users themselves. This user is - tag and the plan should be written as if it were being addressed to a tag. For concise and precise responses, provide the best areas of support. Keep it between 400-500 words, and avoid being overly wordy. When providing your response, limit the suggestions to external parties, unless government led or non for profit organisations, additionally base your responded on the location of the users - for example this user is from the country ${userCountry}. ${userName} Please do not show any notes or mention anything from the prompt I've written in your message back to the user. Please sign off on every message as "the iyarn team" after you. Additionally, if there are any comments, via the segment comments or general comments, please add more weighting to these within your response as this shows the users engagement.`,
    },
    {
      image: thridTemplate,
      title: 'Get me excited!',
      text: 'Give me ideas and resources to make me empowered to build a pathway to bridging the gap.',
      promt: `A user ${userTag} has requested motivation and resources after checking in on multiple segments: ${segmentsData}. They are looking for ideas and resources that not only address their areas of improvement but also utilise their strengths. Propose a mix of inspiring ideas, practical resources, and engaging activities that empower the user to pursue improvement actively, with a focus on creating synergy between their well-performing and underperforming segments. ${onboardingData} For concise and precise responses, provide the best areas of support. Keep it between 400-500 words, and avoid being overly wordy. Make it sound personal and less AI generated. Use the users name if possible When providing your response, limit the suggestions to external parties, unless government-led or non-profit organisations, additionally, base your responded on the location of the users - for example this user is from the country ${userCountry}. This should be written as if it were being shown to the users themselves. This user is - tag and the plan should be written as if it were being addressed to a tag. ${userName} Please do not show any notes or mention anything from the prompt I've written in your message back to the user. Please sign off on every message as "the iyarn team" after you. Additionally, if there are any comments, via the segment comments or general comments, please add more weighting to these within your response as this shows the users engagement.`,
    },
    {
      image: fourthTemplate,
      title: 'How am I tracking? ',
      text: 'Analyse my check in history and share insights, trends and offer some advice.',
      promt: `A user ${userTag} is seeking an analysis of their check-in history, which includes scores and comments across multiple segments. Their scores range from ${segmentsData}. Review these data points for patterns, trends, and notable shifts over time ${onboardingData} . Provide a detailed analysis that includes insights into their overall scores, advice on maintaining strengths, and strategies for addressing any areas of concern.  For concise and precise responses, provide the best areas of support. Keep it between 400-500 words, and avoid being overly wordy. Make it sound personal and less AI generated. Use the users name if possible. When providing your response, limit the suggestions to external parties, unless government-led or non-profit organisations, additionally, base your responded on the location of the users - for example this user is from the country ${userCountry}. This should be written as if it were being shown to the users themselves. This user is - Student and the plan should be written as if it were being addressed to a tag. ${userName} Please do not show any notes or mention anything from the prompt I've written in your message back to the user. Please sign off on every message as "the iyarn team" after you. Additionally, if there are any comments, via the segment comments or general comments, please add more weighting to these within your response as this shows the users engagement.`,
    },
  ];

  const TemplateItem = ({ item }) => {
    const isActive = activeIndex === data.indexOf(item);

    return (
      <ItemContainer onClick={() => handleTemplateItemClick(data.indexOf(item))} className={isActive ? 'active' : ''}>
        <TextBox>
          <Text size="14px" color="#0C2337" className="mb-1">
            {item.title}
          </Text>
          <Text size="14px" color="#7F8B96">
            {item.text}
          </Text>
        </TextBox>
        <img src={item.image} alt={item.title} />
      </ItemContainer>
    );
  };

  return (
    <ListContainer>
      {data.map((item, idx) => (
        <TemplateItem key={idx} item={item} />
      ))}
    </ListContainer>
  );
};
