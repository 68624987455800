import React, { useState } from 'react';
import styled from 'styled-components';
import { AxiosError, AxiosResponse } from 'axios';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { StringParam, useQueryParam } from 'use-query-params';
import { Modal } from 'reactstrap';
import { analytics } from '../../../analytics/analytics';

import { Plus as PlusIcon } from 'react-feather';
import { ModalProps } from 'reactstrap/es/Modal';

import { toasterService } from '../../Shared/Toaster/Toaster.service';

import { FolderClient } from '../../../API/folder.client';
import { Folder, FolderWithoutParent, FolderWithParent, iFolder, tFolder } from '../../../API/interfaces';

import { buildTreeByParent } from '../_utils';
import { CreateFolderMobile, DeleteFolderMobile } from '../FolderModals';
import of from '../../../_utils/of';
import { FolderMobile, FirstFolderMobile, tProps as tFolderProps } from '../Folder';
import { Container, CreateFolderButton } from './_styled';
import { toSafeName } from './_utils';
import { Domains } from '../DashboardNew';
import { makeStyles } from '@material-ui/core';
import { DuplicateFolderModal } from '../Folder/DuplicateFolderModal';
import { InviteToFolderModal } from '../Folder/InviteToFolderModal';

export const SidebarMobile = () => {
  const classes = useStyles();
  const queryClient = useQueryClient();
  const [selectedFolderId, setSelectedFolderId] = useQueryParam('folder_id', StringParam);
  const [showCreateFolderModal, setShowCreateFolderModal] = React.useState(false);
  const [folderToDelete, setFolderToDelete] = React.useState(null);
  const [folderToDuplicate, setFolderToDuplicate] = React.useState(null);
  const [folderToInvite, setFolderToInvite] = useState<boolean>(false);
  const [sidebarExpanded, setSidebarExpanded] = React.useState(false);

  const folderQuery = useQuery<AxiosResponse<iFolder[]>, AxiosError, Folder[]>(Domains.FOLDERS, FolderClient.get, {
    select: ({ data }) => (data ? data.map(Folder.of) : []),
  });

  const selectedFolder = React.useMemo(() => folderQuery.data?.find((folder) => folder.id === selectedFolderId), [
    selectedFolderId,
    folderQuery.data,
  ]);

  const folderUpdate = useMutation(FolderClient.update, {
    onSuccess: () => {
      toasterService.addSuccessToast('Successfully updated');
      queryClient.invalidateQueries(Domains.FOLDERS);
    },
    onError: () => {
      toasterService.addErrorToast('Something went wrong');
    },
  });
  const folderCreate = useMutation(FolderClient.create, {
    onSuccess: () => {
      toasterService.addSuccessToast('Successfully created');
      queryClient.invalidateQueries(Domains.FOLDERS);
      closeCreateFolderModal();

      if (selectedFolderId) {
        analytics.createSubfolder();
      } else {
        analytics.createFolder();
      }
    },
    onError: () => {
      toasterService.addErrorToast('Something went wrong');
    },
  });
  const folderDelete = useMutation(FolderClient.delete, {
    onSuccess: () => {
      toasterService.addSuccessToast('Successfully deleted');
      queryClient.invalidateQueries(Domains.FOLDERS);
      closeDeleteFolderModal();
      setSelectedFolderId('');
    },
    onError: () => {
      toasterService.addErrorToast('Something went wrong');
    },
  });

  const folderDuplicate = useMutation(FolderClient.duplicate, {
    onSuccess: ({ data: { _id } }) => {
      toasterService.addSuccessToast('Successfully duplicated');
      queryClient.invalidateQueries(Domains.FOLDERS);
      queryClient.invalidateQueries(Domains.WHEELS);
      closeDeleteFolderModal();
      setSelectedFolderId(_id);
    },
    onError: () => {
      toasterService.addErrorToast('Something went wrong');
    },
  });

  const closeCreateFolderModal = React.useCallback(() => setShowCreateFolderModal(false), []);
  const closeDeleteFolderModal = React.useCallback(() => setFolderToDelete(null), []);
  const closeDuplicateFolderModal = React.useCallback(() => setFolderToDuplicate(null), []);
  const closeInviteToFolderModal = React.useCallback(() => setFolderToInvite(null), []);

  const onDuplicateFolderSubmit = React.useCallback(
    async (folder) => {
      folderDuplicate.mutate(folder);
    },
    [folderToDuplicate]
  );

  const onDeleteFolderSubmit = React.useCallback(() => {
    folderDelete.mutate(folderToDelete.id);
  }, [folderToDelete]);

  const onDuplicateFolder = React.useCallback((folder) => {
    const nameSafe = toSafeName(existingFolderNames, folder.name);
    folderCreate.mutate({ ...folder, name: nameSafe });
  }, []);

  const folderProps = React.useMemo(() => {
    return folderQuery.data?.map((folder) => ({
      ...folder,
      showCreateModal: () => setShowCreateFolderModal(true),
      onClick: () => setSelectedFolderId(folder.id),
      active: folder.id === selectedFolderId,
      selectedFolderId,
      updateFolder: (folder) => folderUpdate.mutate(folder),
      duplicateFolder: (folder) => setFolderToDuplicate(folder),
      deleteFolder: (folder) => setFolderToDelete(folder),
      inviteToFolder: (folder) => setFolderToInvite(folder),
      sidebarExpanded,
    }));
  }, [folderQuery.data, selectedFolderId, sidebarExpanded]);

  const folderPropsTree = React.useMemo(() => buildTreeByParent(folderProps || []), [folderProps]);

  const existingFolderNames = React.useMemo(() => folderProps?.map(({ name }) => name), [folderProps]);

  const onCreateFolderSubmit = React.useCallback(
    (name: string) => {
      let newFolder: tFolder;
      const nameSafe = toSafeName(existingFolderNames, name);
      if (selectedFolderId) {
        newFolder = new FolderWithParent(nameSafe, selectedFolderId);
      } else {
        newFolder = new FolderWithoutParent(nameSafe);
      }
      folderCreate.mutate(newFolder);
    },
    [selectedFolderId]
  );

  const toggleExpanded = React.useCallback(
    (e) => {
      e.stopPropagation();
      setSidebarExpanded(!sidebarExpanded);
    },
    [sidebarExpanded]
  );

  const onFirstFolderClick = React.useCallback(() => {
    if (sidebarExpanded) {
      setSelectedFolderId('');
    } else {
      setSidebarExpanded(true);
    }
  }, [sidebarExpanded]);

  return (
    <>
      {sidebarExpanded && <Backdrop onClick={() => setSidebarExpanded(false)} />}
      <ContainerMobile expanded={sidebarExpanded}>
        <FirstFolderMobile
          active={!selectedFolderId || !sidebarExpanded}
          sidebarExpanded={sidebarExpanded}
          showSelected={Boolean(!sidebarExpanded && selectedFolder)}
          onClick={onFirstFolderClick}
          onChevronClick={toggleExpanded}
          {...selectedFolder}
        />

        {folderPropsTree?.map(of(FolderMobile))}

        {!selectedFolderId && (
          <CreateFolderButton
            onClick={() => setShowCreateFolderModal(true)}
            style={{ marginLeft: '32px', marginTop: '8px' }}
          >
            <PlusIcon size="16px" style={{ marginRight: '8px' }} />
            Create Folder
          </CreateFolderButton>
        )}

        <StyledModalMobile
          backdropClassName={classes.backDrop}
          centered
          isOpen={showCreateFolderModal}
          onClose={closeCreateFolderModal}
        >
          <CreateFolderMobile onCancel={closeCreateFolderModal} onSubmit={onCreateFolderSubmit} />
        </StyledModalMobile>

        <StyledModalMobile
          backdropClassName={classes.backDrop}
          centered
          isOpen={!!folderToDelete}
          onClose={closeDeleteFolderModal}
        >
          <DeleteFolderMobile
            name={folderToDelete?.name}
            onCancel={closeDeleteFolderModal}
            onSubmit={onDeleteFolderSubmit}
          />
        </StyledModalMobile>
        {folderToDuplicate && (
          <DuplicateFolderModal
            isOpen={!!folderToDuplicate}
            duplicateFolder={folderToDuplicate}
            onCancel={closeDuplicateFolderModal}
            duplicate={onDuplicateFolderSubmit}
          />
        )}
        {folderToInvite && (
          <InviteToFolderModal
            isOpen={!!folderToInvite}
            onClose={closeInviteToFolderModal}
            folder={folderToInvite}
          ></InviteToFolderModal>
        )}
      </ContainerMobile>
    </>
  );
};

const Backdrop = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
`;

const wrapperMobileHeight = ({ expanded }) => (expanded ? 'auto' : '48px');

const ContainerMobile = styled(Container)<{ expanded: boolean }>`
  position: absolute;
  width: 100%;
  z-index: 2;
  padding-top: 0;
  padding-bottom: 32px;
  height: ${wrapperMobileHeight};
  overflow: hidden;
`;

const StyledModalMobile = styled<React.ComponentType<ModalProps>>(Modal)`
  & .modal-content {
    border: none;
    background-color: #fefaf2;
    padding: 32px 24px 24px;
    width: 100%;
    margin: 0 16px;
  }
`;

const useStyles = makeStyles({
  backDrop: {
    backgroundColor: 'transparent',
  },
});
