import React from 'react';
import { makeStyles, Typography, Box } from '@material-ui/core';
import { User, Calendar, Users, BarChart2, PieChart } from 'react-feather';

interface iTabContentProps {
  description: string;
  reportType: string;
  dateRange: string;
  activeSegmentName?: string;
  activeCheckinDate?: string;
  numberOfMembers?: number;
}

export default ({
  description,
  reportType,
  dateRange,
  numberOfMembers,
  activeSegmentName,
  activeCheckinDate,
}: iTabContentProps) => {
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      <Typography className={classes.description}>{description}</Typography>
      <Typography className={classes.subDescription}>Your report will include the data based on:</Typography>
      <div className={classes.details}>
        <User size={16} color="#ff483c" />
        <Typography className={classes.caption}>{reportType}</Typography>
      </div>
      <div className={classes.details}>
        <Calendar size={16} color="#ff483c" />
        <Typography className={classes.caption}>{`Date Range: ${dateRange}`}</Typography>
      </div>
      {activeCheckinDate !== undefined && (
        <div className={classes.details}>
          <BarChart2 size={16} color="#ff483c" />
          <Typography className={classes.caption}>
            {`Selected bar: ${activeCheckinDate !== null ? activeCheckinDate : dateRange}`}
          </Typography>
        </div>
      )}
      {numberOfMembers && (
        <div className={classes.details}>
          <Users size={16} color="#ff483c" />
          <Typography className={classes.caption}>
            {`${numberOfMembers} member${numberOfMembers > 1 ? 's' : ''}`}
          </Typography>
        </div>
      )}
      {activeSegmentName && (
        <div className={classes.details}>
          <PieChart size={16} color="#ff483c" />
          <Typography className={classes.caption}>{`Selected ${activeSegmentName} segment`}</Typography>
        </div>
      )}
    </Box>
  );
};

const useStyles = makeStyles({
  container: {
    padding: '32px 40px',
    backgroundColor: '#fefaf2',
    '@media(max-device-width: 767px)': {
      padding: '32px',
    },
  },
  description: {
    fontSize: '16px',
    lineHeight: 1.5,
  },
  subDescription: {
    marginTop: '18px',
    fontSize: '14px',
    '@media(max-device-width: 767px)': {
      fontSize: '12px',
    },
  },
  details: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '12px',
    '@media(max-device-width: 767px)': {
      marginTop: '6px',
    },
  },
  caption: {
    marginLeft: '12px',
    fontSize: '16px',
    '@media(max-device-width: 767px)': {
      fontSize: '14px',
    },
  },
});
