import React from 'react';
import styled from 'styled-components';
import { StringParam, useQueryParam } from 'use-query-params';

import { iProps } from './_types';
import {
  StyledModalHeader,
  StyledModalFooter,
  StyledModalBody,
  StyledSecondaryButton,
  Text,
  Text1,
  StyledOtherButton,
} from './_styled';
import TextFieldWithCounter from '../../Shared/TextFieldWithCounter/TextFieldWithCounter';
import { MAX_FOLDER_NAME_LENGTH } from '../../../constants';

export const CreateFolderMobile = React.memo<iProps>((props) => {
  const [selectedFolderId] = useQueryParam('folder_id', StringParam);
  const [folderName, setFolderName] = React.useState('');
  const { onCancel, onSubmit } = props;

  const onInputChange = React.useCallback((e) => setFolderName(e.target.value), []);
  const onCreateClick = React.useCallback(() => onSubmit(folderName), [folderName]);

  return (
    <>
      <StyledModalHeader>
        <Text>{selectedFolderId ? 'Create New Subfolder' : 'Create New Folder'}</Text>
      </StyledModalHeader>

      <StyledModalBody className="py-0">
        <TextFieldWithCounter
          value={folderName}
          onChange={onInputChange}
          label={selectedFolderId ? 'Subfolder Name' : 'Folder Name'}
          variant="outlined"
          maxValueLength={MAX_FOLDER_NAME_LENGTH}
          fullWidth
        />
      </StyledModalBody>

      <StyledModalFooter>
        <StyledSecondaryButton type="button" onClick={onCancel}>
          CANCEL
        </StyledSecondaryButton>
        <StyledOtherButton type="button" onClick={onCreateClick}>
          CREATE
        </StyledOtherButton>
      </StyledModalFooter>
    </>
  );
});

export const DeleteFolderMobile = React.memo<iProps & { name: string }>(({ name, onCancel, onSubmit }) => (
  <>
    <StyledModalHeader>
      <TextMobile>{`Are you sure you want to delete folder ${name}?`}</TextMobile>
    </StyledModalHeader>

    <StyledModalBody className="py-0">
      <Text1Mobile>Pay attention all the wheels from this folder going to be moved to “All Wheels” tab.</Text1Mobile>
    </StyledModalBody>

    <StyledModalFooter>
      <StyledSecondaryButton type="button" onClick={onCancel}>
        CANCEL
      </StyledSecondaryButton>
      <StyledOtherButton type="button" onClick={onSubmit}>
        DELETE
      </StyledOtherButton>
    </StyledModalFooter>
  </>
));

const TextMobile = styled(Text)`
  margin: 0 0 16px;
  font-size: 16px;
  margin: 0 0 8px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #2b2e31;
`;

const Text1Mobile = styled(Text1)`
  font-size: 12px;
  margin: 8px 24px 8px 0;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: #2b2e31;
  padding: 0;
`;
