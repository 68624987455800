import React from 'react';
import { Button, makeStyles } from '@material-ui/core';
import { analytics } from '../../../analytics/analytics';
import CommentFilters from './CommentFilters';

interface iCommentSectionHeaderProps {
  activeSegmentName: string | null;
  setExpandAll: () => void;
  isCollapsable: boolean;
  expandAll: boolean;
  onlyWithCommentText: boolean;
  setOnlyWithCommentText: (onlyWithCommentText: boolean) => void;
}

export default ({
  activeSegmentName,
  isCollapsable,
  expandAll,
  setExpandAll,
  onlyWithCommentText,
  setOnlyWithCommentText,
}: iCommentSectionHeaderProps) => {
  const title = activeSegmentName ? `Comments for ${activeSegmentName} segment` : 'General Comments';
  const classes = useStyles();

  const omRadioButtonChange = (e) => {
    const value: 'allCheckins' | 'onlyWithText' = e.target.value;
    setOnlyWithCommentText(value === 'onlyWithText');
    analytics.clickCommentsFilter(value);
  };

  return (
    <div className={classes.container}>
      <div className={classes.flexContainer}>
        <div className={classes.commentsInfo}>
          <div className={classes.title}>{title}</div>

          <div className={classes.subtitle}></div>
        </div>
        <CommentFilters onChange={omRadioButtonChange} value={onlyWithCommentText ? 'onlyWithText' : 'allCheckins'} />
      </div>

      {isCollapsable && (
        <Button classes={{ root: classes.expandBtn }} onClick={setExpandAll} variant="outlined">
          {expandAll ? 'Collapse' : 'Expand'} Comments
        </Button>
      )}
    </div>
  );
};

const useStyles = makeStyles({
  container: {
    display: 'flex',
    alignItems: 'start',
    padding: '0 24px',
    '@media(max-device-width: 768px)': {
      flexDirection: 'column',
    },
  },
  flexContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'start',
    width: '100%',
    '@media(max-device-width: 768px)': {
      minWidth: '100%',
    },
  },
  commentsInfo: {},
  title: {
    fontSize: '18px',
    fontWeight: 500,
  },
  subtitle: {
    marginBottom: '20px',
    fontSize: '12px',
  },
  dateRange: {
    marginLeft: '5px',
    color: '#2b2e31',
    opacity: 0.6,
  },
  controls: {
    display: 'flex',
    justifyContent: 'end',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  expandBtn: {
    textTransform: 'none',
    marginLeft: '32px',
    fontSize: '16px',
    minWidth: '205px',
    '@media(max-device-width: 768px)': {
      display: 'none',
    },
  },
});
