import React, { Component } from 'react';
import { iScore } from '../../../../API/interfaces';
import WheelSegment from './WheelSegment/WheelSegment';
import WheelCircle from './WheelCircle/WheelCircle';
import './Wheel.scss';

interface iWheelProps {
  maxScale: number;
  averageScore: number;
  scores: Array<iScore>;
  numberOfCheckins?: number;
  activeSegmentId: string | null;
  width: number;
  disabled: boolean;
  onClick?: (segmentId: string, segmentIndex: number) => void;
  onMouseOver?: (segmentId: string) => void;
  onMouseOut?: () => void;
  id: string;
  className?: string;
  showLogo?: boolean;
}

interface iWheelState {}

class Wheel extends Component<iWheelProps, iWheelState> {
  static defaultProps = {
    width: 350,
    disabled: false,
    id: 'wheel',
    showLogo: false,
  };

  render() {
    const {
      width,
      scores,
      activeSegmentId,
      disabled,
      onClick,
      numberOfCheckins,
      averageScore,
      id,
      onMouseOver,
      onMouseOut,
      className,
      maxScale,
      showLogo,
    } = this.props;
    const radius = width / 2;
    const innerRadius = width * 0.07;
    const angleInc = 360 / scores.length;

    return (
      <div className={`wheel-container${className ? ` ${className}` : ''}`} style={{ width: width }}>
        <svg width={width} height={width} className="wheel-segment-shadow">
          <g transform={`translate(${radius},${radius})`}>
            {scores.map((score, index) => (
              <WheelSegment
                key={score.segmentId}
                score={score}
                startAngle={index * angleInc}
                endAngle={(index + 1) * angleInc}
                innerRadius={innerRadius}
                outerRadius={(score.score / maxScale) * (radius - innerRadius) + innerRadius} // innerRadius is and offset
                onClick={onClick ? () => onClick(score.segmentId, index) : null}
                onMouseOver={onMouseOver ? () => onMouseOver(score.segmentId) : null}
                onMouseOut={onMouseOut || null}
                isFocused={activeSegmentId === null || score.segmentId === activeSegmentId}
                disabled={disabled}
                index={index}
                id={id}
              />
            ))}

            <WheelCircle
              averageScore={averageScore}
              disabled={disabled}
              radius={innerRadius}
              activeSegmentId={activeSegmentId}
              scores={scores}
              showLogo={showLogo}
            />
          </g>
        </svg>

        {numberOfCheckins && <div className="checkin-date">{`Result based on  ${numberOfCheckins} check ins`}</div>}
      </div>
    );
  }
}

export default Wheel;
