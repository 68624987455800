import React from 'react';
import styled from 'styled-components';

import Fade from './Fade';
import { EmptyPageTitle } from './_styled';

import dashboardEmptyStateImg from '../../Assets/images/dashboard-empty-state-img.png';
import dashboardEmptyStateImg2x from '../../Assets/images/dashboard-empty-state-img@2x.png';
import dashboardEmptyStateImg3x from '../../Assets/images/dashboard-empty-state-img@3x.png';

const NoWheelsCreated = () => (
  <Wrapper className="text-center">
    <EmptyPageTitle>No wheels have been created yet</EmptyPageTitle>
    <StyledImg
      src={dashboardEmptyStateImg}
      srcSet={dashboardEmptyStateImg2x + ' 2x,' + dashboardEmptyStateImg3x + ' 3x'}
      alt="Dashboard Empty"
    />
  </Wrapper>
);

export default NoWheelsCreated;

const Wrapper = styled.div`
  transform: translateY(30%);
  margin: 0 auto;
`;

const StyledImg = styled.img`
  height: auto;
  width: 100%;
  max-width: 600px;
`;
