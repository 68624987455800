import React, { Component } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Popover, PopoverBody } from 'reactstrap';
import { iWheel, WheelMemberRoleType } from '../../../../API/interfaces';
import { analytics } from '../../../../analytics/analytics';
import { createShareLink } from './utils';

interface iInviteButtonProps extends RouteComponentProps {
  wheel: iWheel;
  role?: WheelMemberRoleType;
  userId?: string;
  onClick?: () => void;
}

interface iInviteButtonState {
  popoverMsg: string | null;
}

class InviteButton extends Component<iInviteButtonProps, iInviteButtonState> {
  readonly state = {
    popoverMsg: null,
  };

  setPopoverMsg = (popoverMsg: string) => this.setState({ popoverMsg });

  togglePopover = (success: boolean) => {
    this.setPopoverMsg(success ? 'Url has been copied' : 'Unable to copy');
    setTimeout(() => this.setPopoverMsg(null), 3000);
  };

  copyFallback = (url: string): Promise<void> =>
    new Promise((resolve, reject) => {
      const textArea = document.createElement('textarea');
      textArea.value = url;
      textArea.style.position = 'fixed';
      textArea.style.top = '0';
      textArea.style.left = '0';
      textArea.style.width = '2em';
      textArea.style.height = '2em';
      textArea.style.padding = '0';
      textArea.style.border = 'none';
      textArea.style.outline = 'none';
      textArea.style.boxShadow = 'none';
      textArea.style.background = 'transparent';

      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();

      const isSuccessful = document.execCommand('copy');
      document.body.removeChild(textArea);

      isSuccessful ? resolve() : reject();
    });

  copyToClipboard = (url: string) => {
    if (navigator.clipboard) {
      navigator.clipboard
        .writeText(url)
        .catch(() => this.copyFallback(url))
        .then(
          () => this.togglePopover(true),
          () => this.togglePopover(false)
        );
    } else {
      this.copyFallback(url).then(
        () => this.togglePopover(true),
        () => this.togglePopover(false)
      );
    }
  };

  share = () => {
    const { wheel, role, userId, onClick } = this.props;
    analytics.shareWheel();
    if (onClick) return onClick();
    else {
      const url = createShareLink(wheel, role, userId);
      this.copyToClipboard(url);
    }
  };

  render() {
    const { popoverMsg } = this.state;

    return (
      <>
        <div onClick={this.share} className="d-inline education_invite" id="invite">
          {this.props.children}
        </div>
        <Popover placement="bottom" isOpen={!!popoverMsg} target="invite" toggle={() => this.setPopoverMsg(null)}>
          <PopoverBody>
            <span>{popoverMsg}</span>
          </PopoverBody>
        </Popover>
      </>
    );
  }
}

export default withRouter(InviteButton);
