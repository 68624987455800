import React from 'react';
import { Checkbox, FormControlLabel, List, ListItem, makeStyles } from '@material-ui/core';
import { CheckBoxOutlineBlank } from '@material-ui/icons';
import { iSegment } from '../../../../API/interfaces';

interface iDefaultSegmentListProps {
  defaultSegments: Array<Partial<iSegment>>;
  segments: Array<Partial<iSegment>>;
  onChangeCheckBox: (segment: Partial<iSegment>) => void;
}

export default ({ defaultSegments, segments, onChangeCheckBox }: iDefaultSegmentListProps) => {
  const classes = useStyles();

  return (
    <List className={classes.defaultSegmentList}>
      {defaultSegments.map((segment) => (
        <ListItem key={segment.name} classes={{ root: classes.defaultSegmentListItem }}>
          <FormControlLabel
            classes={{ root: classes.formControl, label: classes.formControlLabel }}
            control={
              <Checkbox
                icon={<CheckBoxOutlineBlank className={classes.checkboxUnchecked} />}
                checked={segments.indexOf(segment) !== -1}
                onChange={() => onChangeCheckBox(segment)}
                name={segment.name}
                color="primary"
              />
            }
            label={segment.name}
          />
        </ListItem>
      ))}
    </List>
  );
};

const useStyles = makeStyles({
  defaultSegmentList: {
    display: 'flex',
    flexDirection: 'column',
    alignContent: 'space-between',
    flexWrap: 'wrap',
    height: '720px',
    '@media(max-device-width: 768px)': {
      height: '1100px',
    },
  },
  defaultSegmentListItem: {
    marginBottom: '16px',
    width: '30%',
    minHeight: 'initial !important',
    padding: '0 !important',
    '@media(max-device-width: 768px)': {
      width: '50%',
    },
  },
  checkboxUnchecked: {
    stroke: '#fff',
    fill: '#ffd29b',
  },
  formControl: {
    marginBottom: 0,
    alignItems: 'flex-start',
  },
  formControlLabel: {
    marginLeft: '8px',
    color: '#0c2337',
  },
});
