export const LOADING = 'Loading';
export const ALERTING = 'Alerting';
export const RESIZE = 'Resize';

export enum ToastActions {
  ADD_TOAST = 'ADD_TOAST',
  REMOVE_TOAST = 'REMOVE_TOAST',
}

export const SESSION_INCR = 'SESSION_INCR';
export const SHOW_QUESTIONNAIRE = 'SHOW_QUESTIONNAIRE';
