import React from 'react';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ArrowRight from '@material-ui/icons/ArrowRight';
import ArrowLeft from '@material-ui/icons/ArrowLeft';
import { StyledChevron } from '../Sort/_styled';

type tProps = React.ComponentProps<any> & {
  label: JSX.Element;
  direction?: Direction;
  mainMenuOpen: boolean;
  left: boolean;
  MenuItemProps: any;
  onClick: (_) => void;
};

export enum Direction {
  LEFT,
  RIGHT,
}

const highlightColor = '#dddddd';

const NestedMenuItem = (props: tProps) => {
  const { children, direction, mainMenuOpen, onClick } = props;
  const [subMenuOpen, setSubMenuOpen] = React.useState(false);
  const [menuEl, setMenuEl] = React.useState(null);
  const subMenuRef = React.createRef<any>();

  const isSubmenuFocused = React.useCallback(() => {
    // @ts-ignore
    const active = menuEl?.ownerDocument?.activeElement;
    // @ts-ignore
    for (const child of subMenuRef?.current?.children ?? []) {
      if (child === active) {
        return true;
      }
    }
    return false;
  }, [menuEl, subMenuRef]);

  const handleMouseEnter = React.useCallback(
    (e) => {
      e.stopPropagation();
      setSubMenuOpen(true);
      // @ts-ignore
      menuEl.style.backgroundColor = highlightColor;
    },
    [menuEl]
  );

  const handleMouseLeave = React.useCallback(
    (e) => {
      setSubMenuOpen(false);
      // @ts-ignore
      menuEl.style.backgroundColor = 'white';
    },
    [menuEl]
  );

  const handleClick = React.useCallback(
    (event) => {
      onClick && onClick(event);
      event.stopPropagation();
      setSubMenuOpen(subMenuOpen ? false : true);
    },
    [subMenuOpen]
  );

  const handleFocus = React.useCallback(
    (event) => {
      if (event.target === menuEl) {
        setSubMenuOpen(true);
        menuEl.style.backgroundColor = highlightColor;
      }
    },
    [menuEl]
  );

  const handleKeyDown = React.useCallback(
    (event) => {
      const arrowRight = direction === Direction.LEFT ? 'ArrowLeft' : 'ArrowRight';
      const arrowLeft = direction === Direction.LEFT ? 'ArrowRight' : 'ArrowLeft';

      const length = subMenuRef.current?.children.length;
      if (length && length > 0) {
        // When keyboard nav goes out of bounds, wrap around the current menu
        // and prevent parent menu from receiving the key input
        // @ts-ignore
        if (event.target === subMenuRef.current?.children[length - 1] && event.key === 'ArrowDown') {
          event.stopPropagation();
          // @ts-ignore
          subMenuRef.current?.children[0]?.focus();
        } else if (event.target === subMenuRef.current?.children[0] && event.key === 'ArrowUp') {
          event.stopPropagation();
          // @ts-ignore
          subMenuRef.current?.children[length - 1]?.focus();
        } else if (isSubmenuFocused()) {
          event.stopPropagation();
        }
      }
      // Handle arrow key directions behaviour
      if (event.key === arrowRight && !isSubmenuFocused()) {
        if (!subMenuOpen) {
          setSubMenuOpen(true);
        }
        // @ts-ignore
        subMenuRef.current?.children[0]?.focus();
        event.stopPropagation();
      } else if ((event.key === 'ArrowDown' || event.key === 'ArrowUp') && event.target === menuEl) {
        setSubMenuOpen(false);
        menuEl.style.backgroundColor = 'white';
      } else if (event.key === arrowLeft) {
        // @ts-ignore
        menuEl?.focus();
        setSubMenuOpen(false);
      }
    },
    [menuEl, subMenuOpen, subMenuRef, direction]
  );

  return (
    <MenuItem
      ref={(el) => setMenuEl(el)}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={handleClick}
      onFocus={handleFocus}
      tabIndex={-1}
      onKeyDown={handleKeyDown}
      style={{ outline: 'none', overflow: 'hidden' }}
      {...props.MenuItemProps}
    >
      {props.label}
      <StyledChevron />
      {menuEl && (
        <Menu
          style={{ pointerEvents: 'none', overflow: 'none' }}
          onMouseLeave={(evt) => {}}
          anchorEl={menuEl}
          anchorOrigin={{
            vertical: 'top',
            horizontal: direction === Direction.LEFT ? 'left' : 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: direction === Direction.LEFT ? 'right' : 'left',
          }}
          open={subMenuOpen && mainMenuOpen}
          onClose={() => setSubMenuOpen(false)}
          disableAutoFocus
          disableEnforceFocus
          disableRestoreFocus
        >
          <div ref={subMenuRef} style={{ pointerEvents: 'auto' }}>
            {children}
          </div>
        </Menu>
      )}
    </MenuItem>
  );
};

export default NestedMenuItem;
