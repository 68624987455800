import { WheelAccess } from '../../API/interfaces';

export const InsightsActionTypes = {
  LOAD_INSIGHTS: 'LOAD_INSIGHTS',
  START_LOAD: 'START_LOAD',
  CREATE_INSIGHTS_REPLY: 'CREATE_INSIGHTS_REPLY',
  EDIT_INSIGHTS_REPLY: 'EDIT_INSIGHTS_REPLY',
  DELETE_INSIGHTS_REPLY: 'DELETE_INSIGHTS_REPLY',
};

export type InsightsCommentReplyType = {
  id: string;
  userId: string;
  comment: string;
  date: string;
  isEdited: boolean;
  attachment?: string;
};

export type InsightsCommentType = {
  id: string;
  date: string;
  comment: string;
  userId: string;
  score: number;
  wheelId: string;
  segmentId: string;
  replies: InsightsCommentReplyType[];
  attachment?: string;
};

export type InsightsUserType = {
  id: string;
  firstName: string;
  lastName: string;
};

export type InsightsSegmentType = {
  id: string;
  name: string;
};

export type InsightsWheelType = {
  id: string;
  name: string;
  access: WheelAccess;
  isScoreComments: boolean;
};

export type InsightsDataType = {
  periodStart: string;
  periodEnd: string;
  membersAmount: number;
  membersChange: number;
  lowScoresAmount: number;
  lowScoresChange: number;
  teamAlertThreshold: number | null;
  comments: InsightsCommentType[];
  users: InsightsUserType[];
  segments: InsightsSegmentType[];
  wheels: InsightsWheelType[];
};

export type InsightsReducerStateType = InsightsDataType & { isLoading: boolean };

export type InsightsPeriodType = 'week' | 'month';
