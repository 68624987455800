import { ModalBody, ModalFooter, ModalFooterProps, ModalHeader, ModalHeaderProps } from 'reactstrap';
import { ModalBodyProps } from 'reactstrap/es/ModalBody';
import styled from 'styled-components';

import { MainButton, SecondaryButton } from '../../Shared/_styled';
import * as React from 'react';

export const StyledModalHeader = styled<React.ComponentType<ModalHeaderProps>>(ModalHeader)`
  border: none;
  padding: 0;
`;
export const StyledModalBody = styled<React.ComponentType<ModalBodyProps>>(ModalBody)`
  border-radius: 4px;
  border: none;
  padding: 10px 0 24px;
`;
export const StyledModalFooter = styled<React.ComponentType<ModalFooterProps>>(ModalFooter)`
  border: none;
  padding: 0;
`;
export const StyledSecondaryButton = styled(SecondaryButton)`
  font-size: 12px;
  padding: 10px 24px;
`;
export const StyledMainButton = styled(MainButton)`
  font-size: 12px;
  padding: 10px 24px;
`;
// TODO
export const StyledOtherButton = styled(MainButton)`
  border: solid 1px #fd6656;
  background-image: none;
  background: transparent;
  box-shadow: none;
  padding: 10px 24px;
  color: #fb463b;
  border-radius: 20px;
  font-size: 12px;

  &:disabled,
  &[disabled] {
    box-shadow: none;
    background-image: none;
    opacity: 0.5;
    cursor: not-allowed;
  }
`;
export const Text = styled.div`
  margin: 0 0 24px;
  font-family: Rubik;
  font-size: 30px;
  font-weight: normal;
  color: #0c2337;
  line-height: normal;
`;
export const Text1 = styled(Text)`
  font-size: 14px;
  line-height: 1.71;
  color: $dark_grey;
`;
