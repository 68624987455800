import React, { ChangeEvent } from 'react';
import styled from 'styled-components';
import { GrAttachment } from 'react-icons/gr';
import FileUploadPreviewItem from './FileUploadPreviewItem';

type Props = {
  file: File;
  isFullWidth?: boolean;
  onRemove: () => void;
  onEdit: (e: ChangeEvent<HTMLInputElement>) => void;
};

const AddAttachmentText = styled.div`
  margin-left: 5px;
`;

const AttachmentButton = styled.label`
  display: flex;
  align-items: center;
  border: 1px solid #86919a;
  border-radius: 16px;
  background-color: #f8f8f8;
  padding: 2px 8px;
  font-size: 12px;
  cursor: pointer;
  width: fit-content;
`;

const AddCommentAttachment: React.FC<Props> = ({ file, onEdit, onRemove, isFullWidth }) => {
  const availableFileFormats = '.jpg,.jpeg,.png,.pdf';

  return file ? (
    <FileUploadPreviewItem isFullWidth={isFullWidth} key={file.name} file={file} onRemove={onRemove} />
  ) : (
    <AttachmentButton htmlFor="uploadFile">
      <input onChange={onEdit} accept={availableFileFormats} type="file" id="uploadFile" hidden />
      <GrAttachment color="#2b2e31" size="12px" />
      <AddAttachmentText>Add attachment</AddAttachmentText>
    </AttachmentButton>
  );
};

export default AddCommentAttachment;
