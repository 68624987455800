export const tagsList = [
  {
    title: 'entrepreneurship',
    tag: 11,
  },
  {
    title: 'facilitator',
    tag: 12,
  },
  {
    title: 'checkin',
    tag: 13,
  },
  {
    title: 'sport',
    tag: 19,
  },
  {
    title: 'athletics',
    tag: 20,
  },
  {
    title: 'Friendship',
    tag: 21,
  },
  {
    title: 'Relationships',
    tag: 22,
  },
  {
    title: 'Mental Health',
    tag: 24,
  },
  {
    title: 'Coping',
    tag: 25,
  },
  {
    title: 'Forgiveness',
    tag: 26,
  },
  {
    title: 'Mental Wellbeing',
    tag: 27,
  },
  {
    title: 'Friends',
    tag: 28,
  },
  {
    title: 'Bullying',
    tag: 29,
  },
  {
    title: 'School',
    tag: 30,
  },
  {
    title: 'Wellbeing',
    tag: 31,
  },
  {
    title: 'Family',
    tag: 32,
  },
  {
    title: 'Families',
    tag: 34,
  },
  {
    title: 'Everyday Issues',
    tag: 35,
  },
  {
    title: 'Domestic Violence',
    tag: 39,
  },
  {
    title: 'Support',
    tag: 40,
  },
  {
    title: 'Sleep',
    tag: 41,
  },
  {
    title: 'How to',
    tag: 42,
  },
  {
    title: 'Student',
    tag: 43,
  },
  {
    title: 'Healthy Eating',
    tag: 45,
  },
  {
    title: 'Nutrition',
    tag: 47,
  },
  {
    title: 'Exercise',
    tag: 49,
  },
  {
    title: 'Fitness',
    tag: 50,
  },
  {
    title: 'Health',
    tag: 51,
  },
  {
    title: 'Swimming',
    tag: 52,
  },
  {
    title: 'Classroom',
    tag: 53,
  },
  {
    title: 'Teacher Support',
    tag: 54,
  },
  {
    title: 'Drugs and Alcohol',
    tag: 55,
  },
  {
    title: 'Managing Time',
    tag: 56,
  },
  {
    title: 'Goal Setting',
    tag: 57,
  },
  {
    title: 'Stress',
    tag: 59,
  },
  {
    title: 'Exam',
    tag: 60,
  },
  {
    title: 'Study',
    tag: 61,
  },
  {
    title: 'Steps to Success',
    tag: 62,
  },
  {
    title: 'Career',
    tag: 64,
  },
  {
    title: 'Positive Thinking',
    tag: 70,
  },
  {
    title: 'Study Hacks',
    tag: 71,
  },
  {
    title: 'Student Support',
    tag: 72,
  },
  {
    title: 'Safety',
    tag: 74,
  },
  {
    title: 'Self-care',
    tag: 75,
  },
  {
    title: 'Self-awareness',
    tag: 76,
  },
  {
    title: 'Self-esteem',
    tag: 77,
  },
  {
    title: 'Mindfulness',
    tag: 79,
  },
  {
    title: 'Confidence',
    tag: 80,
  },
  {
    title: 'Identity',
    tag: 81,
  },
  {
    title: 'Resilience',
    tag: 82,
  },
  {
    title: 'Growth',
    tag: 84,
  },
  {
    title: 'Passion',
    tag: 86,
  },
  {
    title: 'Hobbies',
    tag: 87,
  },
  {
    title: 'Long term Housing',
    tag: 93,
  },
  {
    title: 'Stability',
    tag: 94,
  },
  {
    title: 'Overcoming homelessness',
    tag: 95,
  },
  {
    title: 'Joris Cuesta',
    tag: 96,
  },
  {
    title: 'iyarn',
    tag: 97,
  },
  {
    title: 'check in',
    tag: 98,
  },
  {
    title: 'professional coaching',
    tag: 99,
  },
  {
    title: 'consulting',
    tag: 100,
  },
  {
    title: 'staff engagement',
    tag: 101,
  },
  {
    title: 'productivity',
    tag: 102,
  },
  {
    title: 'employee performance',
    tag: 103,
  },
  {
    title: 'pulse survey',
    tag: 104,
  },
  {
    title: 'employee wellbeing',
    tag: 105,
  },
  {
    title: 'Lex Dadd',
    tag: 106,
  },
  {
    title: 'community',
    tag: 107,
  },
  {
    title: 'young people',
    tag: 108,
  },
  {
    title: 'connection',
    tag: 109,
  },
  {
    title: 'Sebastian Robertson',
    tag: 110,
  },
  {
    title: 'peer support',
    tag: 111,
  },
  {
    title: 'Phil Jones',
    tag: 112,
  },
  {
    title: 'athletes',
    tag: 113,
  },
  {
    title: 'coaches',
    tag: 114,
  },
  {
    title: 'teams',
    tag: 115,
  },
  {
    title: 'athlete wellbeing',
    tag: 116,
  },
  {
    title: 'paddle Australia',
    tag: 117,
  },
  {
    title: 'Adrian Hondros',
    tag: 118,
  },
  {
    title: 'leaders',
    tag: 120,
  },
  {
    title: 'individuals',
    tag: 121,
  },
  {
    title: 'investor',
    tag: 122,
  },
  {
    title: 'empowering leaders',
    tag: 123,
  },
  {
    title: 'Lynne Strong',
    tag: 124,
  },
  {
    title: 'regional Australia',
    tag: 125,
  },
  {
    title: 'Richard Fox',
    tag: 126,
  },
  {
    title: 'high performance sport',
    tag: 127,
  },
  {
    title: 'performance',
    tag: 128,
  },
  {
    title: 'Wheel of Life',
    tag: 129,
  },
  {
    title: 'Financial',
    tag: 130,
  },
  {
    title: 'Spiritual',
    tag: 131,
  },
  {
    title: 'Physical health',
    tag: 132,
  },
  {
    title: 'Intellectual',
    tag: 133,
  },
  {
    title: 'Social',
    tag: 134,
  },
  {
    title: 'Personal growth',
    tag: 135,
  },
  {
    title: 'Personal development',
    tag: 136,
  },
  {
    title: 'atomic habits',
    tag: 137,
  },
  {
    title: 'listening',
    tag: 138,
  },
  {
    title: 'Are You Ok?',
    tag: 139,
  },
  {
    title: 'Are you ok',
    tag: 140,
  },
  {
    title: 'mental health signs',
    tag: 141,
  },
  {
    title: 'student wellbeing',
    tag: 142,
  },
  {
    title: 'student engagement',
    tag: 143,
  },
  {
    title: 'workplace',
    tag: 144,
  },
  {
    title: 'R U OK? Day',
    tag: 146,
  },
  {
    title: 'R U OK? Day 2022',
    tag: 147,
  },
  {
    title: 'Mentally friendly workplace',
    tag: 148,
  },
  {
    title: 'Employee engagement',
    tag: 149,
  },
  {
    title: 'Check in app',
    tag: 150,
  },
  {
    title: 'student performance',
    tag: 153,
  },
  {
    title: 'meditation',
    tag: 154,
  },
  {
    title: 'sleep quality',
    tag: 155,
  },
  {
    title: 'schools',
    tag: 160,
  },
  {
    title: 'education',
    tag: 161,
  },
  {
    title: 'healthy habits',
    tag: 163,
  },
  {
    title: 'healthy lifestyle',
    tag: 164,
  },
  {
    title: 'research',
    tag: 165,
  },
  {
    title: 'comfort zone',
    tag: 166,
  },
  {
    title: 'professional development',
    tag: 167,
  },
  {
    title: 'relaxation',
    tag: 168,
  },
  {
    title: 'reflection',
    tag: 169,
  },
  {
    title: 'the power of now',
    tag: 170,
  },
  {
    title: 'mindful',
    tag: 171,
  },
  {
    title: 'communication',
    tag: 172,
  },
  {
    title: 'respect',
    tag: 173,
  },
  {
    title: 'employee support',
    tag: 174,
  },
  {
    title: 'employee productivity',
    tag: 175,
  },
  {
    title: 'stress management',
    tag: 176,
  },
  {
    title: 'emotional intelligence',
    tag: 177,
  },
  {
    title: 'nourish',
    tag: 178,
  },
  {
    title: 'healthy',
    tag: 179,
  },
  {
    title: 'eating',
    tag: 180,
  },
  {
    title: 'self care',
    tag: 181,
  },
  {
    title: 'Running',
    tag: 182,
  },
  {
    title: 'Recovery',
    tag: 183,
  },
  {
    title: '7WeekChallenge',
    tag: 184,
  },
  {
    title: 'MoneySmart',
    tag: 185,
  },
  {
    title: 'Budget',
    tag: 186,
  },
  {
    title: 'Financial Habits',
    tag: 187,
  },
  {
    title: 'Money',
    tag: 188,
  },
  {
    title: 'vision',
    tag: 189,
  },
  {
    title: 'Strategy',
    tag: 190,
  },
  {
    title: 'exam performance',
    tag: 191,
  },
  {
    title: 'brain health',
    tag: 192,
  },
  {
    title: 'brain function',
    tag: 193,
  },
  {
    title: 'sleep hygiene',
    tag: 194,
  },
  {
    title: 'anxiety',
    tag: 195,
  },
  {
    title: 'remote working',
    tag: 196,
  },
  {
    title: 'working from home',
    tag: 197,
  },
  {
    title: 'distractions',
    tag: 198,
  },
  {
    title: 'employee retention',
    tag: 199,
  },
  {
    title: 'workplace autonomy',
    tag: 200,
  },
  {
    title: 'evidence base',
    tag: 201,
  },
  {
    title: 'evidence',
    tag: 202,
  },
  {
    title: 'UTS',
    tag: 203,
  },
  {
    title: 'case management',
    tag: 212,
  },
  {
    title: 'wunan',
    tag: 213,
  },
  {
    title: 'driver training',
    tag: 214,
  },
  {
    title: 'motor driver&#039;s licence',
    tag: 215,
  },
  {
    title: 'managing stress',
    tag: 220,
  },
  {
    title: 'setting boundaries',
    tag: 221,
  },
  {
    title: 'World Health Day',
    tag: 222,
  },
  {
    title: 'Holistic Health',
    tag: 223,
  },
  {
    title: 'Physical Wellbeing',
    tag: 224,
  },
  {
    title: 'Workout Plan',
    tag: 225,
  },
  {
    title: 'Meditation Practice',
    tag: 226,
  },
  {
    title: 'Health and Wellbeing',
    tag: 227,
  },
  {
    title: 'ISO 27001',
    tag: 228,
  },
  {
    title: 'Privacy',
    tag: 229,
  },
  {
    title: 'Security',
    tag: 230,
  },
  {
    title: 'psychological health and safety',
    tag: 231,
  },
  {
    title: 'Tim Winton',
    tag: 232,
  },
  {
    title: 'WA World Ocean Day',
    tag: 233,
  },
  {
    title: 'World Ocean Day 2023',
    tag: 234,
  },
  {
    title: 'Ningaloo',
    tag: 235,
  },
  {
    title: 'ABC',
    tag: 236,
  },
  {
    title: 'Student Emotions',
    tag: 246,
  },
  {
    title: 'primary school',
    tag: 247,
  },
  {
    title: 'primary school resource',
    tag: 248,
  },
  {
    title: 'Primary School Students',
    tag: 250,
  },
  {
    title: 'self-worth',
    tag: 253,
  },
  {
    title: 'happiness',
    tag: 254,
  },
  {
    title: 'teacher wellbeing',
    tag: 255,
  },
  {
    title: 'teachers',
    tag: 256,
  },
  {
    title: 'work',
    tag: 257,
  },
  {
    title: 'teaching resource',
    tag: 258,
  },
  {
    title: 'youth',
    tag: 259,
  },
  {
    title: 'teacher',
    tag: 260,
  },
  {
    title: 'mindfullness',
    tag: 261,
  },
  {
    title: 'teachers support',
    tag: 262,
  },
  {
    title: 'Social Services',
    tag: 263,
  },
  {
    title: 'Holidays',
    tag: 264,
  },
  {
    title: 'Service',
    tag: 265,
  },
  {
    title: 'Debt',
    tag: 266,
  },
  {
    title: 'Philanthropy',
    tag: 267,
  },
  {
    title: 'Savings',
    tag: 268,
  },
  {
    title: 'Leadership',
    tag: 269,
  },
  {
    title: 'NFP',
    tag: 270,
  },
  {
    title: 'Gym',
    tag: 271,
  },
  {
    title: 'Job',
    tag: 272,
  },
  {
    title: 'Pets',
    tag: 273,
  },
  {
    title: 'Children',
    tag: 274,
  },
  {
    title: 'Romance',
    tag: 275,
  },
  {
    title: 'Energy',
    tag: 276,
  },
  {
    title: 'Volunteering',
    tag: 277,
  },
  {
    title: 'Environment',
    tag: 278,
  },
  {
    title: 'Unity',
    tag: 279,
  },
  {
    title: 'Sex',
    tag: 280,
  },
  {
    title: 'Social Groups',
    tag: 281,
  },
  {
    title: 'Teamwork',
    tag: 282,
  },
  {
    title: 'Challenge',
    tag: 283,
  },
  {
    title: 'Development',
    tag: 284,
  },
  {
    title: 'Giving',
    tag: 285,
  },
  {
    title: 'Love',
    tag: 286,
  },
  {
    title: 'Culture',
    tag: 287,
  },
  {
    title: 'People',
    tag: 288,
  },
  {
    title: 'Spirituality',
    tag: 289,
  },
  {
    title: 'Contribution',
    tag: 290,
  },
  {
    title: 'Trust',
    tag: 291,
  },
  {
    title: 'Family & Friends',
    tag: 292,
  },
  {
    title: 'Friends & Family',
    tag: 293,
  },
  {
    title: 'Health & Fitness',
    tag: 294,
  },
  {
    title: 'Activity',
    tag: 295,
  },
  {
    title: 'Physical Activity',
    tag: 296,
  },
  {
    title: 'Fun & Recreation',
    tag: 297,
  },
  {
    title: 'Gratitude',
    tag: 298,
  },
  {
    title: 'Finance & Money',
    tag: 299,
  },
  {
    title: 'Finances',
    tag: 300,
  },
  {
    title: 'fa',
    tag: 301,
  },
  {
    title: 'Finances',
    tag: 303,
  },
];
