import styled from 'styled-components';

const Typography = styled.span<any>`
  font-family: Rubik, sans-serif;
  font-weight: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #2b2e31;
  display: ${(props) => (props.display ? props.display : 'inline')};
`;

export const Text = styled(Typography)<{
  size?: string;
  weight?: string;
  lineHeight?: string;
  margin?: string;
  color?: string;
  opacity?: number | string;
  whiteSpace?: string;
  mSize?: string;
  mWeight?: string;
}>`
  font-size: ${(props) => props.size};
  font-weight: ${(props) => props.weight};
  line-height: ${(props) => props.lineHeight};
  margin: ${(props) => props.margin};
  color: ${(props) => props.color};
  opacity: ${(props) => props.opacity};
  vertical-align: middle;
  white-space: ${(props) => props.whiteSpace};
  @media (max-width: 1024px) {
    font-size: ${(props) => (props.mSize ? props.mSize : props.size)};
    font-weight: ${(props) => (props.mWeight ? props.mWeight : props.weight)};
  }
`;

Text.defaultProps = {
  size: '16px',
  fontWeight: 'normal',
  lineHeight: 1.5,
  color: '#2b2e31',
  opacity: 1,
  whiteSpace: 'normal',
  lineBreak: 'anywhere',
};

export const ErrorText = styled(Typography)`
  font-size: 12px;
  color: #e63d3d;
`;

export const Title = styled(Typography)`
  font-size: 48px;
  font-weight: bold;
`;
