import styled from 'styled-components';

export const Section = styled.div<any>`
  width: ${(props) => props.width};
  display: flex;
  align-items: center;

  @media (max-width: 1024px) {
    width: ${(props) => (props.mWidth ? props.mWidth : props.width)};
  }
`;
