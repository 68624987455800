import styled from 'styled-components';
import { Text } from '../Shared/Typography/Typography';
import { useEffect } from 'react';
import { FiArrowLeft } from 'react-icons/fi';
import { GRADIENTS } from '../Shared/colors';

export const ResourcesArticle = ({ article, back, index = 0 }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <ArticleContainer>
      <Back onClick={back}>
        <FiArrowLeft className="mr-1" size={18}></FiArrowLeft>
        <Text size="16px" lineHeight={1}>
          Back
        </Text>
      </Back>
      <ArticleWrapper>
        <div className="position-relative">
          <ArticleImage src={article.image}></ArticleImage>
          {article?.tag && <SegmentPill index={index}>{article.tag}</SegmentPill>}
        </div>
        <div className="mt-3">
          <Text size="48px" mSize="24px" weight="bold">
            {article.title}
          </Text>
        </div>
        <div className="mt-3">
          <Text size="21px" color="#2b2e31">
            {article.description}
          </Text>
        </div>
        <div className="mt-3 mb-4">
          <Text size="14px" color="#2b2e31">
            {article.date}
          </Text>
        </div>
        <div dangerouslySetInnerHTML={{ __html: article.content }}></div>
      </ArticleWrapper>
    </ArticleContainer>
  );
};

const Back = styled.div`
  cursor: pointer;
  position: absolute;
  top: 50px;
  left: 60px;
  display: flex;
  align-items: center;
  @media (max-width: 768px) {
    top: 20px;
    left: 20px;
  }
`;

const ArticleImage = styled.img`
  width: 100%;
  position: relative;
`;

const ArticleContainer = styled.div`
  display: flex;
  justify-content: center;
  @media (max-width: 768px) {
    padding-top: 40px;
  }
`;

const ArticleWrapper = styled.div`
  max-width: 680px;
  @media (max-width: 768px) {
    max-width: 100%;
    overflow: hidden;
  }
`;

const SegmentPill = styled.div<any>`
  position: absolute;
  top: 24px;
  left: 24px;
  font-size: 14px;
  line-height: 20px;
  padding: 6px 12px;
  border-radius: 16px;
  color: #ffffff;
  background-image: ${(props) => {
    if (props.index !== null) {
      const gradientIndex = props.index % GRADIENTS.length;
      return `linear-gradient(116deg, ${GRADIENTS[gradientIndex][0]}, ${GRADIENTS[gradientIndex][1]} 87%)`;
    }
  }};
`;
