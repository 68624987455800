import React, { useState } from 'react';
import { Button } from '../../../Shared/Buttons/Button';
import { BlackBtn } from '../../../Auth/Onboarding/_styled';
import { RadioButton } from '../../../Shared/RadioButton';
import { Text } from '../../../Shared/Typography/Typography';

const DownloadSample = ({ onDownloadComplete, onCancel }) => {
  const [fileType, setFileType] = useState('excel');

  const handleDownloadSample = () => {
    const link = document.createElement('a');
    if (fileType === 'csv') {
      link.href = require('../../../../Assets/files/iyarn_sample_csv.csv');
      link.download = 'sample_csv.csv';
    } else {
      link.href = require('../../../../Assets/files/iyarn_sample_excel.xlsx');
      link.download = 'sample_excel.xlsx';
    }
    link.click();
    onDownloadComplete();
  };

  return (
    <div className="d-flex flex-column">
      <Text size="14px" className="mb-3">
        Step 1/3
      </Text>
      <Text size="30px" className="mb-3">
        Download a Sample Table
      </Text>
      <Text size="16px">Download the sample, fill it in with your data, and upload the completed table back.</Text>
      <div className="d-flex flex-column mt-4">
        <RadioButton
          isBoldSelected
          textSize="16px"
          value="excel"
          selected={fileType}
          text="Excel Format"
          onChange={() => setFileType('excel')}
        />
        <RadioButton
          isBoldSelected
          textSize="16px"
          value="csv"
          selected={fileType}
          text="Csv Format"
          onChange={() => setFileType('csv')}
        />
      </div>

      <div className="d-flex justify-content-between">
        <BlackBtn onClick={onDownloadComplete} className=" mt-4">
          Skip download
        </BlackBtn>
        <div className="d-flex justify-content-end mt-4" style={{ gap: '15px' }}>
          <BlackBtn onClick={onCancel}>Cancel</BlackBtn>
          <Button onClick={handleDownloadSample}>DOWNLOAD</Button>
        </div>
      </div>
    </div>
  );
};

export default DownloadSample;
