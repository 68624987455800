import { takeEvery, call } from 'redux-saga/effects';

import { FolderActions, WheelActions } from './Dashboard.actions';

export function* watchFetchFolders() {
  yield takeEvery(FolderActions.LOAD_FOLDER, loadFolders);
}

// TODO move fetch data to sagas
function* loadFolders() {
  // const folders = yield call()
}
