import React, { useState } from 'react';
import { connect } from 'react-redux';
import { iUser } from '../../API/interfaces';
import { ManageContainer } from '../_Containers/ManageContainer';
import './Settings.scss';
import { TabContainer, Tab } from '../Shared/TabContainer/TabContainer';
import Profile from './Profile';
import { RootState } from '../../Redux/Reducers';
import Account from './Account';
import Goal from './Goal';

interface iSettingsProps {
  user: iUser;
}

enum SettingsTabs {
  profile = 'Profile',
  account = 'Account Settings',
  goal = 'Goal Settings',
}

const Settings = ({ user }: iSettingsProps) => {
  const [activeTab, setActiveTab]: [SettingsTabs, (value) => void] = useState(SettingsTabs.profile);

  const onChangeTab = (index: number, selectedTabName: SettingsTabs) => setActiveTab(selectedTabName);

  return (
    <ManageContainer>
      <div className="settings">
        <div className="settings-container">
          <TabContainer onChangeTab={onChangeTab} defaultTabIndex={0}>
            <Tab title={SettingsTabs.profile}>
              <Profile />
            </Tab>
            <Tab title={SettingsTabs.account}>
              <Account />
            </Tab>
            <Tab title={SettingsTabs.goal}>
              <Goal />
            </Tab>
          </TabContainer>
        </div>
      </div>
    </ManageContainer>
  );
};

export default connect((state: RootState) => ({ user: state.userRD.user }))(Settings);
