import styled from 'styled-components';

import { ICON_SIZE } from '../_consts';

import { Chevron } from './Chevron';
import { SortIcon } from './SortIcon';

export const StyledChevron = styled(Chevron)`
  position: absolute;
  right: 10px;
  height: 1em;
  width: 1em;
  font-size: 1.5rem;
  fill: #b1b1b1;
`;
export const StyledSortIcon = styled(SortIcon)`
  position: absolute;
  top: calc(50% - ${ICON_SIZE} / 2);
  left: calc(${ICON_SIZE} / 2);
`;
