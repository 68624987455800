import React, { Component } from 'react';
import * as _ from 'lodash';
import services from '../../../API/services';
import { toasterService } from '../../Shared/Toaster/Toaster.service';
import utils from '../../Shared/utils';
import Alert from '../Alert/Alert';
import { TextField } from '@material-ui/core';
import eye from '../../../Assets/images/onboarding/eye.svg';
import eyeOff from '../../../Assets/images/onboarding/eye-off.svg';
import { BlackBtn, RedBtn } from '../../Auth/Onboarding/_styled';

interface iPasswordFormProps {}

interface iPasswordFormState {
  schema: any;
  data: { pass: string; newPass: string; confirmPass: string };
  errors: { pass?: string; newPass?: string; confirmPass?: string };
  touched: { pass: boolean; newPass: boolean; confirmPass: boolean };
  alertMsg: string | null;
  showPassword: { pass: boolean; newPass: boolean; confirmPass: boolean };
  isCurrentPassValid: boolean;
}

class PasswordForm extends Component<iPasswordFormProps, iPasswordFormState> {
  readonly state = {
    schema: utils.getFormValidationSchema('password'),
    data: {
      pass: '',
      newPass: '',
      confirmPass: '',
    },
    errors: {},
    touched: {
      pass: false,
      newPass: false,
      confirmPass: false,
    },
    alertMsg: null,
    showPassword: {
      pass: false,
      newPass: false,
      confirmPass: false,
    },
    isCurrentPassValid: false,
  };

  handleBlur = (event, fieldName) => {
    this.setState({
      touched: { ...this.state.touched, [fieldName]: true },
    });
  };

  handleSave = async (e) => {
    e.preventDefault();
    await utils.submittingGuard(e, async () => {
      const { data } = this.state;

      const response = await services.changePassword(data);

      if (response && response.status === 200) {
        this.setState({ alertMsg: 'Your password has been updated.' });
      } else {
        toasterService.addErrorToast(response.data.message);
      }
    });
  };

  handleValueChange = (event, fieldName) => {
    const { schema, data, touched } = this.state;
    const newErrors = utils.validateFields(schema, { ...data, [fieldName]: event.target.value });

    this.setState({
      data: { ...data, [fieldName]: event.target.value },
      touched: { ...touched, [fieldName]: true },
      errors: newErrors ? newErrors : {},
    });
  };

  hideMessage = () => {
    this.setState({ alertMsg: null });
  };

  handleClickShowPassword = (fieldName) => {
    this.setState((prevState) => ({
      showPassword: { ...prevState.showPassword, [fieldName]: !prevState.showPassword[fieldName] },
    }));
  };

  handleReset = (e) => {
    e.preventDefault();
    this.setState({
      data: {
        pass: '',
        newPass: '',
        confirmPass: '',
      },
      errors: {},
      touched: {
        pass: false,
        newPass: false,
        confirmPass: false,
      },
      alertMsg: null,
      showPassword: {
        pass: false,
        newPass: false,
        confirmPass: false,
      },
      isCurrentPassValid: false,
    });
  };

  generateInput = ([id, fieldName, label, placeholder]: string[]) => {
    const { data, errors, showPassword } = this.state;
    return (
      <div className="form-group mb-px-2 w-100" key={id}>
        <div className="row align-items-center">
          <TextField
            id={id}
            label={label}
            placeholder={placeholder}
            type={showPassword[fieldName] ? 'text' : 'password'}
            variant="outlined"
            value={data[fieldName]}
            onChange={(e) => this.handleValueChange(e, fieldName)}
            onBlur={(e) => this.handleBlur(e, fieldName)}
            error={!!errors[fieldName]}
            helperText={errors[fieldName]}
            InputProps={{
              endAdornment: showPassword[fieldName] ? (
                <img
                  style={{ cursor: 'pointer', padding: '0 10px' }}
                  src={eye}
                  onClick={() => this.handleClickShowPassword(fieldName)}
                />
              ) : (
                <img
                  style={{ cursor: 'pointer', padding: '0 10px' }}
                  src={eyeOff}
                  onClick={() => this.handleClickShowPassword(fieldName)}
                />
              ),
            }}
          />
        </div>
      </div>
    );
  };

  generateInputs = () =>
    [
      ['pass', 'pass', 'Current password', 'Enter current password'],
      ['newPass', 'newPass', 'New password', 'Enter new password'],
      ['confirmPass', 'confirmPass', 'Confirm new password', 'Confirm new password'],
    ].map((options) => this.generateInput(options));

  render() {
    const { alertMsg, data, errors } = this.state;

    const isNotEmptyInputs = !_.isEmpty(data.pass) && !_.isEmpty(data.newPass) && !_.isEmpty(data.confirmPass);
    const isResetEnabled = data.pass !== '' || data.newPass !== '' || data.confirmPass !== '';

    return (
      <div className="settings-form">
        <div className="row">
          <div className="col-12">
            {alertMsg && <Alert alertType="success" text={alertMsg} handleClose={this.hideMessage} />}
          </div>
        </div>
        <form onSubmit={this.handleSave} style={{ marginRight: '40px' }}>
          {this.generateInputs()}
          {_.isEmpty(errors) && isNotEmptyInputs && (
            <div className="d-flex justify-content-end">
              <RedBtn
                type="submit"
                style={{
                  marginRight: '10px',
                }}
              >
                Update password
              </RedBtn>
              <BlackBtn type="button" onClick={this.handleReset} disabled={!isResetEnabled}>
                Don't save
              </BlackBtn>
            </div>
          )}
        </form>
      </div>
    );
  }
}

export default PasswordForm;
