import { withStyles } from '@material-ui/core';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import { ChevronDown } from 'react-feather';

export const CommentAccordion = ({ dateRange, numberOfComments, children, defaultExpanded, expandAll }) => {
  return (
    <Accordion key={dateRange + expandAll} TransitionProps={{ unmountOnExit: true }} defaultExpanded={defaultExpanded}>
      <AccordionSummary expandIcon={<ChevronDown size={16} />}>
        <span>{dateRange}</span>
        <span style={{ marginLeft: '5px', color: '#6a777f' }}>
          {`(${numberOfComments} comment${numberOfComments > 1 ? 's' : ''})`}
        </span>
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </Accordion>
  );
};

const Accordion = withStyles({
  root: {
    borderRadius: '4px',
    backgroundColor: '#ededed',
    boxShadow: 'none',
    margin: '0',
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      backgroundColor: '#f8f8f8',
      margin: '0',
    },
  },
  expanded: {
    backgroundColor: '#f8f8f8',
  },
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    minHeight: '40px',
    backgroundColor: 'transparent',
    padding: '0 24px',
    '&$expanded': {
      minHeight: '40px',
      borderBottom: '1px solid rgba(0, 0, 0, .125)',
    },
  },
  content: {
    margin: '8px 0',
    fontSize: '12px',
    color: '#2b2e31',
    '&$expanded': {
      margin: '8px 0',
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles({
  root: {
    flexDirection: 'column',
    padding: '0',
  },
})(MuiAccordionDetails);
