import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { makeStyles } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { iUser } from '../../API/interfaces';
import services from '../../API/services';
import { toasterService } from '../Shared/Toaster/Toaster.service';
import { loadingChange } from '../../Redux/Actions/Actions';
import { ManageContainer } from '../_Containers/ManageContainer';
import { Button, RedBorderButton } from '../Shared/Buttons/Button';
import Feature from './Feature/Feature';
import FEATURES from './Features';
import { analytics } from '../../analytics/analytics';
import { showInfoModal } from '../Shared/InfoModal/InfoModal';

interface iFeaturePageProps extends RouteComponentProps {
  user: iUser;
  setIsLoading: (isLoading: boolean) => void;
}

const FeaturesPage = ({ user, location, setIsLoading }: iFeaturePageProps) => {
  const classes = useStyles();
  const requestedFrom = new URLSearchParams(location.search).get('rf') as
    | 'page-header'
    | 'user-menu'
    | 'generate-report'
    | 'members'
    | 'pdf-report';

  const sendRequest = async () => {
    setIsLoading(true);
    try {
      await services.sendDemoRequest({
        email: user.email,
        firstName: user.first_name,
        lastName: user.last_name,
        requestedFrom: requestedFrom ? requestedFrom.replace('-', ' ') : 'direct url input',
      });
    } catch (e: any) {
      toasterService.addErrorToast(e.message);
      return;
    }

    setIsLoading(false);
    showInfoModal({
      title: 'Request sent!',
      description: 'One of our team will contact you shortly via email.',
    });
    analytics.requestDemo(requestedFrom);
  };

  return (
    <ManageContainer className={classes.manageContainer}>
      <Box className={classes.root}>
        <Grid container spacing={2} justify="center" direction="column">
          <Grid item xs={12}>
            <Typography className={classes.title} variant={'h1'}>
              Features
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Grid container alignItems="center" justify="space-between" className={classes.header}>
              <Grid item md={9} xs={12} className={classes.secondaryText}>
                iyarn has additional features for upgraded accounts. These features help you and your team connect and
                perform to a high level!
              </Grid>
              <Grid item md={2} xs={12} className={classes.buttonContainer}>
                <Button className={classes.button} onClick={sendRequest}>
                  REQUEST DEMO
                </Button>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} className={classes.featuresContainer}>
            <Grid container direction="column" spacing={3}>
              {FEATURES.map((f) => (
                <Grid key={f.type} item xs={12}>
                  <Feature title={f.title} description={f.description} image={f.image} />
                </Grid>
              ))}
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Grid container alignItems="center" justify="space-between" className={classes.footer}>
              <Grid item md={9} xs={12} className={classes.primaryText}>
                Take the next step with your team. Contact us for a demonstration and to complete the upgrading process.
              </Grid>
              <Grid item md={2} xs={12} className={classes.buttonContainer}>
                <RedBorderButton className={classes.button} onClick={sendRequest}>
                  REQUEST DEMO
                </RedBorderButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </ManageContainer>
  );
};

const useStyles = makeStyles({
  manageContainer: {
    backgroundColor: '#f8f8f7',
  },
  root: {
    width: '1032px',
    margin: '48px auto',
    '@media(max-device-width: 1024px)': {
      width: '800px',
    },
    '@media(max-device-width: 768px)': {
      width: '90%',
    },
  },
  title: {
    fontSize: '40px',
    fontWeight: 'bold',
    lineHeight: '1.2',
    letterSpacing: 'normal',
    color: '#2b2e31',
    '@media(max-device-width: 768px)': {
      fontSize: '24px',
    },
  },
  header: {
    '@media(max-device-width: 768px)': {
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
  },
  primaryText: {
    fontSize: '18px',
    fontWeight: 500,
    lineHeight: '1.33',
    color: '#2b2e31',
    '@media(max-device-width: 768px)': {
      fontSize: '14px',
    },
  },
  secondaryText: {
    fontSize: '16px',
    lineHeight: '1.5',
    color: '#6a777f',
    '@media(max-device-width: 768px)': {
      fontSize: '12px',
    },
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    '@media(max-device-width: 768px)': {
      padding: '8px',
      alignSelf: 'flex-end',
    },
  },
  button: {
    height: '56px',
    lineHeight: '56px',
    borderRadius: '32px !important',
    boxShadow: 'none !important',
  },
  featuresContainer: {
    margin: '20px 0',
  },
  footer: {
    padding: '32px 26px 32px 40px',
    backgroundColor: '#efefef',
    '@media(max-device-width: 768px)': {
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
  },
});

const mapStateToProps = (state) => ({
  user: state.userRD.user || {},
});

export default connect(mapStateToProps, { setIsLoading: loadingChange })(FeaturesPage);
