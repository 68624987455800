import React from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core';
import { X } from 'react-feather';
import { toggleQuestionnaire } from '../../../Redux/Actions/Actions';

interface iQuestionnaireTriggerProps {
  onClick: () => void;
  toggleQuestionnaire: (show: boolean) => void;
}

const QuestionnaireTrigger = ({ onClick, toggleQuestionnaire }: iQuestionnaireTriggerProps) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div className={classes.text}>
        We're constantly improving the platform for you and we'd love your feedback! We need your input on one simple
        question.
      </div>
      <div className={classes.buttons}>
        <div className={classes.button} onClick={onClick}>
          Yes, I'll Help
        </div>
        <X size={26} color="#ffffff" onClick={() => toggleQuestionnaire(false)} />
      </div>
    </div>
  );
};

const useStyles = makeStyles({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '9px 63px',
    backgroundColor: '#445262',
    '@media(max-device-width: 768px)': {
      padding: '16px',
      flexDirection: 'column',
      alignItems: 'flex-end',
    },
  },
  text: {
    fontSize: '16px',
    fontWeight: 600,
    lineHeight: 1.5,
    color: '#ffffff',
    '@media(max-device-width: 768px)': {
      fontSize: '14px',
      lineHeight: 1.43,
      fontWeight: 'normal',
    },
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    '&>*': {
      cursor: 'pointer',
    },
  },
  button: {
    marginRight: '24px',
    padding: '5px 16px',
    borderRadius: '20px',
    fontSize: '14px',
    lineHeight: 1.44,
    border: 'solid 1px #cececf',
    backgroundColor: '#f8f8f8',
  },
});

export default connect(null, { toggleQuestionnaire })(QuestionnaireTrigger);
