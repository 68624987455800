import React, { useState } from 'react';
import { Dialog, DialogContent, DialogTitle, makeStyles } from '@material-ui/core';
import './InviteToFolderModal.scss';
import { Tab, TabContainer } from '../../Shared/TabContainer/TabContainer';
import { InviteButton } from './InviteButton';
import { WheelMemberRole } from '../../../API/interfaces';
import { X } from 'react-feather';

enum ModalTabs {
  wheelMembers = 'Wheel Members',
  wheelAdmins = 'Wheels Admins',
}

export const InviteToFolderModal = ({ isOpen, onClose, folder }) => {
  const memberInviteLink = `${window.location.origin}/join-folder/${folder.id}`;
  const memberInviteAdminsLink = `${window.location.origin}/join-folder/${folder.id}/admin`;
  const [activeTab, setActiveTab]: [ModalTabs, (value) => void] = useState(ModalTabs.wheelMembers);
  const classes = useStyles();

  const onChangeTab = (index: number, selectedTabName: ModalTabs) => setActiveTab(selectedTabName);

  return (
    <Dialog open={isOpen} onClose={onClose} classes={{ paper: classes.dialog }} className="generate-report-modal">
      <X className={classes.closeBtn} size={18} color="#7b858e" onClick={onClose} />
      <DialogTitle classes={{ root: classes.title }}>
        Invite to {folder.parent ? 'subfolder' : 'folder'} {folder.name}
      </DialogTitle>
      <DialogContent classes={{ root: classes.content }}>
        <TabContainer onChangeTab={onChangeTab} defaultTabIndex={0}>
          <Tab title={ModalTabs.wheelMembers} className={classes.tab}>
            <div className={classes.tabContent}>
              <div>
                Invite members to all wheels in the folder in one time, just generate and send the below invitation link
                to them.
              </div>
              <div className="d-flex justify-content-center mt-4">
                <InviteButton link={memberInviteLink} role={WheelMemberRole.MEMBER} />
              </div>
            </div>
          </Tab>
          <Tab title={ModalTabs.wheelAdmins} className={classes.tab}>
            <div className={classes.tabContent}>
              <div>
                Invite admins to all wheels in the folder in one time, just generate and send the below invitation link
                to them.
              </div>
              <div className="d-flex justify-content-center mt-4">
                <InviteButton link={memberInviteAdminsLink} role={WheelMemberRole.ADMIN} />
              </div>
            </div>
          </Tab>
        </TabContainer>
      </DialogContent>
    </Dialog>
  );
};

const useStyles = makeStyles({
  tab: {
    backgroundColor: '#fefaf2',
    width: '50%',
    textAlign: 'center' as any,
  },
  tabContent: {
    padding: '50px 32px 0',
  },
  dialog: {
    padding: '32px 0px 40px',
    backgroundColor: '#fefaf2',
    width: '540px',
  },
  closeBtn: {
    position: 'absolute' as any,
    top: '15px',
    right: '15px',
    cursor: 'pointer',
  },
  title: {
    padding: '0 40px',
    fontSize: '30px',
  },
  content: {
    margin: '0',
    padding: '0',
  },
});
