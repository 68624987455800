import React, { SyntheticEvent, Component } from 'react';
import { analytics } from '../../../analytics/analytics';
import appStoreLogo from '../../../Assets/images/app-store-logo.png';
import playStoreLogo from '../../../Assets/images/google-play-logo.png';
import appStoreQR from '../../../Assets/images/app-store-qr.svg';
import playStoreQR from '../../../Assets/images/google-play-qr.svg';
import { APP_PLATFORMS, APP_STORE_URL, PLAY_STORE_URL } from '../../../constants';
import './DownloadApp.scss';

interface iDownloadAppProps {
  isMobile: any;
  description?: string;
}

interface iDownloadState {
  view: APP_PLATFORMS | null;
}

const QR_CODE_WIDTH = 200;

export default class DownloadApp extends Component<iDownloadAppProps, iDownloadState> {
  readonly state: iDownloadState = {
    view: null,
  };

  onClick = (e: SyntheticEvent, platform: APP_PLATFORMS, url: string) => {
    e.preventDefault();

    analytics.downloadApp(platform);

    if (this.props.isMobile) {
      window.open(url, '_blank');
    } else {
      this.setState({ view: platform });
    }
  };

  render() {
    const { view } = this.state;

    return (
      <div className="download-app light-gray">
        {this.props.description || ''}
        {!view && (
          <div className="d-flex justify-content-center">
            <div className="m-3">
              <a href="#" onClick={(e) => this.onClick(e, APP_PLATFORMS.IOS, APP_STORE_URL)}>
                <img src={appStoreLogo} alt="App Store" />
              </a>
            </div>
            <div className="m-3">
              <a href="#" onClick={(e) => this.onClick(e, APP_PLATFORMS.ANDROID, PLAY_STORE_URL)}>
                <img src={playStoreLogo} alt="Play Market" />
              </a>
            </div>
          </div>
        )}
        {view && (
          <div className="d-flex justify-content-around m-5">
            <div className="d-flex flex-column">
              <div>Scan QR-code to download app on your mobile</div>
              <div className="btn-back mt-px-2 align-self-start" onClick={() => this.setState({ view: null })}>
                <i className="fas fa-arrow-left" />
                <span className="ml-px-1">Back</span>
              </div>
            </div>
            <div className="p-3 bg-white">
              {view === APP_PLATFORMS.IOS && <img src={appStoreQR} alt="qr-code" width={QR_CODE_WIDTH} />}
              {view === APP_PLATFORMS.ANDROID && <img src={playStoreQR} alt="qr-code" width={QR_CODE_WIDTH} />}
            </div>
          </div>
        )}
      </div>
    );
  }
}
