export const summaryBadgeTooltipText = {
  members: {
    positive: 'The number of members with a red flag has increased compared to the last period.',
    negative: 'The number of members with a red flag has decreased compared to the last period.',
    stable: 'The number of members with red flags has not changed compared to the last period.',
  },
  lowScores: {
    positive: 'The number of low scores has increased compared to the last period.',
    negative: 'The number of low scores has decreased compared to the last period.',
    stable: 'The number of members with low scores has not changed compared to the last period.',
  },
};
