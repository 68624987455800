import { HttpClient } from './HttpClient';
import { ReminderFrequency } from './interfaces';

export class RemindersClient {
  static updateCheckinReminder(
    wheelId: string,
    payload: {
      frequency?: ReminderFrequency;
      nextDate?: string;
      isEnabled: boolean;
    }
  ) {
    return HttpClient.put(`/wheels/${wheelId}/reminder`, payload).catch((error) => error.response);
  }
}
