import React, { useState } from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import Wheel from '../../../WheelPage/common/Wheel/Wheel';
import { iScore, iSegment } from '../../../../API/interfaces';
import SegmentTags from '../../../Shared/SegmentTags/SegmentTags';
import { MAX_SEGMENTS } from '../../../../constants';

interface iWheelAndSegmentsProps {
  maxScale: number;
  segments: Array<Partial<iSegment>>;
}

const SHOW_LOGO = true;
const formatDescription = (description: string) =>
  description.length > 170 ? description.substring(0, 170).concat('...') : description;

export default ({ maxScale, segments }: iWheelAndSegmentsProps) => {
  const classes = useStyles();
  const [activeSegmentId, setActiveSegmentId] = useState(null);
  const activeSegment = segments.find((s) => s._id === activeSegmentId);
  const scores = segments.map(
    (segment, index) =>
      ({
        segmentId: segment._id,
        segmentName: segment.name,
        description: segment.description,
        score: [9, 6, 7, 8, 5, 7, 9, 7, 5, 9, 8, 6, 8][index % MAX_SEGMENTS] % maxScale,
      } as iScore)
  );

  return (
    <div className={classes.root}>
      <Wheel
        width={180}
        maxScale={maxScale}
        averageScore={null}
        scores={scores}
        activeSegmentId={null}
        showLogo={SHOW_LOGO}
      />

      <div className={classes.segmentTagsWrapper}>
        <SegmentTags
          scores={scores}
          activeSegmentId={activeSegmentId}
          onMouseOver={(segmentId) => setActiveSegmentId(segmentId)}
          onMouseOut={() => setActiveSegmentId(null)}
          hideScores={true}
        />

        {activeSegment && (
          <div className={classes.descriptionWrapper}>
            {activeSegment.description ? (
              <Typography className={classes.description}>{formatDescription(activeSegment.description)}</Typography>
            ) : (
              <Typography className={classes.noDescription}>Doesn't have segment description</Typography>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

const useStyles = makeStyles({
  root: {
    display: 'flex',
    '@media(max-device-width: 768px)': {
      flexDirection: 'column',
      alignItems: 'center',
    },
  },
  segmentTagsWrapper: {
    marginLeft: '48px',
    '@media(max-device-width: 768px)': {
      marginLeft: 0,
      marginTop: '12px',
    },
    '& .segment-tag': {
      marginBottom: '12px',
    },
  },
  descriptionWrapper: {
    marginTop: '16px',
    fontSize: '12px',
  },
  description: {
    lineHeight: 1.43,
    color: '#2b2e31',
  },
  noDescription: {
    lineHeight: 1.67,
    color: '#6a777f',
  },
});
