import styled from 'styled-components';

export const DesktopView = styled.div`
  @media (max-width: 768px) {
    display: none;
  }
`;

export const MobileView = styled.div`
  @media (min-width: 768px) {
    display: none;
  }
`;
