import React from 'react';
import { iFolder, iSegment, iWheel } from '../../../API/interfaces';
import { tStep } from '../Sidebar/Sidebar';
import WheelNameStep from './WheelNameStep/WheelNameStep';
import SegmentsStep from './SegmentsStep/SegmentsStep';
import DescriptionStep from './DescriptionStep/DescriptionStep';
import SettingsStep from './SettingsStep/SettingsStep';
import CompleteStep from './CompleteStep/CompleteStep';
import FolderStep from './FolderStep/FolderStep';

interface iStepProps {
  step: tStep;
  isTemplate: boolean;
  wheel: Partial<iWheel>;
  defaultSegments: Array<iSegment>;
  onChange: (filedName: string, value: any) => void;
  setStep: (step: tStep) => void;
  setStepToEdit: (step: tStep) => void;
  folders?: iFolder[];
}

export default ({
  step,
  wheel,
  onChange,
  defaultSegments,
  setStep,
  setStepToEdit,
  isTemplate,
  folders,
}: iStepProps) => {
  let stepComponent;

  switch (step) {
    case 'Wheel Name':
      stepComponent = (
        <WheelNameStep isTemplate={isTemplate} name={wheel.name} description={wheel.description} onChange={onChange} />
      );
      break;
    case 'Segments':
      stepComponent = (
        <SegmentsStep
          isTemplate={isTemplate}
          segments={wheel.segments}
          defaultSegments={defaultSegments}
          onChange={onChange}
        />
      );
      break;
    case 'Description':
      stepComponent = <DescriptionStep segments={wheel.segments} onChange={onChange} />;
      break;
    case 'Settings':
      stepComponent = (
        <SettingsStep
          isTemplate={isTemplate}
          access={wheel.access}
          step={wheel.step}
          maxScale={wheel.maxScale}
          isScoreComments={wheel.isScoreComments}
          onChange={onChange}
        />
      );
      break;
    case 'Folder':
      stepComponent = (
        <FolderStep
          isTemplate={isTemplate}
          folder={wheel.folder}
          step={wheel.step}
          onChange={onChange}
          folders={folders}
        />
      );
      break;
    case 'Complete':
      stepComponent = (
        <CompleteStep isTemplate={isTemplate} wheel={wheel} setStepToEdit={setStepToEdit} setStep={setStep} />
      );
      break;
    default:
      stepComponent = null;
      break;
  }

  return stepComponent;
};
