import React, { Component, SyntheticEvent } from 'react';
import styled from 'styled-components';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { analytics } from '../../../analytics/analytics';
import { AccountPlan, AccountRole, iUser } from '../../../API/interfaces';
import LockIcon from '../../../Assets/images/icon-lock.png';
import './Navbar.scss';

interface iNavbarProps extends RouteComponentProps {
  user: iUser;
  location: any;
  isPublic: boolean;
}

interface iNavbarState {}

type iLink = {
  url: string;
  name: string;
  callback: (e?: SyntheticEvent) => void;
  isLocked?: boolean;
};

const LockImage = styled.img`
  width: 12px;
  height: 13px;
  margin-left: 4px;

  @media (max-width: 768px) {
    width: 10px;
    height: 10px;
  }
`;

const NavigationTabsContainer = styled.div`
  display: flex;
  align-items: center;
  margin-right: 10px;
  color: #233748;
  height: 100%;
`;

const TabItemContainer = styled.div<{ isActive?: boolean }>`
  color: ${({ isActive }) => (isActive ? '#ff4e4b' : '#233748')};
  border-bottom: 2px solid ${({ isActive }) => (isActive ? '#ff4e4b' : 'transparent')};
  font-size: 15px;
  margin-left: 30px;
  font-weight: 500;
  cursor: pointer;

  @media (max-width: 768px) {
    font-size: 12px;
    margin-left: 10px;
  }

  &:hover {
    color: #ff4e4b;
  }
`;

const LockedTabItemContainer = styled.div<{ isActive?: boolean }>`
  display: flex;
  align-items: center;
  color: #6a777f;
  border-bottom: 2px solid transparent;
  font-size: 15px;
  margin-left: 30px;
  font-weight: 500;
  cursor: pointer;

  @media (max-width: 768px) {
    font-size: 12px;
    margin-left: 10px;
  }
`;

export class Navbar extends Component<iNavbarProps, iNavbarState> {
  getLinks = (): Array<iLink> => {
    const { user, history } = this.props;

    // right now these links appear ONLY on the public wheel page. Change events, if you are adding more public pages
    if (!user?._id) {
      return [
        {
          url: 'login',
          name: 'Log In',
          callback: (e: SyntheticEvent) => {
            e.preventDefault();
            analytics.publicWheelLogin();
            window.open('/login', '_blank');
          },
        },
        {
          url: 'sign-up',
          name: 'Sign Up',
          callback: (e: SyntheticEvent) => {
            e.preventDefault();
            analytics.publicWheelSignUp();
            window.open('/sign-up', '_blank');
          },
        },
      ];
    }

    const isAdmin = user.accountRole === AccountRole.ADMIN;

    const isSchoolPlan = user.account?.plan === AccountPlan.SCHOOL;
    const isPerformancePlan = user.account?.plan === AccountPlan.PERFORMANCE;
    const isFreePlan = !user.account || user.account?.plan === AccountPlan.FREE;

    const isSchoolSuperAdmin = isSchoolPlan && isAdmin;
    const isPerformanceSuperAdmin = isPerformancePlan && isAdmin;

    return [
      isSchoolSuperAdmin
        ? {
            url: 'invitations',
            name: 'Teachers',
            callback: () => {
              analytics.teachersTab();
              history.push('/invitations');
            },
          }
        : null,
      isPerformanceSuperAdmin
        ? {
            url: 'invitations',
            name: 'Team',
            callback: () => {
              analytics.teamMembersTab();
              history.push('/invitations');
            },
          }
        : null,
      {
        url: 'templates',
        name: 'Templates',
        callback: () => {
          analytics.templateTab();
          history.push('/templates');
        },
      },
      {
        url: 'insights',
        name: 'Insights',
        callback: () => {
          analytics.insightsTab();
          isFreePlan ? analytics.clickInsightsFree() : analytics.clickInsightsPaid();
          history.push(isFreePlan ? '/features?rf=user-menu' : '/insight');
        },
        isLocked: isFreePlan,
      },
      {
        url: 'dashboard',
        name: 'Dashboard',
        callback: () => {
          history.push('/dashboard');
        },
      },
    ].filter((_) => _);
  };

  getMappedLink = (link: iLink, pathName: string) => {
    if (link?.isLocked)
      return (
        <LockedTabItemContainer onClick={link.callback} key={`locked-${link.url}`}>
          <span>{link.name}</span>
          <LockImage src={LockIcon} />
        </LockedTabItemContainer>
      );
    return (
      <div className="nav-item" key={link.url}>
        {link.callback && (
          <TabItemContainer onClick={link.callback} isActive={pathName === link.url}>
            {link.name}
          </TabItemContainer>
        )}
      </div>
    );
  };

  render() {
    const { user, location, isPublic } = this.props;
    if (!isPublic && (!user || !user._id)) {
      return null;
    }

    const paths = location.pathname.substr(1).split('/');
    const pathName = paths.length > 0 ? paths[0] : '';

    const links = this.getLinks().map((link) => this.getMappedLink(link, pathName));

    return <NavigationTabsContainer>{links}</NavigationTabsContainer>;
  }
}

export default withRouter(Navbar);
