import React from 'react';

const EmbeddedIframe = ({ url, isLoading, onLoad }) => (
  <iframe
    src={url}
    frameBorder="0"
    allowFullScreen
    style={{
      height: isLoading ? '0px' : '1100px',
      width: '100%',
      display: isLoading ? 'none' : 'block',
    }}
    onLoad={onLoad}
  />
);

export default EmbeddedIframe;
