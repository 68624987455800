import React, { ChangeEvent, Component } from 'react';
import { RemindersClient } from '../../../../API/reminders.client';
import { iUser, iWheel, ReminderFrequency } from '../../../../API/interfaces';
import { Section } from '../../../Shared/Layout/Section';
import { Text } from '../../../Shared/Typography/Typography';
import { CheckinReminderCalendar } from './CheckinReminderCalendar';
import { CheckinReminderFrequency } from './CheckinReminderFrequency';
import { CheckinReminderTime } from './CheckinReminderTime';
import { DateTime } from 'luxon';
import { Button, Checkbox, FormControlLabel, Switch, withStyles } from '@material-ui/core';
import { toasterService } from '../../../Shared/Toaster/Toaster.service';
import { connect } from 'react-redux';
import { MembersClient } from '../../../../API/members.client';
import { FaBellSlash } from 'react-icons/fa';
import { customConfirm } from '../../../Shared/ConfirmModal/ConfirmationModal';
import { analytics } from '../../../../analytics/analytics';
import { Theme } from '@material-ui/core';

const styles = (theme: Theme) => ({
  wrapper: {
    padding: '45px',
    [theme.breakpoints.down('md')]: {
      padding: '16px',
    },
  },
  editorContainer: {
    margin: '24px 0',
    borderRadius: '4px',
    border: 'solid 1px #eaeaea',
    backgroundColor: '#f8f8f8',
    padding: '25px',
    [theme.breakpoints.down('md')]: {
      margin: '15px 0',
      padding: '16px',
    },
  },
  mainSwitcher: {
    margin: 0,
  },
  datePicker: {
    width: '250px',
    display: 'inline-flex',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      marginBottom: '15px',
    },
  },
  at: {
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '50px',
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  timePicker: {
    width: '150px',
    display: 'inline-flex',
    position: 'relative' as const,
    zIndex: 2,
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  frequencyPicker: {
    width: '450px',
    marginTop: '20px',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      marginTop: '15px',
    },
  },
  emptyWrapper: {
    height: '350px',
  },
  emptyIcon: {
    fontSize: '80px',
    color: '#cfd9e5',
    marginBottom: '32px',
    [theme.breakpoints.down('md')]: {
      fontSize: '60px',
    },
  },
  divider: {
    width: '100%',
    height: '1px',
    backgroundColor: '#dfdfdf',
    margin: '20px 0',
  },
});

interface iCheckinReminderAdminProps {
  wheel: iWheel;
  user: iUser;
  classes: any;
}

interface iCheckinReminderAdminState {
  frequency: ReminderFrequency;
  nextDate: string;
  isEnabled: boolean;
  isReminderEnabledForUser: boolean;
  key: number;
  error: boolean;
}

class CheckinReminderAdminComponent extends Component<iCheckinReminderAdminProps, iCheckinReminderAdminState> {
  copy: any;

  constructor(props) {
    super(props);
    this.state = {
      frequency: props.wheel.reminder.frequency,
      nextDate: props.wheel.reminder.nextDate
        ? props.wheel.reminder.nextDate
        : DateTime.local()
            .set({
              minute: 0,
              second: 0,
              millisecond: 0,
            })
            .plus({ hour: 2 })
            .toISO(),
      isEnabled: props.wheel.reminder.isEnabled,
      isReminderEnabledForUser: this.checkIsReminderEnabled(props.wheel, props.user._id),
      key: 1,
      error: false,
    };
    this.copy = { ...this.state };
  }

  checkIsReminderEnabled(wheel, userId) {
    const member = wheel.members.find((member) => member.userId === userId);
    return member?.isReminderEnabled;
  }

  saveReminderConfig = async () => {
    const { isEnabled, frequency, nextDate } = this.state;
    const res = await RemindersClient.updateCheckinReminder(this.props.wheel.id, {
      isEnabled,
      frequency,
      nextDate,
    });
    await MembersClient.updateWheelMember(this.props.wheel.id, this.props.user._id, {
      isReminderEnabled: this.state.isReminderEnabledForUser,
    });
    toasterService.handleResponse(res, 'Check in reminder has been successfully updated');
    this.copy = { ...this.state };
    this.forceUpdate();
  };

  setNewDate = (newDate: DateTime) => {
    const nextDate = DateTime.fromISO(this.state.nextDate)
      .set({
        year: newDate.year,
        month: newDate.month,
        day: newDate.day,
      })
      .toISO();
    this.setState({ nextDate });
  };

  setNewFrequency = (option: ReminderFrequency) => {
    const frequency = option;
    this.setState({ frequency });
  };

  setNewTime = (time: DateTime) => {
    const nextDate = DateTime.fromISO(this.state.nextDate)
      .set({
        hour: time.hour,
        minute: time.minute,
      })
      .toISO();
    this.setState({ nextDate });
  };

  setReminderStatus = async (value: ChangeEvent<HTMLInputElement>) => {
    const isEnabled: boolean = value.target.checked;
    if (isEnabled) {
      analytics.checkinReminderAdminOn();
    } else {
      analytics.checkinReminderAdminOff();
    }
    const res = await RemindersClient.updateCheckinReminder(this.props.wheel.id, {
      isEnabled,
      nextDate: this.state.nextDate,
    });
    const isSuccess = toasterService.handleResponse(res, 'Check in reminder status has been updated successfully');
    if (isSuccess) {
      this.setState({ isEnabled });
    }
  };

  setError = (error: boolean) => {
    this.setState({
      error,
    });
  };

  changeMyReminderSubscription = async (event) => {
    const newStatus = !this.state.isReminderEnabledForUser;
    this.setState({
      isReminderEnabledForUser: newStatus,
    });
  };

  resetConfig = async () => {
    const answer = await customConfirm({
      title: `Are you sure that you don’t wan’t to save changes?`,
      confirmationText: `DON'T SAVE`,
    });
    if (answer) {
      this.copy.key++;
      this.setState({ ...this.copy });
    }
  };

  render() {
    const { classes } = this.props;

    const MainSwitcher = (
      <div>
        <FormControlLabel
          value="start"
          className={classes.mainSwitcher}
          control={
            <Switch size="medium" color="secondary" checked={this.state.isEnabled} onChange={this.setReminderStatus} />
          }
          label={this.state.isEnabled ? 'On' : 'Off'}
          labelPlacement="start"
        />
      </div>
    );

    const Header = (
      <div className="d-block d-lg-flex">
        <Section width="70%" mWidth="100%">
          <div className="d-flex flex-column w-100">
            <div className="d-flex align-items-center justify-content-between mb-2 mb-lg-0">
              <Text size="24px" weight="500" mSize="18px">
                Check in Reminder
              </Text>
              <div className="d-block d-lg-none">{MainSwitcher}</div>
            </div>
            {this.state.isEnabled && (
              <Text color="#86919a" size="14px">
                Regular check ins are easy with reminders. We'll send an email and/or push notification.
              </Text>
            )}
          </div>
        </Section>
        <Section width="30%" className="d-none d-lg-flex justify-content-end align-items-start">
          {MainSwitcher}
        </Section>
      </div>
    );

    const Editor = (
      <div className={classes.editorContainer}>
        <Text size="18px" weight="500">
          Set up time and frequency of the check in reminder
        </Text>
        <div className="mt-4 d-block">
          <div className={classes.datePicker}>
            <CheckinReminderCalendar
              disabled={false}
              activeDate={DateTime.fromISO(this.state.nextDate)}
              setNewDate={this.setNewDate}
            />
          </div>
          <div className={classes.at}>
            <Text size="16px" color="#6a777f;">
              at
            </Text>
          </div>
          <div className={classes.timePicker}>
            <CheckinReminderTime
              disabled={false}
              activeTime={DateTime.fromISO(this.state.nextDate)}
              setNewTime={this.setNewTime}
              handleError={this.setError}
            />
          </div>
        </div>
        <div className={classes.frequencyPicker}>
          <CheckinReminderFrequency
            disabled={false}
            activeFrequency={this.state.frequency}
            setNewFrequency={this.setNewFrequency}
          />
        </div>
        <div className={classes.divider} />
        <div className="mr-2 d-flex align-items-center">
          <FormControlLabel
            value="start"
            control={
              <Checkbox
                checked={this.state.isReminderEnabledForUser}
                onChange={this.changeMyReminderSubscription}
                color="primary"
                className="mr-1"
              />
            }
            label="Remind me too"
            labelPlacement="end"
          />
        </div>
      </div>
    );

    const EmptyState = (
      <div>
        <div className={classes.divider} />
        <div
          className={`${classes.emptyWrapper} d-flex align-items-center justify-content-lg-center justify-content-start flex-column`}
        >
          <FaBellSlash className={classes.emptyIcon} />
          <Text size="18px" mSize="16px" weight="500" className="mb-2">
            Reminders are off.
          </Text>
          <Text color="#86919a" size="12px" className="mt-3 mt-lg-0">
            You're more likely to complete your check ins when you've set a reminder.
          </Text>
        </div>
      </div>
    );

    return (
      <div className={classes.wrapper} key={this.state.key}>
        {Header}
        {this.state.isEnabled ? (
          <div>
            {this.props.wheel?.reminder && Editor}
            <div className="d-flex justify-content-end align-items-center">
              <div className="d-flex">
                <Button color="default" variant="contained" onClick={this.resetConfig}>
                  CANCEL
                </Button>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={this.saveReminderConfig}
                  disabled={
                    this.state.error ||
                    (this.state.frequency === this.copy.frequency &&
                      this.state.nextDate === this.copy.nextDate &&
                      this.state.isEnabled === this.copy.isEnabled &&
                      this.state.isReminderEnabledForUser === this.copy.isReminderEnabledForUser)
                  }
                >
                  SAVE CHANGES
                </Button>
              </div>
            </div>
          </div>
        ) : (
          EmptyState
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.userRD.user,
});

export const CheckinReminderAdmin = withStyles(styles)(connect(mapStateToProps)(CheckinReminderAdminComponent));
