import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core';
import { Plus } from 'react-feather';
import { MAX_SEGMENT_NAME_LENGTH } from '../../../../constants';
import TextFieldWithCounter from '../../../Shared/TextFieldWithCounter/TextFieldWithCounter';

interface iAddNewSegmentProps {
  onAdd: (segmentName: string) => void;
}

const SIZE = 28;

export default ({ onAdd }: iAddNewSegmentProps) => {
  const classes = useStyles();
  const [newSegmentName, setNewSegmentName] = useState('');

  return (
    <div className={classes.textFieldWrapper}>
      <TextFieldWithCounter
        variant="outlined"
        label="Segment Name"
        value={newSegmentName}
        onChange={(e) => setNewSegmentName(e.target.value)}
        maxValueLength={MAX_SEGMENT_NAME_LENGTH}
        rightShift={newSegmentName ? SIZE * 2 : 0}
        required
      />
      {newSegmentName && (
        <div
          className={classes.addNewSegment}
          onClick={() => {
            onAdd(newSegmentName);
            setNewSegmentName('');
          }}
        >
          <Plus size={SIZE} stroke="#fff" />
        </div>
      )}
    </div>
  );
};

const useStyles = makeStyles({
  textFieldWrapper: {
    position: 'relative',
  },
  textField: {
    width: '100%',
    '& .MuiOutlinedInput-root.Mui-focused': {
      backgroundColor: '#f8f8f8',
    },
    '& input': {
      color: '#2b2e31',
    },
  },
  addNewSegment: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    right: 0,
    aspectRatio: '1',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '4px',
    backgroundImage: 'linear-gradient(175deg, #fa8868 3%, #ff554d)',
    cursor: 'pointer',
  },
});
