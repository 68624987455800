import { HttpClient } from './HttpClient';

export class InvitationClient {
  static createInvitations(invitations: Array<{ email: string }>) {
    return HttpClient.post(`/invitations`, invitations).catch((error) => error.response);
  }

  static joinInvitation(invitationId: string) {
    return HttpClient.post(`/invitations/${invitationId}/join`).catch((error) => error.response);
  }

  static getInvitations() {
    return HttpClient.get('/invitations').catch((error) => error.response);
  }

  static getInvitationById(invitationId) {
    return HttpClient.get(`/invitations/${invitationId}`).catch((error) => error.response);
  }

  static deleteInvitation(invitationId: string) {
    return HttpClient.delete(`/invitations/${invitationId}`).catch((error) => error.response);
  }
}
