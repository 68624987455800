import { HttpClient } from './HttpClient';
import { iFolder, tFolder, WheelMemberRole } from './interfaces';

export class FolderClient {
  static async get() {
    return HttpClient.get<iFolder[]>('/folder').catch((error) => error.response);
  }

  static async create(folder: tFolder) {
    return HttpClient.post<string>('/folder', folder).catch((error) => error.response);
  }

  static async duplicate(folder) {
    return HttpClient.post(`/folder/${folder.id}/duplicate`, folder).catch((error) => error.response);
  }

  static async update(folder: Partial<iFolder>) {
    const { id } = folder;
    delete folder.id;
    return HttpClient.put<iFolder>(`/folder/${id}`, folder);
  }

  static async delete(id: string) {
    return HttpClient.delete<any>(`/folder/${id}`).catch((error) => error.response);
  }

  static async addWheel({ folderId, wheelId }: { folderId: string; wheelId: string }) {
    return HttpClient.post<iFolder>(`/folder/${folderId}/wheel/${wheelId}`);
  }

  static async deleteWheel({ folderId, wheelId }: { folderId: string; wheelId: string }) {
    return HttpClient.delete<any>(`/folder/${folderId}/wheel/${wheelId}`);
  }

  static async getFolderById({ folderId }) {
    return HttpClient.get(`/folder/${folderId}`);
  }

  static async joinFolder({ folderId, role }: { folderId: string; role: WheelMemberRole }) {
    return HttpClient.post(`/folder/${folderId}/join/${role}`);
  }
}
