import React from 'react';
import './ExitMessage.scss';

interface iExitMessageProps {
  toggle: (isCheckinCompleted: boolean) => void;
  cancelExit: () => void;
}

export default ({ toggle, cancelExit }: iExitMessageProps) => (
  <div className="exit-message-container">
    <div className="primary-text">Exit without saving</div>
    <div className="secondary-text">
      You will lose all progress for this check in if you exit now. Are you sure you want to exit?
    </div>

    <div className="buttons-container">
      <div className="cancel-button" onClick={cancelExit}>
        CANCEL
      </div>
      <div className="exit-button" onClick={() => toggle(false)}>
        DON'T SAVE AND EXIT
      </div>
    </div>
  </div>
);
