import React, { useState } from 'react';
import { MoreVertical } from 'react-feather';
import { FormControlLabel, makeStyles, Popover, Radio, RadioGroup, Typography } from '@material-ui/core';
import utils from '../../Shared/utils';

interface iCommentFiltersProps {
  onChange: (e) => void;
  value: 'allCheckins' | 'onlyWithText';
}

export default ({ onChange, value }: iCommentFiltersProps) => {
  const isMobile = utils.checkIsPhone();
  if (!isMobile) {
    return <RadioButtons onChange={onChange} value={value} />;
  }

  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const onClick = (e) => setAnchorEl(e.currentTarget);
  const onClose = () => setAnchorEl(null);

  return (
    <>
      <MoreVertical onClick={onClick} />
      <Popover
        classes={{ paper: classes.popover }}
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={onClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Typography className={classes.typography}>Filters</Typography>
        <RadioButtons
          onChange={(e) => {
            onChange(e);
            onClose();
          }}
          value={value}
        />
      </Popover>
    </>
  );
};

const useStyles = makeStyles({
  popover: {
    padding: '8px 16px 8px 8px',
  },
  typography: {
    padding: '5px 12px',
    fontSize: '12px',
    color: '#6a777f',
  },
});

interface iRadioButtonsProps {
  onChange: (e) => void;
  value: 'allCheckins' | 'onlyWithText';
}

const RadioButtons = ({ onChange, value }: iRadioButtonsProps) => {
  const classes = useRadioButtonsStyles({ isMobile: utils.checkIsPhone() });

  return (
    <RadioGroup classes={{ root: classes.radioGroup }} value={value} name="comments-filter" onChange={onChange}>
      <FormControlLabel
        className={classes.formLabel}
        value="allCheckins"
        control={<Radio color="default" />}
        label="All Check ins"
      />
      <FormControlLabel
        className={classes.formLabel}
        value="onlyWithText"
        control={<Radio color="default" />}
        label="Only with comments"
      />
    </RadioGroup>
  );
};

const useRadioButtonsStyles = makeStyles<any, any, any>({
  radioGroup: {
    display: 'flex',
    justifyContent: 'end',
    flexDirection: (props) => (props.isMobile ? 'column' : 'row'),
  },
  formLabel: {
    margin: 0,
  },
});
