import React from 'react';
import { StringParam, useQueryParam } from 'use-query-params';

import { iProps } from './_types';
import {
  StyledModalHeader,
  StyledModalFooter,
  StyledModalBody,
  StyledMainButton,
  StyledSecondaryButton,
  Text,
  Text1,
  StyledOtherButton,
} from './_styled';
import { MAX_FOLDER_NAME_LENGTH } from '../../../constants';
import TextFieldWithCounter from '../../Shared/TextFieldWithCounter/TextFieldWithCounter';

export const CreateFolderDesktop = React.memo<iProps>((props) => {
  const [selectedFolderId] = useQueryParam('folder_id', StringParam);
  const [folderName, setFolderName] = React.useState('');
  const { onCancel, onSubmit } = props;

  const onInputChange = React.useCallback((e) => setFolderName(e.target.value), []);
  const onCreateClick = React.useCallback(() => onSubmit(folderName), [folderName]);

  return (
    <>
      <StyledModalHeader>
        <Text>{selectedFolderId ? 'Create New Subfolder' : 'Create New Folder'}</Text>
      </StyledModalHeader>

      <StyledModalBody>
        <TextFieldWithCounter
          value={folderName}
          onChange={onInputChange}
          label={selectedFolderId ? 'Subfolder Name' : 'Folder Name'}
          variant="outlined"
          maxValueLength={MAX_FOLDER_NAME_LENGTH}
          fullWidth
          required
        />
      </StyledModalBody>

      <StyledModalFooter>
        <StyledSecondaryButton type="button" onClick={onCancel}>
          CANCEL
        </StyledSecondaryButton>
        <StyledOtherButton type="button" disabled={!folderName.length} onClick={onCreateClick}>
          CREATE
        </StyledOtherButton>
      </StyledModalFooter>
    </>
  );
});

export const DeleteFolderDesktop = React.memo<iProps & { name: string }>(({ name, onCancel, onSubmit }) => (
  <>
    <StyledModalHeader>
      <Text className="mb-2">{`Are you sure you want to delete folder ${name}?`}</Text>
    </StyledModalHeader>

    <StyledModalBody className="py-0">
      <Text1>Pay attention all the wheels from this folder going to be moved to “All Wheels” tab.</Text1>
    </StyledModalBody>

    <StyledModalFooter>
      <StyledSecondaryButton type="button" onClick={onCancel}>
        CANCEL
      </StyledSecondaryButton>
      <StyledOtherButton type="button" onClick={onSubmit}>
        DELETE
      </StyledOtherButton>
    </StyledModalFooter>
  </>
));
