import React from 'react';
import './Logo.scss';

interface iLogoProps {
  url: string;
}

export default ({ url }: iLogoProps) => {
  return <div className="client-logo">{url && <img src={url} alt="logo" />}</div>;
};
