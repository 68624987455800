import { useState } from 'react';
import styled from 'styled-components';
import { Modal, ModalBody } from 'reactstrap';
import { Text } from '../../../Shared/Typography/Typography';
import { makeStyles, withStyles } from '@material-ui/core';
import { LineLink } from '../../../Shared/LineLink/LineLink';
import { FiX } from 'react-icons/fi';
import { GRADIENTS } from '../../../Shared/colors';
import { useSelector } from 'react-redux';
import { selectIpAddress } from '../../../Auth/Auth.selectors';
import { analytics } from '../../../../analytics/analytics';
import PopUpResources from './PopUpResources';
import aiIcon from '../../../../Assets/images/AI/AI_24x24.png';
import MuiButton from '@material-ui/core/Button';

interface PostCheckinPopupProps {
  close: () => void;
  segmentScores?: any;
  setTabActive?: (title: any) => void;
  title?: string;
  show?: boolean;
  user?: string;
  onOpenAI?: () => void;
  accountName?: string;
}

export const PostCheckinPopup: React.FC<PostCheckinPopupProps> = ({
  close,
  segmentScores,
  setTabActive,
  title,
  show,
  user,
  onOpenAI,
  accountName,
}) => {
  const classes = useStyles();
  const [isAccordionOpened, openAccordion] = useState(false);
  const userIpAddress = useSelector(selectIpAddress);

  const open = () => {
    openAccordion(true);
  };

  const handleOpenAiClick = (title?: string) => {
    analytics.clickAiSuggestedPopup();
    onOpenAI();
    title && setTabActive(title);
    close();
  };

  const handleButtonClick = (title) => {
    setTabActive(title);
    analytics.openSuggestedResources();
    close();
  };

  const isAustraliaLocation = userIpAddress?.country === 'AU';

  // postcheckin popup for wungening account
  const isWungening = accountName?.toLowerCase() === 'wungening';
  const renderWungeningResources = () => (
    <div className="d-flex justify-content-center mt-3" style={{ gap: '20px' }}>
      <Button
        variant="contained"
        id="suggested-res-btn"
        href="https://wungening.sharepoint.com/:w:/r/sites/WungeningAboriginalCorporation/Document%20Management%20System/Human%20Resources/Policies/Performance%20Management%20Policy.docx?d=w4b34c883b3854cb0b013038bfa324d62&csf=1&web=1&e=3fkDDH"
      >
        Managment Policy
      </Button>
      <Button
        variant="contained"
        id="suggested-res-btn"
        href="https://wungening.sharepoint.com/:w:/r/sites/WungeningAboriginalCorporation/Document%20Management%20System/Human%20Resources/Policies/Line%20Supervision%20Policy.docx?d=w281cc444e09941738e867181524c0251&csf=1&web=1&e=SeKZxU"
      >
        Supervision Policy
      </Button>
      <Button
        variant="contained"
        id="suggested-res-btn"
        href="https://wungening.sharepoint.com/sites/WungeningAboriginalCorporation/SitePages/Human-Resources.aspx"
      >
        EAP Information
      </Button>
    </div>
  );

  return (
    <Modal isOpen={true} toggle={close} className={classes.dialog}>
      <ModalBody className={classes.dialogBody}>
        <FiX className={classes.closeButton} size={18} onClick={close} />
        <div className="mb-2">
          <Text size="30px" color="#0c2337">
            Check In done!
          </Text>
        </div>
        <div className="mb-3">
          <Text size="18px" color="#2b2e31">
            See your iyarn check in results below
          </Text>
        </div>
        {segmentScores && (
          <div>
            <SegmentsWrapper>
              {segmentScores?.map((seg, i) => (
                <SegmentPill index={i} key={i}>
                  {seg.segmentName} {seg.score}
                </SegmentPill>
              ))}
            </SegmentsWrapper>
          </div>
        )}
        <div className="mt-5 mb-2">
          <Text size="18px" color="#2b2e31">
            <b>What Next?</b>
          </Text>
        </div>
        <div className="mb-2">
          <Text size="16px" color="#2b2e31">
            What to do when having a conversation about your iyarn check in:
          </Text>
        </div>
        <div className="mb-4">
          <Text>
            {isWungening ? (
              <>
                1. Share your check in with a trusted person. If you can’t connect now, organise a time to catch up with
                someone you trust.
                <br />
                2. When discussing your results, focus on the strengths and discuss things going well.
                <br />
                3. If appropriate, explore ways to bridge the gap in the low scores and discuss the goals you want to
                set, utilising the performance management processes including your Work and Career Development Plan.
                <br />
                4. Discuss your results and goals with your supervisor during your line supervision.
                <br />
                5. Ensure you access the Wungening EAP, Relationships Australia WA if needed.
                {renderWungeningResources()}
              </>
            ) : (
              <>
                1. Share your check in with a trusted person. If you cant connect now, organise a time to catch up with
                someone you trust.
                <br />
                2. When discussing your results, focus on the strengths and discuss things going well.
                <br />
                3. If appropriate, explore ways to bridge the gap in the low scores and discuss the goals you want to
                set.
                <br />
                4. You can always invite your trusted person to be your accountability buddy, to support you along your
                check in journey.
              </>
            )}
          </Text>
        </div>
        {!show && (
          <PopUpResources segmentScores={segmentScores} onOpenAI={handleOpenAiClick} userId={user} show={show} />
        )}
        {!show && user && <LineLink link="/support?type=0">Other Resources</LineLink>}
        <AreYouOkWrapper>
          <div className="mb-3">
            <Text color="#0c2337" size="18px" weight={500}>
              Are you okay?
            </Text>
          </div>
          <div className="mb-2">
            <Text size="14px" lineHeight="1.4" color="#6a777f">
              A reminder that while iyarn is a great way to check in on yourself and peers, it is important that if you
              are really not ok, you take the actions to seek support from loved ones or professionals.
            </Text>
          </div>
          <div className="mb-2">
            <Text size="14px" lineHeight="1.4" color="#6a777f">
              If you really are not ok, go and talk to a trusted friend, family member or peer worker. We encourage you
              to book in an appointment with a professional your local GP or psychologist if you're consistently feeling
              low.
            </Text>
          </div>
          {isAustraliaLocation && (
            <div className="mb-2">
              <Text size="14px" lineHeight="1.4" color="#6a777f">
                Access to publicly available services in Australia can be connected to directly via our crisis support
                links below.
              </Text>
            </div>
          )}
        </AreYouOkWrapper>
        {isAustraliaLocation && <LineLink link="/support?type=2">Crisis Support Link</LineLink>}
        {show && (
          <ButtonWrapper className="mt-4">
            <Button
              variant="contained"
              startIcon={<img src={aiIcon} width="24" />}
              onClick={() => handleOpenAiClick(title)}
            >
              AI Suggested
            </Button>

            <Button variant="contained" onClick={() => handleButtonClick(title)} id="suggested-res-btn">
              CHECK SUGGESTED RESOURCES
            </Button>
          </ButtonWrapper>
        )}
      </ModalBody>
    </Modal>
  );
};

const useStyles = makeStyles({
  dialog: {
    // width: '1000px',
    maxWidth: '1000px',
    '& .modal-content': {
      border: 'none',
    },
  },
  dialogBody: {
    width: '100%',
    padding: '40px',
    '@media (max-width: 768px)': {
      padding: '24px',
    },
  },
  closeButton: {
    position: 'absolute',
    top: '30px',
    right: '30px',
    cursor: 'pointer',
  },
});

const AreYouOkWrapper = styled.div`
  width: 920px;
  max-width: 100%;
  margin: 24px 8px 0 0;
  padding: 24px;
  border-radius: 8px 8px 0px 0px;
  box-shadow: 0 2px 30px 0 rgba(0, 0, 0, 0.07);
  background-color: #fff;
  @media (max-width: 768px) {
    width: 100%;
    height: auto;
  }
`;

const ButtonWrapper = styled.div`
  width: 920px;
  display: flex;
  gap: 32px;
  justify-content: flex-end;

  @media (max-width: 768px) {
    width: 100%;
    height: auto;
    flex-direction: column;
    gap: 16px;
    align-items: center;
  }
`;

const SegmentsWrapper = styled.div`
  display: flex;
  width: 920px;
  gap: 8px;
  justify-content: flex-start;
  flex-wrap: wrap;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

const SegmentPill = styled.div<any>`
  position: relative;
  font-size: 14px;
  line-height: 20px;
  padding: 6px 12px;
  border-radius: 16px;
  color: #ffffff;
  background-image: ${(props) => {
    if (props.index !== null) {
      const gradientIndex = props.index % GRADIENTS.length;
      return `linear-gradient(116deg, ${GRADIENTS[gradientIndex][0]}, ${GRADIENTS[gradientIndex][1]} 87%)`;
    }
  }};
`;

const Button = withStyles({
  root: {
    whiteSpace: 'nowrap',
    padding: '0 16px !important',
    fontSize: '16px',
    height: '48px',
    fontWeight: 400,
    lineHeight: 1.13,
    textTransform: 'none',
    borderRadius: '24px',
    boxShadow: '0 2px 16px 0 rgba(0, 0, 0, 0.24)',
    zIndex: 2,
  },
  contained: {
    color: '#ffffff',
    backgroundColor: '#2a3e50',
    '&:hover': {
      backgroundColor: '#0f2136',
    },
  },
})(MuiButton);
