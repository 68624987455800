import React, { Component } from 'react';
import LuxonUtils from '@date-io/luxon';
import { DateTime, Interval } from 'luxon';
import { Box, Button, Dialog, DialogActions, Typography } from '@material-ui/core';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { CALENDAR_DATE_FORMAT } from '../../../../../constants';

interface DateRangeCalendarProps {
  toggle: () => void;
  setActiveOption: (option) => void;
}

interface DateRangeCalendarState {
  from: DateTime;
  to: DateTime | null;
}

class DateRangeCalendar extends Component<DateRangeCalendarProps, DateRangeCalendarState> {
  readonly state = {
    from: null,
    to: DateTime.now(),
  };

  onChange = (date: DateTime) => {
    const { from, to } = this.state;
    if (from) {
      this.setState({ to: date, from: null });
    } else {
      if (date.toMillis() < to.toMillis()) {
        this.setState({ from: date });
      } else {
        this.setState({ to: date, from: to });
      }
    }
  };

  onSubmit = () => {
    const { toggle, setActiveOption } = this.props;
    const { from, to } = this.state;

    const duration = to.diff(from, ['days']);
    const unit = duration.days > 365 ? 'year' : duration.days > 83 ? 'month' : duration.days > 7 ? 'week' : 'day';

    setActiveOption({ label: 'Custom range', from, to, unit });
    toggle();
  };

  renderDay = (day, selectedDate, dayInCurrentMonth, dayComponent) => {
    const { from, to } = this.state;
    const interval = Interval.fromDateTimes((from || to).minus({ day: 1 }), to);

    const selected = interval.isValid && interval.contains(day);
    const current = day === from || day === to;

    return React.cloneElement(dayComponent, { ...dayComponent.props, selected, current });
  };

  renderToolbar = () => {
    const { from, to } = this.state;
    return (
      <Box p={[2, 3]} bgcolor="primary.main" color="#ffffff">
        <Typography variant="h6" align="center" color="inherit">
          {`${from?.toFormat(CALENDAR_DATE_FORMAT) || '...'} - ${to?.toFormat(CALENDAR_DATE_FORMAT) || '...'}`}
        </Typography>
      </Box>
    );
  };

  render() {
    const { toggle } = this.props;
    const { from, to } = this.state;

    return (
      <Dialog open={true} onClose={toggle}>
        <>
          <MuiPickersUtilsProvider utils={LuxonUtils}>
            <DatePicker
              value={from || to}
              onChange={this.onChange}
              variant="static"
              disableFuture={true}
              renderDay={this.renderDay}
              ToolbarComponent={this.renderToolbar}
            />
          </MuiPickersUtilsProvider>
          <DialogActions>
            <Button onClick={toggle} color="primary">
              Cancel
            </Button>
            <Button onClick={this.onSubmit} color="primary" disabled={!(from && to)}>
              Filter
            </Button>
          </DialogActions>
        </>
      </Dialog>
    );
  }
}

export default DateRangeCalendar;
