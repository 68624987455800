import { Text } from '../Shared/Typography/Typography';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { SmallButton } from '../Shared/Buttons/Button';
import { SupportClient } from '../../API/support.client';
import { SupportCompany, SupportCompanyType } from './support.types';
import { CrisisCompany } from './CrisisCompany';

export const CrisisSupportTab = () => {
  const [companies, setCompanies]: [SupportCompany[], (data) => void] = useState([]);
  const [selectedType, selectType]: [SupportCompanyType | null, (data) => void] = useState(null);
  const fetchCrisisSupport = async () => {
    const companies: SupportCompany[] = await SupportClient.getCrisisSupport();
    setCompanies(companies);
  };

  const toggleType = (type: SupportCompanyType) => {
    if (selectedType === type) {
      selectType(null);
    } else {
      selectType(type);
    }
  };

  useEffect(() => {
    fetchCrisisSupport();
  }, []);

  const adultCompanies = companies.filter((c) => c.type === SupportCompanyType.AdultSupport);
  const youthCompanies = companies.filter((c) => c.type === SupportCompanyType.YouthSupport);
  const otherCompanies = companies.filter((c) => c.type === SupportCompanyType.OtherResources);

  return (
    <>
      <TagWrapper>
        <Tag
          onClick={() => toggleType(SupportCompanyType.AdultSupport)}
          selected={selectedType === SupportCompanyType.AdultSupport}
        >
          Adult Support
        </Tag>
        <Tag
          onClick={() => toggleType(SupportCompanyType.YouthSupport)}
          selected={selectedType === SupportCompanyType.YouthSupport}
        >
          Youth Support
        </Tag>
        <Tag
          onClick={() => toggleType(SupportCompanyType.OtherResources)}
          selected={selectedType === SupportCompanyType.OtherResources}
        >
          Other Resources
        </Tag>
      </TagWrapper>
      {(!selectedType || selectedType === SupportCompanyType.AdultSupport) && (
        <div>
          <div className="mb-3">
            <Text color="#2b2e31" size="18px">
              Adult Support
            </Text>
          </div>
          <CompanyContainer>
            {adultCompanies.map((c) => {
              return <CrisisCompany company={c} key={c.name}></CrisisCompany>;
            })}
          </CompanyContainer>
        </div>
      )}
      {(!selectedType || selectedType === SupportCompanyType.YouthSupport) && (
        <div>
          <div className="mb-3">
            <Text color="#2b2e31" size="18px">
              Youth Support
            </Text>
          </div>
          <CompanyContainer>
            {youthCompanies.map((c) => {
              return <CrisisCompany company={c} key={c.name}></CrisisCompany>;
            })}
          </CompanyContainer>
        </div>
      )}
      {(!selectedType || selectedType === SupportCompanyType.OtherResources) && (
        <div>
          <div className="mb-3">
            <Text color="#2b2e31" size="18px">
              Other Resources
            </Text>
          </div>
          <CompanyContainer>
            {otherCompanies.map((c) => {
              return <CrisisCompany company={c} key={c.name}></CrisisCompany>;
            })}
          </CompanyContainer>
        </div>
      )}
    </>
  );
};

const CompanyContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const TagWrapper = styled.div`
  flex: 1;
  white-space: nowrap;
  overflow-x: scroll;
  padding: 1px 0px;
  scrollbar-width: none;
  -ms-overflow-style: none;
  margin-bottom: 1.5rem;
  @media (max-width: 768px) {
    width: 100vw;
    margin-left: -20px;
    padding-left: 20px;
    margin-top: 15px;
  }
`;

const Tag = styled<any>(SmallButton)`
  user-select: none;
  font-size: 14px;
  font-weight: normal;
  border: 1px solid;
  background-image: none;
  border-color: ${({ selected }) => (selected ? 'transparent' : '#cececf')};
  color: ${({ selected }) => (selected ? '#fff' : '#0c2337')};
  background-color: ${({ selected }) => (selected ? '#2a3e50' : '#fff')};
  box-shadow: none;
  transition: background-color 0.2s ease-in-out;
  margin-right: 4px;
`;
