import { combineReducers } from 'redux';
import loadingReducer from './loadingReducer';
import alertReducer from './alertReducer';
import JoinWheelReducer from '../../Components/JoinWheel/JoinWheel.reducer';
import windowReducer from './windowReducer';
import sessionReducer from './sessionReducer';
import { toastsReducer } from '../../Components/Shared/Toaster/Toaster.reducer';
import { educationReducer } from '../../Components/Education/Education.reducer';
import { dashboardReducer } from '../../Components/Dashboard/Dashboard.reducer';
import authReducer from '../../Components/Auth/Auth.reducer';
import { invitationReducer } from '../../Components/Invitations/invitation.reducer';
import { insightsReducer } from '../../Components/Insights/insights.reducer';
import { notifications } from '../../Components/Shared/Header/Notifications/Notifications.reducer';

const RxApp = combineReducers({
  userRD: authReducer,
  loadingRD: loadingReducer,
  alertRD: alertReducer,
  shareLink: JoinWheelReducer,
  invitationLink: invitationReducer,
  windowRD: windowReducer,
  toasts: toastsReducer,
  education: educationReducer,
  dashboardReducer: dashboardReducer,
  session: sessionReducer,
  insightsReducer: insightsReducer,
  notifications: notifications,
});

export type RootState = ReturnType<typeof RxApp>;

export default RxApp;
