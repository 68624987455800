import React, { useState } from 'react';
import { withRouter } from 'react-router';
import { RouteComponentProps } from 'react-router-dom';
import { connect, useSelector } from 'react-redux';
import { Menu, MenuItem, withStyles } from '@material-ui/core';
import MuiButton from '@material-ui/core/Button';
import MuiIconButton from '@material-ui/core/IconButton';
import { HelpCircle } from 'react-feather';
import { iUser } from '../../API/interfaces';
import { analytics } from '../../analytics/analytics';
import utils from '../Shared/utils';
import SupportForm from './SupportDrawer';
import { RootState } from '../../Redux/Reducers';
import { Text } from '../Shared/Typography/Typography';
import { FiBook, FiFileText, FiLifeBuoy, FiHelpCircle, FiMessageSquare } from 'react-icons/fi';
import { selectIpAddress } from '../Auth/Auth.selectors';

interface iSupportButtonProps extends RouteComponentProps {
  user: iUser;
}

export enum HelpOptions {
  Resources = 'Resources',
  Tutorial = 'Tutorial',
  CrisisSupport = 'CrisisSupport',
  HelpRequest = 'HelpRequest',
  SupportNow = 'SupportNow',
}

export type SupportDrawerType = 'HelpRequest' | 'SupportNow' | '';

const SupportButton = ({ user, location }: iSupportButtonProps) => {
  const [drawerType, setDrawerType] = useState<SupportDrawerType>('');
  const [anchorEl, setAnchorEl] = React.useState(null);
  const userIpAddress = useSelector(selectIpAddress);

  const openDrawer = (type: SupportDrawerType) => {
    setDrawerType(type);
  };

  const openMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorEl(null);
  };

  const pickOption = (option: HelpOptions) => {
    switch (option) {
      case HelpOptions.HelpRequest:
        analytics.clickHelp(
          user ? 'afterSignIn' : location.pathname.includes('/public/wheel/') ? 'publicCheckin' : 'beforeSignIn'
        );
        openDrawer(HelpOptions.HelpRequest);
        break;
      case HelpOptions.SupportNow:
        analytics.supportNowClick();
        openDrawer(HelpOptions.SupportNow);
        break;
      case HelpOptions.Resources:
        window.location.href = '/support?type=0';
        break;
      case HelpOptions.Tutorial:
        window.location.href = '/support?type=1';
        break;
      case HelpOptions.CrisisSupport:
        window.location.href = '/support?type=2';
        break;
      default:
        return;
    }
    closeMenu();
    return false;
  };

  const isAustraliaLocation = userIpAddress?.country === 'AU';

  return (
    <>
      {utils.checkIsPhone() ? (
        <IconButton onClick={openMenu}>
          <HelpCircle fill="#0f2136" size={16} />
        </IconButton>
      ) : (
        <Button variant="contained" startIcon={<HelpCircle fill="#0f2136" size={16} />} onClick={openMenu}>
          {utils.checkIsPhone() ? '' : 'Help'}
        </Button>
      )}

      <Menu
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={closeMenu}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
      >
        {user && (
          <MenuItem onClick={() => pickOption(HelpOptions.Resources)}>
            <FiFileText color="#0c2337" className="mr-2" />
            <Text color="#0c2337">Resources</Text>
          </MenuItem>
        )}
        {user && (
          <MenuItem onClick={() => pickOption(HelpOptions.Tutorial)}>
            <FiBook color="#0c2337" className="mr-2" />
            <Text color="#0c2337">Tutorial</Text>
          </MenuItem>
        )}
        {isAustraliaLocation && (
          <MenuItem onClick={() => pickOption(HelpOptions.CrisisSupport)}>
            <FiLifeBuoy color="#0c2337" className="mr-2" />
            <Text color="#0c2337">Crisis Support</Text>
          </MenuItem>
        )}
        {user && (
          <MenuItem onClick={() => pickOption(HelpOptions.SupportNow)}>
            <FiMessageSquare color="#0c2337" className="mr-2" />
            <Text color="#0c2337">Support now</Text>
          </MenuItem>
        )}
        <MenuItem onClick={() => pickOption(HelpOptions.HelpRequest)}>
          <FiHelpCircle color="#0c2337" className="mr-2" />
          <Text color="#0c2337">Technical support</Text>
        </MenuItem>
      </Menu>

      <SupportForm open={!!drawerType} drawerType={drawerType} onClose={() => setDrawerType('')} />
    </>
  );
};

export default withRouter(connect((state: RootState) => ({ user: state.userRD.user }))(SupportButton));

const IconButton = withStyles({
  root: {
    position: 'fixed',
    right: '24px',
    bottom: '24px',
    boxShadow: '0 2px 16px 0 rgba(0, 0, 0, 0.24)',
    backgroundColor: '#0f2136',
    color: '#fff',
    zIndex: 10,
  },
})(MuiIconButton);

const Button = withStyles({
  root: {
    position: 'fixed',
    right: '86px',
    bottom: '32px',
    padding: '0 16px !important',
    fontSize: '16px',
    lineHeight: 1.13,
    textTransform: 'none',
    borderRadius: '20px',
    boxShadow: '0 2px 16px 0 rgba(0, 0, 0, 0.24)',
    zIndex: 10,
  },
  contained: {
    color: '#ffffff',
    backgroundColor: '#0f2136',
    '&:hover': {
      backgroundColor: '#0f2136',
    },
  },
})(MuiButton);
