import React from 'react';
import styled from 'styled-components';

export const Button = styled.button<any>`
  background: none;
  border: none;
  cursor: pointer;
  text-transform: none;
  user-select: none;
  transition: all 0.3s ease-in-out;

  :active,
  :focus {
    outline: none !important;
    box-shadow: none !important;
  }
`;
export const MainButton = styled(Button)`
  background-image: linear-gradient(111deg, #ff815d 0%, #ff4138 100%);
  border-radius: 20px;
  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.1);
  color: #fff;
  font-weight: bold;
  line-height: 1.67;
  letter-spacing: 0.86px;

  &:disabled,
  &[disabled] {
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);
    background-image: linear-gradient(114deg, #fa8868 0%, #ff554d 100%);
    opacity: 0.5;
  }
`;
export const SecondaryButton = styled(Button)`
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: 0.86px;
  text-align: center;
  color: #0c2337;
`;

export const CreateWheelButton = styled(Button)``;
