import { store } from '../../Redux/store';
import { bindActionCreators } from 'redux';
import { afterFirstCheckinFlow, firstVisitOfDashboardFlow, membersTabFlow } from './Education';
import { EducationStatus } from './Education.model';
import utils from '../Shared/utils';
import {
  setAfterFirstCheckin,
  setEducationFlow,
  setFirstVisitOfDashboard,
  setFirstVisitOfMembersTab,
} from './Education.actions';

class EducationService {
  actions = bindActionCreators(
    {
      setEducationFlow,
      setAfterFirstCheckin,
      setFirstVisitOfDashboard,
      setFirstVisitOfMembersTab,
    },
    store.dispatch
  );
  isPhone = utils.checkIsPhone();

  public processCheckinEducation() {
    const educationStatus = store.getState().education.afterFirstCheckin;

    if (educationStatus === EducationStatus.REMAINING && !this.isPhone) {
      const { setEducationFlow, setAfterFirstCheckin } = this.actions;
      setEducationFlow(afterFirstCheckinFlow);
      setAfterFirstCheckin(EducationStatus.PASSED);
    }
  }

  public processMembersTabEducation() {
    const educationStatus = store.getState().education.membersTab;

    if (educationStatus === EducationStatus.REMAINING && !this.isPhone) {
      const { setEducationFlow, setFirstVisitOfMembersTab } = this.actions;
      setEducationFlow(membersTabFlow);
      setFirstVisitOfMembersTab(EducationStatus.PASSED);
    }
  }

  public processDashboardEducation() {
    const educationStatus = store.getState().education.firstVisitOfDashboard;

    if (educationStatus === EducationStatus.REMAINING && !this.isPhone) {
      const { setEducationFlow, setFirstVisitOfDashboard } = this.actions;
      setEducationFlow(firstVisitOfDashboardFlow);
      setFirstVisitOfDashboard(EducationStatus.PASSED);
    }
  }
}

export const educationService = new EducationService();
