import { EducationFeature, EducationStatus } from './Education.model';

export const setFirstVisitOfDashboard = function (status: EducationStatus) {
  return {
    type: EducationFeature.FIRST_VISIT_OF_DASHBOARD,
    payload: status,
  };
};

export const setAfterFirstCheckin = function (status: EducationStatus) {
  return {
    type: EducationFeature.AFTER_FIRST_CHECKIN,
    payload: status,
  };
};

export const setFirstVisitOfMembersTab = function (status: EducationStatus) {
  return {
    type: EducationFeature.MEMBERS_TAB,
    payload: status,
  };
};

export const setEducationFlow = function (educationFlowConfig: any) {
  return {
    type: EducationFeature.FLOW,
    payload: educationFlowConfig,
  };
};
