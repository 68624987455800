export enum ShareActions {
  SHARE = 'SHARE',
}

export const joinLinkAction = function (joinParams: string) {
  return {
    type: ShareActions.SHARE,
    payload: joinParams,
  };
};
