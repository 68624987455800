import { HttpClient } from './HttpClient';
import { WheelMemberRole, WheelMemberRoleType } from './interfaces';

export class MembersClient {
  static async updateWheelMember(
    wheelId: string,
    memberId: string,
    payload: {
      isReminderEnabled: boolean;
    }
  ) {
    return await HttpClient.put(`/wheels/${wheelId}/members/${memberId}`, payload).catch((error) => error.response);
  }

  static async updateWheelMemberRole(wheelId: string, memberId: string, memberRole: string) {
    return await HttpClient.put(`/wheels/${wheelId}/members/${memberId}/role`, {
      memberRole,
    }).catch((error) => error.response);
  }

  static async deleteWheelMember(param: { wheelId: string; userId: string }) {
    return HttpClient.delete(`/wheels/${param.wheelId}/members/${param.userId}`).catch((error) => error.response);
  }

  static async addMemberToWheel(param: { wheelId: string; invitingMemberId: string; inviteRole: WheelMemberRoleType }) {
    const endpoint = `/wheels/${param.wheelId}/join${
      param.inviteRole === WheelMemberRole.ACCOUNTABILITY_BUDDY ? `/${param.invitingMemberId}` : ''
    }`;
    return await HttpClient.post(endpoint, {
      role: param.inviteRole,
    }).catch((error) => error.response);
  }

  static async getWheelMembersByWheelId(wheelId: string) {
    return await HttpClient.get(`/wheels/${wheelId}/members`).catch((error) => error.response);
  }
}
