import React, { Component } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Input from 'reactstrap/es/Input';
import Button from 'reactstrap/es/Button';
import Alert from 'reactstrap/es/Alert';
import services from '../../API/services';
import InputGroup from 'reactstrap/es/InputGroup';
import utils from '../Shared/utils';
import { iWheel, iWheelTemplate } from '../../API/interfaces';

interface iWheelConfigurationProps extends RouteComponentProps {
  wheelId: string;
  isTemplate: boolean;
  setWheel: (wheel) => void;
}

interface iWheelConfigurationState {
  wheel: iWheel | iWheelTemplate;
  alertVisible: boolean;
  name: string;
}

class WheelConfiguration extends Component<iWheelConfigurationProps, iWheelConfigurationState> {
  readonly state: iWheelConfigurationState = {
    alertVisible: false,
    name: '',
    wheel: null,
  };
  readonly schema: any = utils.getFormValidationSchema('wheel_edit');

  constructor(props) {
    super(props);
    this.loadWheel();
  }

  handleChange = (event) => {
    this.setState({
      name: event.target.value,
      alertVisible: false,
    });
  };

  async loadWheel() {
    let wheel;
    const { wheelId, setWheel, isTemplate } = this.props;

    if (isTemplate) {
      wheel = await services.getTemplateById(wheelId);
    } else {
      wheel = await services.getWheelById(wheelId).then((res: any) => res.data);
    }

    this.setState({ wheel, name: wheel.name });
    setWheel(wheel);
  }

  handleSave = async (e) => {
    e.preventDefault();
    const { isTemplate } = this.props;
    const { name, wheel } = this.state;

    const response = await services[isTemplate ? 'updateTemplate' : 'updateWheelById']({
      id: wheel.id,
      name: name,
    });

    if (response.status === 200) {
      this.setState({ alertVisible: true, wheel: response.data });
    }
  };

  onDismiss = () => {
    this.setState({ alertVisible: false });
  };

  render() {
    const { isTemplate } = this.props;
    const { name, alertVisible, wheel } = this.state;

    const errors: any = utils.validateFields(this.schema, { name });

    return (
      <>
        {wheel && (
          <div>
            <Alert color="success" isOpen={alertVisible} toggle={this.onDismiss}>
              Wheel is updated!
            </Alert>
            <h4 className="mb-3">{isTemplate ? 'Template' : 'Wheel'} Name</h4>
            <InputGroup>
              <Input type="text" name="name" id="name" value={name} onChange={this.handleChange} />
            </InputGroup>
            {errors && errors.name && (
              <Alert color="danger" className="mt-2">
                {errors.name}
              </Alert>
            )}
            <Button
              className="iyarn-button mt-2 btn-red"
              size="sm"
              onClick={this.handleSave}
              disabled={(wheel && wheel.name === name) || !!errors}
            >
              Save
            </Button>
          </div>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.userRD.user || {},
  };
};

export default connect(mapStateToProps)(withRouter(WheelConfiguration));
