import React, { useMemo, useState } from 'react';
import styled from 'styled-components';
import ReactTooltip from 'react-tooltip';
import { BiInfoCircle } from 'react-icons/bi';
import { ClickAwayListener, makeStyles, Tooltip } from '@material-ui/core';

interface iRadioButtonProps {
  selected;
  onChange: (event) => void;
  text: string;
  value: string;
  disabled?: boolean;
  tooltipText?: string;
  infoIconTooltipText?: string;
  textSize?: string;
  isBoldSelected?: boolean;
}

const RadioContentWrapper = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const RadioContainer = styled.div`
  margin: 4px 0;
  display: inline-flex;
  cursor: pointer;
  user-select: none;
  align-items: center;
  @media (max-width: 1024px) {
    margin: 10px 0;
  }
`;

const OuterCircle = styled.div<{ selected: boolean }>`
  width: 16px;
  height: 16px;
  min-width: 16px;
  min-height: 16px;
  border: ${(props) => (props.selected ? '2px solid #0c2337' : '2px solid #0c2337')};
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 12px;
  transition: all 0.1s linear;
`;

const InnerCircle = styled.div<{ selected: boolean }>`
  width: ${(props) => (props.selected ? '6px' : '0px')};
  height: ${(props) => (props.selected ? '6px' : '0px')};
  border-radius: 50%;
  background-color: #0c2337;
  transition: all 0.1s linear;
`;

const HelperText = styled.div<{ disabled: boolean; isSelected?: boolean; textSize?: string }>`
  color: ${(props) => (props.disabled ? '#86919a' : '#0c2337')};
  font-weight: ${({ isSelected }) => (isSelected ? '500' : 'auto')};
  font-size: ${({ textSize }) => textSize || '14px'};
  padding-right: 8px;
  font-size: 14px;
`;

const DisabledCircle = styled.div`
  width: 16px;
  height: 16px;
  min-width: 16px;
  min-height: 16px;
  border: 2px solid #86919a;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 12px;
  transition: all 0.1s linear;
`;

const InfoIconContainer = styled.div`
  margin-top: -3px;
`;

export const RadioButton: React.FC<iRadioButtonProps> = ({
  selected,
  onChange,
  text,
  value,
  disabled = false,
  tooltipText = 'disabled',
  infoIconTooltipText,
  isBoldSelected = false,
  textSize,
}) => {
  const [isOpenInfoTooltip, setIsOpenInfoTooltip] = useState(false);
  const classes = useStyles();
  const isSelected = value === selected;
  const isSmallScreen = window.innerWidth <= 768;

  const handleOpenInfoTooltip = (open: boolean) => {
    setIsOpenInfoTooltip(open);
  };

  // TODO: create separate component for InfoTooltip with onClick mobile logic
  const InfoIconTooltip = useMemo(
    () => (
      <Tooltip
        arrow
        title={infoIconTooltipText}
        classes={{ tooltip: classes.tooltip, arrow: classes.arrow }}
        {...(isSmallScreen ? { open: isOpenInfoTooltip } : {})}
      >
        <InfoIconContainer {...(isSmallScreen ? { onClick: () => handleOpenInfoTooltip(true) } : {})}>
          <BiInfoCircle size={16} />
        </InfoIconContainer>
      </Tooltip>
    ),
    [infoIconTooltipText, isSmallScreen, isOpenInfoTooltip]
  );

  return (
    <RadioContentWrapper>
      <RadioContainer
        data-tip={tooltipText}
        data-tip-disable={!disabled}
        onClick={() => {
          if (!disabled && !isSelected) {
            onChange(value);
          }
        }}
      >
        {disabled ? (
          <>
            <DisabledCircle />
            <ReactTooltip place="right" />
          </>
        ) : (
          <OuterCircle selected={isSelected}>
            <InnerCircle selected={isSelected} />
          </OuterCircle>
        )}
        <HelperText disabled={disabled} isSelected={isBoldSelected && isSelected} textSize={textSize}>
          {text}
        </HelperText>
      </RadioContainer>
      {infoIconTooltipText && (
        <>
          {isSmallScreen ? (
            <ClickAwayListener onClickAway={() => handleOpenInfoTooltip(false)}>{InfoIconTooltip}</ClickAwayListener>
          ) : (
            InfoIconTooltip
          )}
        </>
      )}
    </RadioContentWrapper>
  );
};

const useStyles = makeStyles({
  tooltip: {
    backgroundColor: '#ffffff',
    color: '#2b2e31',
    fontSize: '14px',
    lineHeight: 1.43,
    padding: '16px 24px',
    boxShadow: '1px 5px 32px 0 rgba(0, 0, 0, 0.1)',
    maxWidth: '316px',
    fontFamily: 'Rubik',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
  },
  arrow: {
    color: '#fff',
  },
});
