import React, { FC } from 'react';
import _ from 'lodash';
import styled from 'styled-components';

type Props = {
  Icon: FC<any> | string;
  label: string;
  width: number | string; // use width in pixels for smooth transitions
  onClick?: () => void;
};

const ButtonWrapper = styled.div<{ width?: number | string }>`
  position: relative;
  display: inline-block;
  border-radius: 25px;
  border: solid 1px rgba(64, 64, 66, 0.24);
  background-color: #f8f8f8;
  cursor: pointer;
  outline: none;

  &:hover {
    & > div > span {
      max-width: ${({ width }) => (width ? `${width}px` : 'auto')};
      padding-right: 8px;
    }
  }
`;

const ContentContainer = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  overflow: hidden;
`;

const StyledImage = styled.img`
  width: 16px;
  height: 16px;
  margin: 8px;
`;

const ButtonLabel = styled.span`
  max-width: 0;
  font-size: 14px;
  white-space: nowrap;
  transition: max-width 0.5s;
`;

const ExpandButton = ({ Icon, label, width, onClick }: Props) => {
  return (
    <ButtonWrapper onClick={onClick} width={width}>
      <ContentContainer>
        {_.isString(Icon) ? <StyledImage src={Icon as string} /> : <Icon color="#ffffff" size={16} />}
        <ButtonLabel>{label}</ButtonLabel>
      </ContentContainer>
    </ButtonWrapper>
  );
};

export default ExpandButton;
