import React, { Component } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { User } from 'react-feather';
import { analytics } from '../../../../analytics/analytics';
import TutorialModal from '../../TutorialModal/TutorialModal';
import './UserInfo.scss';
import { authService } from '../../../Auth/Auth.service';
import { FiSettings, FiBook, FiBriefcase, FiFileText, FiLifeBuoy, FiLogOut, FiMessageSquare } from 'react-icons/fi';
import { connect } from 'react-redux';
import { UserIpAddressType } from '../../../Auth/Auth.reducer';
import SupportForm from '../../../Support/SupportDrawer';
import { SupportDrawerType, HelpOptions } from '../../../Support/SupportButton';
import styled from 'styled-components';

interface iUserInfoProps extends RouteComponentProps {
  history: any;
  user: any;
  userIpAddress: UserIpAddressType;
}

const UserIconWrapper = styled.div`
  cursor: pointer;
  background-color: #fff;
  box-shadow: 0 6px 20px 0 rgba(35, 55, 72, 0.1);
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 768px) {
    width: 30px;
    height: 30px;
  }
`;

const StyledUserIcon = styled(User)`
  width: 20px;
  height: 20px;

  @media (max-width: 768px) {
    width: 15px;
    height: 15px;
  }
`;

interface iUserInfoState {
  showContextMenu: boolean;
  showTutorial: boolean;
  supportDrawerType: SupportDrawerType;
  isNotificationDrawerOpen: boolean;
  bellIconColor: string;
}

class UserInfo extends Component<iUserInfoProps, iUserInfoState> {
  readonly state: iUserInfoState = {
    showContextMenu: false,
    showTutorial: false,
    supportDrawerType: '',
    isNotificationDrawerOpen: false,
    bellIconColor: '#b1b1b1',
  };

  toggleContextMenu = () => {
    const dm: any = document.getElementById('context-menu');

    if (!dm) {
      return;
    }

    if (dm.style.display === 'flex') {
      dm.style.display = 'none';
    } else {
      dm.style.display = 'flex';
    }
  };

  openSettings = (e) => {
    e.preventDefault();
    this.props.history.push('/settings');
  };

  openFeaturesPage = (e) => {
    e.preventDefault();
    analytics.clickFeaturesInMenu();
    this.props.history.push('/features?rf=user-menu');
  };

  openResources = (e) => {
    e.preventDefault();
    analytics.openResourceFromMenu();
    window.location.href = '/support?type=0';
  };

  openTutorials = (e) => {
    e.preventDefault();
    analytics.openTutorialsFromMenu();
    window.location.href = '/support?type=1';
  };

  openCrisisSupport = (e) => {
    e.preventDefault();
    analytics.openCrisisFromMenu();
    window.location.href = '/support?type=2';
  };

  handleLogout = (e) => {
    e.preventDefault();
    authService.logout();
  };

  openDrawer = () => {
    this.setState({ supportDrawerType: HelpOptions.SupportNow });
  };

  render() {
    const { user, userIpAddress } = this.props;
    const { showTutorial } = this.state;

    const isAustraliaLocation = userIpAddress?.country === 'AU';

    return (
      <div className="user-info">
        <div className="d-flex align-items-center">
          <div className="user-info_avatar-wrap avatar-wrap">
            <UserIconWrapper id="login-user-avatar" onClick={this.toggleContextMenu}>
              <StyledUserIcon color="#b1b1b1" />
            </UserIconWrapper>
            <div className="dropdown-menu dropdown-menu-sm" id="context-menu">
              <a className="dropdown-item" style={{ color: '#0c2337' }} href="/" onClick={this.openSettings}>
                <FiSettings className="mr-2" />
                Settings
              </a>
              <a className="dropdown-item" style={{ color: '#0c2337' }} href="/" onClick={this.openResources}>
                <FiFileText className="mr-2" />
                Resources
              </a>
              <a className="dropdown-item" style={{ color: '#0c2337' }} href="/" onClick={this.openTutorials}>
                <FiBook className="mr-2" />
                Tutorial
              </a>
              {isAustraliaLocation && (
                <a className="dropdown-item" style={{ color: '#0c2337' }} href="/" onClick={this.openCrisisSupport}>
                  <FiLifeBuoy className="mr-2" />
                  Crisis Support
                </a>
              )}
              <a className="dropdown-item" style={{ color: '#0c2337' }} href="/" onClick={this.openFeaturesPage}>
                <FiBriefcase className="mr-2" /> Features
              </a>
              {user && (
                <div className="dropdown-item" style={{ color: '#0c2337' }} onClick={this.openDrawer}>
                  <FiMessageSquare color="#0c2337" className="mr-2" /> Support now
                </div>
              )}
              <a className="dropdown-item" style={{ color: '#fb463b' }} href="/" onClick={this.handleLogout}>
                <FiLogOut className="mr-2" /> Log out
              </a>
            </div>
          </div>
        </div>

        {showTutorial && <TutorialModal toggle={() => this.setState({ showTutorial: !this.state.showTutorial })} />}
        <SupportForm
          open={!!this.state.supportDrawerType}
          drawerType={this.state.supportDrawerType}
          onClose={() => this.setState({ supportDrawerType: '' })}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  userIpAddress: state.userRD.userIpAddress,
});

export default connect(mapStateToProps)(withRouter(UserInfo));
