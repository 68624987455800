import React from 'react';

import { tIconProps } from '../../_types';

const UserPlusIcon = ({ className }: tIconProps) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12">
    <defs>
      <filter id="t12295xeya" colorInterpolationFilters="auto">
        <feColorMatrix
          in="SourceGraphic"
          values="0 0 0 0 0.082353 0 0 0 0 0.168627 0 0 0 0 0.243137 0 0 0 1.000000 0"
        />
      </filter>
    </defs>
    <g fill="none" fillRule="evenodd">
      <g>
        <g>
          <g>
            <g
              filter="url(#t12295xeya)"
              transform="translate(-525.000000, -486.000000) translate(308.000000, 380.000000) translate(136.000000, 100.000000) translate(73.000000, 0.000000)"
            >
              <g>
                <path
                  fill="#000"
                  fillRule="nonzero"
                  d="M6 7c1.38 0 2.5 1.12 2.5 2.5v1c0 .276-.224.5-.5.5s-.5-.224-.5-.5v-1C7.5 8.672 6.828 8 6 8H2.5C1.672 8 1 8.672 1 9.5v1c0 .276-.224.5-.5.5s-.5-.224-.5-.5v-1C0 8.12 1.12 7 2.5 7zm4-3.5c.276 0 .5.224.5.5v1h1c.245 0 .45.177.492.41L12 5.5c0 .276-.224.5-.5.5h-1v1c0 .245-.177.45-.41.492L10 7.5c-.276 0-.5-.224-.5-.5V6h-1c-.245 0-.45-.177-.492-.41L8 5.5c0-.276.224-.5.5-.5h1V4c0-.245.177-.45.41-.492zM4.25 1c1.38 0 2.5 1.12 2.5 2.5S5.63 6 4.25 6s-2.5-1.12-2.5-2.5S2.87 1 4.25 1zm0 1c-.828 0-1.5.672-1.5 1.5S3.422 5 4.25 5s1.5-.672 1.5-1.5S5.078 2 4.25 2z"
                  transform="translate(8.000000, 6.000000)"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default UserPlusIcon;
