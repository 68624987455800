import React, { Component } from 'react';
import { iScore, iWheel, iWheelMember } from '../../../../API/interfaces';
import { MAX_SEGMENTS } from '../../../../constants';
import { iDateRangeOptionData } from '../DateRangeDropdown/DateRangeDropdown';

interface iNoResultsBaseProps {
  wheel: iWheel;
  members?: Array<iWheelMember>;
  showCheckinModal?: () => void;
  activeMemberId?: string;
  setActiveMemberId?: (memberId: string) => void;
  shouldShowDateDropdown: boolean;
  dateRange?: iDateRangeOptionData;
  setDateRange?: (dateRange: iDateRangeOptionData) => void;
  isTeamResults?: boolean;
}

export default class extends Component<iNoResultsBaseProps> {
  getFakeScoresForSegmentTag = (): Array<iScore> =>
    this.props.wheel.segments.map(
      (segment) =>
        ({
          segmentId: segment.id,
          segmentName: segment.name,
          description: segment.description,
          score: 0,
        } as iScore)
    );

  getFakeScoresForWheel = (): Array<iScore> =>
    this.props.wheel.segments.map(
      (segment, index) =>
        ({
          segmentId: segment.id,
          segmentName: segment.name,
          description: segment.description,
          score: [10, 9, 7, 8, 5, 7, 9, 7, 5, 9, 8, 6, 8][index % MAX_SEGMENTS] % this.props.wheel.maxScale,
        } as iScore)
    );

  getFakeScoresForChart = (): Array<number> => [0.8, 3, 4, 1.5, 4, 2.5];
}
