import React, { Component } from 'react';
import { withRouter, Link, RouteComponentProps } from 'react-router-dom';
import { connect } from 'react-redux';
import Nav from 'reactstrap/es/Nav';
import NavItem from 'reactstrap/es/NavItem';
import TabContent from 'reactstrap/es/TabContent';
import TabPane from 'reactstrap/es/TabPane';
import Row from 'reactstrap/es/Row';
import Col from 'reactstrap/es/Col';
import NavLink from 'reactstrap/es/NavLink';
import classnames from 'classnames';
import './WheelSettings.scss';
import WheelConfiguration from './WheelConfiguration';
import { WheelSegments } from './WheelSegments';
import { ManageContainer } from '../_Containers/ManageContainer';
import { iUser, iWheel, iWheelTemplate } from '../../API/interfaces';

interface iWheelSettingsProps extends RouteComponentProps<{ id: string }> {
  user: iUser;
  isTemplate: boolean;
}

interface iWheelSettingsState {
  activeTab: string;
  wheelId: string;
  wheel: iWheel | iWheelTemplate;
}

class WheelSettings extends Component<iWheelSettingsProps, iWheelSettingsState> {
  static defaultProps = {
    isTemplate: false,
  };

  readonly state: iWheelSettingsState = {
    activeTab: '1',
    wheelId: this.props.match.params.id,
    wheel: null,
  };

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({ activeTab: tab });
    }
  }

  setWheel = (wheel) => {
    this.setState({ wheel });
  };

  render() {
    const { isTemplate } = this.props;
    const { wheel, activeTab, wheelId } = this.state;

    return (
      <ManageContainer className="gray">
        <div className="wheel-settings container">
          <Link className="row col-12 wheel-settings_back" to={isTemplate ? '/templates' : '/dashboard'}>
            <i className="fas fa-arrow-left mr-px-1" />
            <span>Back to {isTemplate ? 'Templates' : 'Dashboard'}</span>
          </Link>
          <div className="row col-12 title">
            {isTemplate ? 'Template' : 'Wheel'} Settings - {wheel?.name}
          </div>
          <div className="row">
            <div className="col-lg-3 col-12 mb-3">
              <Nav className="d-flex flex-column" tabs>
                <NavItem>
                  <NavLink
                    className={classnames('tab first', {
                      active: activeTab === '1',
                    })}
                    onClick={() => {
                      this.toggle('1');
                    }}
                  >
                    {isTemplate ? 'Template' : 'Wheel'}
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames('tab first', {
                      active: activeTab === '2',
                    })}
                    onClick={() => {
                      this.toggle('2');
                    }}
                  >
                    Segments
                  </NavLink>
                </NavItem>
              </Nav>
            </div>
            <TabContent className="col-lg-9 col-12" activeTab={activeTab}>
              <TabPane tabId="1">
                <Row>
                  <Col sm="12">
                    <WheelConfiguration wheelId={wheelId} setWheel={this.setWheel} {...this.props} />
                  </Col>
                </Row>
              </TabPane>
              <TabPane tabId="2">
                <Row>
                  <Col sm="12">
                    <WheelSegments wheel={wheel} wheelId={wheelId} {...this.props} />
                  </Col>
                </Row>
              </TabPane>
            </TabContent>
          </div>
        </div>
      </ManageContainer>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.userRD.user || {},
  };
};

export default connect(mapStateToProps)(withRouter(WheelSettings));
