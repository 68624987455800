import styled from 'styled-components';
import { Breakpoint } from 'react-socks';

import { ICON_SIZE } from './_consts';
import { SmallButton } from '../Shared/Buttons/Button';

export const IconLabel = styled.span`
  position: absolute;
  top: calc(50% - 12px);
  left: calc(${ICON_SIZE} * 2);
`;

export const Tag = styled<any>(SmallButton)`
  user-select: none;
  font-size: 14px;
  font-weight: normal;
  border: 1px solid;
  background-image: none;
  border-color: ${({ selected }) => (selected ? 'transparent' : '#cececf')};
  color: ${({ selected }) => (selected ? '#fff' : '#0c2337')};
  background-color: ${({ selected }) => (selected ? '#2a3e50' : '#fff')};
  box-shadow: none;
  transition: background-color 0.2s ease-in-out;
  margin-right: 4px;
`;
export const TagMobile = styled<any>(Tag)`
  font-size: 12px;
  line-height: 26px;
  height: 26px;
  padding: 0 8px;
  margin-right: 2px;
  margin-bottom: 4px;
`;
export const TagResponsive = ({ children, ...otherProps }) => (
  <>
    <Breakpoint medium up>
      <Tag {...otherProps}>{children}</Tag>
    </Breakpoint>
    <Breakpoint medium down>
      <TagMobile {...otherProps}>{children}</TagMobile>
    </Breakpoint>
  </>
);

export const EmptyPageTitle = styled.h5`
  color: #6a777f;
  font-family: 'Rubik';
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #6a777f;
  user-select: none;
`;

export const Text = styled.div`
  font-family: Rubik;
  font-size: 40px;
  font-weight: bold;
  line-height: 1.2;
`;

export const TextMobile = styled(Text)`
  font-size: 18px;
`;
export const TextResponsive = ({ children }) => (
  <>
    <Breakpoint medium up>
      <Text>{children}</Text>
    </Breakpoint>
    <Breakpoint medium down>
      <TextMobile>{children}</TextMobile>
    </Breakpoint>
  </>
);

export const Text1 = styled.div`
  font-family: Rubik;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: #7f8b96;
  margin-bottom: 0.5rem;
`;
export const Text1Mobile = styled.div`
  font-size: 12px;
`;
export const Text1Responsive = ({ children }) => (
  <>
    <Breakpoint medium up>
      <Text1>{children}</Text1>
    </Breakpoint>
    <Breakpoint medium down>
      <Text1Mobile>{children}</Text1Mobile>
    </Breakpoint>
  </>
);
