import React, { Component } from 'react';
import { Slider, Rail, Handles, Tracks } from 'react-compound-slider';
import { ChevronLeft, ChevronRight } from 'react-feather';

export enum SliderSize {
  SMALL,
  BIG,
}

interface iSliderProps {
  step: number;
  value: number;
  domain: [number, number];
  disabled?: boolean;
  size: SliderSize;
  onChange: (score: number) => void;
  gradient?: Array<string>;
}

interface iSliderState {}

class SliderComponent extends Component<iSliderProps, iSliderState> {
  static defaultProps = {
    step: 0.1,
  };
  slider = React.createRef();

  handleChange = (scores) => {
    const { step, onChange } = this.props;
    const [score] = scores;

    onChange(step === 1 ? score : Number(score.toFixed(1)));
  };

  render() {
    const { size, step, domain, value, disabled, gradient } = this.props;
    const isBig = size === SliderSize.BIG;

    return (
      <Slider
        className="slider"
        rootStyle={{
          position: 'relative',
          width: `calc(100% - ${isBig ? 40 : 30}px)`, // handle width
          height: isBig ? 20 : 10,
          marginTop: 5,
          padding: '0 28px',
        }}
        domain={domain}
        step={step}
        mode={2}
        values={[value]}
        onUpdate={this.handleChange}
        disabled={disabled}
      >
        <Rail>
          {(
            { getRailProps } // adding the rail props sets up events on the rail
          ) => (
            <div
              style={{
                position: 'absolute',
                width: '100%',
                height: isBig ? 20 : 10,
                marginTop: 0,
                borderRadius: 5,
                backgroundColor: '#cdd4da',
                cursor: 'pointer',
              }}
              {...getRailProps()}
            />
          )}
        </Rail>
        <Handles>
          {({ handles, getHandleProps }) => (
            <div className="slider-handles">
              {handles.map((handle) => (
                <Handle key={handle.id} isBig={isBig} handle={handle} getHandleProps={getHandleProps} />
              ))}
            </div>
          )}
        </Handles>
        <Tracks right={false}>
          {({ tracks, getTrackProps }) => (
            <div className="slider-tracks">
              {tracks.map(({ id, source, target }) => (
                <Track
                  key={id}
                  isBig={isBig}
                  source={source}
                  target={target}
                  getTrackProps={getTrackProps}
                  gradient={gradient}
                />
              ))}
            </div>
          )}
        </Tracks>
      </Slider>
    );
  }
}

export default SliderComponent;

const Handle = ({ isBig, handle, getHandleProps }) => (
  <div
    style={{
      display: 'flex',
      justifyContent: 'space-between',
      position: 'absolute',
      marginTop: isBig ? '-10px' : '-10px',
      left: `${handle.percent - 0.1}%`,
      width: isBig ? 40 : 30,
      height: isBig ? 40 : 30,
      padding: '6px 5px',
      zIndex: 2,
      border: 'solid 1px #233748',
      borderRadius: '50%',
      textAlign: 'center',
      color: '#fff',
      backgroundColor: '#0c2337',
      cursor: 'pointer',
    }}
    {...getHandleProps(handle.id)}
  >
    <ChevronLeft size={16} />
    <ChevronRight size={16} />
  </div>
);

const Track = ({ source, target, isBig, getTrackProps, gradient = ['#fa8868', '#ff554d'] }) => (
  <div
    style={{
      position: 'absolute',
      height: isBig ? 20 : 10,
      zIndex: 1,
      background: `linear-gradient(90deg, ${gradient[0]}, ${gradient[1]} 50%)`,
      borderTopLeftRadius: 5,
      borderBottomLeftRadius: 5,
      cursor: 'pointer',
      left: `${source.percent}%`,
      width: `${target.percent - source.percent + 0.1}%`,
    }}
    {...getTrackProps()} // this will set up events if you want it to be clickable (optional)
  />
);
