import React, { ChangeEvent, FC, useState } from 'react';
import { ReminderFrequency } from '../../../../API/interfaces';
import { FormControl, InputLabel, makeStyles, MenuItem, Select } from '@material-ui/core';

interface iCheckinReminderFrequencyProps {
  activeFrequency: ReminderFrequency;
  setNewFrequency?: (frequency: ReminderFrequency) => void;
  disabled: boolean;
}

export const CheckinReminderFrequency: FC<iCheckinReminderFrequencyProps> = ({
  activeFrequency,
  setNewFrequency,
  disabled,
}) => {
  const [inputValue, setInputValue] = useState(activeFrequency);

  const handleChange = (event: ChangeEvent<{ name?: string; value: unknown }>) => {
    const newValue = event.target.value as ReminderFrequency;
    setInputValue(newValue);
    setNewFrequency(newValue);
  };

  return (
    <FormControl fullWidth={true} variant="outlined">
      <InputLabel id="frequency-label">Frequency</InputLabel>
      <Select
        labelId="frequency-label"
        label="Frequency"
        value={inputValue}
        onChange={handleChange}
        disabled={disabled}
        fullWidth={true}
      >
        <MenuItem value={ReminderFrequency.ONCE}>Do not repeat</MenuItem>
        <MenuItem value={ReminderFrequency.WEEKLY}>Weekly</MenuItem>
        <MenuItem value={ReminderFrequency.FORTNIGHTLY}>Fortnightly</MenuItem>
        <MenuItem value={ReminderFrequency.MONTHLY}>Monthly</MenuItem>
      </Select>
    </FormControl>
  );
};
