import { OktaAuth, OAuthResponseMode, OktaAuthOptions } from '@okta/okta-auth-js';

export const initOktaClient = ({ issuer, clientId }) => {
  const config: OktaAuthOptions = {
    devMode: false,
    // Required config
    issuer,

    // Required for login flow using getWithRedirect()
    clientId,

    // Parse authorization code from hash fragment instead of search query
    responseMode: 'fragment' as OAuthResponseMode,

    // Configure TokenManager to use sessionStorage instead of localStorage
    tokenManager: {
      storage: 'memory',
    },

    scopes: ['profile', 'email', 'openid'],
  };

  const oktaClient = new OktaAuth(config);

  return oktaClient;
};
