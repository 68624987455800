import React, { useState } from 'react';
import { iWheel, iWheelMember, WheelMemberRole, WheelMemberRoleType } from '../../../../API/interfaces';
import styled from 'styled-components';
import { Text } from '../../../Shared/Typography/Typography';
import { RadioButton } from '../../../Shared/RadioButton';
import { customConfirm } from '../../../Shared/ConfirmModal/ConfirmationModal';
import { FiTrash2 } from 'react-icons/fi';
import { Button, ModalBody, ModalFooter, Popover, PopoverBody } from 'reactstrap';
import { BsThreeDotsVertical } from 'react-icons/bs';
import utils from '../../../Shared/utils';
import { Section } from '../../../Shared/Layout/Section';
import { InitialsCircle } from '../../../Shared/InitialsCircle/InitialsCircle';
import { FiUser } from 'react-icons/fi';
import { useSelector } from 'react-redux';
import { selectUser } from '../../../Auth/Auth.selectors';
import MemberDropdown from '../MemberResults/MemberDropdown/MemberDropdown';
import CheckInAssistIcon from '../../../../Assets/images/checkin-assist.png';
import ExpandButton from '../../../Shared/ExpandButton';
import { AssistMember } from '../../WheelPage';
import { analytics } from '../../../../analytics/analytics';
import { TextField, Tooltip } from '@material-ui/core';
import { BlackBtn, RedBtn, StyledModalOB, toolTipStyles } from '../../../Auth/Onboarding/_styled';
import alertIcon from '../../../../Assets/images/onboarding/alert-circle.svg';
import refreshIcon from '../../../../Assets/images/invitations/refresh-cw.svg';
import services from '../../../../API/services';
import { toasterService } from '../../../Shared/Toaster/Toaster.service';
import { FaTimes } from 'react-icons/fa';
import * as yup from 'yup';
import { useFormik } from 'formik';

interface iWheelMemberProps {
  wheelId?: string;
  member: iWheelMember;
  wheelName: string;
  adminsNumber?: number | null;
  onChangeRole: (userId: string, newRole: WheelMemberRole) => void;
  onDeleteMember: (userId: string) => void;
  allWheelMembers?: iWheelMember[];
  wheelRole?: WheelMemberRoleType;
  isAdminList: boolean;
  openCheckinAssistModal?: (member: AssistMember) => void;
}

const validationSchema = yup.object({
  email: yup.string().email('Invalid email format').required('Email is required'),
});

export const WheelMember: React.FC<iWheelMemberProps> = ({
  wheelId,
  member,
  wheelName,
  adminsNumber = null,
  onChangeRole,
  onDeleteMember,
  allWheelMembers,
  wheelRole,
  openCheckinAssistModal,
}) => {
  const { memberRole, firstName, lastName, userId, accountabilityBuddyFor } = member;
  const [popoverOpen, setPopoverOpen] = useState(false);
  const toggle = () => setPopoverOpen(!popoverOpen);
  const user = useSelector(selectUser);
  const memberFullName = `${firstName} ${lastName}`;
  const isAccountabilityBuddyList = memberRole === WheelMemberRole.ACCOUNTABILITY_BUDDY;
  const isAdminList = memberRole === WheelMemberRole.ADMIN;
  const isMemberList = memberRole === WheelMemberRole.MEMBER;
  const isWheelAdmin = wheelRole === WheelMemberRole.ADMIN;
  const showCheckInAssistButton = isMemberList && isWheelAdmin;
  const classes = toolTipStyles();
  const [modal, setModal] = useState(false);
  const [email, setEmail] = useState(member.email);
  const [isSending, setIsSending] = useState(false);

  const toggleModal = () => setModal(!modal);

  const handleResendClick = () => {
    toggleModal();
  };

  const formik = useFormik({
    initialValues: {
      email: member.email || '',
    },
    validationSchema,
    onSubmit: async (values) => {
      setIsSending(true);
      const response = await services.resendEmailToManualUser(wheelId, member.userId, email);
      setIsSending(false);

      if (response.status === 200) {
        toasterService.addSuccessToast('Invitation re-sent successfully');
        toggleModal();
      } else {
        toasterService.addErrorToast('Error re-sending the invitation');
      }
    },
  });

  const changeRoleToAdmin = async (value: WheelMemberRole) => {
    const answer = await customConfirm({
      title: `Are you sure that you want to assign admin rights to ${memberFullName}?`,
      body: ` As an admin, access will be granted to: Wheel settings, Team results, Individual member results, User management.`,
      confirmationText: 'CHANGE ROLE',
    });
    if (answer) {
      onChangeRole(userId, value);
    }
  };

  const changeRoleToMember = async (value: WheelMemberRole) => {
    const answer = await customConfirm({
      title: `Are you sure that you want to change ${memberFullName} to a regular member?`,
      body: 'Admin permissions will be removed.',
      confirmationText: 'CHANGE ROLE',
    });
    if (answer) {
      onChangeRole(userId, value);
    }
  };

  const deleteMember = async () => {
    const title = isAccountabilityBuddyList
      ? `Remove ${memberFullName} as accountability buddy?`
      : `Delete ${memberFullName}?`;
    const body = isAccountabilityBuddyList
      ? `Are you sure you want to remove ${memberFullName} as an accountability buddy for ${wheelName}?`
      : 'All data will be deleted. You will not be able to restore the data of this member.';
    const confirmationText = isAccountabilityBuddyList ? 'REMOVE' : 'DELETE';
    const answer = await customConfirm({
      title,
      body,
      confirmationText,
    });
    if (answer) {
      onDeleteMember(userId);
    }
  };

  const isMobile = utils.checkIsPhone();

  const onCheckinAssist = () => {
    openCheckinAssistModal({
      id: userId,
      name: memberFullName,
    });
    analytics.openMembersCheckinAssist();
  };

  const accountabilityBuddyForMembersList =
    allWheelMembers && accountabilityBuddyFor?.length
      ? accountabilityBuddyFor.map((userForABId: string) => {
          const accountabilityBuddyForMember = allWheelMembers.find((member) => userForABId === member.userId);
          return (
            accountabilityBuddyForMember && {
              ...accountabilityBuddyForMember,
              fullName: `${accountabilityBuddyForMember.firstName} ${accountabilityBuddyForMember.lastName}`,
            }
          );
        })
      : [];

  const isMyBuddy = member.accountabilityBuddyFor.includes(user._id);
  const membersDropdownInputText = `${accountabilityBuddyFor.length} members`;

  const AccountabilityBuddyControls = (
    <>
      {accountabilityBuddyForMembersList.length === 1 ? (
        <BuddyMemberContainer>
          <UserIconContainer>
            <FiUser color="#fff" size="8px" />
          </UserIconContainer>
          <Text color="#2b2e31" size="12px">
            {accountabilityBuddyForMembersList[0].fullName}
          </Text>
        </BuddyMemberContainer>
      ) : (
        <>
          {!!accountabilityBuddyForMembersList.length && (
            <MemberDropdown
              isDisabledAction
              className="education_member-dropdown"
              members={accountabilityBuddyForMembersList}
              inputText={membersDropdownInputText}
            />
          )}
        </>
      )}
    </>
  );

  const Controls = (
    <>
      <div className="d-lg-none d-block"></div>
      {wheelRole !== WheelMemberRole.MEMBER && (
        <>
          {isAccountabilityBuddyList ? (
            <>{AccountabilityBuddyControls}</>
          ) : (
            <RoleControls>
              <RadioButton
                value={WheelMemberRole.MEMBER}
                selected={memberRole}
                text="Member"
                disabled={
                  (adminsNumber && adminsNumber < 2) || (member?.isManuallyAddedUser && !member?.isPasswordReset)
                }
                onChange={changeRoleToMember}
                tooltipText={
                  member?.isManuallyAddedUser && !member?.isPasswordReset
                    ? `All wheels require at least one admin. Please add another admin.`
                    : `Invite is not accepted. Role change is unavailable temporarily.`
                }
              />
              <RadioButton
                value={WheelMemberRole.ADMIN}
                selected={memberRole}
                text="Admin"
                onChange={changeRoleToAdmin}
                disabled={member?.isManuallyAddedUser && !member?.isPasswordReset}
                tooltipText="Invite is not accepted. Role change is unavailable temporarily."
              />
            </RoleControls>
          )}
        </>
      )}
      <Section width="70px" mWidth="100%">
        {!isAdminList && (
          <div className="d-flex justify-content-start align-items-center mt-lg-0 mt-1">
            <Delete onClick={deleteMember}>
              <FiTrash2 color="#ff483c" size={isMobile ? 16 : 20} />
              <div className="d-lg-none d-inline">
                <Text color="#ff483c" weight={400} size="14px">
                  Delete Member
                </Text>
              </div>
            </Delete>
          </div>
        )}
      </Section>
    </>
  );

  const resendInvitationToolTip =
    "This member was manually invited via email but hasn't accepted the invitation or may not have received it yet. You can resend the invitation.";
  const manuallyAddedUserToolTip = 'Manually invited member.';

  return (
    <>
      <Rectangle key={userId}>
        <Section width="70%">
          <InitialsCircle className="mr-3">{`${firstName[0]?.toUpperCase()}${lastName[0]?.toUpperCase()}`}</InitialsCircle>
          <div className="d-flex flex-column justify-content-around">
            <div className="d-flex">
              <Text weight={500} lineHeight="16px" mSize="12px">
                {memberFullName}
              </Text>
              {member?.isManuallyAddedUser && (
                <Tooltip
                  arrow
                  title={manuallyAddedUserToolTip}
                  classes={{ tooltip: classes.tooltip, arrow: classes.arrow }}
                >
                  <img
                    src={alertIcon}
                    style={{ marginLeft: '5px', width: '14px', height: '14px', cursor: 'pointer' }}
                    alt=""
                  />
                </Tooltip>
              )}
            </div>
            {member?.isManuallyAddedUser && !member?.isPasswordReset ? (
              <Tooltip
                arrow
                title={resendInvitationToolTip}
                classes={{ tooltip: classes.tooltip, arrow: classes.arrow }}
              >
                <RefreshBtn onClick={handleResendClick}>
                  <img src={refreshIcon} alt="refresh" />
                  Re-send invitation
                </RefreshBtn>
              </Tooltip>
            ) : (
              <Text size="12px" mSize="9px" color="#5d666e">
                {member.email}
              </Text>
            )}
            <StyledModalOB id="styled-modal" isOpen={modal} centered>
              <ModalBody style={{ padding: '5px 2px' }}>
                <div className="d-flex justify-content-end">
                  <FaTimes className="close-icon" onClick={toggleModal} style={{ cursor: 'pointer' }} />
                </div>
                <div className="d-flex flex-column">
                  <Text size="30px" mSize="12px" className="mb-4">
                    Re-send Invitation to {member.firstName} {member.lastName}
                  </Text>
                  <Text className="mb-4">
                    The member could have missed your invitation. Remind them by sending it again.
                  </Text>
                  <form onSubmit={formik.handleSubmit}>
                    <TextField
                      id="email"
                      fullWidth
                      label="Member Email"
                      variant="outlined"
                      placeholder="Member Email"
                      type="email"
                      value={formik.values.email}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={formik.touched.email && Boolean(formik.errors.email)}
                      helperText={formik.touched.email && formik.errors.email}
                      style={{ width: '100%' }}
                    />
                  </form>
                </div>
              </ModalBody>
              <ModalFooter style={{ borderTop: 'none', marginTop: '25px', padding: '5px 2px' }}>
                <BlackBtn onClick={toggleModal}>Cancel</BlackBtn>
                <RedBtn
                  onClick={() => formik.handleSubmit()}
                  style={{ marginLeft: '10px' }}
                  disabled={isSending || !formik.isValid}
                >
                  {isSending ? 'Sending...' : 'Re-send Invite'}
                </RedBtn>
              </ModalFooter>
            </StyledModalOB>
          </div>
          {isMyBuddy && (
            <Text color="#005ebd" size="12px" margin="0 4px">
              your buddy
            </Text>
          )}
          {showCheckInAssistButton && (
            <ExpandButtonContainer>
              <ExpandButton Icon={CheckInAssistIcon} label="Check in assist" width={105} onClick={onCheckinAssist} />
            </ExpandButtonContainer>
          )}
        </Section>
        <Section width="30%" className="d-flex justify-content-end">
          <div className="d-none d-lg-flex">{Controls}</div>
          <div className="d-flex d-lg-none">
            <div className="d-lg-none d-flex justify-content-end">
              <Button id={`Popover_${userId}`} type="button" color="link">
                <BsThreeDotsVertical color="#000000" />
              </Button>
              <Popover
                placement="left-start"
                isOpen={popoverOpen}
                target={`Popover_${userId}`}
                toggle={toggle}
                trigger="legacy"
              >
                <PopoverBody>{Controls}</PopoverBody>
              </Popover>
            </div>
          </div>
        </Section>
      </Rectangle>
    </>
  );
};

const Rectangle = styled.div`
  width: 100%;
  height: 60px;
  padding: 15px;
  border-radius: 2px;
  border: solid 1px #cdd4da;
  border-bottom: none;
  background-color: #ffffff;
  display: flex;
  align-items: center;

  &:last-child {
    border-bottom: solid 1px #cdd4da;
  }

  @media (max-width: 1024px) {
    padding: 10px;
  }
`;

const Delete = styled.div`
  padding: 0 30px;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    cursor: pointer;
  }

  @media (max-width: 1024px) {
    padding: 0;
    svg {
      margin-right: 10px;
    }
  }
`;

const RoleControls = styled.div`
  display: flex;
  width: 200px;
  justify-content: space-between;
  @media (max-width: 1024px) {
    flex-direction: column;
  }
`;

const BuddyMemberContainer = styled.div`
  display: flex;
  align-items: center;
  border: 1px solid #cdd4da;
  border-radius: 16px;
  padding: 4px 8px 4px 4px;
`;

const UserIconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: #cdd4da;
  height: 16px;
  width: 16px;
  margin-right: 4px;
`;

const ExpandButtonContainer = styled.div`
  margin-left: 24px;
`;

const RefreshBtn = styled.div`
  border: none;
  border-radius: 16px;
  background-color: #ededed;
  margin-top: 3px;
  padding: 3px 8px;
  cursor: pointer;
  font-size: 12px;
  line-height: normal;
  color: #005ebd;
  display: flex;
  align-items: center;
  gap: 8px;

  &:hover {
    background-color: #dedede;
  }
`;
