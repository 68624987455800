import styled from 'styled-components';
import React from 'react';
import { InitialsCircle } from '../Shared/InitialsCircle/InitialsCircle';
import { Text } from '../Shared/Typography/Typography';
import { FiAlertCircle, FiClock, FiMoreVertical, FiRefreshCcw, FiTrash2, FiUser, FiUserCheck } from 'react-icons/fi';
import { makeStyles, MenuItem, Tooltip } from '@material-ui/core';
import { Invitation, InvitationStatus } from './invitation.model';
import { IconButton } from '@material-ui/core';
import { Menu } from '@material-ui/core';
import { makeTooltipText } from './invitation.utils';
import { AccountPlan } from '../../API/interfaces';

interface MembershipProps {
  invitation: Invitation;
  plan: AccountPlan;
  onDelete: () => void;
  onResend: () => void;
}

export const Membership = ({ invitation, plan, onDelete, onResend }: MembershipProps) => {
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const buildStatus = (status: InvitationStatus) => {
    switch (status) {
      case InvitationStatus.JOINED:
        return (
          <Status bColor="#2a3e50" className="mr-2 mr-lg-0">
            <FiUserCheck size={12} color="#ffffff" className="mr-1 d-inline-block" />
            <div className={classes.statusText}>Joined</div>
          </Status>
        );
      case InvitationStatus.PENDING:
        return (
          <Tooltip title={makeTooltipText(plan)} aria-label="add" classes={{ tooltip: classes.tooltip }}>
            <Status bColor="#cececf" className="cursor-pointer mr-2 mr-lg-0">
              <FiClock size={12} color="#ffffff" className="mr-1 d-inline-block" />
              <div className={classes.statusText}>Pending</div>
            </Status>
          </Tooltip>
        );
      case InvitationStatus.ERROR:
        return (
          <Status bColor="#fb463b" className="mr-2 mr-lg-0">
            <FiAlertCircle size={12} color="#ffffff" className="mr-1 d-inline-block" />
            <div className={classes.statusText}>Error</div>
          </Status>
        );
      default:
        return null;
    }
  };

  return (
    <div className={`${classes.membershipContainer} p-3`}>
      <div className={classes.nameSection}>
        {invitation.status === InvitationStatus.JOINED ? (
          <>
            <InitialsCircle className="mr-3">{`${invitation.firstName?.[0].toUpperCase()}${invitation.lastName?.[0].toUpperCase()}`}</InitialsCircle>
            <div className="d-flex flex-column justify-content-center">
              <Text weight={500} size="16px" mSize="12px" lineHeight={1}>
                {invitation.firstName} {invitation.lastName}
              </Text>
              <Text weight={400} size="12px" mSize="12px" lineHeight={1} color="#5d666e">
                {invitation.email}
              </Text>
            </div>
          </>
        ) : (
          <>
            <InitialsCircle color="#cececf">
              <FiUser color="#ffffff" size="14" />
            </InitialsCircle>
            <div className="d-flex flex-column justify-content-center">
              <Text weight={400} size="16px" mSize="12px" lineHeight={1} color="#5d666e">
                {invitation.email}
              </Text>
            </div>
          </>
        )}
      </div>
      <div className={classes.controlsSection}>
        <div className={classes.statusContainer}>{invitation.status && buildStatus(invitation.status)}</div>
        <div className={`${classes.controlsContainer} mr-0 mr-lg-2`}>
          {invitation.status !== InvitationStatus.JOINED ? (
            <>
              <IconButton
                aria-label="more"
                aria-controls="long-menu"
                aria-haspopup="true"
                className="p-0"
                onClick={handleClick}
              >
                <FiMoreVertical color="#0f2136" size={16} />
              </IconButton>
              <Menu
                id="long-menu"
                anchorEl={anchorEl}
                keepMounted
                open={open}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
              >
                <MenuItem key={1} onClick={handleClose}>
                  <div onClick={onResend} className="d-flex align-items-center position-relative p-2">
                    <FiRefreshCcw size={16} color="#0f2136" style={{ position: 'relative', top: '-1px' }} />
                    <Text className="ml-2" lineHeight={1}>
                      Resend
                    </Text>
                  </div>
                </MenuItem>
                <MenuItem key={2} onClick={handleClose}>
                  <div onClick={onDelete} className="d-flex align-items-center position-relative p-2">
                    <FiTrash2 size={16} color="#fb463b" style={{ position: 'relative', top: '-1px' }} />
                    <Text className="ml-2" color="#fb463b" lineHeight={1}>
                      Delete
                    </Text>
                  </div>
                </MenuItem>
              </Menu>
            </>
          ) : (
            <FiTrash2 size={16} color="#fb463b" onClick={onDelete} className={classes.control} />
          )}
        </div>
      </div>
    </div>
  );
};

const useStyles = makeStyles({
  control: {
    cursor: 'pointer',
  },
  controlRefresh: {
    cursor: 'pointer',
    marginRight: '15px',
  },
  tooltip: {
    backgroundColor: '#ffffff',
    color: '#2b2e31',
    fontSize: '14px',
    lineHeight: 1.43,
    padding: '16px 24px',
    boxShadow: '1px 5px 32px 0 rgba(0, 0, 0, 0.1)',
    maxWidth: '316px',
    fontFamily: 'Rubik',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
  },
  membershipContainer: {
    height: '60px',
    objectFit: 'contain',
    borderRadius: '2px',
    border: 'solid 1px #cdd4da',
    backgroundColor: '#fff',
    display: 'flex',
    alignItems: 'center',
    borderBottom: 'none',
    '&:last-child': {
      borderBottom: 'solid 1px #cdd4da',
    },
  },
  controlsContainer: {
    display: 'flex',
    alignContent: 'center',
    justifyContent: 'flex-end',
    width: '50px',
  },
  controlsSection: {
    display: 'flex',
    width: '20%',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  statusContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    width: '100px',
  },
  statusText: {
    display: 'inline-block',
    color: '#fff',
  },
  nameSection: {
    display: 'flex',
    width: '80%',
  },
});

const Status = styled.div<{ bColor: string }>`
  height: 24px;
  padding: 3px 8px;
  border-radius: 20px;
  background-color: ${(props) => props.bColor};
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  display: flex;
  align-items: center;
`;
