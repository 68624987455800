// just bunch of chart configs
export default Object.freeze({
  bar: Object.freeze({
    type: 'bar',
    order: 0,
    barThickness: 88,
  }),
  mainLine: Object.freeze({
    type: 'line',
    fill: false,
    order: 0,
    borderColor: '#000000',
    pointBackgroundColor: '#000000',
    pointBorderColor: '#000000',
    pointHoverBackgroundColor: '#ffffff',
  }),
  line: Object.freeze({
    fill: false,
  }),
  options: Object.freeze({
    responsive: false,
    legend: {
      display: false,
    },
    tooltips: {
      mode: 'index',
      filter: (dataset) => !!dataset.datasetIndex, // do not include bar,
      position: 'nearest',
      bodySpacing: 4,
      xPadding: 18,
      yPadding: 18,
    },
  }),
  xAxes: Object.freeze({
    offset: true,
    gridLines: {
      display: true,
    },
    ticks: {
      fontColor: '#6A777F',
      reverse: true,
    },
  }),
  yAxesTicks: Object.freeze({
    display: true,
    stepSize: 1,
    beginAtZero: true,
    fontColor: '#6A777F',
  }),
});
