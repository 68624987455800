import React from 'react';

import { tIconProps } from '../../_types';

const UserIcon = ({ className }: tIconProps) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12">
    <defs>
      <filter id="jc4eoum1la" colorInterpolationFilters="auto">
        <feColorMatrix
          in="SourceGraphic"
          values="0 0 0 0 1.000000 0 0 0 0 1.000000 0 0 0 0 1.000000 0 0 0 1.000000 0"
        />
      </filter>
      <path
        id="f5gl6syoxb"
        d="M8 7c1.38 0 2.5 1.12 2.5 2.5v1c0 .276-.224.5-.5.5s-.5-.224-.5-.5v-1C9.5 8.672 8.828 8 8 8H4c-.828 0-1.5.672-1.5 1.5v1c0 .276-.224.5-.5.5s-.5-.224-.5-.5v-1C1.5 8.12 2.62 7 4 7zM6 1c1.38 0 2.5 1.12 2.5 2.5S7.38 6 6 6 3.5 4.88 3.5 3.5 4.62 1 6 1zm0 1c-.828 0-1.5.672-1.5 1.5S5.172 5 6 5s1.5-.672 1.5-1.5S6.828 2 6 2z"
      />
    </defs>
    <g fill="none" fillRule="evenodd">
      <g>
        <g>
          <g
            filter="url(#jc4eoum1la)"
            transform="translate(-452.000000, -322.000000) translate(308.000000, 252.000000) translate(136.000000, 64.000000)"
          >
            <g transform="translate(8.000000, 6.000000)">
              <mask id="x7vhgdem7c" fill="#fff">
                <use href="#f5gl6syoxb" />
              </mask>
              <use fill="#000" fillRule="nonzero" href="#f5gl6syoxb" />
              <g fill="#0C2337" mask="url(#x7vhgdem7c)">
                <path d="M0 0H12V12H0z" />
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default UserIcon;
