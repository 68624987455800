import React, { useEffect, useRef } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { makeStyles } from '@material-ui/core';
import { Lock } from 'react-feather';
import { analytics } from '../../analytics/analytics';

interface iWithLockedFeatureProps extends RouteComponentProps {
  lockFeature?: boolean;
}

// pass lockFeature: true if component should be locked
// you can control styling via lock-feature-wrapper and lock-feature-icon css classes
function withFeatureLock<CP>(Component: React.ComponentType<CP>, featureType) {
  return withRouter(({ lockFeature, history, location, match, ...props }: CP & iWithLockedFeatureProps) => {
    if (!lockFeature) {
      return <Component {...(props as CP)} />;
    }

    const classes = useStyles();
    const wrapperRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
      // interrupt event and go to features page
      const listener = (e) => {
        e.stopPropagation();
        // @ts-ignore
        const feature = featureType === 'tab' ? props.title.toLowerCase().replace(' ', '-') : featureType;
        analytics.clickOnLockedFeature(feature);
        history.push({ pathname: '/features', search: `?rf=${feature}` });
      };
      // triggers before synthetic event
      wrapperRef.current?.addEventListener('click', listener);

      return () => wrapperRef.current?.removeEventListener('click', listener);
    });

    return (
      <div ref={wrapperRef} className={`lock-feature-wrapper ${classes.wrapper}`}>
        <Lock className={`lock-feature-icon ${classes.lock}`} size={16} />
        <Component {...(props as CP)} />
        <div className={classes.cover} />
      </div>
    );
  });
}

export default withFeatureLock;

const useStyles = makeStyles({
  wrapper: {
    position: 'relative',
    filter: 'grayscale(100%)',
    opacity: 0.5,
  },
  lock: {
    position: 'absolute',
    top: '-16px',
    right: '-16px',
    zIndex: 1000,
    '@media(max-device-width: 768px)': {},
  },
  cover: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    zIndex: 1001,
    cursor: 'pointer',
  },
});
