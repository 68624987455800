import React from 'react';
import {
  ChevronDown as ChevronDownIcon,
  ChevronRight as ChevronRightIcon,
  ChevronUp as ChevronUpIcon,
  Folder as FolderIcon,
  MoreVertical as MoreVerticalIcon,
  Plus as PlusIcon,
} from 'react-feather';
import styled, { css } from 'styled-components';
import { InputGroup, InputGroupAddon, InputGroupText } from 'reactstrap';
import { Check as CheckIcon } from 'react-feather';

import of from '../../../_utils/of';
import { Button } from '../../Shared/_styled';

import { tProps } from './_types';
import { StyledSpan, CreateFolderButton, Container } from './_styled';
import { StyledInput } from './FolderDesktop';
import { cloneFolder } from './_utils';
import { FolderContextMenu } from './FolderContextMenu';
import { idEq } from '../_utils';
import { COLORS } from '../../../Templates/WheelChart';

const PREVENT_2ND_NESTING = true;

type tMobileProps = tProps & { sidebarExpanded: boolean };

export const FolderMobile = React.memo<tMobileProps>((props) => {
  const {
    active,
    onClick,
    child,
    id,
    name,
    showCreateModal,
    parent,
    selectedFolderId,
    updateFolder,
    duplicateFolder,
    deleteFolder,
    sidebarExpanded,
    inviteToFolder,
  } = props;

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [showInput, setShowInput] = React.useState(false);
  const [newFolderName, setNewFolderName] = React.useState('');

  const [isExpanded, setIsExpanded] = React.useState(false);
  const hasChildren = Boolean(child?.length);
  const showCreateSubfolder = active && isExpanded && PREVENT_2ND_NESTING && !parent;

  const isSomeChildActive = React.useMemo(() => child.some(idEq(selectedFolderId)), []);

  const memberInviteLink = `${window.location.origin}/join-folder/${id}`;
  const memberInviteAdminsLink = `${window.location.origin}/join-folder/${id}/admin`;

  React.useEffect(() => {
    if (isSomeChildActive && sidebarExpanded) {
      setIsExpanded(true);
    }
  }, [isSomeChildActive, sidebarExpanded]);

  // hide input on first render
  React.useEffect(() => {
    setShowInput(false);
  }, [sidebarExpanded]);

  // rewrite local state when
  React.useEffect(() => {
    if (!active && !hasChildren) {
      setIsExpanded(false);
    }
  }, [active, hasChildren]);

  const onContainerClick = React.useCallback(() => {
    onClick();
    if (!(hasChildren && isExpanded && !active)) {
      setIsExpanded(!isExpanded);
    }
  }, [isExpanded, hasChildren, active]);

  // context menu
  const onContextMenu = React.useCallback((e) => {
    e.stopPropagation();
    e.preventDefault();
    setAnchorEl(e.currentTarget);
  }, []);

  const onFolderNameChanged = React.useCallback(
    (name: string) => {
      if (name) {
        updateFolder({ name, id });
      } else {
        // TODO
        // show modal
        // are you sure you want to cancel the change
      }
      setShowInput(false);
      setNewFolderName('');
    },
    [newFolderName]
  );

  const handleKeyPress = React.useCallback((e) => {
    if (e.key === 'Enter') {
      onFolderNameChanged(e.target.value);
    }
  }, []);

  const onFolderNameChange = React.useCallback((e) => {
    setNewFolderName(e.target.value);
  }, []);

  const onRename = React.useCallback(() => {
    setShowInput(true);
    setAnchorEl(null);
  }, []);

  const onDuplicateFolder = React.useCallback(() => {
    duplicateFolder(props);
    setAnchorEl(null);
  }, [props]);

  const onDeleteFolder = React.useCallback(() => {
    deleteFolder(props);
    setAnchorEl(null);
  }, [props]);

  const onInviteToFolder = React.useCallback(() => {
    inviteToFolder(props);
    setAnchorEl(null);
  }, [props]);

  if (showInput) {
    return (
      <InputWrapperMobile>
        <StyledInput
          value={newFolderName}
          onChange={onFolderNameChange}
          onTouchLeave={() => onFolderNameChanged(newFolderName)}
          placeholder="Enter Folder Name"
          autoFocus
        />
        <InputGroupAddon addonType="append">
          <InputGroupText onClick={() => onFolderNameChanged(newFolderName)}>
            <CheckIcon size={16} color={COLORS.GREEN} />
          </InputGroupText>
        </InputGroupAddon>
      </InputWrapperMobile>
    );
  }

  return (
    <>
      <ContainerMobile active={active} hasParent={Boolean(parent)} onClick={onContainerClick}>
        {active ? (
          <MoreVerticalIcon onClick={onContextMenu} size="16px" style={{ marginRight: '8px' }} />
        ) : (
          <FolderIcon size="16px" style={{ marginRight: '8px' }} />
        )}

        <StyledSpan style={{ maxWidth: '150px' }}>{name}</StyledSpan>

        {hasChildren && (isExpanded ? <StyledChevronDownIcon size="16px" /> : <StyledChevronRightIcon size="16px" />)}
      </ContainerMobile>

      {isExpanded && hasChildren && child.map(of(FolderMobile))}

      {showCreateSubfolder && (
        <CreateFolderButton onClick={showCreateModal} style={{ marginLeft: '56px', marginTop: '8px' }}>
          <PlusIcon size="16px" style={{ marginRight: '8px' }} />
          Create Subfolder
        </CreateFolderButton>
      )}

      <FolderContextMenu
        folderName={props.name}
        isSubfolder={Boolean(parent)}
        anchorEl={anchorEl}
        onRename={onRename}
        onDuplicate={onDuplicateFolder}
        onDelete={onDeleteFolder}
        onClose={() => setAnchorEl(null)}
        onInvite={onInviteToFolder}
      />
    </>
  );
});

type tFirstFolderProps = Partial<tProps> & {
  showSelected: boolean;
  onChevronClick: (e: any) => void;
  sidebarExpanded: boolean;
};

export const FirstFolderMobile = ({
  active,
  onClick,
  name,
  onChevronClick,
  showSelected,
  sidebarExpanded,
}: tFirstFolderProps) => (
  <FirstFolderMobileContainer active={active && sidebarExpanded} onClick={onClick}>
    {!showSelected ? (
      'All Wheels'
    ) : (
      <>
        <FolderIcon size="16px" style={{ marginRight: '8px' }} />
        <StyledSpan style={{ maxWidth: '150px' }}>{name}</StyledSpan>
      </>
    )}
    <StyledButton onClick={onChevronClick}>
      {sidebarExpanded ? <ChevronUpIcon size={16} /> : <ChevronDownIcon size={16} />}
    </StyledButton>
  </FirstFolderMobileContainer>
);

const chevronIconStyles = css`
  position: absolute;
  right: 16px;
  top: 50%;
  transform: translate(-50%, -50%);
`;
export const StyledChevronRightIcon = styled(ChevronRightIcon)`
  ${chevronIconStyles};
`;
export const StyledChevronDownIcon = styled(ChevronDownIcon)`
  ${chevronIconStyles};
`;
export const StyledButton = styled(Button)`
  position: absolute;
  right: 4px;
  top: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
`;
const ContainerMobile = styled(Container)`
  &:before {
    display: none;
  }
`;
const FirstFolderMobileContainer = styled(ContainerMobile)`
  font-size: 14px;
  font-weight: normal;
  padding: 12px 16px 12px 32px;
`;
const InputWrapperMobile = styled(InputGroup)`
  padding: 4px 16px 4px 32px;
`;
