import React, { useState } from 'react';
import { Dialog, makeStyles, Typography } from '@material-ui/core';
import { RedBorderButton } from '../Buttons/Button';
import successIcon from '../../../Assets/images/success-icon.svg';
import failIcon from '../../../Assets/images/fail-icon.svg';
import ReactDOM from 'react-dom';

interface iInfoModalProps {
  title: string;
  description: string;
  status?: string;
  onConfirm?: () => void;
}

const infoRoot = document.createElement('div');
const body = document.querySelector('body');
body.appendChild(infoRoot);

export const showInfoModal = ({
  title = 'Please confirm your action',
  description = null,
  status = 'success',
  onConfirm,
}: iInfoModalProps) =>
  ReactDOM.render(
    <InfoModal title={title} description={description} status={status} onConfirm={onConfirm} />,
    infoRoot
  );

export const InfoModal = ({ title, description, status, onConfirm }: iInfoModalProps) => {
  const classes = useStyles();
  const [modal, setModal] = useState(true);

  const onClick = () => {
    setModal(!modal);
    ReactDOM.unmountComponentAtNode(infoRoot);
    onConfirm && onConfirm();
  };

  const statusImg = status === 'success' ? successIcon : failIcon;
  const imgSize = status === 'success' ? 72 : 112;

  return (
    <Dialog open={modal} classes={{ paper: classes.dialog }}>
      <img width={imgSize} height={imgSize} src={statusImg} alt="" />
      <Typography variant="h4" className={classes.title}>
        {title}
      </Typography>
      <Typography className={classes.text} dangerouslySetInnerHTML={{ __html: description }} />
      <div className={classes.footer}>
        <RedBorderButton className={classes.button} onClick={onClick}>
          GOT IT
        </RedBorderButton>
      </div>
    </Dialog>
  );
};

const useStyles = makeStyles((theme) => ({
  dialog: {
    padding: '40px',
    backgroundColor: '#fefaf2',
    maxWidth: '400px',
    [theme.breakpoints.down('md')]: {
      padding: '20px',
      margin: '20px',
    },
  },
  title: {
    marginTop: '24px',
  },
  text: {
    marginTop: '16px',
    whiteSpace: 'pre-line',
  },
  footer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '24px',
  },
  button: {
    boxShadow: 'none',
  },
}));
