import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import createSagaMiddleware from 'redux-saga';
import { persistReducer, persistStore, createMigrate } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { toasterMiddleware } from '../Components/Shared/Toaster/Toaster.middleware';
import RxApp from './Reducers';
import { mySaga } from './saga';
import migrations from './persistStoreMigrations';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['education', 'shareLink', 'userRD', 'session'],
  version: 0,
  migrate: createMigrate(migrations, { debug: false }),
};

const persistedReducer = persistReducer(persistConfig, RxApp);

const composeEnhancers = composeWithDevTools({
  // Specify name here, actionsBlacklist, actionsCreators and other options if needed
});

const sagaMiddleware = createSagaMiddleware();

export const store = createStore(
  persistedReducer,
  composeEnhancers(applyMiddleware(toasterMiddleware, sagaMiddleware))
);

export const persistor = persistStore(store);

sagaMiddleware.run(mySaga);
