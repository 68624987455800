import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core';
import { FiLink } from 'react-icons/fi';
import { Popover, PopoverBody } from 'reactstrap';
import { WheelMemberRole } from '../../../API/interfaces';

export const InviteButton = ({ link, role }) => {
  const classes = useStyles();
  const [popoverMsg, setPopoverMsg] = useState(null);

  const copyToClipboard = (url: string) => {
    console.log(url);
    if (navigator.clipboard) {
      navigator.clipboard
        .writeText(url)
        .catch(() => copyFallback(url))
        .then(
          () => togglePopover(true),
          () => togglePopover(false)
        );
    } else {
      copyFallback(url).then(
        () => togglePopover(true),
        () => togglePopover(false)
      );
    }
  };

  const copyFallback = (url: string): Promise<void> =>
    new Promise((resolve, reject) => {
      const textArea = document.createElement('textarea');
      textArea.value = url;
      textArea.style.position = 'fixed';
      textArea.style.top = '0';
      textArea.style.left = '0';
      textArea.style.width = '2em';
      textArea.style.height = '2em';
      textArea.style.padding = '0';
      textArea.style.border = 'none';
      textArea.style.outline = 'none';
      textArea.style.boxShadow = 'none';
      textArea.style.background = 'transparent';

      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();

      const isSuccessful = document.execCommand('copy');
      document.body.removeChild(textArea);

      isSuccessful ? resolve() : reject();
    });

  const togglePopover = (success: boolean) => {
    setPopoverMsg(success ? 'Url has been copied' : 'Unable to copy');
    setTimeout(() => setPopoverMsg(null), 3000);
  };

  return (
    <div className="position-relative">
      <div id="share-invite" className={classes.inviteButton} onClick={() => copyToClipboard(link)}>
        <FiLink size={14} className="mr-2" />
        <span>Invite {role === WheelMemberRole.MEMBER ? 'Members' : 'Admins'}</span>
      </div>
      <Popover placement="bottom" isOpen={!!popoverMsg} target="share-invite" toggle={() => setPopoverMsg(null)}>
        <PopoverBody>
          <span>{popoverMsg}</span>
        </PopoverBody>
      </Popover>
    </div>
  );
};

const useStyles = makeStyles({
  inviteButton: {
    height: '32px',
    display: 'inline-flex',
    alignItems: 'center',
    padding: '3px 12px 3px 8px',
    borderRadius: '24px',
    border: 'solid 1px rgba(64, 64, 66, 0.24)',
    backgroundColor: '#f8f8f8',
    cursor: 'pointer',
    position: 'relative',
  },
});
