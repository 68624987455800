import styled from 'styled-components';

export const InitialsCircle = styled.div<any>`
  margin-right: 12px;
  width: ${(props) => props.size};
  height: ${(props) => props.size};
  border-radius: 50%;
  font-size: ${(props) => props.fontSize};
  text-align: center;
  color: white;
  background: ${({ color }) => (color ? color : '#0c2337')};
  box-shadow: ${({ boxShadow }) => (boxShadow ? boxShadow : 'none')};
  display: flex;
  align-items: center;
  justify-content: center;
`;

InitialsCircle.defaultProps = {
  size: '32px',
  fontSize: '10px',
};
