import React, { FC, useRef } from 'react';
import './ControlButton.scss';
import _ from 'lodash';
import styled from 'styled-components';

interface iControlButtonProps {
  Icon: FC<any> | string;
  label: string;
  width: number; // use width in pixels for smooth transitions
  onClick?: () => void;
  id?: string;
  className?: string;
}

const StyledImage = styled.img`
  width: 16px;
  height: 16px;
  margin: 8px;
`;

export default ({ Icon, label, width, onClick, id, className }: iControlButtonProps) => {
  const labelRef = useRef<HTMLSpanElement>();

  return (
    <div
      id={id}
      className={`control-button${className ? ` ${className}` : ''}`}
      onClick={onClick}
      onMouseOver={() => (labelRef.current.style.width = width + 'px')}
      onMouseOut={() => (labelRef.current.style.width = 0 + 'px')}
    >
      <div className="d-flex align-items-center">
        {_.isString(Icon) ? (
          <StyledImage src={Icon as string} />
        ) : (
          <Icon color="#ffffff" size={16} className="control-button-icon" />
        )}
        <span className="control-button-label" ref={labelRef}>
          {label}
        </span>
      </div>
    </div>
  );
};
