import React, { Component } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { iCheckin, iWheel } from '../../../API/interfaces';
import services from '../../../API/services';
import { ManageContainer } from '../../_Containers/ManageContainer';
import { Spinner } from '../../Shared/Spinner/Spinner';
import Header from '../Header/Header';
import CheckinModal from '../CheckinModal/CheckinModal';
import NoResults from '../common/NoResults/NoResults';
import PublicResult from './PublicResult/PublicResult';
import { analytics } from '../../../analytics/analytics';
import './PublicWheelPage.scss';
import { PostCheckinPopup } from '../CheckinModal/PostCheckin/PostCheckinPopUp';
import { WHEEL_ACCESS_TYPES } from '../../../constants';
import AI from '../../AI/AI';

interface iPublicWheelPageParams {
  wheelId: string;
}

type iPublicWheelPage = RouteComponentProps<iPublicWheelPageParams>;

interface iPublicWheelState {
  isAiOpen: boolean;
  isLoading: boolean;
  wheel: iWheel | null;
  showCheckinModal: boolean;
  latestCheckin: iCheckin | null;
  postCheckinData: null | any;
  scoresData: null | any;
  segmentScores: Array<{ score: number; segmentName: string }>;
}

export default class extends Component<iPublicWheelPage, iPublicWheelState> {
  readonly state = {
    isAiOpen: false,
    isLoading: true,
    wheel: null,
    showCheckinModal: false,
    latestCheckin: null,
    postCheckinData: null,
    scoresData: null,
    segmentScores: [],
  };

  showCheckinModal = () => {
    this.setState({ showCheckinModal: true });
    analytics.createCheckinAttempt(true);
  };

  closeCheckinModal = (isCheckinCompleted, data) => {
    this.setState({
      showCheckinModal: false,
      latestCheckin: data,
      postCheckinData: data?.postCheckinData?.articles,
      scoresData: data?.scores,
      segmentScores: data?.scores ? this.calculateSegmentScores(this.state.wheel.segments, data.scores) : [],
    });
  };

  async componentDidMount() {
    const { data } = await services.getPublicWheelById(this.props.match.params.wheelId);

    // TODO remove _comments from BE response
    const wheel = data ? { ...data, segments: data.segments.filter((segment) => segment.name !== '_comments') } : null;
    this.setState({ isLoading: false, wheel });

    const { scoresData } = this.state;
    if (scoresData) {
      const updatedSegmentScores = this.calculateSegmentScores(wheel.segments, scoresData);
      this.setState({ segmentScores: updatedSegmentScores });
    }
  }

  calculateSegmentScores(segments, scoresData) {
    return segments.map((segment) => {
      const matchingScore = scoresData.find((score) => score.segment_id === segment.id);
      return {
        score: matchingScore ? matchingScore.score : 0,
        segmentName: segment.name,
        segmentId: segment.id,
      };
    });
  }

  render() {
    const { isLoading, wheel, showCheckinModal, latestCheckin, postCheckinData, segmentScores } = this.state;

    // TODO make common component
    if (isLoading) {
      return (
        <ManageContainer>
          <div className="wheel-page">
            <div className="main-spinner-container">
              <Spinner />
            </div>
          </div>
        </ManageContainer>
      );
    }

    return (
      <ManageContainer isPublic={true}>
        <div className="wheel-page">
          <Header wheel={wheel} toggleCheckinModal={this.showCheckinModal} />

          <div className="public-wheel-result">
            {latestCheckin && <PublicResult wheel={wheel} checkin={latestCheckin} />}
            {!latestCheckin && (
              <NoResults wheel={wheel} showCheckinModal={this.showCheckinModal} shouldShowDateDropdown={false} />
            )}
          </div>

          {showCheckinModal && <CheckinModal wheel={wheel} toggle={this.closeCheckinModal} />}

          {postCheckinData && (
            <PostCheckinPopup
              close={() => {
                this.setState({ postCheckinData: null });
              }}
              onOpenAI={() => this.setState({ isAiOpen: true })}
              segmentScores={segmentScores}
              accountName={wheel?.wheelCreator?.account?.accountName}
            />
          )}
        </div>
        <AI isOpen={this.state.isAiOpen} isPublic={true} onClose={null} wheel={wheel} checkin={latestCheckin} />
      </ManageContainer>
    );
  }
}
