import React, { FC, useMemo, useState } from 'react';
import styled from 'styled-components';
import { iWheel, iWheelMember, iWheelPermissions } from '../../../API/interfaces';
import { Spinner } from '../../Shared/Spinner/Spinner';
import MemberResults from '../Tabs/MemberResults/MemberResults';

interface iTabContainerProps {
  wheel: iWheel;
  wheelPermissions: iWheelPermissions;
  filteredMembers: iWheelMember[];
  setActiveBuddy?: (buddy: iWheelMember) => void;
}

const Root = styled.div`
  position: relative;
  margin-bottom: 40px;
  min-height: 615px;
  border-radius: 4px;
  background: #ffffff;
`;

const SpinnerBack = styled.div`
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  justify-content: center;
  z-index: 1000;
`;

const SpinnerContainer = styled.div`
  position: absolute;
  top: 50vh;
`;

export const AccountabilityBuddyWheelPage: FC<iTabContainerProps> = ({
  wheel,
  filteredMembers,
  wheelPermissions,
  setActiveBuddy,
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const PageBody = useMemo(
    () => (
      <MemberResults
        wheel={wheel}
        isAccountabilityBuddy
        members={filteredMembers}
        wheelPermissions={wheelPermissions}
        setIsLoading={setIsLoading}
        setActiveBuddy={setActiveBuddy}
      />
    ),
    [filteredMembers.length]
  );

  return (
    <Root>
      {isLoading && (
        <SpinnerBack>
          <SpinnerContainer>
            <Spinner />
          </SpinnerContainer>
        </SpinnerBack>
      )}
      {PageBody}
    </Root>
  );
};
