import { bindActionCreators } from 'redux';
import { store } from '../../../Redux/store';
import { addToast, removeToast, ToastTypes } from './Toaster.actions';

class ToasterService {
  actions = bindActionCreators({ addToast, removeToast }, store.dispatch);

  public addSuccessToast(message: string) {
    const { addToast } = this.actions;
    addToast({
      message,
      type: ToastTypes.SUCCESS,
    });
  }

  public addErrorToast(message: string) {
    const { addToast } = this.actions;
    addToast({
      message,
      type: ToastTypes.ERROR,
    });
  }

  public handleResponse(res, successMessage) {
    if (res.status === 200) {
      this.addSuccessToast(successMessage);
      return true;
    } else {
      const errorMessage = res.data?.message;
      this.addErrorToast(errorMessage ? errorMessage : res.statusText);
    }
    return false;
  }
}

export const toasterService = new ToasterService();
