import React, { Component } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { connect } from 'react-redux';
import { Typography, withStyles } from '@material-ui/core';
import { ArrowLeft } from 'react-feather';
import utils from '../Shared/utils';
import services from '../../API/services';
import { iFolder, iSegment, iWheel } from '../../API/interfaces';
import { ManageContainer } from '../_Containers/ManageContainer';
import Sidebar, { getAvailableSteps, STEPS, tStep } from './Sidebar/Sidebar';
import StepContainer from './Steps/StepContainer';
import Step from './Steps/Step';
import { MAX_WHEEL_SCALE, MIN_SEGMENTS, MIN_WHEEL_SCALE, WHEEL_ACCESS_TYPES, WHEEL_STEP_TYPES } from '../../constants';
import { analytics } from '../../analytics/analytics';
import { toasterService } from '../Shared/Toaster/Toaster.service';
import ExitPopup from './ExitPopup';
import nUser from '../../_utils/user';
import { FolderClient } from '../../API/folder.client';

interface iCreateWheelProps extends RouteComponentProps {
  user: nUser.iUser;
  isTemplate?: boolean;
  classes: any;
}

interface iCreateWheelState {
  activeStep: tStep;
  lastCompletedStep: tStep;
  stepToEdit: tStep | null;
  wheel: Partial<iWheel>;
  defaultSegments: Array<Partial<iSegment>>;
  showExitPopup: boolean;
  folders: iFolder[];
}

class CreateWheelPage extends Component<iCreateWheelProps, iCreateWheelState> {
  state = {
    activeStep: STEPS[0],
    lastCompletedStep: STEPS[0],
    stepToEdit: null,
    wheel: {
      name: '',
      description: '',
      segments: [],
      access: WHEEL_ACCESS_TYPES.PRIVATE,
      isScoreComments: false,
      maxScale: 10,
      step: WHEEL_STEP_TYPES.NUMBER,
      folder: '',
    },
    defaultSegments: [],
    folders: [],
    showExitPopup: false,
  };

  setStep = (nextStep: tStep) => {
    const { lastCompletedStep } = this.state;

    const newState = this.firstBefore(nextStep, lastCompletedStep)
      ? { activeStep: nextStep }
      : { activeStep: nextStep, lastCompletedStep: nextStep };

    // @ts-ignore
    this.setState(newState);
  };

  isValidTransition = (nextStep: tStep) => {
    const { lastCompletedStep } = this.state;

    return this.firstBefore(nextStep, lastCompletedStep);
  };

  firstBefore = (step1: tStep, step2: tStep) => {
    const steps = this.getSteps();

    return steps.indexOf(step1) <= steps.indexOf(step2);
  };

  getSteps = () => {
    const { user } = this.props;
    const { folders } = this.state;
    return getAvailableSteps(user, folders);
  };

  setStepToEdit = (step: tStep) => this.setState({ stepToEdit: step });

  toggleExitPopup = () => this.setState({ showExitPopup: !this.state.showExitPopup });

  onChangeWheel = (fieldName: string, value) => {
    this.setState({ wheel: { ...this.state.wheel, [fieldName]: value } });
  };

  onSubmit = async () => {
    const { isTemplate, history } = this.props;
    const { wheel } = this.state;

    utils.startLoading();

    const newWheel = {
      name: wheel.name,
      description: wheel.description,
      max_scale: wheel.maxScale,
      access: wheel.access,
      step: wheel.step,
      isScoreComments: wheel.isScoreComments,
      segments: wheel.segments.map((x) => {
        return {
          _id: x._id,
          name: x.name,
          description: x.description ? x.description : null,
        };
      }),
    };

    // @ts-ignore
    if (wheel.folder) {
      // @ts-ignore
      newWheel.folder = wheel.folder;
    }

    try {
      const response = await services[isTemplate ? 'createTemplate' : 'createWheel'](newWheel);

      history.push(isTemplate ? '/templates' : `/wheel/${response.data}`);
    } catch (e: any) {
      toasterService.addErrorToast(e?.message);
    } finally {
      utils.endLoading();
      analytics.createWheelSubmit(wheel.segments.length, wheel.access);
    }
  };

  isNextDisabled = (): boolean => {
    const { activeStep, wheel } = this.state;

    switch (activeStep) {
      case 'Wheel Name':
        return !wheel.name.trim();
      case 'Segments':
        return wheel.segments.length < MIN_SEGMENTS;
      case 'Settings':
        return wheel.maxScale < MIN_WHEEL_SCALE || wheel.maxScale > MAX_WHEEL_SCALE;
      default:
        return false;
    }
  };

  loadDefaultSegments = async () => {
    utils.startLoading();
    const defaultSegments = await services.getDefaultSegmentsList();

    this.setState({ defaultSegments }, () => utils.endLoading());
  };

  loadFolders = async () => {
    const { data: folders } = await FolderClient.get();

    this.setState({ folders });
  };

  async componentDidMount() {
    await this.loadDefaultSegments();

    if (nUser.hasAccessToFolders(this.props.user)) {
      await this.loadFolders();
    }
  }

  render() {
    const { classes, history, isTemplate } = this.props;
    const { activeStep, stepToEdit, wheel, defaultSegments, showExitPopup, folders, lastCompletedStep } = this.state;
    const steps = this.getSteps();

    return (
      <ManageContainer className={classes.manageContainer}>
        <div className={classes.root}>
          <Sidebar
            isTemplate={isTemplate}
            activeStep={activeStep}
            lastCompletedStep={lastCompletedStep}
            isValidTransition={this.isValidTransition}
            setStep={this.setStep}
            stepToEdit={stepToEdit}
            steps={steps}
          />

          <div className={classes.createWheelSection}>
            <div>
              <Typography className={classes.backToDashboard} onClick={this.toggleExitPopup}>
                <ArrowLeft size={16} stroke="#2a3e50" />
                Back to {isTemplate ? 'Templates' : 'Dashboard'}
              </Typography>
              <Typography className={classes.title}>Create New {isTemplate ? 'Template' : 'Wheel'}</Typography>
            </div>

            <StepContainer
              isTemplate={isTemplate}
              activeStep={activeStep}
              setStep={this.setStep}
              isNextDisabled={this.isNextDisabled()}
              onSubmit={this.onSubmit}
              steps={steps}
            >
              <Step
                isTemplate={isTemplate}
                wheel={wheel}
                defaultSegments={defaultSegments}
                step={activeStep}
                onChange={this.onChangeWheel}
                setStep={this.setStep}
                setStepToEdit={this.setStepToEdit}
                folders={folders}
              />
            </StepContainer>
          </div>

          {showExitPopup && (
            <ExitPopup
              isTemplate={isTemplate}
              onCancel={this.toggleExitPopup}
              onConfirm={() => history.push(isTemplate ? '/templates' : '/dashboard')}
            />
          )}
        </div>
      </ManageContainer>
    );
  }
}

const styles = {
  root: {
    display: 'flex',
    minHeight: 'calc(100vh - 70px - 74px)', // minus header and footer height
    '@media(max-device-width: 768px)': {
      flexDirection: 'column',
      minHeight: 'calc(100vh - 70px)', // minus header height
    },
  },
  createWheelSection: {
    padding: '24px 48px 72px',
    color: '#0c2337',
    '@media(max-device-width: 768px)': {
      padding: '32px 16px',
    },
  },
  backToDashboard: {
    fontSize: '14px',
    lineHeight: 1.7,
    color: '#2a3e50',
    '& svg': {
      marginRight: '10px',
    },
    cursor: 'pointer',
    userSelect: 'none',
    '@media(max-device-width: 768px)': {
      display: 'none',
    },
  },
  title: {
    marginTop: '16px',
    fontSize: '32px',
    lineHeight: 1.25,
    '@media(max-device-width: 768px)': {
      marginTop: 0,
      fontSize: '18px',
      lineHeight: 1.33,
    },
  },
};
const mapStateToProps = (state) => ({ user: state.userRD.user });

// @ts-ignore
export default withStyles(styles)(connect(mapStateToProps)(CreateWheelPage));
