import { HttpClient } from './HttpClient';

export class CommentClient {
  static async createReply(commentId, body) {
    return HttpClient.post(`/comments/${commentId}/replies`, body).catch((error) => error.response);
  }

  static async editReply(commentId: string, replyId: string, body) {
    return HttpClient.put(`/comments/${commentId}/replies/${replyId}`, body).catch((error) => error.response);
  }

  static async deleteReply(commentId: string, replyId: string) {
    return HttpClient.delete(`/comments/${commentId}/replies/${replyId}`).catch((error) => error.response);
  }
}
