import { InvitationActionTypes } from './invitation.model';

const initialState = {
  link: null,
};

export function invitationReducer(state = initialState, action) {
  switch (action.type) {
    case InvitationActionTypes.SAVE_INVITATION_LINK:
      return {
        ...state,
        link: action.payload,
      };
    case InvitationActionTypes.RESET_INVITATION_LINK:
      return {
        ...state,
        link: null,
      };
    default:
      return state;
  }
}
