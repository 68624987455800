import React, { useState } from 'react';
import { WheelMemberRoleType, WheelRoleNaming } from '../../API/interfaces';
import { Button, CancelButton } from '../Shared/Buttons/Button';
import { Spinner } from '../Shared/Spinner/Spinner';
import styled from 'styled-components';
import { Text } from '../Shared/Typography/Typography';
import { BlackBtn, RedBtn } from '../Auth/Onboarding/_styled';
import { WHEEL_ACCESS_TYPES } from '../../constants';
import { Checkbox } from './../Shared/Inputs/Checkbox';

interface iBrowserStepProps {
  wheelName: string;
  wheelAccess: string;
  invitingMemberName: string;
  onCancel: () => void;
  onSubmit: () => void;
  inviteRole: WheelMemberRoleType;
}

export default ({ wheelName, wheelAccess, invitingMemberName, onCancel, onSubmit, inviteRole }: iBrowserStepProps) => {
  const [isWearableConsentChecked, setWearableConsentChecked] = useState(false);

  const handleCheckboxChange = () => {
    setWearableConsentChecked(!isWearableConsentChecked);
  };

  const isJoinDisabled = wheelAccess === WHEEL_ACCESS_TYPES.WEARABLE && !isWearableConsentChecked;

  return wheelName ? (
    <Container>
      <Text size="18px" weight={500}>
        {invitingMemberName} has invited you to join their Wheel “{wheelName}” as {WheelRoleNaming[inviteRole]}.
      </Text>

      {wheelAccess === WHEEL_ACCESS_TYPES.WEARABLE && (
        <>
          <Text size="18px">
            ☝️ <b>Please note,</b> as a Member by joining,{' '}
            <b>
              your wearable health data - such as heart rate, activity levels, and sleep patterns - will be shared with
              the Admins of this wheel.
            </b>
            <br />
            This data will be used to provide deeper insights into and help track your progress.
          </Text>
          <WearableConsent>
            <Checkbox
              id="wearable-checkbox"
              className="signup_checkbox"
              checked={isWearableConsentChecked}
              onChange={handleCheckboxChange}
            >
              <Text size="18px">I understand and consent to share my data with the admin of this wheel.</Text>
            </Checkbox>
          </WearableConsent>
        </>
      )}

      <ButtonContainer>
        <RedBtn onClick={onSubmit} disabled={isJoinDisabled}>
          Join
        </RedBtn>
        <BlackBtn onClick={onCancel}>Decline Invitation</BlackBtn>
      </ButtonContainer>
    </Container>
  ) : (
    <SpinnerContainer>
      <Spinner />
    </SpinnerContainer>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 32px;
  margin-top: 10px;
`;

const WearableConsent = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: start;
  gap: 24px;
`;

const SpinnerContainer = styled.div`
  display: flex;
  justify-content: start;
  align-items: start;
  height: 100px;
`;
