import React from 'react';
import styled from 'styled-components';
import { Text } from '../../Shared/Typography/Typography';
import { CgClose } from 'react-icons/cg';

type Props = {
  file: File;
  isFullWidth?: boolean;
  onRemove: () => void;
};

const FilePreviewWrapper = styled.div`
  display: flex;
  align-items: start;
  margin: 0 8px 8px 0;
`;

const FilePreviewContainer = styled.div<{ isFullWidth?: boolean }>`
  padding: 8px 12px;
  border-radius: 4px;
  background-color: #ededed;
  display: flex;
  flex-direction: column;
  min-width: ${({ isFullWidth }) => (isFullWidth ? '100%' : '300px')};
`;

const RemoveButtonContainer = styled.div`
  cursor: pointer;
`;

const FileUploadPreviewItem: React.FC<Props> = ({ file, onRemove, isFullWidth = false }) => {
  const formatBytes = (bytes, decimals = 2) => {
    if (!+bytes) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
  };

  const fileDetailsText = () => {
    const fileType = file.type.split('/')[1].toUpperCase();
    return `${fileType} ${fileType === 'PDF' ? 'document' : 'image'}${file.size ? ` - ${formatBytes(file.size)}` : ''}`;
  };

  return (
    <FilePreviewWrapper>
      <FilePreviewContainer isFullWidth={isFullWidth}>
        <Text size="14px" weight={500}>
          {file.name}
        </Text>
        <Text color="#6a777f" size="12px">
          {fileDetailsText()}
        </Text>
      </FilePreviewContainer>
      <RemoveButtonContainer onClick={onRemove}>
        <CgClose color="#7b858e" size="16px" />
      </RemoveButtonContainer>
    </FilePreviewWrapper>
  );
};

export default FileUploadPreviewItem;
