import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Text } from '../Shared/Typography/Typography';
import SettingsForm from './SettingsForm/SettingsForm';
import './Settings.scss';
import { updatePersonalDataAction } from '../Auth/Auth.actions';
import { AccountPlan, iUser } from '../../API/interfaces';
import { RootState } from '../../Redux/Reducers';
import { useHistory } from 'react-router-dom';
import PasswordForm from './PasswordForm/PasswordForm';

interface AccountProps extends PropsFromRedux {}

const Account: React.FC<AccountProps> = ({ user }) => {
  const history = useHistory();

  const upgradeOnClick = () => {
    history.push({ pathname: '/features', search: '?rf=page-header' });
  };

  return (
    <div className="settings-tab">
      <div className="d-flex align-items-center">
        <Text size="18px" weight={500}>
          {user?.account?.plan ? `${user.account.plan} plan` : 'Free plan'}
        </Text>
        {(!user?.account || user?.account.plan === AccountPlan.FREE) && (
          <button className="edit-button" onClick={upgradeOnClick} style={{ marginLeft: '10px' }}>
            Upgrade
          </button>
        )}
      </div>
      {user?.account && (
        <div className="info-card">
          <div className="info-item">
            <div className="d-flex flex-column">
              <Text size="14px">Account name</Text>
              <Text size="16px" weight={700}>
                {user.account.accountName}
              </Text>
            </div>
          </div>

          <div className="info-item">
            <div className="d-flex flex-column">
              <Text size="14px">Account role</Text>
              <Text size="16px" weight={700}>
                {user.accountRole}
              </Text>
            </div>
          </div>
        </div>
      )}
      <div className="edit-container">
        <Text size="18px" weight={500}>
          Connect to iyarn with Email
        </Text>
        <div className="d-flex justify-content-between">
          <SettingsForm user={user} fields={['email']} />
          <PasswordForm />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  user: state.userRD.user || {},
});

const mapDispatchToProps = {
  updatePersonalDataAction,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(Account);
