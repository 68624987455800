import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Copy, Settings, LifeBuoy } from 'react-feather';
import { iUser, iWheel } from '../../../../API/interfaces';
import { analytics } from '../../../../analytics/analytics';
import ControlButton from '../../common/ControlButton/ControlButton';
import { isWheelAccountabilityBuddy, isWheelAdmin, isWheelMemberRole } from '../../../Shared/utils';
import './Controls.scss';
import InviteToWheelModal from '../../WheelInviteModal/InviteToWheelModal';
import { UserPlus } from 'react-feather';

interface iControlsProps extends RouteComponentProps {
  wheel: iWheel;
  user: iUser;
  toggleDuplicateWheelModal: () => void;
}

export default withRouter(({ wheel, user, history, toggleDuplicateWheelModal }: iControlsProps) => {
  const [wheelToInvite, setWheelToInvite] = React.useState<iWheel | null>(null);
  const isAdmin = isWheelAdmin(wheel, user._id);
  const isAccountabilityBuddy = isWheelAccountabilityBuddy(wheel, user._id);
  const isMember = isWheelMemberRole(wheel, user._id);

  const inviteButtonProps = {
    label: `Invite${isMember ? ' accountability buddy' : ''}`,
    Icon: isAdmin ? UserPlus : LifeBuoy,
    width: isAdmin ? 50 : 190,
    onClick: () => {
      setWheelToInvite(wheel);
      isMember ? analytics.clickInviteBuddy() : analytics.shareWheel();
    },
  };

  return (
    <div className="wheel-controls">
      {isAdmin && (
        <ControlButton
          Icon={Settings}
          label="Settings"
          width={63}
          onClick={() => {
            history.push(`/wheel/${wheel.id}/settings`);
            analytics.settingsOnWheelPage();
          }}
        />
      )}

      <ControlButton Icon={Copy} label="Duplicate Wheel" width={115} onClick={toggleDuplicateWheelModal} />

      {!isAccountabilityBuddy && (
        <>
          <ControlButton {...inviteButtonProps} />
          <InviteToWheelModal wheel={wheelToInvite} onCancel={() => setWheelToInvite(null)} />
        </>
      )}
    </div>
  );
});
