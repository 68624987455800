import styled from 'styled-components';

import { MainButton } from '../../Shared/_styled';
import { noScrollbarCss } from '../Sidebar/_styled';

export const StyledMainButton = styled(MainButton)`
  font-size: 14px;
  font-weight: 700;
  background: linear-gradient(180deg, #f79070, #ff4e4b);
  border-radius: 29px;
  margin-left: 24px;
  padding: 17px 36px;
`;

export const List = styled.ul`
  display: flex;
  flex-wrap: wrap;
`;

export const Cards = styled.div`
  max-height: calc(100vh - 70px - 181px);
  overflow-y: scroll;
  ${noScrollbarCss}
`;
