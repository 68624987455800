import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Drawer, makeStyles, TextField, Typography, MenuItem, Tooltip } from '@material-ui/core';
import { X } from 'react-feather';
import services from '../../API/services';
import { AccountType, iMember, iUser, SendSupportMessageParams } from '../../API/interfaces';
import { analytics } from '../../analytics/analytics';
import { toasterService } from '../Shared/Toaster/Toaster.service';
import { Button } from '../Shared/Buttons/Button';
import { RootState } from '../../Redux/Reducers';
import { SupportDrawerType, HelpOptions } from './SupportButton';

interface iSupportFormProps {
  user: iUser;
  open: boolean;
  onClose: () => void;
  drawerType: SupportDrawerType;
}

const MainSection = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const ContentWrapper = styled.div`
  display: contents;
`;

const inputsDefaultState = { message: '', supportEmail: '', email: '' };

const SupportDrawer = ({ user, open, onClose, drawerType }: iSupportFormProps) => {
  const classes = useStyles();
  const [inputs, setInputs] = useState<SendSupportMessageParams>(inputsDefaultState);
  const [options, setOptions] = useState<{ value: string; name: string }[]>([]);

  const isSupportNowDrawer = drawerType === HelpOptions.SupportNow;
  const isUserSchoolPlan = user?.accountTag === AccountType.SCHOOL;

  const getAdminsList = async () => {
    if (!isSupportNowDrawer) return;
    await services.getWheelAdmins().then(({ data }) => {
      const mappedOptions = data.map((admin: iMember) => ({
        name: `${admin.firstName} ${admin.lastName}`,
        value: admin.email,
      }));
      setOptions(mappedOptions);
    });
  };

  useEffect(() => {
    setInputs({ ...inputs, email: user?.email || '' });
    isSupportNowDrawer && getAdminsList();
  }, [user, drawerType]);

  const closeDrawer = () => {
    onClose();
    setInputs(inputsDefaultState);
  };
  const onSubmit = async (e) => {
    e.preventDefault();
    closeDrawer();

    try {
      await services.sendSupportMessage(inputs);
      toasterService.addSuccessToast('Thank you for your message. You will receive the response on your e-mail');
    } catch (e: any) {
      toasterService.addErrorToast(e.message);
    }

    isSupportNowDrawer ? analytics.supportNowSent() : analytics.submitHelp();
  };

  const title = isSupportNowDrawer ? 'Organisation support request' : 'We’re here to help you out';
  const description = isSupportNowDrawer
    ? 'If you need help or support now, please select who you would like a message to go to and they will receive an email notification and be in touch as soon as possible.'
    : 'If you have any questions, just reach out to us and we’ll respond via email as soon as we can.';

  const messagePlaceholder = isSupportNowDrawer
    ? 'Enter your message here…'
    : 'Please provide as much information as possible here…';

  const isDisabledSendButton = isSupportNowDrawer
    ? !inputs.supportEmail || !inputs.message
    : !inputs.email || !inputs.message;

  const emptySupportAdminsListText = 'You do not have anyone else associated with this account.';

  return (
    <Drawer anchor="right" open={open} onClose={closeDrawer} classes={{ paper: classes.drawer }}>
      <ContentWrapper>
        <X className={classes.closeBtn} onClick={closeDrawer} size={16} />
        <Typography variant="h4">{title}</Typography>
        <Typography className={classes.description}>{description}</Typography>

        <form className={classes.form} onSubmit={onSubmit}>
          <MainSection>
            {!user && drawerType === HelpOptions.HelpRequest && (
              <TextField
                type="email"
                classes={{ root: classes.textField }}
                size="small"
                placeholder="Enter your Email"
                variant="outlined"
                onChange={(e) => setInputs({ ...inputs, email: e.target.value })}
              />
            )}
            {isSupportNowDrawer && (
              <Tooltip
                arrow
                title={!options.length ? emptySupportAdminsListText : ''}
                classes={{ tooltip: classes.tooltip, arrow: classes.arrow }}
              >
                <TextField
                  fullWidth
                  select
                  variant="outlined"
                  label="Choose support person"
                  value={inputs.supportEmail}
                  onChange={(e) => setInputs({ ...inputs, supportEmail: e.target.value })}
                  className={classes.root}
                  disabled={!options.length}
                >
                  {options.map(({ name, value }) => (
                    <MenuItem key={value} value={value}>
                      {name}
                    </MenuItem>
                  ))}
                </TextField>
              </Tooltip>
            )}
            <TextField
              required
              classes={{ root: classes.textField }}
              size="small"
              placeholder={messagePlaceholder}
              variant="outlined"
              multiline
              rows={8}
              onChange={(e) => setInputs({ ...inputs, message: e.target.value })}
            />
            {isSupportNowDrawer && !isUserSchoolPlan && (
              <TextField
                fullWidth
                size="small"
                placeholder="Your Phone Number"
                variant="outlined"
                type="number"
                onChange={(e) => setInputs({ ...inputs, phoneNumber: e.target.value })}
                className={classes.phoneInput}
                value={inputs.phoneNumber}
              />
            )}
          </MainSection>

          <Button className={classes.submitBtn} disabled={isDisabledSendButton}>
            Send
          </Button>
        </form>
      </ContentWrapper>
    </Drawer>
  );
};

export default connect((state: RootState) => ({ user: state.userRD.user }))(SupportDrawer);

const useStyles = makeStyles({
  drawer: {
    padding: '60px 32px 48px',
    maxWidth: '360px',
    '@media(max-device-width: 767px)': {
      maxWidth: 'none',
      width: '100%',
    },
  },
  closeBtn: {
    position: 'absolute',
    top: '30px',
    right: '30px',
    cursor: 'pointer',
  },
  description: {
    marginTop: '24px',
    fontSize: '16px',
    lineHeight: 1.5,
  },
  form: {
    marginTop: '16px',
    height: '100%',
  },
  textField: {
    width: '100%',
    marginBottom: '16px',
  },
  multiline: {
    minHeight: '200px',
  },
  submitBtn: {
    position: 'absolute',
    bottom: '40px',
    right: '32px',
  },
  root: {
    marginBottom: '18px',
    '& .MuiOutlinedInput-input': {
      color: '#6a777f',
    },
  },
  phoneInput: {
    marginBottom: '70px',
    '& input::-webkit-clear-button, & input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
      display: 'none',
    },
    '& .MuiOutlinedInput-input': {
      paddingTop: '18.5px',
      paddingBottom: '18.5px',
    },
  },
  tooltip: {
    backgroundColor: '#ffffff',
    color: '#2b2e31',
    fontSize: '14px',
    lineHeight: 1.43,
    padding: '16px 24px',
    boxShadow: '1px 5px 32px 0 rgba(0, 0, 0, 0.1)',
    maxWidth: '316px',
    fontFamily: 'Rubik',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
  },
  arrow: {
    color: '#fff',
  },
});
