import React, { useMemo } from 'react';
import styled from 'styled-components';
import { Text } from '../../Shared/Typography/Typography';
import CheckIn from './CheckIn';
import { InsightsCommentType, InsightsUserType, InsightsSegmentType, InsightsWheelType } from '../insights.model';
import { GRADIENTS } from '../../Shared/colors';
import { WHEEL_ACCESS_TYPES } from '../../../constants';

type Props = {
  comments: InsightsCommentType[];
  members: InsightsUserType[];
  segments: InsightsSegmentType[];
  wheels: InsightsWheelType[];
};

const CommentsContainer = styled.div`
  margin-top: 24px;
  border-radius: 4px;
  border: solid 1px #eaeaea;
  background-color: #f8f8f8;
`;

const HeaderContainer = styled.div`
  display: flex;
  align-items: baseline;
  margin: 24px 24px 0 24px;

  @media (max-width: 768px) {
    margin-left: 16px;
  }
`;

const CheckInsContainer = styled.div`
  margin: 16px 0;
`;

const InsightsComments: React.FC<Props> = ({ comments, members, segments, wheels }) => {
  const MappedCheckIns = useMemo(() => {
    return (
      !!comments.length &&
      comments.map((comment, index) => {
        const gradientIndex = index % GRADIENTS.length;
        const gradientColorByIndex = GRADIENTS[gradientIndex];
        const commentSegment = segments.find((segment) => segment.id === comment.segmentId);
        const commentWheel = wheels.find((wheel) => wheel.id === comment.wheelId);
        const isReplyEnabled = commentWheel.access !== WHEEL_ACCESS_TYPES.PUBLIC && commentWheel.isScoreComments;
        return (
          <CheckIn
            isReplyEnabled={isReplyEnabled}
            key={comment.id}
            comment={comment}
            refresh={() => null}
            teamAlertThreshold={5}
            members={members}
            commentSegment={commentSegment}
            commentWheel={commentWheel}
            gradientColorsArray={gradientColorByIndex}
          />
        );
      })
    );
  }, [comments, members, segments, wheels]);

  return (
    <CommentsContainer>
      <HeaderContainer>
        <Text className="mr-2" size="18px" weight="500">
          Comments
        </Text>
      </HeaderContainer>
      <CheckInsContainer>{MappedCheckIns}</CheckInsContainer>
    </CommentsContainer>
  );
};

export default InsightsComments;
