import React, { SyntheticEvent } from 'react';

interface iAlertProps {
  text: string;
  alertType: 'success' | any;
  noCloseBtn?: boolean;
  handleClose?: (e: SyntheticEvent) => void;
}

export default ({ handleClose, text, noCloseBtn = false, alertType }: iAlertProps) => (
  <div className={`alert alert-dismissible fade show alert-${alertType}`} role="alert">
    {text}
    {!noCloseBtn && (
      <button type="button" className="close" data-dismiss="alert" aria-label="Close" onClick={handleClose}>
        <span aria-hidden="true">&times;</span>
      </button>
    )}
  </div>
);
