import React, { FC, useEffect, useState } from 'react';
import { DateTime } from 'luxon';
import { BiTimeFive } from 'react-icons/bi';
import {
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  makeStyles,
  OutlinedInput,
} from '@material-ui/core';
import { IMaskInput } from 'react-imask';
import IMask from 'imask';
import styled from 'styled-components';

interface iCheckinReminderTimeProps {
  activeTime: DateTime;
  setNewTime?: (any) => void;
  disabled: boolean;
  handleError?: (error: boolean) => void;
}

const useStyles = makeStyles({
  root: {},
  input: {
    backgroundColor: '#ffffff',
    zIndex: 3,
  },
  label: {
    zIndex: 4,
  },
  icon: {
    fontSize: '26px',
  },
  error: {
    margin: '3px 0 0',
  },
});

function MaskedInput(props) {
  return (
    <IMaskInput
      {...props}
      overwrite={true}
      autofix={true}
      mask={'hh{:}mm'}
      lazy={false}
      blocks={{
        hh: {
          mask: IMask.MaskedRange,
          from: 0,
          to: 23,
          maxLength: 2,
          placeholderChar: '0',
        },
        mm: {
          mask: IMask.MaskedRange,
          from: 0,
          to: 59,
          placeholderChar: '0',
          maxLength: 2,
        },
      }}
    />
  );
}

export const CheckinReminderTime: FC<iCheckinReminderTimeProps> = ({
  activeTime,
  setNewTime,
  disabled,
  handleError,
}) => {
  const classes = useStyles();
  const [state, setState] = useState(activeTime.toFormat('HH:mm'));
  const [error, setError] = useState(null);
  const [dropdownOpen, setDropdown] = useState(false);

  useEffect(() => {
    validateTime(activeTime);
  }, [activeTime.toMillis()]);

  const handleChange = (value, mask) => {
    setState(value);
    const [hour, minute] = value.split(':');
    const time = activeTime.set({ hour, minute });
    setNewTime(time);
    validateTime(time);
  };

  const validateTime = (time: DateTime) => {
    if (time < DateTime.local()) {
      setError('Please select a later time');
      handleError && handleError(true);
    } else {
      setError(null);
      handleError && handleError(false);
    }
  };

  const pickTime = (e) => {
    const value = e.target.innerText;
    handleChange(value, null);
  };

  const isDisabled = (point) => {
    const [hour] = point.split(':');
    const calendarHour = activeTime.set({
      hour: hour,
      minute: 0,
      second: 0,
      millisecond: 0,
    });
    const nextHour = DateTime.local()
      .set({
        minute: 0,
        second: 0,
        millisecond: 0,
      })
      .plus({ hour: 1 });
    return calendarHour <= nextHour;
  };

  return (
    <FormControl variant="outlined" className={classes.root} fullWidth={true}>
      <InputLabel variant="outlined" htmlFor="formatted-text-mask-input" className={classes.label} error={!!error}>
        Time
      </InputLabel>
      <OutlinedInput
        disabled={disabled}
        className={classes.input}
        label="Time"
        inputComponent={MaskedInput}
        inputProps={{ onAccept: handleChange }}
        onFocus={() => setDropdown(true)}
        onBlur={() => setDropdown(false)}
        error={!!error}
        value={state}
        aria-describedby="component-error-text"
        endAdornment={
          <InputAdornment position="end" style={{ pointerEvents: 'none' }}>
            <IconButton disabled={disabled}>
              <BiTimeFive className={classes.icon} />
            </IconButton>
          </InputAdornment>
        }
      />
      <FormHelperText className={classes.error} error={!!error} id="component-error-text" variant="outlined">
        {error}
      </FormHelperText>
      <Dropdown open={dropdownOpen}>
        {TimePoints.map((point) => {
          return (
            <DropdownItem className={isDisabled(point) ? 'disabled' : ''} onMouseDown={pickTime} key={point}>
              {point}
            </DropdownItem>
          );
        })}
      </Dropdown>
    </FormControl>
  );
};

const Dropdown = styled.div<{ open: boolean }>`
  position: absolute;
  top: 50px;
  left: 0;
  z-index: 1;
  width: 100%;
  display: ${({ open }) => (open ? 'block' : 'none')};
  overflow: scroll;
  background-color: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.23);
  border-radius: 4px;
  height: 200px;
  padding-top: 5px;
`;

const DropdownItem = styled.div`
  padding: 5px 14px;
  cursor: pointer;

  &.disabled {
    color: rgba(0, 0, 0, 0.2);
    pointer-events: none;
  }

  &:hover {
    background-color: #f8f8f8;
  }
`;

const TimePoints = [
  '00:00',
  '01:00',
  '02:00',
  '03:00',
  '04:00',
  '05:00',
  '06:00',
  '07:00',
  '08:00',
  '09:00',
  '10:00',
  '11:00',
  '12:00',
  '13:00',
  '14:00',
  '15:00',
  '16:00',
  '17:00',
  '18:00',
  '19:00',
  '20:00',
  '21:00',
  '22:00',
  '23:00',
];
