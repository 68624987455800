import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  makeStyles,
  Typography,
  Link,
} from '@material-ui/core';

interface iDisabledCheckinModal {
  toggle: () => void;
}

export default ({ toggle }: iDisabledCheckinModal) => {
  const classes = useStyles();

  return (
    <Dialog open={true} onClose={toggle} classes={{ paper: classes.dialog }}>
      <DialogTitle classes={{ root: classes.title }} disableTypography={true}>
        Check ins are disabled at this time
      </DialogTitle>
      <DialogContent classes={{ root: classes.content }}>
        <Typography>
          You can complete check ins during the
          <br />
          school’s working hours
        </Typography>
        <Typography className={classes.support}>
          If you need urgent support, contact a counseling service such as
          <br />
          Life Line (
          <Link className="font-weight-bold" href="tel:131114">
            13 11 14
          </Link>
          )
        </Typography>
      </DialogContent>
      <DialogActions classes={{ root: classes.actions }}>
        <Button onClick={toggle} classes={{ root: classes.button }}>
          GOT IT
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const useStyles = makeStyles({
  dialog: {
    padding: '50px 50px 36px',
    backgroundColor: '#fefaf2',
    maxWidth: '540px',
  },
  title: {
    padding: '0',
    fontSize: '30px',
  },
  content: {
    marginTop: '16px',
    padding: '0',
    fontSize: '16px',
    lineHeight: 1.5,
  },
  support: {
    marginTop: '4px',
    fontSize: '12px',
    lineHeight: 2,
    color: '#fb463b',
  },
  anchor: {
    fontWeight: 'bold',
    color: '#fb463b',
  },
  actions: {
    padding: '24px 0 0',
  },
  button: {
    border: 'solid 1px #fb463b',
    color: '#fb463b',
    fontWeight: 'bold',
  },
});
