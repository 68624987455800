import { ArrowLeft, ArrowRight } from 'react-feather';

import { BlackBtn, BlackButtonBox, ContentBox, LeftPanel, RedBtn } from '../Onboarding/_styled';
import { Text } from '../../Shared/Typography/Typography';
import { Button } from '../../Shared/Buttons/Button';
import { TextInput } from '../../Shared/Inputs/TextInput';
import { TextField } from '@material-ui/core';

const NameForm = ({ formik, prevStep, nextStep }) => {
  const shouldShowNextButton = formik.values.firstName && formik.values.lastName;

  return (
    <LeftPanel style={{ width: '100%' }}>
      <BlackButtonBox style={{ width: '100%' }}>
        <BlackBtn onClick={prevStep} style={{ color: '#2b2e31' }}>
          <ArrowLeft size={13} /> BACK
        </BlackBtn>
      </BlackButtonBox>
      <ContentBox style={{ width: '100%' }}>
        <Text weight={600} size="36px">
          Tell us about yourself
        </Text>
        <form style={{ width: '100%', marginTop: '34px' }}>
          <div className="mb-5 d-flex flex-column" style={{ gap: '30px' }}>
            <TextField
              fullWidth
              id="firstName"
              label="First Name"
              variant="outlined"
              placeholder="Enter Your First Name"
              value={formik.values.firstName}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            <TextField
              fullWidth
              id="lastName"
              label="Last Name"
              variant="outlined"
              placeholder="Enter Your Last Name"
              value={formik.values.lastName}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </div>
          {shouldShowNextButton && (
            <RedBtn onClick={nextStep}>
              Next <ArrowRight size={13} />
            </RedBtn>
          )}
        </form>
      </ContentBox>
    </LeftPanel>
  );
};

export default NameForm;
