import React, { useState, useEffect, SetStateAction, Dispatch } from 'react';
import { useHistory } from 'react-router-dom';
import { connect, ConnectedProps } from 'react-redux';
import { RootState } from '../../../Redux/Reducers';
import {
  Root,
  LeftPanel,
  BlackBtn,
  BlackButtonBox,
  ContentBox,
  RedButtonBox,
  BtnsGroup,
  RedBtn,
  EmptyButton,
} from './_styled';
import { ArrowLeft, ArrowRight } from 'react-feather';
import { updatePersonalDataAction } from '../Auth.actions';
import { RightScreen } from './RightScreen';
import services from '../../../API/services';
import { GenderField, NationalityField } from '../../Settings/ProfileFields';

interface PersonalInfoProps extends PropsFromRedux {
  onNext: () => void;
  step: number;
  setStep: Dispatch<SetStateAction<number>>;
}

const PersonalInfoScreen: React.FC<PersonalInfoProps> = ({ user, updatePersonalDataAction, onNext, step, setStep }) => {
  const [gender, setGender] = useState(user.personalData.gender || '');
  const [nationality, setNationality] = useState(user.personalData.nationality || '');
  // const [ethnicity, setEthnicity] = useState(user.personalData.ethnicity || '');
  const [customGender, setCustomGender] = useState(user.personalData.customGender || []);
  // const [customEthnicity, setCustomEthnicity] = useState(user.personalData.customEthnicity || []);
  const [skipped, setSkipped] = useState(false);

  const history = useHistory();

  useEffect(() => {
    if (step === 0) {
      if (user.personalData.gender) {
        setGender(user.personalData.gender);
      }
      if (user.personalData.customGender) {
        setCustomGender(user.personalData.customGender);
      }
    }
    // else if (step === 2) {
    //   if (user.personalData.ethnicity) {
    //     setEthnicity(user.personalData.ethnicity);
    //   }
    //   if (user.personalData.customEthnicity) {
    //     setCustomEthnicity(user.personalData.customEthnicity);
    //   }
    // }
  }, [step, user.personalData]);

  const handleNext = () => {
    saveCurrentStep();
    if (step === 1) {
      onNext();
    } else {
      setStep(step + 1);
    }
  };

  const handleBack = () => {
    if (step === 0) {
      history.push('/dashboard');
    } else {
      setStep(step - 1);
      if (skipped) {
        resetCurrentStep();
        setSkipped(false);
      }
    }
  };

  const skipQuestion = () => {
    setSkipped(true);
    if (step === 2) {
      onNext();
    } else {
      setStep(step + 1);
    }
  };

  const saveCurrentStep = async () => {
    let updatedData = { ...user.personalData };
    if (step === 0) {
      updatedData.gender = gender;
      updatedData.customGender = customGender;
    } else if (step === 1) {
      updatedData.nationality = nationality;
    }
    // else if (step === 2) {
    //   updatedData.ethnicity = ethnicity;
    //   updatedData.customEthnicity = customEthnicity;
    // }
    updatePersonalDataAction(updatedData);
    await services.updatePersonalData(updatedData);
  };

  const resetCurrentStep = () => {
    if (step === 0) {
      setGender('');
    } else if (step === 1) {
      setNationality('');
    }
    // else if (step === 2) {
    //   setEthnicity('');
    // }
  };

  const isNextButtonDisabled = () => {
    if (step === 0) return !gender;
    if (step === 1) return !nationality;
    // if (step === 2) return !ethnicity;
    return true;
  };

  return (
    <Root>
      <LeftPanel>
        <BlackButtonBox>
          <BlackBtn onClick={handleBack}>
            <ArrowLeft size={13} />
            {step === 0 ? 'DASHBOARD' : 'BACK'}
          </BlackBtn>
        </BlackButtonBox>

        <ContentBox>
          {step === 0 && (
            <GenderField
              gender={gender}
              setGender={setGender}
              customGender={customGender}
              setCustomGender={setCustomGender}
            />
          )}

          {step === 1 && <NationalityField nationality={nationality} setNationality={setNationality} />}

          {/* {step === 2 && (
            <EthnicityField
              ethnicity={ethnicity}
              setEthnicity={setEthnicity}
              customEthnicity={customEthnicity}
              setCustomEthnicity={setCustomEthnicity}
            />
          )} */}
          <RedButtonBox style={{ width: '100%' }}>
            <BtnsGroup>
              {!isNextButtonDisabled() ? (
                <RedBtn onClick={handleNext}>
                  Next <ArrowRight size={13} />
                </RedBtn>
              ) : (
                <EmptyButton style={{ height: '42px' }} />
              )}
              <BlackBtn onClick={skipQuestion}>Skip question</BlackBtn>
            </BtnsGroup>
          </RedButtonBox>
        </ContentBox>
      </LeftPanel>
      <RightScreen />
    </Root>
  );
};

const mapStateToProps = (state: RootState) => ({
  user: state.userRD.user || {},
});

const mapDispatchToProps = {
  updatePersonalDataAction,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(PersonalInfoScreen);
