import './AuthContainer.scss';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { FC } from 'react';
import { AccountType } from '../../API/interfaces';
import styled from 'styled-components';
import { ResponsiveConstants } from '../Shared/responsive-constants';
import defaultBg from '../../Assets/images/auth-image.png';
import athleticWestBg from '../../Assets/images/auth-image-athletic-west.jpg';
import skillhireBg from '../../Assets/images/auth-image-skillhire.jpeg';
import wunanBg from '../../Assets/images/auth-image-wunan.png';
import gtconnectBg from '../../Assets/images/auth-image-gt-connect.png';
import noStressBg from '../../Assets/images/auth-image-nostress.png';

type AuthContainerProps = RouteComponentProps;

const AuthContainerComponent: FC<AuthContainerProps> = ({ location, children }) => {
  const tagParam = new URLSearchParams(location.search).get('tag') as AccountType | null;

  return (
    <div className="auth-container">
      {tagParam === 'no_stress_mate' ? (
        <>
          <div className="auth-container_content nostress-container">{children}</div>
          <img className="nostress-bg" />
        </>
      ) : (
        <>
          <div className="auth-container_content">{children}</div>
          <BackgroundImage tagParam={tagParam} />
        </>
      )}
    </div>
  );
};

const BackgroundImage = styled.img<{ tagParam: AccountType | null }>`
  width: 50%;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  @media all and (max-width: ${ResponsiveConstants.mw_md}) {
    display: none;
  }

  background-image: url(${(props) => {
    switch (props.tagParam) {
      case null:
        return defaultBg;
      case AccountType.GT_CONNECT:
        return gtconnectBg;
      case AccountType.ATHLETIC_WEST:
        return athleticWestBg;
      case AccountType.SKILLHIRE:
        return skillhireBg;
      case AccountType.WUNAN:
        return wunanBg;
      case AccountType.METATTUDE:
        return defaultBg;
      default:
        return defaultBg;
    }
  }});
`;

export const AuthContainer = withRouter(AuthContainerComponent);
