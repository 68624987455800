import { Search as SearchFeather } from 'react-feather';
import styled from 'styled-components';

import { ICON_SIZE } from '../_consts';

export const SearchWrapper = styled.div`
  position: relative;
  margin-right: 8px;
  cursor: pointer;
`;
export const SearchWrapperMobile = styled(SearchWrapper)`
  width: calc(100% - 16px * 2);
`;
export const StyledSearchIcon = styled(SearchFeather)`
  position: absolute;
  top: calc(50% - ${ICON_SIZE} / 2);
  left: calc(${ICON_SIZE} / 2);
  height: 16px;
  width: 16px;
`;
export const StyledInput = styled.input`
  border: none;
  font-size: 14px !important;
  padding: 4px 5px 4px 36px;
  min-width: 294px;
  color: #2b2e31;
`;
export const InputWrapper = styled.div`
  position: relative;
  border: solid 1px #cececf;
  background-color: #fff;
  border-radius: 20px;
  overflow: hidden;
`;
export const Wrapper = styled.div<any>`
  height: 32px;
  width: 32px;
  background-color: #2a3e50;
  border-radius: 16px;
  padding: 0;
  transition: width 0.2s ease-in-out;
  overflow: hidden;
  position: relative;

  color: #fff;
`;
