import React, { useState } from 'react';
import './Header.scss';
import { RouteComponentProps } from 'react-router-dom';
import { withRouter } from 'react-router';
import { AccountPlan, iUser } from '../../../API/interfaces';
import { analytics } from '../../../analytics/analytics';
import Navbar from '../Navbar/Navbar';
import UserInfo from './UserInfo/UserInfo';
import logo from '../../../Assets/images/logo-navy.png';
import logoWhite from '../../../Assets/images/logo-white.png';
import logoIcon from '../../../Assets/images/logo-icon.png';
import { BlackBorderButton } from '../Buttons/Button';
import NotificationsButton from './Notifications/NotificationsButton';
import NotificationsDrawer from './Notifications/NotificationsDrawer';
import styled from 'styled-components';

interface iHeaderProps extends RouteComponentProps {
  className: string;
  user: iUser;
  isPublic?: boolean;
}

const ContentContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const ControlsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 57%;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const IconsWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const Header = ({ className, user, isPublic = false, history }: iHeaderProps) => {
  const [isNotificationDrawerOpen, setIsNotificationDrawerOpen] = useState(false);
  const [bellIconColor, setBellIconColor] = useState('#b1b1b1');

  const toggleNotificationsDrawer = (e) => {
    e.stopPropagation();
    setIsNotificationDrawerOpen(!isNotificationDrawerOpen);
    !isNotificationDrawerOpen && analytics.openNotifications();
  };

  const onCloseNotificationsDrawer = () => {
    setIsNotificationDrawerOpen(false);
    setBellIconColor('#b1b1b1');
  };

  const onMouseEnterNotificationsButton = () => setBellIconColor('#fff');

  const onMouseLeaveNotificationsButton = () => {
    !isNotificationDrawerOpen && setBellIconColor('#b1b1b1');
  };

  // const upgradeOnClick = () => {
  //   analytics.clickUpgradeImHeader();
  //   history.push({ pathname: '/features', search: '?rf=page-header' });
  // };

  return (
    <>
      <div id="header" className={className || ''}>
        <div className="container">
          <ContentContainer>
            <div className="logo-img">
              <a
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  if (user?._id) {
                    window.location.href = '/dashboard';
                  } else {
                    window.location.href = '/login';
                  }
                }}
              >
                <img
                  className="d-none d-lg-inline-block"
                  alt={className === 'dark' ? 'logo white' : 'logo navy'}
                  src={className === 'dark' ? logoWhite : logo}
                />
                <img className="d-inline-block d-lg-none" src={logoIcon} alt="" />
              </a>
            </div>

            {/* {(!user?.account || user?.account.plan === AccountPlan.FREE) && (
              <div className="upgrade-btn-container">
                <BlackBorderButton onClick={upgradeOnClick}>UPGRADE</BlackBorderButton>
              </div>
            )} */}

            <ControlsContainer>
              <Navbar user={user} isPublic={isPublic} />

              {user?._id && (
                <IconsWrapper>
                  <NotificationsButton
                    bellIconColor={bellIconColor}
                    isNotificationDrawerOpen={isNotificationDrawerOpen}
                    toggleNotificationsDrawer={toggleNotificationsDrawer}
                    onMouseEnterNotificationsButton={onMouseEnterNotificationsButton}
                    onMouseLeaveNotificationsButton={onMouseLeaveNotificationsButton}
                  />

                  <div className="user-info-wrap">
                    <UserInfo user={user} />
                  </div>
                </IconsWrapper>
              )}
            </ControlsContainer>
          </ContentContainer>
        </div>
      </div>
      <NotificationsDrawer isOpen={isNotificationDrawerOpen} onClose={onCloseNotificationsDrawer} />
    </>
  );
};

export default withRouter(Header);
