import React from 'react';
import { Dialog, DialogActions, DialogContent, makeStyles, Typography } from '@material-ui/core';
import { RedBorderButton, TransparentButton } from '../../Shared/Buttons/Button';
import { iWheelTemplate } from '../../../API/interfaces';
import services from '../../../API/services';
import { toasterService } from '../../Shared/Toaster/Toaster.service';

interface iDeleteTemplateModalProps {
  template: iWheelTemplate;
  onClose: (result: boolean) => void;
}

export default ({ template, onClose }: iDeleteTemplateModalProps) => {
  const classes = useStyles();

  const deleteTemplate = async () => {
    try {
      await services.deleteTemplate(template.id);
      onClose(true);
    } catch (e: any) {
      onClose(false);
      toasterService.addErrorToast(e.message);
    }
  };

  return (
    <Dialog open={true} classes={{ paper: classes.dialog }} onClose={() => onClose(false)}>
      <DialogContent classes={{ root: classes.content }}>
        <Typography className={classes.text}>Are you sure you want to delete {template.name} template?</Typography>
      </DialogContent>
      <DialogActions className={classes.buttons}>
        <TransparentButton onClick={() => onClose(false)}>CANCEL</TransparentButton>
        <RedBorderButton onClick={deleteTemplate}>DELETE</RedBorderButton>
      </DialogActions>
    </Dialog>
  );
};

const useStyles = makeStyles({
  dialog: {
    padding: '50px 50px 36px',
    backgroundColor: '#fefaf2',
    borderRadius: '4px',
  },
  content: {
    padding: '0',
    '&:first-child': {
      padding: '0',
    },
  },
  text: {
    fontSize: '30px',
    color: '#0c2337',
  },
  buttons: {
    padding: '0',
    marginTop: '32px',
  },
});
