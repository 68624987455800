import React, { useState } from 'react';
import * as yup from 'yup';
import DownloadSample from './DownloadSample';
import FileUpload from './FileUpload';
import CustomTable from './CustomTable';

const validationSchema = yup.object({
  firstName: yup.string().required('First Name is required'),
  lastName: yup.string().required('Last Name is required'),
  email: yup.string().email('Invalid email format').required('Email is required'),
});

const MembersInvitationSteps = ({ onNext, setUserData, onBack, wheelId, onInvitationsSent }) => {
  const [data, setData] = useState([]);
  const [fileName, setFileName] = useState('');
  const [errors, setErrors] = useState([]);
  const [currentStep, setCurrentStep] = useState(1);

  const validateRows = (rows) => {
    const newErrors = [];

    rows.forEach((row, index) => {
      const fieldErrors = {};

      try {
        validationSchema.validateSync(row, { abortEarly: false });
      } catch (validationError) {
        if (validationError instanceof yup.ValidationError) {
          validationError.inner.forEach((err) => {
            fieldErrors[err.path] = err.message;
          });
        }
      }

      if (Object.keys(fieldErrors).length > 0) {
        newErrors.push({ index, fields: fieldErrors });
      }
    });

    setErrors(newErrors);
    return newErrors.length === 0;
  };

  const dataHandlers = () => ({
    setUserData,
    setData,
    setFileName,
    validateRows,
    setCurrentStep,
  });

  const tableHandlers = () => ({
    setUserData,
    data,
    setData,
    onInvitationsSent,
    validateRows,
    fileName,
    setFileName,
    errors,
    setErrors,
    onNext,
    onBack,
    wheelId,
    setCurrentStep,
  });

  return (
    <div style={{ padding: '0 40px' }}>
      {currentStep === 1 && <DownloadSample onDownloadComplete={() => setCurrentStep(2)} onCancel={onBack} />}
      {currentStep === 2 && <FileUpload handlers={dataHandlers()} />}

      {currentStep === 3 && <CustomTable handlers={tableHandlers()} />}
    </div>
  );
};

export default MembersInvitationSteps;
