import React from 'react';

import { tIconProps } from '../../_types';

const UsersIcon = ({ className }: tIconProps) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12">
    <defs>
      <filter id="m4zallpk2a" colorInterpolationFilters="auto">
        <feColorMatrix
          in="SourceGraphic"
          values="0 0 0 0 0.082353 0 0 0 0 0.168627 0 0 0 0 0.243137 0 0 0 1.000000 0"
        />
      </filter>
    </defs>
    <g fill="none" fillRule="evenodd">
      <g>
        <g>
          <g
            filter="url(#m4zallpk2a)"
            transform="translate(-452.000000, -622.000000) translate(308.000000, 536.000000) translate(136.000000, 80.000000)"
          >
            <g>
              <path
                fill="#000"
                fillRule="nonzero"
                d="M10.036 7.066l.089.015C11.228 7.366 11.999 8.36 12 9.5v1c0 .276-.224.5-.5.5s-.5-.224-.5-.5v-1c0-.683-.463-1.28-1.125-1.45-.267-.07-.428-.343-.36-.61.07-.267.343-.428.61-.36zM6.5 7C7.88 7 9 8.12 9 9.5v1c0 .276-.224.5-.5.5s-.5-.224-.5-.5v-1C8 8.672 7.328 8 6.5 8h-4C1.672 8 1 8.672 1 9.5v1c0 .276-.224.5-.5.5s-.5-.224-.5-.5v-1C0 8.12 1.12 7 2.5 7zm-2-6C5.88 1 7 2.12 7 3.5S5.88 6 4.5 6 2 4.88 2 3.5 3.12 1 4.5 1zm3.535.066l.089.015c1.106.283 1.88 1.28 1.88 2.421 0 1.142-.774 2.14-1.88 2.422-.267.069-.54-.092-.608-.36-.069-.267.092-.54.36-.608.664-.17 1.128-.768 1.128-1.454 0-.685-.464-1.283-1.128-1.453-.268-.068-.429-.34-.36-.608.068-.268.34-.429.608-.36zM4.5 2C3.672 2 3 2.672 3 3.5S3.672 5 4.5 5 6 4.328 6 3.5 5.328 2 4.5 2z"
                transform="translate(8.000000, 6.000000)"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default UsersIcon;
