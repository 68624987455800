import React from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import Comment from './Comment';
import { CommentClient } from '../../../API/comments.client';
import { InsightsCommentType, InsightsDataType, InsightsSegmentType, InsightsWheelType } from '../insights.model';
import { createInsightsReplySuccess, editInsightsReplySuccess, deleteInsightsReplySuccess } from '../insights.actions';

type Props = {
  comment: InsightsCommentType;
  teamAlertThreshold: number;
  members?: InsightsDataType['users'];
  refresh: () => Promise<void>;
  isReplyEnabled: boolean;
  commentSegment?: InsightsSegmentType;
  commentWheel?: InsightsWheelType;
  gradientColorsArray: string[];
};

const CheckIn: React.FC<Props> = ({
  comment,
  members,
  teamAlertThreshold,
  refresh,
  isReplyEnabled,
  commentSegment,
  commentWheel,
  gradientColorsArray,
}) => {
  const dispatch = useDispatch();
  const isRedFlag = comment.score < teamAlertThreshold;
  const member = members && members.find((m) => m.id === comment.userId);

  const onCreate = async (value) => {
    await CommentClient.createReply(comment.id, value).then(({ data }) => dispatch(createInsightsReplySuccess(data)));
    await refresh();
  };

  const onEdit = async (replyId, newValue) => {
    await CommentClient.editReply(comment.id, replyId, newValue).then(({ data }) =>
      dispatch(editInsightsReplySuccess(data))
    );
    await refresh();
  };

  const onDelete = async (replyId) => {
    await CommentClient.deleteReply(comment.id, replyId).then(() =>
      dispatch(deleteInsightsReplySuccess({ commentId: comment.id, replyId: replyId }))
    );
    await refresh();
  };

  return (
    <CheckInContainer key={comment.date}>
      <AverageScoreWrapper>
        <AverageScore redFlag={isRedFlag}>{comment.score}</AverageScore>
        <VerticalLine />
      </AverageScoreWrapper>

      <CommentWrapper>
        <Comment
          isMain={true}
          reply={comment}
          onEdit={onEdit}
          member={member}
          onCreate={onCreate}
          onDelete={onDelete}
          isReplyEnabled={isReplyEnabled}
          commentSegment={commentSegment}
          commentWheel={commentWheel}
          last={comment?.replies?.length === 0}
          gradientColorsArray={gradientColorsArray}
        />
        {comment?.replies?.map((reply, i) => {
          const replyOwner = members.find((m) => m.id === reply.userId);
          return (
            <Comment
              isReplyComment
              reply={reply}
              key={reply.id}
              onEdit={onEdit}
              member={replyOwner}
              onCreate={onCreate}
              onDelete={onDelete}
              isReplyEnabled={isReplyEnabled}
              last={i === comment.replies.length - 1}
            />
          );
        })}
      </CommentWrapper>
    </CheckInContainer>
  );
};

export default CheckIn;

export const CommentText = styled.div`
  font-size: 14px;
  line-break: anywhere;
`;

const AverageScoreWrapper = styled.div`
  width: 80px;
  position: absolute;
  top: 6px;
  left: 0;
  height: 100%;
  display: flex;
  flex-flow: column;
  align-items: center;
  z-index: 2;
  @media (max-width: 768px) {
    padding: 0;
    width: 58px;
  }
`;

const CommentWrapper = styled.div`
  width: 100%;
`;

const AverageScore = styled.div<{ redFlag?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 50;
  width: 32px;
  height: 32px;
  font-size: 12px;
  font-weight: bold;
  border-radius: 50%;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.16);
  background-color: #ffffff;
  color: ${(props) => (props.redFlag ? '#fb463b' : 'inherit')};
`;

const VerticalLine = styled.div`
  position: absolute;
  top: 40px;
  height: calc(100% - 48px);
  bottom: 0;
  border-left: solid 0.5px #d1d1d2;
`;

const CheckInContainer = styled.div`
  display: flex;
  position: relative;
`;
