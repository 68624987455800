import React, { useState } from 'react';
import { Dialog, DialogContent, TextField, Typography, DialogActions, makeStyles } from '@material-ui/core';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { iWheelDefaultTemplate, iWheelTemplate } from '../../../API/interfaces';
import { MediumButton, TransparentButton } from '../../Shared/Buttons/Button';
import services from '../../../API/services';
import { toasterService } from '../../Shared/Toaster/Toaster.service';

interface iUseTemplateModalProps extends RouteComponentProps {
  template: iWheelTemplate | iWheelDefaultTemplate;
  toggle: () => void;
}

const UseTemplateModal = ({ template, toggle, history }: iUseTemplateModalProps) => {
  const [wheelName, setWheelName] = useState(template.name);
  const [isLoading, setIsLoading] = useState(false);
  const classes = useStyles();

  const onCreate = async () => {
    setIsLoading(true);
    try {
      const response = await services.createWheel({
        name: wheelName,
        max_scale: template.maxScale,
        access: template.access,
        step: template.step,
        isScoreComments: (template as iWheelTemplate).isScoreComments || false,
        templateId: template.id,
        segments: template.segments.map((segment) => {
          return {
            _id: segment.id,
            name: segment.name,
            description: segment.description || null,
          };
        }),
      });

      const wheelId = response.data;

      history.push(`/wheel/${wheelId}`);
    } catch (e: any) {
      toasterService.addErrorToast(e.message);
    }
    setIsLoading(false);
  };

  return (
    <Dialog open={true} classes={{ paper: classes.dialog }} onClose={toggle}>
      <DialogContent classes={{ root: classes.content }}>
        <Typography className={classes.title}>Create your wheel name</Typography>
        <TextField
          className={classes.textField}
          variant="outlined"
          label="Wheel name"
          value={wheelName}
          onChange={(e) => setWheelName(e.target.value)}
        />
        <Typography className={classes.text}>This can be edited later on</Typography>
      </DialogContent>

      <DialogActions className={classes.buttons}>
        <TransparentButton className={classes.button} onClick={toggle}>
          CANCEL
        </TransparentButton>
        <MediumButton onClick={onCreate} disabled={!wheelName || isLoading}>
          CREATE
        </MediumButton>
      </DialogActions>
    </Dialog>
  );
};

const useStyles = makeStyles({
  dialog: {
    padding: '40px 50px',
    borderRadius: '4px',
    backgroundColor: '#fefaf2',
    '@media(max-device-width: 768px)': {
      padding: '32px',
    },
  },
  content: {
    '&:first-child': {
      padding: 0,
    },
  },
  title: {
    fontSize: '30px',
    color: '#0c2337',
  },
  textField: {
    marginTop: '34px',
    width: '441px',
    '& .MuiOutlinedInput-input': {
      padding: '12px 16px',
    },
    '& .MuiInputLabel-outlined:not(.MuiInputLabel-shrink)': {
      transform: 'translate(14px, 15px) scale(1)',
    },
    '@media(max-device-width: 768px)': {
      width: '100%',
    },
  },
  text: {
    marginTop: '8px',
    fontSize: '12px',
    color: '#7f8b96',
  },
  buttons: {
    marginTop: '24px',
    padding: 0,
  },
  button: {
    height: '40px !important',
    lineHeight: '40px !important',
  },
});

export default withRouter(UseTemplateModal);
