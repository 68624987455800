import React from 'react';
import { Typography, makeStyles } from '@material-ui/core';

interface iSectionProps {
  number: number;
  title: string;
  children: React.ReactNode;
}

export default ({ number, title, children }: iSectionProps) => {
  const classes = useStyles();

  return (
    <div>
      <div className={classes.title}>
        <div className={classes.number}>{number}</div>
        <Typography className={classes.text}>{title}</Typography>
      </div>

      <div className={classes.children}>{children}</div>
    </div>
  );
};

const useStyles = makeStyles({
  title: {
    display: 'flex',
    alignItems: 'flex-start',
    marginBottom: '34px',
  },
  number: {
    marginRight: '16px',
    minWidth: '24px',
    minHeight: '24px',
    maxWidth: '24px',
    maxHeight: '24px',
    fontSize: '12px',
    lineHeight: 2,
    letterSpacing: 0.86,
    textAlign: 'center',
    borderRadius: '50%',
    fontWeight: 'bold',
    color: '#fff',
    backgroundColor: '#0c2337',
  },
  text: {
    fontSize: '18px',
    lineHeight: 1.33,
    fontWeight: 500,
    color: '#0c2337',
    '@media(max-device-width: 768px)': {
      fontSize: '14px',
      weight: 500,
      lineHeight: 1.71,
    },
  },
  children: {
    marginLeft: '42px',
    '@media(max-device-width: 768px)': {
      margin: 0,
    },
  },
});
