export enum InvitationStatus {
  PENDING = 'pending',
  JOINED = 'joined',
  ERROR = 'error',
}

export interface Invitation {
  accountId: string;
  email: string;
  firstName?: string;
  lastName?: string;
  status: InvitationStatus;
  _id: string;
}

export enum InvitationActionTypes {
  SAVE_INVITATION_LINK = 'SAVE_INVITATION_LINK',
  RESET_INVITATION_LINK = 'RESET_INVITATION_LINK',
}
