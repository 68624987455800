import { EducationFeature, EducationStatus } from './Education.model';

const initialState = {
  firstVisitOfDashboard: EducationStatus.REMAINING,
  afterFirstCheckin: EducationStatus.REMAINING,
  membersTab: EducationStatus.REMAINING,
  currentFlow: [],
};

export const educationReducer = (state = initialState, action) => {
  switch (action.type) {
    case EducationFeature.FIRST_VISIT_OF_DASHBOARD:
      return Object.assign({}, state, {
        firstVisitOfDashboard: action.payload,
      });
    case EducationFeature.AFTER_FIRST_CHECKIN:
      return Object.assign({}, state, {
        afterFirstCheckin: action.payload,
      });
    case EducationFeature.MEMBERS_TAB:
      return Object.assign({}, state, {
        membersTab: action.payload,
      });
    case EducationFeature.FLOW:
      return Object.assign({}, state, {
        currentFlow: action.payload,
      });
    default:
      break;
  }

  return state;
};
