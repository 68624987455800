import React, { Component, createRef } from 'react';
import Chart from 'chart.js';
import { iCheckinsAggregation, iScoresAggregation } from '../../../API/interfaces';
import utils from '../utils';
import { DIMMED_GRADIENTS, GRADIENTS } from '../colors';
import chartDefaultOptions from './helpers';
import './HistoryChart.scss';

interface iHistoryChartProps {
  checkins: Array<iCheckinsAggregation>;
  activeSegmentId: string;
  activeSegmentIndex: number;
  activeCheckinDate: string;
  wheelScale: number;
  onClick: (checkinCreateDate: string) => void;
  width?: number;
  height?: number;
}

interface iHistoryChartState {}

interface iChartDataSet {
  data: Array<number> | null;
  type: string;
  order: number;
  fill?: boolean;
  backgroundColor?: Array<CanvasGradient | string>; // bar gradient, line points
  borderColor?: string; // lines
}

interface iChartData {
  datasets: Array<iChartDataSet>;
  labels: Array<string>;
}

export default class HistoryChart extends Component<iHistoryChartProps, iHistoryChartState> {
  chartRef = createRef<HTMLCanvasElement>();
  containerRef = createRef<HTMLDivElement>();
  chartInstance = null;
  static defaultProps = {
    width: 900,
    height: 300,
  };

  getScrollDistance = (): number => {
    // scroll to the center if it's phone and timeline consists of one entry
    if (utils.checkIsPhone() && this.chartInstance.data.labels.length === 1) {
      // plus 10px padding
      return this.props.width / 2 - window.innerWidth / 2 + 10;
    }
    return this.props.width;
  };

  // get bar color based on active segment
  getBarGradient = (isActive: boolean): CanvasGradient => {
    const index = this.props.activeSegmentIndex;
    const ctx = this.chartRef.current.getContext('2d');
    const gradient = ctx.createLinearGradient(350, 0, 350, 350);
    gradient.addColorStop(
      0,
      index === null
        ? isActive
          ? '#ff815d'
          : '#ffe7e0'
        : isActive
        ? GRADIENTS[index % GRADIENTS.length][0]
        : DIMMED_GRADIENTS[index % DIMMED_GRADIENTS.length][0]
    );
    gradient.addColorStop(
      1,
      index === null
        ? isActive
          ? '#ff4138'
          : '#ffcac9'
        : isActive
        ? GRADIENTS[index % GRADIENTS.length][1]
        : DIMMED_GRADIENTS[index % DIMMED_GRADIENTS.length][1]
    );
    return gradient;
  };

  // aggregate data for chart
  getChartData = (): iChartData => {
    const { activeSegmentId, activeCheckinDate, checkins, width } = this.props;
    const labels = [];
    const datasets = {
      bar: {
        ...chartDefaultOptions.bar,
        backgroundColor: [],
        data: [],
        label: null,
      },
      // black line
      mainLine: {
        ...chartDefaultOptions.mainLine,
        data: [],
        label: null,
        borderWidth: width * 0.0025,
        pointRadius: width * 0.008,
        pointHoverRadius: width * 0.008,
        pointHoverBorderWidth: width * 0.0035,
      },
    };

    // active segment
    if (activeSegmentId) {
      let activeDateIndex = 0;
      // get active segment scores from all checkins
      const segmentScores = checkins.reduce(
        (scores: Array<iScoresAggregation>, checkin: iCheckinsAggregation, index) => {
          // not all checkins may have score for particular segment
          const score: iScoresAggregation = checkin.scores.find((score) => score.segmentId === activeSegmentId);
          score && scores.push(score);
          const checkinDate = checkin.dateRange;
          // add all checkin dates for timeline
          labels.push(checkinDate);
          // for bg color
          activeDateIndex = activeCheckinDate === checkinDate ? index : activeDateIndex;
          return scores;
        },
        []
      );

      datasets.mainLine.label = segmentScores[0].segmentName;
      segmentScores.forEach((score, index) => {
        datasets.bar.data.push(score.score - 0.5);
        datasets.bar.backgroundColor.push(this.getBarGradient(!activeCheckinDate || activeDateIndex === index));
        datasets.mainLine.data.push(score.score);
      });
    } else if (activeSegmentId === null) {
      // all segments
      datasets.mainLine.label = 'All';
      checkins.forEach((checkin) => {
        // average
        datasets.bar.data.push(checkin.averageScore - 0.5);
        datasets.bar.backgroundColor.push(
          this.getBarGradient(!activeCheckinDate || activeCheckinDate === checkin.dateRange)
        );
        datasets.mainLine.data.push(checkin.averageScore);

        checkin.scores.forEach((score, i) => {
          if (!datasets[score.segmentId]) {
            datasets[score.segmentId] = {
              ...chartDefaultOptions.line,
              borderWidth: width * 0.0015,
              pointRadius: width * 0.002,
              pointHoverRadius: width * 0.002,
              label: score.segmentName,
              data: [],
              order: i + 1, // bar chart has order 0
              borderColor: GRADIENTS[i % GRADIENTS.length][1],
              backgroundColor: GRADIENTS[i % GRADIENTS.length][1],
            };
          }
          datasets[score.segmentId].data.push(score.score);
        });

        // date for member results, week for team results
        labels.push(checkin.dateRange);
      });
    }

    // average bar should be the last one
    datasets.bar.order = Object.keys(datasets).length - 1;
    datasets.bar.barThickness = labels.length > 8 ? (labels.length > 12 ? width * 0.03 : width * 0.075) : width * 0.1; // px

    return { datasets: Object.values(datasets), labels };
  };

  renderChart = () => {
    const { width, wheelScale } = this.props;
    const { datasets, labels } = this.getChartData();
    const ctx = this.chartRef.current.getContext('2d');

    this.chartInstance = new Chart(ctx, {
      type: 'line',
      data: {
        datasets,
        labels,
      },
      options: {
        ...chartDefaultOptions.options,
        layout: {
          padding: {
            top: width * 0.022,
            bottom: width * 0.044,
          },
        },
        onClick: this.onClick,
        scales: {
          xAxes: [
            {
              ...chartDefaultOptions.xAxes,
              ticks: {
                ...chartDefaultOptions.xAxes.ticks,
                fontSize: width * 0.013,
              },
            },
          ],
          yAxes: [
            {
              position: 'right',
              ticks: {
                ...chartDefaultOptions.yAxesTicks,
                max: wheelScale,
                fontSize: width * 0.013,
              },
            },
            {
              position: 'left',
              ticks: {
                ...chartDefaultOptions.yAxesTicks,
                max: wheelScale,
                fontSize: width * 0.013,
              },
            },
          ],
        },
      },
    });
  };

  onClick = (event, activeElements) => {
    const element = activeElements[0];
    if (element) {
      this.props.onClick(element._xScale.ticks[element._index]);
    }
  };

  componentDidMount() {
    this.renderChart();
    this.containerRef.current.scrollLeft = this.getScrollDistance();
  }

  componentDidUpdate(prevProps: Readonly<iHistoryChartProps>) {
    const isSegmentIdChanged = prevProps.activeSegmentId !== this.props.activeSegmentId;
    const isDateChanged = prevProps.activeCheckinDate !== this.props.activeCheckinDate;
    const isCheckinsChanged = prevProps.checkins !== this.props.checkins;

    if (isSegmentIdChanged || isCheckinsChanged || isDateChanged) {
      this.chartInstance.data = this.getChartData();
      // apply animations only if segment ID was changed
      this.chartInstance.update({ duration: isDateChanged ? 0 : 800 });
    }
  }

  render() {
    const { width, height } = this.props;

    return (
      <div className="history-chart-container" ref={this.containerRef}>
        <canvas id="history-chart" width={width} height={height} ref={this.chartRef} />
      </div>
    );
  }
}
