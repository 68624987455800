import * as actionTypes from '../Actions/ActionTypes';

const initialState = {
  viewModal: '',
  offsetWidth: document.querySelector('body').offsetWidth,
};

const width_mb = 576;
const width_sm = 760;

function windowReducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.RESIZE:
      const offsetWidth = action.payload;
      const viewModal = offsetWidth < width_mb ? 'mobile' : offsetWidth < width_sm ? 'desktop_small' : 'desktop';

      return {
        viewModal,
        offsetWidth,
      };
    default:
      return state;
  }
}

export default windowReducer;
