import { WHEEL_ACCESS_TYPES } from '../../../../constants';
import { WheelMemberRole } from '../../../../API/interfaces';

export const createShareLink = (wheel, role, userId) => {
  const baseUrl = window.location.origin;
  const wheelAccessLink = wheel.access === WHEEL_ACCESS_TYPES.PUBLIC ? `public/wheel/${wheel.id}` : 'share';
  const linkParams = new URLSearchParams({
    ...(wheel.access !== WHEEL_ACCESS_TYPES.PUBLIC ? { wheelId: wheel.id } : {}),
    role,
    ...(role === WheelMemberRole.ACCOUNTABILITY_BUDDY ? { invitingMemberId: userId } : {}),
  }).toString();
  return `${baseUrl}/${wheelAccessLink}?${linkParams}`;
};
