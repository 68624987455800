import styled from 'styled-components';
import { Comment } from './Comment';
import { iComment, iWheelMember } from '../../../API/interfaces';
import { CommentClient } from '../../../API/comments.client';
import { useLocation } from 'react-router-dom';
import { useEffect, useRef } from 'react';

interface iCommentItemProps {
  comment: iComment;
  teamAlertThreshold: number;
  members?: iWheelMember[];
  refresh: () => Promise<void>;
  isReplyEnabled: boolean;
}

export const Checkin = ({ comment, members, teamAlertThreshold, refresh, isReplyEnabled }: iCommentItemProps) => {
  const score = comment.averageScore || comment.score;
  const isRedFlag = +score < teamAlertThreshold;
  const member = members && members.find((m) => m.userId === comment.userId);
  const { state: { commentId = '' } = {} } = useLocation<{ commentId: string }>();
  const notificationRef = useRef(null);

  // if redirected from CommentCreated type Notification it will scroll to particular comment
  useEffect(() => {
    notificationRef?.current &&
      commentId &&
      notificationRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'nearest',
      });
  }, [commentId]);

  const onCreate = async (value) => {
    await CommentClient.createReply(comment.commentId, value);
    await refresh();
  };

  const onEdit = async (replyId, newValue) => {
    await CommentClient.editReply(comment.commentId, replyId, newValue);
    await refresh();
  };

  const onDelete = async (replyId) => {
    await CommentClient.deleteReply(comment.commentId, replyId);
    await refresh();
  };

  return (
    <CheckinContainer key={comment.date}>
      <AverageScoreWrapper>
        <AverageScore redFlag={isRedFlag}>{score}</AverageScore>
        <VerticalLine />
      </AverageScoreWrapper>

      <CommentWrapper>
        <Comment
          isMain={true}
          reply={comment}
          onCreate={onCreate}
          onEdit={onEdit}
          onDelete={onDelete}
          member={member}
          last={comment?.replies?.length === 0}
          isReplyEnabled={isReplyEnabled}
        />
        {comment?.replies?.map((reply, i) => {
          const replyOwner = members.find((m) => m.userId === reply.userId);
          return (
            <div ref={commentId === reply.id ? notificationRef : null} key={reply.id}>
              <Comment
                reply={reply}
                onCreate={onCreate}
                onEdit={onEdit}
                onDelete={onDelete}
                member={replyOwner}
                last={i === comment.replies.length - 1}
                isReplyEnabled={isReplyEnabled}
              />
            </div>
          );
        })}
      </CommentWrapper>
    </CheckinContainer>
  );
};

export const CommentText = styled.div`
  font-size: 14px;
  line-break: anywhere;
`;

const AverageScoreWrapper = styled.div`
  width: 80px;
  position: absolute;
  top: 6px;
  left: 0;
  height: 100%;
  display: flex;
  flex-flow: column;
  align-items: center;
  padding: 0 24px;
  z-index: 2;
  @media only screen and (max-device-width: 767px) {
    padding: 0 8px 0 24px;
    width: 58px;
  }
`;

const CommentWrapper = styled.div`
  width: 100%;
`;

const AverageScore = styled.div<any>`
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 50;
  width: 32px;
  height: 32px;
  font-size: 12px;
  font-weight: bold;
  border-radius: 50%;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.16);
  background-color: #ffffff;
  color: ${(props) => (props.redFlag ? '#fb463b' : 'inherit')};
`;

const VerticalLine = styled.div`
  position: absolute;
  top: 40px;
  height: calc(100% - 48px);
  bottom: 0;
  border-left: solid 0.5px #d1d1d2;
`;

const CheckinContainer = styled.div`
  display: flex;
  position: relative;
`;
