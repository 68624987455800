import React from 'react';
import { TextField, Typography, makeStyles } from '@material-ui/core';
import Section from '../common/Section';
import { MAX_DESCRIPTION_LENGTH, MAX_WHEEL_NAME_LENGTH } from '../../../../constants';
import TextFieldWithCounter from '../../../Shared/TextFieldWithCounter/TextFieldWithCounter';

interface iWheelNameStep {
  isTemplate: boolean;
  name: string;
  description: string;
  onChange: (filedName: string, value: any) => void;
}

export default ({ name, description, onChange, isTemplate }: iWheelNameStep) => {
  const classes = useStyles();

  return (
    <Section number={1} title={`What do you want the name of this ${isTemplate ? 'template' : 'wheel'} to be?`}>
      <TextFieldWithCounter
        variant="outlined"
        label={`${isTemplate ? 'Template' : 'Wheel'} Name`}
        value={name}
        onChange={(e) => onChange('name', e.target.value)}
        maxValueLength={MAX_WHEEL_NAME_LENGTH}
        required
      />

      <Typography className={classes.text}>
        You also can add description to this {isTemplate ? 'template' : 'wheel'}, if you want…
      </Typography>

      <TextFieldWithCounter
        variant="outlined"
        label={`${isTemplate ? 'Template' : 'Wheel'} Description (optional)`}
        value={description}
        onChange={(e) => onChange('description', e.target.value)}
        maxValueLength={MAX_DESCRIPTION_LENGTH}
        multiline
        rows={3}
      />
    </Section>
  );
};

const useStyles = makeStyles({
  wheelName: {
    width: '100%',
    borderColor: '#cececf',
    '& .MuiOutlinedInput-root.Mui-focused': {
      backgroundColor: '#f8f8f8',
    },
    '& input': {
      color: '#2b2e31',
    },
  },
  wheelDescriptionWrapper: {
    position: 'relative',
    marginTop: '10px',
  },
  wheelDescription: {
    width: '100%',
    borderColor: '#cececf',
    '& .MuiOutlinedInput-root': {
      paddingRight: '70px',
    },
    '& .MuiOutlinedInput-root.Mui-focused': {
      backgroundColor: '#f8f8f8',
    },
    '& input': {
      color: '#2b2e31',
    },
  },
  wheelDescriptionCounter: {
    position: 'absolute',
    top: '12px',
    right: '24px',
    fontSize: '12px',
    lineHeight: 1.67,
    color: '#6a777f',
    zIndex: 1,
  },
  wheelNameCounter: {
    position: 'absolute',
    top: 'calc(12px + 34px)',
    right: '24px',
    fontSize: '12px',
    lineHeight: 1.67,
    color: '#6a777f',
    zIndex: 1,
  },
  text: {
    marginTop: '16px',
    marginBottom: '10px',
    fontSize: '14px',
    lineHeight: 1.43,
    color: '#0c2337',
    '@media(max-device-width: 768px)': {
      fontSize: '12px',
      lineHeight: 1.67,
    },
  },
});
