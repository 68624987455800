const commonQuestions = [
  {
    id: 'common_1',
    title: 'How does it work?',
    subtitle: '1. Reflect',
    content: [
      'Stop and give yourself a moment to check in and honestly give yourself a score of where you are at with the areas of life.',
      'Leave a comment so you can understand your score in the future.',
    ],
  },
  {
    id: 'common_2',
    title: 'How does it work?',
    subtitle: '2. Connect',
    content: [
      'Connect and share your check ins with trusted friends or mentors.',
      "Connect with iyarn's support resources and professionals within the application.",
    ],
  },
  {
    id: 'common_3',
    title: 'How does it work?',
    subtitle: '3. Track',
    content: [
      'Overtime and through regular check ins you can identify trends and see your progress.',
      'Keep checkins regular to gain the best insights :-)',
    ],
  },
  {
    id: 'common_4',
    title: 'Why you should do it?',
    content: [
      'This process of Reflecting, Connecting and Tracking progress will help you develop better self-awareness and understand when you need support the most and get the support when needed to ensure you make better decisions and achieve your goals. \n\n Are you in?',
    ],
  },
];

export const questionnaireData = {
  Personal: [
    {
      id: 'personal_focus',
      name: 'focus',
      question: 'What are you interested in focusing on?',
      options: [
        'Physical activity',
        'Hobbies',
        'Sleep',
        'Mental health',
        'Nutrition',
        'Family',
        'Communication',
        'Career',
        'Calmness',
        'Confidence',
        'Self development',
        'Relationship',
      ],
      canSkipQuestion: false,
    },
    {
      id: 'personal_challenges',
      name: 'challenges',
      question: 'Do you have any challenges that you want to overcome?',
      options: [
        'Smoking',
        'Pornography',
        'Drinking alcohol',
        'Drug abuse',
        "I don't have any vices",
        'Unhealthy eating',
        'Being inactive',
        'Social media',
        'Not getting enough sleep',
      ],
      canSkipQuestion: false,
    },
    {
      id: 'personal_resources',
      name: 'resources',
      question: 'What support resources are you interested in?',
      options: [
        'Mental health',
        'Goal setting',
        'Peak Performance',
        'Fitness',
        'Relationships',
        'Sleep',
        'Recovery',
        'Nutrition',
        'General Health and wellbeing',
        'Managing Stress and Anxiety',
      ],
      canSkipQuestion: true,
    },
    {
      id: 'personal_goals',
      name: 'goals',
      question: 'What are your goals?',
      options: [
        'Exercise Regularly:\nAim for 30 minutes of moderate exercise 5 times a week',
        'Mindfulness Practice:\nSpend 10 minutes a day practising mindfulness or meditation',
        'Reach Out:\nContact a friend or family member at least once a week',
        'Read Daily:\nRead a book or an article for 20 minutes each day',
        'Gratitude Practice:\nWrite down three things you are grateful for each day',
        'Time Management:\nPlan and prioritise your tasks each morning',
        'Declutter:\nSpend 15 minutes each day organising your living space',
        'iyarn Check-In:\nComplete an iyarn check-in weekly to track your personal goals',
      ],
      canSkipQuestion: false,
    },
    ...commonQuestions,
  ],
  Professionals: [
    {
      id: 'prof_userRole',
      name: 'userRole',
      question: 'What type of work are you involved with that you may use iyarn for?',
      options: [
        'Clinician',
        'Psychologist',
        'General Practitioner',
        'Trainer',
        'Coach',
        'Mental Health Expert',
        'Facilitator',
      ],
      canSkipQuestion: false,
    },
    {
      id: 'prof_focus',
      name: 'focus',
      question: 'What are you interested in focusing on?',
      options: [
        'Case management',
        'Tracking engagement',
        'Measuring outcome',
        'Evaluation',
        'Supporting clients',
        'Gauge sentiment',
        'After hours support',
      ],
      canSkipQuestion: false,
    },
    {
      id: 'prof_challenges',
      name: 'challenges',
      question: 'Do you have any Challenges you think iyarn can assist with?',
      options: ['Tracking client outcomes', 'Measurement and evaluation', 'Report making', 'After hours support'],
      canSkipQuestion: false,
    },
    {
      id: 'prof_resources',
      name: 'resources',
      question: 'What support resources are you or your clients interested in?',
      options: ['Mental Health', 'Wellbeing', 'Nutrition', 'Finding their purpose', 'Personal Growth'],
      canSkipQuestion: false,
    },
    {
      id: 'prof_goals',
      name: 'goals',
      question: 'What are your goals?',
      options: [
        'Meet KPIs:\nAchieve monthly KPIs by setting and reviewing targets each week',
        'Professional Learning:\nDedicate 1 hour each week to professional development courses or training',
        'Networking:\nConnect with at least one new professional contact each month',
        'Team Meetings:\nHold a 30-minute team meeting once a week to discuss progress and challenges',
        'Wellness Activities:\nEngage in a wellness activity, like yoga or meditation, for 20 minutes daily',
        'Work Boundaries:\nSet clear boundaries between work and personal time',
        'Knowledge Sharing:\nPresent a knowledge-sharing session or workshop once a quarter',
      ],
      canSkipQuestion: false,
    },
    ...commonQuestions,
  ],
  Education: [
    {
      id: 'edu_userRole',
      name: 'userRole',
      question: 'What type of role do you play in Education sector?',
      options: [
        'Secondary School Student',
        'Primary School Student',
        'University Student',
        'Apprentice',
        'Group Training Organisation',
        'Lecturer',
        'Primary School Teacher',
        'Secondary School Teacher',
      ],
      canSkipQuestion: false,
    },
    {
      id: 'edu_focus',
      name: 'focus',
      question: 'What are you interested in focusing on?',
      options: [
        'Wellbeing',
        'Staff Wellbeing',
        'Student Wellbeing',
        'Student Performance',
        'Staff Engagement',
        'Staff Appraisals',
        'Mental Health',
        'Quality Control',
      ],
      canSkipQuestion: false,
    },
    {
      id: 'edu_resources',
      name: 'resources',
      question: 'What support resources are you or your Students interested in?',
      options: ['Wellbeing', 'Mental Health', 'Sleep hygiene', 'Managing Stress', 'Asking for help'],
      canSkipQuestion: false,
    },
    {
      id: 'edu_goals',
      name: 'goals',
      question: 'What are your goals?',
      options: [
        'Study Routine:\nSpend 2 hours daily on focused study sessions',
        'New Skills:\nDedicate 30 minutes daily to learning a new academic skill or subject',
        'Study Groups:\nJoin or form a study group and meet weekly',
        'Mindfulness:\nPractise mindfulness or meditation for 10 minutes daily',
        'Assignments:\nComplete and submit assignments at least 2 days before the deadline',
        'Peer Check-Ins:\nUse iyarn to check in with your accountability buddy weekly',
        'Extra-Curricular:\nParticipate in at least one extra-curricular activity each term',
      ],
      canSkipQuestion: false,
    },
    ...commonQuestions,
  ],
  Workplace: [
    {
      id: 'work_userRole',
      name: 'userRole',
      question: 'How do you think iyarn could be useful for you in your workplace?',
      options: [
        'Staff engagement',
        'Value alignment',
        'Staff appraisals',
        '180 degree feedback',
        'Staff wellbeing',
        'Staff sentiment',
        'Staff pulse checks',
        'Toolbox check ins',
        'Work Health and Safety',
        'Psychological safety',
      ],
      canSkipQuestion: true,
    },
    {
      id: 'work_focus',
      name: 'focus',
      question: 'What are you interested in focusing on?',
      options: [
        'Human Resources',
        'Workplace culture',
        'Facilitation',
        'Wellbeing',
        'Mental Health',
        'Company value alignment',
        'Employment Assist Programs',
      ],
      canSkipQuestion: true,
    },
    {
      id: 'work_challenges',
      name: 'challenges',
      question: 'Do you have any high risk situations that you feel iyan could assist with mitigating?',
      options: ['Mental Health', 'Psychological safety', 'Workplace Health & safety', 'None'],
      canSkipQuestion: false,
    },
    {
      id: 'work_goals',
      name: 'goals',
      question: 'What are your goals?',
      options: [
        'Task Management:\nPrioritise and plan daily tasks each morning',
        'Training:\nParticipate in a professional development training session once a month',
        'Team Collaboration:\nWork on a team project or initiative monthly to enhance teamwork',
        'Breaks:\nTake a 5-minute break every hour during work',
        'Support Systems:\nUse iyarn to check in with your direct line manager weekly for support',
        'Wellness Activities:\nParticipate in wellness programs or activities at least once a month',
        'Employee Feedback:\nProvide constructive feedback to team members in a weekly iyarn check-in',
        'iyarn Check-In:\nComplete an iyarn check-in with your team weekly',
      ],
      canSkipQuestion: true,
    },
    ...commonQuestions,
  ],
  Other: [
    {
      id: 'other_reason',
      name: 'reason',
      question: 'Is there a reason you want to sign up to iyarn?',
      options: [
        "I'm part of a program",
        'Not sure',
        'A friend said I should',
        'Wellbeing',
        'Mental Health',
        'Personal development',
        'Growth',
        'Be a better friend',
        'Support someone',
        'Get more aligned with my values',
      ],
      canSkipQuestion: false,
    },
    {
      id: 'other_goals',
      name: 'goals',
      question: 'What are your goals?',
      options: [
        'Volunteering:\nParticipate in a community service activity at least once a month',
        'Learning:\nDedicate 20 minutes daily to reading or learning something new',
        'Exercise:\nAim for 30 minutes of moderate exercise 5 times a week',
        'Gratitude:\nWrite down three things you are grateful for each day',
        'Work-Life Balance:\nMaintain a consistent work schedule and avoid working overtime regularly',
        'Nature Time:\nSpend 30 minutes in nature or a green space each week',
        'Sustainability:\nImplement one new eco-friendly habit, like recycling or reducing plastic use',
        'iyarn Check-In:\nUse iyarn to check in with your personal goals weekly',
      ],
      canSkipQuestion: false,
    },
    ...commonQuestions,
  ],
};

export const countries = [
  'Afghanistan',
  'Albania',
  'Algeria',
  'Andorra',
  'Angola',
  'Antigua and Barbuda',
  'Argentina',
  'Armenia',
  'Australia',
  'Austria',
  'Azerbaijan',
  'Bahamas',
  'Bahrain',
  'Bangladesh',
  'Barbados',
  'Belarus',
  'Belgium',
  'Belize',
  'Benin',
  'Bhutan',
  'Bolivia',
  'Bosnia and Herzegovina',
  'Botswana',
  'Brazil',
  'Brunei',
  'Bulgaria',
  'Burkina Faso',
  'Burundi',
  'Cabo Verde',
  'Cambodia',
  'Cameroon',
  'Canada',
  'Central African Republic',
  'Chad',
  'Chile',
  'China',
  'Colombia',
  'Comoros',
  'Congo',
  'Costa Rica',
  'Croatia',
  'Cuba',
  'Cyprus',
  'Czech Republic',
  'Denmark',
  'Djibouti',
  'Dominica',
  'Dominican Republic',
  'Ecuador',
  'Egypt',
  'El Salvador',
  'Equatorial Guinea',
  'Eritrea',
  'Estonia',
  'Eswatini',
  'Ethiopia',
  'Fiji',
  'Finland',
  'France',
  'Gabon',
  'Gambia',
  'Georgia',
  'Germany',
  'Ghana',
  'Greece',
  'Grenada',
  'Guatemala',
  'Guinea',
  'Guinea-Bissau',
  'Guyana',
  'Haiti',
  'Honduras',
  'Hungary',
  'Iceland',
  'India',
  'Indonesia',
  'Iran',
  'Iraq',
  'Ireland',
  'Israel',
  'Italy',
  'Jamaica',
  'Japan',
  'Jordan',
  'Kazakhstan',
  'Kenya',
  'Kiribati',
  'Kuwait',
  'Kyrgyzstan',
  'Laos',
  'Latvia',
  'Lebanon',
  'Lesotho',
  'Liberia',
  'Libya',
  'Liechtenstein',
  'Lithuania',
  'Luxembourg',
  'Madagascar',
  'Malawi',
  'Malaysia',
  'Maldives',
  'Mali',
  'Malta',
  'Marshall Islands',
  'Mauritania',
  'Mauritius',
  'Mexico',
  'Micronesia',
  'Moldova',
  'Monaco',
  'Mongolia',
  'Montenegro',
  'Morocco',
  'Mozambique',
  'Myanmar',
  'Namibia',
  'Nauru',
  'Nepal',
  'Netherlands',
  'New Zealand',
  'Nicaragua',
  'Niger',
  'Nigeria',
  'North Korea',
  'North Macedonia',
  'Norway',
  'Oman',
  'Pakistan',
  'Palau',
  'Palestine State',
  'Panama',
  'Papua New Guinea',
  'Paraguay',
  'Peru',
  'Philippines',
  'Poland',
  'Portugal',
  'Qatar',
  'Romania',
  'Russia',
  'Rwanda',
  'Saint Kitts and Nevis',
  'Saint Lucia',
  'Saint Vincent and the Grenadines',
  'Samoa',
  'San Marino',
  'Sao Tome and Principe',
  'Saudi Arabia',
  'Senegal',
  'Serbia',
  'Seychelles',
  'Sierra Leone',
  'Singapore',
  'Slovakia',
  'Slovenia',
  'Solomon Islands',
  'Somalia',
  'South Africa',
  'South Korea',
  'South Sudan',
  'Spain',
  'Sri Lanka',
  'Sudan',
  'Suriname',
  'Sweden',
  'Switzerland',
  'Syria',
  'Tajikistan',
  'Tanzania',
  'Thailand',
  'Timor-Leste',
  'Togo',
  'Tonga',
  'Trinidad and Tobago',
  'Tunisia',
  'Turkey',
  'Turkmenistan',
  'Tuvalu',
  'Uganda',
  'Ukraine',
  'United Arab Emirates',
  'United Kingdom',
  'United States of America',
  'Uruguay',
  'Uzbekistan',
  'Vanuatu',
  'Venezuela',
  'Vietnam',
  'Yemen',
  'Zambia',
  'Zimbabwe',
];

export const toolTips = {
  goals:
    'Here you can set clear and achievable goals that will help you stay focussed on what matters most to you. These goals will help us tailor support resources to assist you on your journey to achieving these goals. (Pro tip choose S.M.A.R.T goals)',
  focus:
    'Here you can choose different focus areas or themes that you want to prioritise in your life. These focus areas help inform our support resources to better understand your specific needs and speak to you with this focus in mind.',
  challenges:
    'Here you can select any obstacles or difficulties you are trying to overcome, you can even write your own challenges. Identifying your challenges helps iyarn provide targeted support with this in mind. (We know this is super private so only you have access to these answers that you can chance in the future)',
  resources:
    'Here you can select the types of support resources you are interested in. Identifying your preferred resources helps us tailor our recommendations and support resources to best assist you.',
  reason:
    'Here you can select the reasons why you want to sign up to iyarn. Understanding your motivations helps us provide a more personalised experience and tailor our support to better suit your needs.',
};
