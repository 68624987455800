import { ToastActions } from '../../../Redux/Actions/ActionTypes';

export const toastsReducer = (state = [], action: { type: string; payload: any }) => {
  const { payload, type } = action;

  switch (type) {
    case ToastActions.ADD_TOAST:
      return [payload, ...state];

    case ToastActions.REMOVE_TOAST:
      return state.filter((toast: any) => toast.id !== payload);

    default:
      return state;
  }
};
