import React from 'react';
import './CheckinButton.scss';

interface iCheckinButtonProps {
  onClick: () => void;
  className?: string;
}

export default ({ onClick, className }: iCheckinButtonProps) => (
  <div className={`checkin-button${className ? ` ${className}` : ''}`} onClick={onClick}>
    Complete Check In
  </div>
);
