import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { ArrowRight } from 'react-feather';
import { AccountButton, RedBtn, RedButtonBox, ContentBox, LeftPanel, Root } from './_styled';
import { RightScreen } from './RightScreen';
import { Text } from '../../Shared/Typography/Typography';
import { StaticContentScreen } from './StaticContentScreen';

const AccountCheck: React.FC = () => {
  const [hasAccount, setHasAccount] = useState<boolean | null>(null);
  const [showStaticContent, setShowStaticContent] = useState(false);
  const history = useHistory();

  const redirectToLogin = () => {
    history.push('/login');
  };

  const redirectToSignUp = () => {
    history.push('/sign-up');
  };

  const handleOptionClick = (hasAccount: boolean) => {
    setHasAccount(hasAccount);
  };

  const handleNextClick = () => {
    if (hasAccount) {
      redirectToLogin();
    } else {
      setShowStaticContent(true);
    }
  };

  const handleBackClick = () => {
    setShowStaticContent(false);
    setHasAccount(null);
  };

  return (
    <Root>
      {!showStaticContent ? (
        <>
          <LeftPanel>
            <ContentBox style={{ justifyContent: 'center' }}>
              <Text className="mb-2" weight={600} size="36px" color="#2b2e31">
                Do you have iyarn account?
              </Text>
              <AccountButton selected={hasAccount === true} onClick={() => handleOptionClick(true)}>
                <Text weight={500} size="16px">
                  Yes, I have
                </Text>
              </AccountButton>
              <AccountButton selected={hasAccount === false} onClick={() => handleOptionClick(false)}>
                <Text weight={500} size="16px">
                  No, it's my first time here
                </Text>
              </AccountButton>

              {hasAccount !== null && (
                <RedButtonBox>
                  <RedBtn onClick={handleNextClick}>
                    NEXT <ArrowRight size={13} />
                  </RedBtn>
                </RedButtonBox>
              )}
            </ContentBox>
          </LeftPanel>
          <RightScreen />
        </>
      ) : (
        <StaticContentScreen
          onBack={handleBackClick}
          onNext={redirectToSignUp}
          title={null}
          subtitle="Nice to meet you here 👋"
          description="We exist to support users through hard times and level up when times are good. <br/><br/>We have a few questions for you to answer to set up your account."
          showTitle={false}
          showSkipButton={false}
        />
      )}
    </Root>
  );
};

export default AccountCheck;
