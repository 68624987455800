import React, { useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { Bell as BellIcon } from 'react-feather';
import { Expandable } from '../../../Dashboard/Expandable';
import services from '../../../../API/services';
import { useDispatch, useSelector } from 'react-redux';
import { getNotificationsSuccess } from './Notifications.actions';
import { selectNotifications } from './Notifications.selectors';
import { DesktopView, MobileView } from '../../DisplayTypeView';

type Props = {
  isNotificationDrawerOpen: boolean;
  bellIconColor: string;
  toggleNotificationsDrawer: (e) => void;
  onMouseEnterNotificationsButton: () => void;
  onMouseLeaveNotificationsButton: () => void;
};

const ICON_SIZE = '20px';
const INACTIVE_EXPANDABLE_CONTAINER_STYLE =
  'background-color: #fff; color: #b1b1b1; box-shadow: 0 6px 20px 0 rgba(35, 55, 72, 0.1);';

const NotificationWrapper = styled.div`
  margin-right: 16px;
  cursor: pointer;
  position: relative;

  @media (max-width: 768px) {
    margin-right: 8px;
  }
`;

const StyledBellIcon = styled(BellIcon)`
  position: absolute;
  top: calc(50% - ${ICON_SIZE} / 2);
  left: calc(${ICON_SIZE} / 2);
  height: ${ICON_SIZE};
  width: ${ICON_SIZE};
`;

const NotificationsLabel = styled.span`
  position: absolute;
  top: calc(50% - 12px);
  left: calc(${ICON_SIZE} * 2);
`;

export const Badge = styled.div<{ isShown: boolean; size?: string; rightPosition?: number; topPosition?: number }>`
  display: ${({ isShown }) => (isShown ? 'block' : 'none')};
  width: ${({ size }) => size || '12px'};
  height: ${({ size }) => size || '12px'};
  border-radius: 50%;
  background: #ff554d;
  position: absolute;
  top: ${({ topPosition }) => topPosition || 0};
  right: ${({ rightPosition }) => rightPosition || 0};
  z-index: 1;
`;

const MobileNotificationIconWrapper = styled.div`
  background-color: #fff;
  box-shadow: 0 6px 20px 0 rgba(35, 55, 72, 0.1);
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const NotificationsButton: React.FC<Props> = ({
  isNotificationDrawerOpen,
  bellIconColor,
  toggleNotificationsDrawer,
  onMouseEnterNotificationsButton,
  onMouseLeaveNotificationsButton,
}) => {
  const dispatch = useDispatch();
  const { notificationsList } = useSelector(selectNotifications);

  const isNonReadNotifications = notificationsList.some(({ read }) => !read);

  useEffect(() => {
    services
      .getNotifications()
      .then(({ data }) => dispatch(getNotificationsSuccess(data)))
      .catch((error) => error);
  }, []);

  const DisplayTypeNotificationButton = useMemo(() => {
    return (
      <>
        <DesktopView>
          <Expandable
            width="138px"
            onClick={toggleNotificationsDrawer}
            isExpendedOnActive={isNotificationDrawerOpen}
            inactiveContainerStyle={INACTIVE_EXPANDABLE_CONTAINER_STYLE}
            containerHeight="40px"
            containerWidth="40px"
          >
            <div>
              <StyledBellIcon color={bellIconColor} />
              <NotificationsLabel>Notifications</NotificationsLabel>
            </div>
          </Expandable>
        </DesktopView>
        <MobileView>
          <MobileNotificationIconWrapper onClick={(e) => toggleNotificationsDrawer(e)}>
            <BellIcon color="#b1b1b1" width="15px" height="15px" />
          </MobileNotificationIconWrapper>
        </MobileView>
      </>
    );
  }, [isNotificationDrawerOpen, bellIconColor]);

  return (
    <NotificationWrapper onMouseEnter={onMouseEnterNotificationsButton} onMouseLeave={onMouseLeaveNotificationsButton}>
      <Badge isShown={isNonReadNotifications} />
      {DisplayTypeNotificationButton}
    </NotificationWrapper>
  );
};

export default NotificationsButton;
