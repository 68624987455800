import { iFolder, iWheelExt } from '../../API/interfaces';

export enum FolderActions {
  LOAD_FOLDER = 'LOAD_FOLDER',
  LOAD_FOLDER_SUCCESS = 'LOAD_FOLDER_SUCCESS',
  LOAD_FOLDER_FAIL = 'LOAD_FOLDER_FAIL',
  UPDATE_FOLDER = 'UPDATE_FOLDER',
  UPDATE_FOLDER_SUCCESS = 'UPDATE_FOLDER_SUCCESS',
  UPDATE_FOLDER_FAIL = 'UPDATE_FOLDER_FAIL',
}

export const loadFolders = () => ({
  type: FolderActions.LOAD_FOLDER,
});

export const loadFoldersSuccess = ({ folders }: { folders: iFolder[] }) => ({
  type: FolderActions.LOAD_FOLDER,
  payload: { folders },
});

export const loadFoldersFail = ({ error }: { error: any }) => ({
  type: FolderActions.LOAD_FOLDER,
  error,
});

export const updateFolder = ({ folder }: { folder: Partial<iFolder> }) => ({
  type: FolderActions.UPDATE_FOLDER,
  payload: { folder },
});

export const updateFolderSuccess = ({ folder }: { folder: iFolder }) => ({
  type: FolderActions.UPDATE_FOLDER_SUCCESS,
  payload: { folder },
});

export const updateFolderFail = ({ error }: { error: any }) => ({
  type: FolderActions.UPDATE_FOLDER_FAIL,
  error,
});

export enum WheelActions {
  LOAD_WHEELS = 'LOAD_WHEELS',
  LOAD_WHEELS_SUCCESS = 'LOAD_WHEELS_SUCCESS',
  LOAD_WHEELS_FAIL = 'LOAD_WHEELS_FAIL',
  UPDATE_WHEEL = 'UPDATE_WHEEL',
  UPDATE_WHEEL_SUCCESS = 'UPDATE_WHEEL_SUCCESS',
  UPDATE_WHEEL_FAIL = 'UPDATE_WHEEL_FAIL',
}

export const loadWheels = () => ({
  type: WheelActions.LOAD_WHEELS,
});

export const loadWheelsSuccess = ({ wheels }: { wheels: iWheelExt[] }) => ({
  type: WheelActions.LOAD_WHEELS_SUCCESS,
  payload: { wheels },
});

export const loadWheelsFail = ({ error }: { error: any }) => ({
  type: WheelActions.LOAD_WHEELS_FAIL,
  error,
});

export const updateWheel = ({ wheel }: { wheel: Partial<iWheelExt> }) => ({
  type: WheelActions.UPDATE_WHEEL,
  payload: { wheel },
});

export const updateWheelSuccess = ({ wheel }: { wheel: iWheelExt }) => ({
  type: WheelActions.UPDATE_WHEEL_SUCCESS,
  payload: { wheel },
});

export const updateWheelFail = ({ error }: { error: any }) => ({
  type: WheelActions.UPDATE_WHEEL_FAIL,
  error,
});
