import React from 'react';
import { Button, CancelButton } from '../Shared/Buttons/Button';
import DownloadApp from '../Shared/DownloadApp/DownloadApp';

interface iMobileStepProps {
  linkParams: string;
  onCancel: () => void;
}

export default ({ linkParams, onCancel }: iMobileStepProps) => (
  <div className="text-center">
    <div className="row mb-px-3">
      <div className="col-12">Open in mobile application?</div>
    </div>
    <div className="row">
      <div className="col-12">
        <CancelButton onClick={onCancel} className="mr-2">
          Open in browser
        </CancelButton>
        <Button>
          <a className="text-white font-weight-bold" href={`iyarn://share${linkParams}`}>
            Yes
          </a>
        </Button>
      </div>
    </div>

    <div className="row mt-5">
      <div className="col-12">
        <DownloadApp isMobile={true} description="Please, download the app if you don’t have it yet" />
      </div>
    </div>
  </div>
);
