import { pathEq, pathSatisfies } from 'ramda';
import { AccountPlan, AccountType, PersonalData, iAccount } from '../API/interfaces';

namespace nUser {
  export interface iUser {
    _id: string;
    token: string;
    email: string;
    first_name: string;
    last_name: string;
    createdAt: string;
    updatedAt: string;
    enterprise: boolean;
    accountTag: AccountType;
    account: iAccount;
    personalData: PersonalData;
  }

  const accountPlanPath = ['account', 'plan'];

  export const accountPlanEq = pathEq(accountPlanPath);

  const FOLDER_ACCESSIBLE_ACCOUNTS = [AccountPlan.BUSINESS, AccountPlan.SCHOOL, AccountPlan.PERFORMANCE];
  // TODO map user on fetch to class with hasAccessToFolders
  export const hasAccessToFolders = pathSatisfies(
    (v: AccountPlan) => FOLDER_ACCESSIBLE_ACCOUNTS.includes(v),
    accountPlanPath
  );
}

export default nUser;
