import styled from 'styled-components';

export const Padding = styled.div<any>`
  padding: ${(props) => props.padding};
  @media only screen and (max-device-width: 767px) {
    padding: ${(props) => props.mPadding};
  }
`;

Padding.defaultProps = {
  padding: '34px',
  mPadding: '24px 10px',
};
