import { bindActionCreators } from 'redux';
import { store } from '../../Redux/store';
import { loginAction, logoutAction, updatePersonalDataAction } from './Auth.actions';
import { joinLinkAction } from '../JoinWheel/JoinWheel.actions';
import { alertModal } from '../../Redux/Actions/Actions';
import { HttpClient } from '../../API/HttpClient';

class AuthService {
  actions = bindActionCreators(
    { loginAction, logoutAction, joinLinkAction, alertModal, updatePersonalDataAction },
    store.dispatch
  );

  public login(user) {
    const { loginAction, alertModal } = this.actions;
    alertModal(null);
    loginAction(user);
  }

  public logout() {
    const { logoutAction, joinLinkAction } = this.actions;
    logoutAction();
    joinLinkAction(null);
  }

  public async updateUserData() {
    const res = await this.me();
    if (res.status === 200) {
      this.login({
        token: res.data.access_token,
        ...res.data.user,
      });
      return res.data.user;
    }
  }

  public async me() {
    return HttpClient.get(`/users/me`).catch((error) => error.response);
  }

  public async updatePersonalData(personalData) {
    const { updatePersonalDataAction } = this.actions;
    updatePersonalDataAction(personalData);
  }
}

export const authService = new AuthService();
