import React from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import { iSegment } from '../../../../API/interfaces';
import Section from '../common/Section';
import { MAX_SEGMENTS } from '../../../../constants';
import AddNewSegment from './AddNewSegment';
import DefaultSegmentsList from './DefaultSegmentsList';
import SegmentsList from './SegmentsList';

interface iSegmentsStep {
  isTemplate: boolean;
  segments: Array<Partial<iSegment>>;
  defaultSegments: Array<Partial<iSegment>>;
  onChange: (filedName: string, value: any) => void;
}

export default ({ segments, defaultSegments, onChange, isTemplate }: iSegmentsStep) => {
  const classes = useStyles();

  const addSegmentToList = (segment: Partial<iSegment>) =>
    segments.length < MAX_SEGMENTS && onChange('segments', [...segments, segment]);
  const removeSegmentFromList = (segment: Partial<iSegment>) =>
    onChange(
      'segments',
      segments.filter((s) => s !== segment)
    );

  const addNewSegmentToList = (newSegmentName: string) =>
    addSegmentToList({ name: newSegmentName, description: null, _id: `${new Date().getTime()}` });

  const onChangeCheckBox = (segment: Partial<iSegment>) =>
    segments.indexOf(segment) === -1 ? addSegmentToList(segment) : removeSegmentFromList(segment);

  return (
    <Section number={2} title={`What segments do you want your ${isTemplate ? 'template' : 'wheel'} to include?`}>
      <AddNewSegment onAdd={addNewSegmentToList} />

      <Typography className={classes.text}>Or select segment from this list:</Typography>

      <DefaultSegmentsList defaultSegments={defaultSegments} segments={segments} onChangeCheckBox={onChangeCheckBox} />

      <Typography className={classes.segmentsCounter}>
        Total segments: {segments.length}/<span className={classes.maxSegments}>{MAX_SEGMENTS}</span>
      </Typography>

      <SegmentsList segments={segments} removeSegmentFromList={removeSegmentFromList} />
    </Section>
  );
};

const useStyles = makeStyles({
  text: {
    marginTop: '24px',
    fontSize: '14px',
    lineHeight: 1.43,
    color: '#0c2337',
  },
  segmentsCounter: {
    marginTop: '20px',
    fontSize: '14px',
    lineHeight: 1.43,
    color: '#0c2337',
  },
  maxSegments: {
    color: '#6e7b83',
  },
});
